import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Select,
  Space,
  Table,
  message,
} from "antd";

import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SaveFilled,
  SettingOutlined,
} from "@ant-design/icons";
import { Link, useHistory, useParams } from "react-router-dom";
import { CompanyContext } from "../../contexts/CompanyContext";
import { JSON_API } from "../../services/Constants";

import { useTranslation } from "react-i18next";

import { Card, Col, Row } from "antd";
import axios from "axios";
// import "./Tableau.css";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
const { Option } = Select;

function GIproduct() {
  let { t } = useTranslation();
  const { Company } = useContext(CompanyContext);

  const [messageApi, contextHolder] = message.useMessage();
  const { id } = useParams();

  const [TypeIndustries, setTypeIndustries] = useState([{}]);

  const [editingRow, setEditingRow] = useState(null);
  const { TextArea } = Input;

  useEffect(() => {
    getProduct();
  }, []);

  const getProduct = async () => {
    await axios
      .get(`${JSON_API}/Products/enterprise/${Company.id}`)
      .then((response) => {
        setTypeIndustries(response.data);
        console.log("Products", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDelete = async (e) => {
    console.log(e);
    await axios.delete(`${JSON_API}/Products/${e}`);
    message.success(t("Productsdeletedsuccessfully"));
    getProduct();
  };

  const submite = async (e) => {
    const formData = {
      label: e.label,
      enterpriseId: Company.id,
    };

    console.log("form", formData);
    axios
      .post(`${JSON_API}/Products`, formData)
      .then((res) => {
        console.log("Products", res);
        getProduct();

        console.log(res.data);
        setOpen(!open);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onFinishEdit = async (e) => {
    const type = {
      id: editingRow,
      label: e.label,
    };

    console.log("Productsform: ", type);

    await axios.put(`${JSON_API}/Products`, type).then((response) => {
      getProduct();
      console.log("ProductsupdatedSuccessfully");

      messageApi.open({
        type: "success",
        content: `${t("ProductsupdatedSuccessfullyy")}`,
      });
    });

    setEditingRow(null);
  };

  const [form] = Form.useForm();
  const [form1] = Form.useForm();

  const [open, setOpen] = useState(false);

  const CollectionCreateForm = ({ open, onCreate, onCancel }) => {
    return (
      <Modal
        forceRender
        open={open}
        title={t("AddProducttt")}
        okText={t("Create")}
        cancelText={t("Cancel")}
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onCreate(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
        >
          <Form.Item
            name="label"
            label={t("Label")}
            rules={[
              {
                required: true,
                message: `${t("Pleaseenterlabel")}`,
              },
            ]}
          >
            <Input
              style={{
                width: 470,
              }}
            ></Input>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const showNameModal = (name) => {
    setSelectedName(name);
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedName(null);
  };
  const columns = [
    {
      title: <h1 style={{ textAlign: "center" }}>{t("Label")}</h1>,
      align: "left",
      render: (_, record) => {
        if (editingRow === record.id) {
          return (
            <Form.Item
              name="label"
              rules={[
                {
                  required: true,
                  message: `${t("Pleaseenterlabel")}`,
                },
              ]}
            >
              <Input
                style={{
                  width: 300,
                }}
              />
            </Form.Item>
          );
        } else {
          return <div style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}onClick={() => showNameModal(record.label)}>{record.label}</div>;
        }
      },
    },

    {
      title: <h3>Actions</h3>,
      key: "operation",
      align: "center",
      width: 300,

      render: (_, record) => (
        <Space size="middle">
          {editingRow === record.id ? (
            <>
              <Button
                type="primary"
                onClick={() =>
                  form1.validateFields().then((values) => {
                    onFinishEdit(values);
                  })
                }
              >
                {t("save")}
              </Button>
              <Button
                type="link"
                danger
                icon={<CloseOutlined />}
                onClick={() => setEditingRow(null)}
              >
                {t("cancel")}
              </Button>
            </>
          ) : (
            <>
              <Button
                type="link"
                onClick={() => {
                  setEditingRow(record.id);
                  form1.setFieldsValue({
                    label: record.label,
                  });
                }}
              >
                <EditOutlined />
                {t("edit")}{" "}
              </Button>
              {/* <Popconfirm
                title={t("deleterow")}
                onConfirm={() => handleDelete(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link " danger>
                  <DeleteOutlined />
                  {t("Delete")}
                </Button>
              </Popconfirm> */}
            </>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <Card>
        <Row justify="space-between">
          <Space style={{ marginBottom: 15 }}>
            <Button
              className="Create-button"
              type="link"
              onClick={() => {
                setOpen(true);
              }}
            >
              <PlusOutlined />
              {t("ProductsServicesButton")}
              {/* <PlusOutlined /> {t("Create")} */}
            </Button>
            <CollectionCreateForm
              open={open}
              onCreate={submite}
              onCancel={() => {
                setOpen(false);
                // onAddChamp();
              }}
            />
          </Space>
        </Row>
        <Form form={form1} onFinish={onFinishEdit}>
          <div style={{ overflowX: "auto" }}>
          <Table bordered columns={columns} dataSource={TypeIndustries} />
          </div>
          <Modal
          title={t("Name")}
          visible={isModalVisible}
          onOk={closeModal}
          onCancel={closeModal}
        >
          <p>{selectedName}</p>
        </Modal>
        </Form>
      </Card>
    </>
  );
}

export default GIproduct;

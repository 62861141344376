import React, { useContext, useEffect, useState } from "react";
import {
  AppstoreOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  PlusSquareOutlined,
  RollbackOutlined,
  DollarOutlined,
  SaveOutlined,
  PlusCircleFilled,
  CloseCircleFilled,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Table,
  Tag,
  Popconfirm,
  Col,
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Tabs,
  Typography,
  message,
} from "antd";
import { Link, useHistory, useParams } from "react-router-dom";
import { CompanyContext } from "../../contexts/CompanyContext";
import { JSON_API } from "../../services/Constants";
import { useTranslation } from "react-i18next";
import axios from "axios";

const { Option } = Select;

const FinancialStatementCategory = () => {
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedClassId, setSelectedClassId] = useState(null);
  const [financialStatementClass, setFinancialStatementClass] = useState([{}]);
  const [financialStatementCategory, setFinancialStatementCategory] = useState(
    []
  );
  const [editingRow, setEditingRow] = useState(null);
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);

  const handleDelete = async (financialStatementCategoryId) => {
    console.log(financialStatementCategoryId);
    await axios.delete(
      `${JSON_API}/FinancialStatementCategory/${financialStatementCategoryId}`
    );
    message.success(t("Categorydeletedsuccessfully"));
    fetchFinancialStatementCategories();
  };

  const getFinancialStatementClasses = async () => {
    await axios
      .get(`${JSON_API}/FinancialStatementClass`)
      .then((res) => {
        console.log("Financial Statement Class : ", res.data);
        setFinancialStatementClass(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchFinancialStatementCategories();
    getFinancialStatementClasses();
  }, []);

  useEffect(() => {
    if (selectedClassId) {
      fetchFinancialStatementCategories(selectedClassId);
    }
  }, [selectedClassId]);

  const onFinishEdit = async () => {
    if (!currentRecord) {
      message.error("No record selected for editing.");
      return;
    }
    try {
      const values = await form.validateFields();
      const dataUpdated = {
        id: currentRecord?.id,
        label: values.label,
        labelFr: values.labelFr,
        financialStatementClassId:
          values.financialStatementClassId ||
          currentRecord?.financialStatementClassId,
      };

      if (!dataUpdated.id) {
        throw new Error("No record is being edited.");
      }

      const response = await axios.put(
        `${JSON_API}/FinancialStatementCategory`,
        dataUpdated
      );
      if (response.status === 200) {
        message.success(`${t("CategoryUpdatedSuccessfully")}`);
        fetchFinancialStatementCategories(selectedClassId);
        setEditingRow(null);
        setCurrentRecord(null);
      } else {
        throw new Error("Response not successful");
      }
    } catch (error) {
      console.error("Failed to update the category", error);
      message.error(`${t("UpdateFailed")}`);
      setEditingRow(null);
      setCurrentRecord(null);
    }
  };

  const fetchFinancialStatementCategories = async (classId) => {
    if (!classId) {
      setLoading(false);
      message.info(t("PleaseSelectClassToDisplayTheCategories"));
      return;
    }

    setLoading(true);
    try {
      let url = `${JSON_API}/FinancialStatementCategory/class/${classId}`;
      const response = await axios.get(url);
      setFinancialStatementCategory(response.data);
      console.log("financial statement categories :", response.data);
    } catch (error) {
      console.error("Failed to fetch financial statement categories", error);
      message.error(t("SomethingWentWrongWhileRecuperingCategories"));
    } finally {
      setLoading(false);
    }
  };

  const handleClassChange = (value) => {
    fetchFinancialStatementCategories(value);
  };

  const Submit = async (e) => {
    const formData = {
      label: e.label,
      labelFr: e.labelFr,
      financialStatementClassId: e.financialStatementClassId,
    };

    console.log("Financial Statement Category", formData);

    await axios
      .post(`${JSON_API}/FinancialStatementCategory`, formData)
      .then((res) => {
        fetchFinancialStatementCategories();
        console.log("DATA", res.data);
        setOpen(!open);
        message.success(`${t("CategoryCreatedSuccessfully")}`);
      })
      .catch(function (error) {
        console.log(error);
        message.error(`${t("SomethingWentWrongWhileCreatingCategory")}`);
      });
  };

  const showModal = (record) => {
    setCurrentRecord(record);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    await onFinishEdit();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: <h1 style={{ textAlign: "center" }}>{t("labelEn")}</h1>,
      align: "left",
      render: (_, record) => {
        return editingRow === record.id ? (
          <Form.Item
            name="label"
            rules={[
              {
                required: true,
                message: `${t("pleaseinputthelabelinEnglish")}`,
              },
            ]}
          >
            <Input style={{ width: 300 }} />
          </Form.Item>
        ) : (
          <div style={{ textAlign: "left" }}>{record.label}</div>
        );
      },
    },
    {
      title: <h1 style={{ textAlign: "center" }}>{t("labelFr")}</h1>,
      align: "left",
      render: (_, record) => {
        return editingRow === record.id ? (
          <Form.Item
            name="labelFr"
            rules={[
              {
                required: true,
                message: `${t("pleaseinputthelabelinFrench")}`,
              },
            ]}
          >
            <Input style={{ width: 300 }} />
          </Form.Item>
        ) : (
          <div style={{ textAlign: "left" }}>{record.labelFr}</div>
        );
      },
    },
    {
      title: <h3>Actions</h3>,
      key: "operation",
      align: "center",
      width: 300,
      render: (_, record) => (
        <Space size="middle">
          {editingRow === record.id ? (
            <>
              <Button type="link" icon={<SaveOutlined />}>
                {t("save")}
              </Button>
              <Button
                type="link"
                danger
                onClick={() => setEditingRow(null)}
                icon={<CloseOutlined />}
              >
                {t("cancel")}
              </Button>
            </>
          ) : (
            <>
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() => showModal(record)}
              >
                {t("edit")}
              </Button>

              <Popconfirm
                title={t("deleterow")}
                onConfirm={() => handleDelete(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link" danger icon={<DeleteOutlined />}>
                  {t("Delete")}
                </Button>
              </Popconfirm>
            </>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <Card
        title={
          <span style={{ fontSize: "16px" }}>
            <DollarOutlined /> {t("FinancialstatementCategories")}
          </span>
        }
        extra={
          <Space>
            <Select
              style={{ width: 200 }}
              placeholder={t("SelectClass")}
              onChange={handleClassChange}
              value={selectedClassId}
            >
              {financialStatementClass.map((option) => (
                <Option key={option.id} value={option.id}>
                  {currentLang === "en" ? option.label : option.labelFr}
                </Option>
              ))}
            </Select>

            <Button
              type="primary"
              size="medium"
              style={{
                fontSize: "10px",
                textTransform: "uppercase",
              }}
              icon={<PlusCircleFilled />}
              onClick={() => {
                setOpen(true);
              }}
            >
              {t("AddNewCategory")}
            </Button>
          </Space>
        }
      >
        <Form form={form} component={false}>
          <Table
            rowKey="id"
            bordered
            columns={columns}
            dataSource={financialStatementCategory}
            loading={loading}
            style={{ overflowX: "auto",backgroundColor:'white' }}
          />
        </Form>

        <Modal
          open={open}
          width={800}
          title={
            <h4
              style={{
                marginBottom: 50,
                marginTop: 10,
              }}
            >
              {t("AddNewCategory")}
            </h4>
          }
          cancelButtonProps={{
            danger: true,
            type: "primary",
          }}
          okText={
            <>
              <PlusCircleFilled /> {t("Create")}
            </>
          }
          cancelText={
            <>
              <CloseCircleFilled /> {t("Cancel")}
            </>
          }
          onCancel={() => {
            form.resetFields();
            setOpen(false);
          }}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                Submit(values);
                console.log("tatww", values);
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          <Form
            form={form}
            layout="horizontal"
            name="form_in_modal"
            labelCol={{
              span: 6,
            }}
            wrapperCol={{
              span: 14,
            }}
            initialValues={{
              modifier: "public",
            }}
          >
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="financialStatementClassId"
                  label={t("financialStatementClass")}
                  rules={[
                    {
                      required: true,
                      message: t("pleaseinputthefinancialStatementClass"),
                    },
                  ]}
                >
                  <Select style={{ width: "100%" }}>
                    {financialStatementClass.map((option) => (
                      <Option key={option.id} value={option.id}>
                        {currentLang === "en" ? option.label : option.labelFr}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="label"
                  label={t("labelEn")}
                  rules={[
                    {
                      required: true,
                      message: t("pleaseinputthelabelinEnglish"),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="labelFr"
                  label={t("labelFr")}
                  rules={[
                    {
                      required: true,
                      message: t("pleaseinputthelabelinFrench"),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>

        <Modal
          title="Edit Category"
          width={800}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form
            form={form1}
            initialValues={{
              label: currentRecord?.label,
              labelFr: currentRecord?.labelFr,
              financialStatementClassId:
                currentRecord?.financialStatementClassId,
            }}
          >
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="financialStatementClassId"
                  label={t("financialStatementClass")}
                  rules={[
                    {
                      required: true,
                      message: t("pleaseinputthefinancialStatementClass"),
                    },
                  ]}
                >
                  <Select style={{ width: "100%" }}>
                    {financialStatementClass.map((option) => (
                      <Option key={option.id} value={option.id}>
                        {currentLang === "en" ? option.label : option.labelFr}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="label"
                  label={t("labelEn")}
                  rules={[
                    {
                      required: true,
                      message: t("pleaseinputthelabelinEnglish"),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="labelFr"
                  label={t("labelFr")}
                  rules={[
                    {
                      required: true,
                      message: t("pleaseinputthelabelinFrench"),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Card>
    </>
  );
};

export default FinancialStatementCategory;

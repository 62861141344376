import {
  CheckOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Empty,
  Input,
  Popconfirm,
  Select,
  Table,
  Typography,
  Tag,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CompanyContext } from "../../../contexts/CompanyContext";
import { message } from "antd";
import axios from "axios";
import { JSON_API } from "../../../services/Constants";
import { useAbility } from "../../../contexts/abilityContext";
const { Text } = Typography;

const { TextArea } = Input;

var date;
function Budget(props) {
  let { t } = useTranslation();
  const [marketingtype, setmarketingtype] = useState([]);
  const [AssetBudgets, setAssetBudgets] = useState(props.AssetBudgets);
  const { TextArea } = Input;
  const { ability } = useAbility();
  const readOnly = !ability.can("update", "Marketing Expenses");
  const canDelete = ability.can("delete", "Marketing Expenses");
  const canConfirm = ability.can("update", "Marketing Expenses");
  const { Option } = Select;
  const [currentPage, setCurrentPage] = useState(() => {
    return Number(localStorage.getItem("currentPageMarketingBudget")) || 1;
  });
  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    localStorage.setItem("currentPageMarketingBudget", pagination.current);
  };

  useEffect(() => {
    getMarketingType();
    getMarketing();
  }, []);

  const getMarketing = async () => {
    await axios
      .get(`${JSON_API}/Marketing/MarketingBudgets/${Company.id}/${props.year}`)
      .then((res) => {
        console.log("getmarketing", res);

        props.onBudgetChange(res.data.budgets);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function handleinputchange(ee, i, n) {
    const newBudget = [...props.AssetBudgets];
    console.log("Assetbudgtes", props.AssetBudgets);
    newBudget.map((e) => {
      if (e.id == i) {
        e.budgets[n] = parseFloat(ee.target.value) || 0;
        e.reals[n] = parseFloat(ee.target.value) || 0;
      }
    });
    setAssetBudgets(newBudget);
    props.onBudgetChange(newBudget);
  }

  function handledescreptionchange(ee, i) {
    const newBudget = [...AssetBudgets];
    newBudget.map((e) => {
      if (e.id == i) {
        e.description = ee.target.value || "";
      }
    });
    setAssetBudgets(newBudget);
    props.onBudgetChange(newBudget);
  }

  function handletypechange(ee, i) {
    const newBudget = [...AssetBudgets];
    newBudget.map((e) => {
      if (e.id == i) {
        e.typeId = ee;
      }
      console.log("target", ee);
    });
    setAssetBudgets(newBudget);
    props.onBudgetChange(newBudget);
  }

  function handleAnnualbudgetchange(ee, i) {
    const newBudget = [...AssetBudgets];
    newBudget.map((e) => {
      if (e.id == i) {
        e.annualBudget = ee.target.value || "";
      }
    });
    setAssetBudgets(newBudget);
    props.onBudgetChange(newBudget);
  }

  const cancel = (e) => {
    console.log(e);
  };

  function confirm(i) {
    const newBudget = [...props.AssetBudgets];
    newBudget.map((e) => {
      if (e.id == i) {
        e.confirmed = true;
      }
    });
    setAssetBudgets(newBudget);
    props.onBudgetChange(newBudget);
  }

  const { Company } = useContext(CompanyContext);

  const getMarketingType = async () => {
    await axios
      .get(`${JSON_API}/Marketing/MarketingType`)
      .then((res) => {
        setmarketingtype(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteAssetBudget = async (id) => {
    try {
      const response = await axios.delete(`${JSON_API}/Marketing/${id}`);
      message.success(t("Marketingbudgetdeletedsuccessfully"));

      getMarketing();
      // Remove the deleted item from the state
      const updatedBudgets = AssetBudgets.filter((item) => item.id !== id);

      // Update the state and notify the parent component about the change
      setAssetBudgets(updatedBudgets);
      props.onBudgetChange(updatedBudgets);

      if (response.status === 204) {
        console.log("Marketingbudgetdeletedsuccessfully");
        return response; // Return the response object
      } else {
        console.error("Failed to delete investment:", response.statusText);
        return null; // Return null or handle the failure case
      }
    } catch (error) {
      console.error("Error deleting Marketing:", error);
      return null;
    }
  };

  const items = [
    {
      title: "Description",
      width: 200,
      dataIndex: "description",
      align: "center",
      render: (text, record) => {
        return (
          <Input
            disabled={readOnly}
            defaultValue={text}
            onChange={(e) => handledescreptionchange(e, record.id)}
            style={{
              width: 200,
            }}
          ></Input>
        );
      },
    },
    {
      title: "Type",
      width: 120,
      dataIndex: "typeId",
      align: "center",
      render: (text, record) => {
        return (
          <Select
            disabled={readOnly}
            defaultValue={text}
            onChange={(e) => handletypechange(e, record.id)}
            style={{
              width: 200,
              textAlign: "center",
            }}
          >
            {marketingtype &&
              marketingtype.map((option) => (
                <Option key={option.id} value={option.id}>
                  {option.label}
                </Option>
              ))}
          </Select>
        );
      },
    },

    {
      title: t("Annualbudget"),
      width: 120,
      dataIndex: "annualBudget",
      align: "center",
      render: (text, record) => {
        return (
          <Input
            defaultValue={text}
            disabled={readOnly}
            onChange={(e) => handleAnnualbudgetchange(e, record.id)}
            style={{
              width: 200,
              textAlign: "center",
            }}
          ></Input>
        );
      },
    },
  ];

  const months = [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
  ];

  const startingMonthIndex = Company.startPeriod - 1;

  months.forEach((month, index) => {
    const monthIndex = (index + startingMonthIndex) % months.length;
    const monthName = months[monthIndex];
    console.log("monthIndex", monthIndex);

    items.push({
      title: monthName,
      width: 120,
      align: "center",
      render: (text, record) => {
        return (
          <Input
            type="number"
            value={record.budgets[monthIndex].toFixed(0)}
            disabled={record.confirmed || readOnly}
            onChange={(e) => handleinputchange(e, record.id, monthIndex)}
            style={{ textAlign: "center", width: 120 }}
          />
        );
      },
    });
  });

  items.push({
    title: "Total",
    width: 120,
    align: "center",
    render: (_, record) => {
      return (
        <div style={{ textAlign: "center", fontWeight: "bold", width: 120 }}>
          {(
            record.budgets[0] +
            record.budgets[1] +
            record.budgets[2] +
            record.budgets[3] +
            record.budgets[4] +
            record.budgets[5] +
            record.budgets[6] +
            record.budgets[7] +
            record.budgets[8] +
            record.budgets[9] +
            record.budgets[10] +
            record.budgets[11]
          ).toFixed(0)}
        </div>
      );
    },
  });

  if (canDelete || canConfirm) {
    items.push({
      title: "Actions",
      width: 170,
      align: "center",
      key: "actions",
      render: (_, record) => (
        <>
          {record.confirmed ? (
            <Tag color="green" icon={<CheckCircleOutlined />}>
              {t("Confirmed")}
            </Tag>
          ) : (
            <>
              {canConfirm && (
                <Popconfirm
                  title={t("Areyousuretoconfirm")}
                  onConfirm={() => confirm(record.id)}
                  onCancel={cancel}
                  okText={t("yes")}
                  cancelText={t("no")}
                  icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                >
                  <Button
                    size="medium"
                    type="primary"
                    style={{ borderRadius: "20px" }}
                  >
                    {t("Confirm")}
                  </Button>
                </Popconfirm>
              )}

              {canDelete && (
                <Popconfirm
                  title={t("deleterow")}
                  onConfirm={() => deleteAssetBudget(record.id)}
                  onCancel={cancel}
                  okText={t("yes")}
                  cancelText={t("no")}
                  icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                >
                  <Button
                    type="link"
                    icon={<DeleteOutlined />}
                    style={{ color: "red", marginLeft: ".5rem" }}
                  >
                    {t("Delete")}
                  </Button>
                </Popconfirm>
              )}
            </>
          )}
        </>
      ),
    });
  }

  return (
    <Table
      locale={{
        emptyText: <Empty description={t("nodata")} />,
      }}
      pagination={{ current: currentPage }}
      onChange={handleTableChange}
      columns={items}
      dataSource={props.AssetBudgets}
      scroll={{
        x: 1300,
      }}
      bordered
      summary={(pageData) => {
        const items = [];

        const startingMonthIndex = Company.startPeriod - 1;

        let totalmontant = 0;
        let totalbudget = 0;
        let totalfevrierbudget = 0;
        let totalMarsbudget = 0.0;
        let totalavrilbudget = 0;
        let totalmaibudget = 0;
        let totalJuinbudget = 0.0;
        let totaljuilletbudget = 0;
        let totalaoutbudget = 0;
        let totalseptembrebudget = 0;
        let totaloctobrebudget = 0;
        let totalnovemberbudget = 0;
        let totaldecembrebudget = 0;
        let totaltotal = 0;

        let totalbudgetcount = 0;
        let totalfevrierbudgetcount = 0;
        let totalMarsbudgetcount = 0;
        let totalavrilbudgetcount = 0;
        let totalmaibudgetcount = 0;
        let totalJuinbudgetcount = 0;
        let totaljuilletbudgetcount = 0;
        let totalaoutbudgetcount = 0;
        let totalseptembrebudgetcount = 0;
        let totaloctobrebudgetcount = 0;
        let totalnovemberbudgetcount = 0;
        let totaldecembrebudgetcount = 0;

        props.AssetBudgets &&
          props.AssetBudgets.map((e) => {
            //console.log(e.budgets[1]),
            totalmontant = e.budgets[0];
            totalbudget += totalmontant;
            totalmontant = e.budgets[1];
            totalfevrierbudget += totalmontant;
            totalmontant = e.budgets[2];
            totalMarsbudget += totalmontant;
            totalmontant = e.budgets[3];
            totalavrilbudget += totalmontant;
            totalmontant = e.budgets[4];
            totalmaibudget += totalmontant;
            totalmontant = e.budgets[5];
            totalJuinbudget += totalmontant;
            totalmontant = e.budgets[6];
            totaljuilletbudget += totalmontant;
            totalmontant = e.budgets[7];
            totalaoutbudget += totalmontant;
            totalmontant = e.budgets[8];
            totalseptembrebudget += totalmontant;
            totalmontant = e.budgets[9];
            totaloctobrebudget += totalmontant;
            totalmontant = e.budgets[10];
            totalnovemberbudget += totalmontant;
            totalmontant = e.budgets[11];
            totaldecembrebudget += totalmontant;
            totalmontant = e.totalBudget;
            totaltotal += totalmontant;
          });

        const months = [
          totalbudget,
          totalfevrierbudget,
          totalMarsbudget,
          totalavrilbudget,
          totalmaibudget,
          totalJuinbudget,
          totaljuilletbudget,
          totalaoutbudget,
          totalseptembrebudget,
          totaloctobrebudget,
          totalnovemberbudget,
          totaldecembrebudget,
        ];
        const monthscount = [
          totalbudgetcount,
          totalfevrierbudgetcount,
          totalMarsbudgetcount,
          totalavrilbudgetcount,
          totalmaibudgetcount,
          totalJuinbudgetcount,
          totaljuilletbudgetcount,
          totalaoutbudgetcount,
          totalseptembrebudgetcount,
          totaloctobrebudgetcount,
          totalnovemberbudgetcount,
          totaldecembrebudgetcount,
        ];
        for (let i = 0; i < months.length; i++) {
          const monthIndex = (i + startingMonthIndex) % months.length;
          const monthName = months[monthIndex];
          let monthNamecount = monthscount[monthIndex];
          {
            if (monthNamecount == 0) {
              monthNamecount = 1;
            }
          }

          items.push(
            <Table.Summary.Cell index={monthIndex}>
              <Text style={{ textAlign: "center", fontWeight: "bold" }}>
                {monthName.toFixed(0)}
              </Text>
            </Table.Summary.Cell>
          );
        }

        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={1} colSpan={3}>
                <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
                  Total
                </h3>
              </Table.Summary.Cell>
              {items}
            </Table.Summary.Row>
          </>
        );
      }}
    />
  );
}
Budget.acl = {
  subject: "Marketing Expenses",
  action: "read",
};

export default Budget;

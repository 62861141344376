import {
  EyeFilled,
  DollarTwoTone,
  InfoCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Descriptions,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import axios from "axios";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CompanyContext } from "../../contexts/CompanyContext";
import { JSON_API } from "../../services/Constants";
import { useAbility } from "../../contexts/abilityContext";

const onChangee = (date, dateString) => {
  console.log(date, dateString);
};

function Expense() {
  const { ability } = useAbility();
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const { Company } = useContext(CompanyContext);
  const [year, setYear] = useState(null);
  const [expense, setexpense] = useState(null);
  const [form2] = Form.useForm();
  const [editingRowbook, setEditingRowbook] = useState(null);
  const [statementcategory, setStatementCategory] = useState([{}]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const [currentPage, setCurrentPage] = useState(() => {
    return Number(localStorage.getItem("currentPageExpense")) || 1;
  });
  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    localStorage.setItem("currentPageExpense", pagination.current);
  };
  const showNameModal = (name) => {
    setSelectedName(name);
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedName(null);
  };
  var date;
  useEffect(() => {
    console.log("year" + date);
    getExpense();
  }, [Company.id, year]);
  const onChangeyear = (date, dateString) => {
    setexpense(null);
    console.log(date, dateString);
    setYear(dateString);
  };

  const columns = [
    {
      title: `${t("glnumber")}`,
      // dataIndex: "glAccountId",
      align: "center",
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          {record.chartAccount.glAccount.glNumber}
        </div>
      ),
    },
    {
      title: `${t("Class")}`,
      align: "center",
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          {/* {record.financialStatementType.financialStatementCategory.financialStatementClass.label}
           */}
          {currentLang === "en"
            ? record.financialStatementClass.label
            : record.financialStatementClass.labelFr}
        </div>
      ),
    },
    {
      title: `${t("Category")}`,
      // dataIndex: "category",
      align: "center",
      render: (_, record) => (
        <div style={{ textAlign: "left" }}>
          {currentLang === "en"
            ? record.financialStatementType.financialStatementCategory.label
            : record.financialStatementType.financialStatementCategory.labelFr}
        </div>
      ),
    },
    {
      title: `${t("Gifi")}`,
      // dataIndex: "financialStatementTypeId",
      align: "center",
      // render: (text) => <a>{text}</a>,
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          {record.financialStatementType.gifi}
        </div>
      ),
    },
    {
      title: `${t("Description")}`,
      // dataIndex: "note",
      align: "center",
      render: (_, record) => (
        <div
          style={{
            textAlign: "left",
            maxWidth: "400px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            cursor: "pointer",
          }}
          onClick={() =>
            showNameModal(record.chartAccount.glAccount.description)
          }
        >
          {record.chartAccount.glAccount.description}
        </div>
      ),
    },
    {
      title: `${t("Expensetype")}`,
      align: "center",
      render: (_, record) => {
        if (!record.expenseType) {
          return (
            <div
              style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
            >
              -
            </div>
          );
        }
        return (
          <div
            style={{
              textAlign: "left",
              maxWidth: "400px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor: "pointer",
            }}
          >
            {currentLang === "en"
              ? record.expenseType.labelEn
              : record.expenseType.labelFr}
          </div>
        );
      },
    },

    {
      title: "Actions",
      align: "center",

      render: (_, record) => {
        return (
          <Link
            to={{
              pathname: `/expenseDetails/${record.id}`,
              state: { stateParam: record.id },
            }}
          >
            <Button type="link" icon={<EyeFilled />}>
              {t("details")}
            </Button>
          </Link>
        );
      },
    },
  ];
  const getExpense = async () => {
    if (year == null) {
      await axios
        .get(`${JSON_API}/Expense/filter/${Company.id}`)
        .then((res) => {
          console.log("ress", res);
          setexpense(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await axios
        .get(`${JSON_API}/Expense/filter/${Company.id}?year=${year}`)
        .then((res) => {
          console.log(res);
          setexpense(res.data);
          console.log(expense);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <Card
      bordered={false}
      className="header-solid mb-24"
      title={
        <h3 className="font-semibold">
          <DollarTwoTone /> {t("Expense")}
        </h3>
      }
    >
      <Row justify="space-between">
        <Space
          style={{
            display: "flex",
            marginBottom: 24,
            marginLeft: "flex",
          }}
          align="baseline"
        >
          <DatePicker
            name="year"
            picker="year"
            placeholder={t("Selectyear")}
            style={{ width: 400, height: 35 }}
            onChange={onChangeyear}
          />
        </Space>

        <Space
          style={{
            marginBottom: 24,
          }}
        >
          <Link
            to={{
              pathname: `/summaryexpense`,
            }}
          >
            <Button
              className="Create-button"
              type="primary"
              style={{
                textAlign: "right",
                marginLeft: "flex",
              }}
            >
              <InfoCircleOutlined />
              {t("Summary")}
            </Button>
          </Link>
        </Space>
      </Row>
      <div style={{ overflowX: "auto", backgroundColor: "white" }}>
        <Table
          pagination={{ current: currentPage }}
          onChange={handleTableChange}
          columns={columns}
          dataSource={expense}
          bordered
        />
      </div>
      <Modal
        title={t("detail")}
        visible={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
      >
        <p>{selectedName}</p>
      </Modal>
    </Card>
  );
}

Expense.acl = {
  subject: "Expense",
  action: "read",
};

export default Expense;

import React, { useState, useEffect, useContext } from "react";
import { Modal, Form, Input, Select, Button, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import * as signalR from "@microsoft/signalr";
import { CompanyContext } from "../contexts/CompanyContext";
import { JSON_API_logo, JSON_API } from "../services/Constants";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const ChartAccountsImportModal = ({ visible, onClose }) => {
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [token, setToken] = useState("");
  const [selectedApi, setSelectedApi] = useState("api1");
  const [messages, setMessages] = useState([]);
  const { Company } = useContext(CompanyContext);
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  const generateUniqueSessionId = () => {
    return (
      "import_" + Date.now() + "_" + Math.random().toString(36).substr(2, 9)
    );
  };

  const sessionId = generateUniqueSessionId();
  const storedToken = localStorage.getItem("token");
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(`${JSON_API_logo}progressHub?sessionId=${sessionId}`, {
      accessTokenFactory: () => storedToken,
    })
    .withAutomaticReconnect([0, 2000, 10000, 30000])
    .configureLogging(signalR.LogLevel.Information)
    .build();
  async function startSignalRConnection() {
    try {
      await connection.start();
      console.log("SignalR Connected.");
    } catch (err) {
      console.error(err);
      setTimeout(startSignalRConnection, 5000); // Retry connection
    }
  }

  connection.on("ReceiveMessage", function (message) {
    console.log(message);
    console.log("SignalR Received.");
    if (message.sessionId === sessionId) {
      const msgDiv = document.createElement("div");
      document.getElementById("messages").innerHTML = "";
      msgDiv.textContent = message.message;
      msgDiv.style.color = message.message.includes("info") ? "blue" : "red";
      document.getElementById("messages").appendChild(msgDiv);
    }
  });

  // Handle progress updates
  connection.on("ReceiveProgressUpdate", function (update) {
    console.log("SignalR Progress.");
    if (update.sessionId === sessionId) {
      setProgress(update.progress);
    }
  });
  connection.onreconnecting((error) => {
    console.log(`Connection lost due to error "${error}". Reconnecting.`);
    // Update UI to show reconnecting status
  });

  connection.onreconnected((connectionId) => {
    console.log(`Reconnected. Connection ID: ${connectionId}`);
    // Update UI to show connected status
  });

  useEffect(() => {
    const fetchToken = async () => {
      const fetchedToken = await getTokenFromSource();
      setToken(fetchedToken);
    };

    fetchToken();
    console.log(token, sessionId);

    /*
    connection.on("ReceiveProgressUpdate", (update) => {
      if (update.sessionId === sessionId) {
        document.getElementById(
          "progressText"
        ).innerText = `Progress: ${update.progress}%`;
      }
      console.log("progress",update.progress)
      if (update.sessionId === sessionId) {
        setProgress("progress",update.progress);
      }
    });

    connection.on("ReceiveMessage", (message) => {
      console.log("message",message)
      if (message.sessionId === sessionId) {
        setMessages((prevMessages) => [...prevMessages, message]);
      }
    });

    const startSignalRConnection = async () => {
      try {
        await connection.start();
        console.log("SignalR Connected.");
      } catch (err) {
        console.error(err);
        setTimeout(startSignalRConnection, 5000);
      }
    };
    connection.onclose(async () => {
            await startSignalRConnection();
        });

    startSignalRConnection();

    return () => {
      connection.stop();
    };*/
  }, [Company.id]);

  const getTokenFromSource = async () => {
    const storedToken = localStorage.getItem("token");
    console.log("token", storedToken);
    return storedToken;
  };

  const handleFileChange = (info) => {
    console.log(info);
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
    setFile(info.file.originFileObj);
  };

  const handleApiChange = (value) => {
    setSelectedApi(value);
  };

  const handleSubmit = async () => {
    setProgress(0);
    await startSignalRConnection();
    const formData = new FormData();
    formData.append("enterpriseId", Company.id);
    formData.append("file", file);
    console.log("formdata", formData);

    try {
      const apiUrl =
        selectedApi === "api1"
          ? `${JSON_API}/chartaccounts/import`
          : `${JSON_API}/chartaccounts/import/history`;
      console.log(formData);
      setProgress(0);

      const response = await axios.post(apiUrl, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          "Session-Id": sessionId,
        },
        responseType: "blob", // Set the response type to blob

        /*onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },*/
      });
      console.log("reponse", response);
      if (response.status == 200) {
        const createdAccountCount = response.headers.get(
          "X-CreatedAccountCount"
        );
        const { data, headers } = response;

        // Create a Blob object from the data
        const blob = new Blob([data], { type: headers["content-type"] });
        //const blob = await response.blob();
        console.log("blob", blob);
        const url = URL.createObjectURL(blob);
        const contentDisposition = response.headers.get("Content-Disposition");
        let filename = "defaultDownload.xlsx"; // Fallback filename
        if (contentDisposition) {
          let matches = /filename\*\s*=\s*UTF-8''([^;]*)/i.exec(
            contentDisposition
          );
          if (matches && matches[1]) {
            filename = decodeURIComponent(matches[1]);
          } else {
            matches = /filename\s*=\s*(['"]?)([^\1;]*)\1/i.exec(
              contentDisposition
            );
            if (matches && matches[2]) {
              filename = matches[2];
            }
          }
        }
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = filename;
        a.innerText = `${filename}`;
        const targetDiv = document.getElementById("messages");
        // Append the created anchor element to the div
        targetDiv.appendChild(a);
a.click();
        //document.body.appendChild(a);
        message.success("Chart accounts imported successfully.");
        //onClose();
      } else {
        message.error("Failed to import chart accounts.");
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("Could not import chart accounts.");
    }
  };

  return (
    <Modal
      visible={visible}
      title="Import Chart Accounts"
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          {t("cancel")}
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          {t("ImportCharaccounts")}
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <Form.Item label="Select API">
          <Select defaultValue="api1" onChange={handleApiChange}>
            <Option value="api1">{t("Chartaccounts")}</Option>
            <Option value="api2">{t("History")}</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Excel File">
          <Upload
            accept=".xlsx, .xls"
            customRequest={() => {}}
            onChange={handleFileChange}
            showUploadList={false}
          >
            <Button icon={<UploadOutlined />}>{t("ChooseFile")}</Button>
          </Upload>
          <span style={{ marginLeft: "10px" }}>{file && file.name}</span>
        </Form.Item>
      </Form>
      <div
        id="messages"
        style={{
          marginBottom: "20px",
          border: "1px solid #ccc",
          padding: "10px",
          maxHeight: "200px",
          overflowY: "auto",
        }}
      ></div>
      <div>
        {t("Progression")}: {progress}%
      </div>
    </Modal>
  );
};

export default ChartAccountsImportModal;

import React, { useContext } from "react";
import { useAbility } from "../../../contexts/abilityContext";
import { Input, Table, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { CompanyContext } from "../../../contexts/CompanyContext";
const { Text } = Typography;
const { TextArea } = Input;

function Real(props) {
  let { t } = useTranslation();
  const { Company } = useContext(CompanyContext);
  const { ability } = useAbility();
  const readOnly = !ability.can("update", "Revenue");
  const [AssetReals, setAssetReals] = useState(props.AssetReals);
  const { TextArea } = Input;
  const [revenuevalue, setrevenuevalue] = useState(0);
  console.log(AssetReals);

  function handleinputchange(ee, i, n) {
    console.log(props.AssetReals);
    const newReal = [...props.AssetReals];
    newReal.map((e) => {
      if (e.id == i) {
        e.reals[n] = parseFloat(ee.target.value) || 0;
      }
    });
    setAssetReals(newReal);
    setrevenuevalue(parseFloat(ee.target.value) || 0);
    console.log(revenuevalue);
    props.onRealChange(newReal);
  }

  const items = [
    {
      title: `${t("Year")}`,
      width: 120,
      dataIndex: "year",
      align: "center",
      render: (text, record) => {
        return <p style={{ textAlign: "center" }}>{text}</p>;
      },
    },
  ];

  const months = [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
  ];

  const startingMonthIndex = Company.startPeriod - 1;

  for (let i = 0; i < months.length; i++) {
    const monthIndex = (i + startingMonthIndex) % months.length;
    const monthName = months[monthIndex];

    items.push({
      title: monthName,
      width: 120,
      align: "center",
      render: (text, record) => {
        let confirm = false;

        return (
          <>
            {props.Revenue.sellingPrice != 0
              ? (props.AssetUnit &&
                  props.AssetUnit.map((e) => {
                    if (e.year == record.year) {
                      record.reals[monthIndex] =
                        e.unitReals[monthIndex] * props.Revenue.sellingPrice;
                      setrevenuevalue(
                        e.unitReals[monthIndex] * props.Revenue.sellingPrice
                      );
                      console.log("twiyest", revenuevalue);
                    }
                  }),
                (confirm = true))
              : props.AssetBudgets.map((e) => {
                  if (e.year == record.year) {
                    confirm = !e.confirmed;
                    if (confirm) {
                      record.reals[monthIndex] = e.budgets[monthIndex];
                      setrevenuevalue(record.reals[monthIndex]);
                    }
                  }
                })}
            <Input
              type="number"
              value={record.reals[monthIndex].toFixed(0)}
              disabled={confirm || readOnly}
              onChange={(e) => handleinputchange(e, record.id, monthIndex)}
              style={{ textAlign: "right", width: 100 }}
            />
          </>
        );
      },
    });
  }

  items.push({
    title: <h1 style={{ textAlign: "center", width: 100 }}>Total </h1>,
    width: 120,
    render: (_, record) => {
      return (
        <p style={{ textAlign: "right" }}>
          {" "}
          {(
            record.reals[0] +
            record.reals[1] +
            record.reals[2] +
            record.reals[3] +
            record.reals[4] +
            record.reals[5] +
            record.reals[6] +
            record.reals[7] +
            record.reals[8] +
            record.reals[9] +
            record.reals[10] +
            record.reals[11]
          ).toFixed(0)}
        </p>
      );
    },
  });

  return (
    <Table
      columns={items}
      dataSource={props.AssetReals}
      scroll={{
        x: 1300,
      }}
      pagination={true}
      bordered
      summary={(pageData) => {
        const items = [];

        const startingMonthIndex = Company.startPeriod - 1;

        let totalmontant = 0;
        let totalReal = 0;
        let totalfevrierReal = 0;
        let totalMarsReal = 0.0;
        let totalavrilReal = 0;
        let totalmaiReal = 0;
        let totalJuinReal = 0.0;
        let totaljuilletReal = 0;
        let totalaoutReal = 0;
        let totalseptembreReal = 0;
        let totaloctobreReal = 0;
        let totalnovemberReal = 0;
        let totaldecembreReal = 0;
        let totaltotal = 0;

        let totalbudgetcount = 0;
        let totalfevrierbudgetcount = 0;
        let totalMarsbudgetcount = 0;
        let totalavrilbudgetcount = 0;
        let totalmaibudgetcount = 0;
        let totalJuinbudgetcount = 0;
        let totaljuilletbudgetcount = 0;
        let totalaoutbudgetcount = 0;
        let totalseptembrebudgetcount = 0;
        let totaloctobrebudgetcount = 0;
        let totalnovemberbudgetcount = 0;
        let totaldecembrebudgetcount = 0;

        if (props.Revenue.sellingPrice != 0) {
          {
            props.AssetUnit &&
              props.AssetUnit.map((e) => {
                //console.log(e.budgets[1]),
                totalmontant = e.unitReals[0] * props.Revenue.sellingPrice;
                totalReal += totalmontant;
                totalmontant = e.unitReals[1] * props.Revenue.sellingPrice;
                totalfevrierReal += totalmontant;
                totalmontant = e.unitReals[2] * props.Revenue.sellingPrice;
                totalMarsReal += totalmontant;
                totalmontant = e.unitReals[3] * props.Revenue.sellingPrice;
                totalavrilReal += totalmontant;
                totalmontant = e.unitReals[4] * props.Revenue.sellingPrice;
                totalmaiReal += totalmontant;
                totalmontant = e.unitReals[5] * props.Revenue.sellingPrice;
                totalJuinReal += totalmontant;
                totalmontant = e.unitReals[6] * props.Revenue.sellingPrice;
                totaljuilletReal += totalmontant;
                totalmontant = e.unitReals[7] * props.Revenue.sellingPrice;
                totalaoutReal += totalmontant;
                totalmontant = e.unitReals[8] * props.Revenue.sellingPrice;
                totalseptembreReal += totalmontant;
                totalmontant = e.unitReals[9] * props.Revenue.sellingPrice;
                totaloctobreReal += totalmontant;
                totalmontant = e.unitReals[10] * props.Revenue.sellingPrice;
                totalnovemberReal += totalmontant;
                totalmontant = e.unitReals[11] * props.Revenue.sellingPrice;
                totaldecembreReal += totalmontant;
                totalmontant = e.totalBudget;
                totaltotal += totalmontant;
              });
          }
        } else {
          {
            props.AssetReals &&
              props.AssetReals.map((e) => {
                //console.log(e.Reals[1]),
                totalmontant = e.reals[0];
                if (totalmontant.toFixed(0) != 0) {
                  totalbudgetcount += 1;
                }
                totalReal += totalmontant;
                totalmontant = e.reals[1];
                if (totalmontant.toFixed(0) != 0) {
                  totalfevrierbudgetcount += 1;
                }
                totalfevrierReal += totalmontant;
                totalmontant = e.reals[2];
                if (totalmontant.toFixed(0) != 0) {
                  totalMarsbudgetcount += 1;
                }
                totalMarsReal += totalmontant;
                totalmontant = e.reals[3];
                if (totalmontant.toFixed(0) != 0) {
                  totalavrilbudgetcount += 1;
                }
                totalavrilReal += totalmontant;
                totalmontant = e.reals[4];
                if (totalmontant.toFixed(0) != 0) {
                  totalmaibudgetcount += 1;
                }
                totalmaiReal += totalmontant;
                totalmontant = e.reals[5];
                if (totalmontant.toFixed(0) != 0) {
                  totalJuinbudgetcount += 1;
                }
                totalJuinReal += totalmontant;
                totalmontant = e.reals[6];
                if (totalmontant.toFixed(0) != 0) {
                  totaljuilletbudgetcount += 1;
                }
                totaljuilletReal += totalmontant;
                totalmontant = e.reals[7];
                if (totalmontant.toFixed(0) != 0) {
                  totalaoutbudgetcount += 1;
                }
                totalaoutReal += totalmontant;
                totalmontant = e.reals[8];
                if (totalmontant.toFixed(0) != 0) {
                  totalseptembrebudgetcount += 1;
                }
                totalseptembreReal += totalmontant;
                totalmontant = e.reals[9];
                if (totalmontant.toFixed(0) != 0) {
                  totaloctobrebudgetcount += 1;
                }
                totaloctobreReal += totalmontant;
                totalmontant = e.reals[10];
                if (totalmontant.toFixed(0) != 0) {
                  totalnovemberbudgetcount += 1;
                }
                totalnovemberReal += totalmontant;
                totalmontant = e.reals[11];
                if (totalmontant.toFixed(0) != 0) {
                  totaldecembrebudgetcount += 1;
                }
                totaldecembreReal += totalmontant;
                totalmontant = e.totalBudget;
                totaltotal += totalmontant;
              });
          }
        }

        const months = [
          totalReal,
          totalfevrierReal,
          totalMarsReal,
          totalavrilReal,
          totalmaiReal,
          totalJuinReal,
          totaljuilletReal,
          totalaoutReal,
          totalseptembreReal,
          totaloctobreReal,
          totalnovemberReal,
          totaldecembreReal,
        ];
        const monthscount = [
          totalbudgetcount,
          totalfevrierbudgetcount,
          totalMarsbudgetcount,
          totalavrilbudgetcount,
          totalmaibudgetcount,
          totalJuinbudgetcount,
          totaljuilletbudgetcount,
          totalaoutbudgetcount,
          totalseptembrebudgetcount,
          totaloctobrebudgetcount,
          totalnovemberbudgetcount,
          totaldecembrebudgetcount,
        ];

        for (let i = 0; i < months.length; i++) {
          const monthIndex = (i + startingMonthIndex) % months.length;
          const monthName = months[monthIndex];
          let monthNamecount = monthscount[monthIndex];
          {
            if (monthNamecount == 0) {
              monthNamecount = 1;
            }
          }

          items.push(
            <Table.Summary.Cell index={monthIndex}>
              <Text style={{ textAlign: "right" }}> {(monthName / monthNamecount).toFixed(0)}</Text>
            </Table.Summary.Cell>
          );
        }

        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={1} colSpan={1}>
                <h3 style={{ textAlign: "center" }}>{t("Average")}</h3>
              </Table.Summary.Cell>

              {items}

              <Table.Summary.Cell index={10}>
                <Text>
                  {(
                    totalReal +
                    totalfevrierReal +
                    totalMarsReal +
                    totalavrilReal +
                    totalmaiReal +
                    totalJuinReal +
                    totaljuilletReal +
                    totalaoutReal +
                    totalseptembreReal +
                    totaloctobreReal +
                    totalnovemberReal +
                    totaldecembreReal
                  ).toFixed(0)}
                </Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        );
      }}
    />
  );
}

Real.acl = {
  subject: "Revenue",
  action: "read",
};
export default Real;

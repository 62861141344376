import { Card, DatePicker, Table, Tabs, Typography, Space, Button } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CompanyContext } from "../../contexts/CompanyContext";
import { JSON_API } from "../../services/Constants";
import { countmonth, summary } from "../Financial Statement/Utils/Function";
import {
  CalendarOutlined,
  RightOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
const { Text } = Typography;

function RevenueSummary(props) {
  let { t } = useTranslation();
  const { Company } = useContext(CompanyContext);
  const [year, setYear] = useState(null);
  const [Revenue, setRevenue] = useState(null);
  const [count, setcount] = useState(1);
  const [total, settotal] = useState(0);
  const [totalReal, settotalReal] = useState(0);
  const [totalBudget, settotalBudget] = useState(0);

  const onChange = (key) => {
    console.log(key);
  };
  var date;
  useEffect(() => {
    date = new Date().getFullYear();
    setYear(year == null ? date : year);

    console.log("year" + date);
    getRevenue();
  }, [Company.id, year]);

  const onChangee = (date, dateString) => {
    console.log(date.$y);
    setYear(date.$y);
    console.log("." + year);
  };

  const getRevenue = async () => {
    await axios
      .get(`${JSON_API}/Revenue/summaries/${Company.id}/${year}`)
      .then((res) => {
        setRevenue(res.data);
        console.log(res.data);
        let count = countmonth(res.data);

        setcount(count);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    let per = 0;

    Revenue?.map((e) => {
      per +=
        e.monthlyReals[0] -
        e.monthlyBudgets[0] +
        e.monthlyReals[1] -
        e.monthlyBudgets[1] +
        e.monthlyReals[2] -
        e.monthlyBudgets[2] +
        e.monthlyReals[3] -
        e.monthlyBudgets[3] +
        e.monthlyReals[4] -
        e.monthlyBudgets[4] +
        e.monthlyReals[5] -
        e.monthlyBudgets[5] +
        e.monthlyReals[6] -
        e.monthlyBudgets[6] +
        e.monthlyReals[7] -
        e.monthlyBudgets[7] +
        e.monthlyReals[8] -
        e.monthlyBudgets[8] +
        e.monthlyReals[9] -
        e.monthlyBudgets[9] +
        e.monthlyReals[10] -
        e.monthlyBudgets[10] +
        e.monthlyReals[10] -
        e.monthlyBudgets[11];
    });
    settotal((total) => {
      total = per;
      return total;
    });
  }, [Revenue]);

  useEffect(() => {
    let totalReall = 0;

    Revenue?.map((e) => {
      totalReall +=
        e.monthlyReals[0] +
        e.monthlyReals[1] +
        e.monthlyReals[2] +
        e.monthlyReals[3] +
        e.monthlyReals[4] +
        e.monthlyReals[5] +
        e.monthlyReals[6] +
        e.monthlyReals[7] +
        e.monthlyReals[8] +
        e.monthlyReals[9] +
        e.monthlyReals[10] +
        e.monthlyReals[11];
    });
    settotalReal((totalReal) => {
      totalReal = totalReall;
      return totalReal;
    });
  }, [Revenue]);

  useEffect(() => {
    let totalbudgett = 0;

    Revenue?.map((e) => {
      totalbudgett +=
        e.monthlyBudgets[0] +
        e.monthlyBudgets[1] +
        e.monthlyBudgets[2] +
        e.monthlyBudgets[3] +
        e.monthlyBudgets[4] +
        e.monthlyBudgets[5] +
        e.monthlyBudgets[6] +
        e.monthlyBudgets[7] +
        e.monthlyBudgets[8] +
        e.monthlyBudgets[9] +
        e.monthlyBudgets[10] +
        e.monthlyBudgets[11];
    });
    settotalBudget((totalBudget) => {
      totalBudget = totalbudgett;
      console.log("allalalal", totalBudget);
      return totalBudget;
    });
  }, [Revenue]);

  const columnsbalanceBudget = [
    {
      title: `${t("glnumber")}`,
      dataIndex: "glNumber",
      align: "center",
    },
    {
      title: `${t("Category")}`,
      dataIndex: "category",
      align: "center",
      width: 180,
    },
    {
      title: `${t("Description")}`,
      dataIndex: "description",
      align: "center",
      width: 200,
    },
  ];

  const months = [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
  ];

  const startingMonthIndex = Company.startPeriod - 1;

  months.forEach((month, index) => {
    const monthIndex = (index + startingMonthIndex) % months.length;
    const monthName = months[monthIndex];

    columnsbalanceBudget.push({
      title: monthName,
      width: 120,
      align: "center",
      render: (text, record) => {
        return (
          <p style={{ textAlign: "right" }}>
            {record.monthlyBudgets[monthIndex].toFixed(0)}
          </p>
        );
      },
    });
  });

  columnsbalanceBudget.push({
    title: <h1 style={{ textAlign: "center", width: 100 }}>Total </h1>,
    width: 120,
    align: "center",
    render: (_, record) => {
      let totalbudgett =
        record.monthlyBudgets[0] +
        record.monthlyBudgets[1] +
        record.monthlyBudgets[2] +
        record.monthlyBudgets[3] +
        record.monthlyBudgets[4] +
        record.monthlyBudgets[5] +
        record.monthlyBudgets[6] +
        record.monthlyBudgets[7] +
        record.monthlyBudgets[8] +
        record.monthlyBudgets[9] +
        record.monthlyBudgets[10] +
        record.monthlyBudgets[11];

      return <p style={{ textAlign: "right" }}> {totalbudgett.toFixed(0)}</p>;
    },
  });

  const columnsbalanceBudgetReal = [
    {
      title: `${t("glnumber")}`,
      dataIndex: "glNumber",
      align: "center",
    },
    {
      title: `${t("Category")}`,
      dataIndex: "category",
      align: "center",
    },
    {
      title: `${t("Description")}`,
      dataIndex: "description",
      align: "center",
    },
  ];

  months.forEach((month, index) => {
    const monthIndex = (index + startingMonthIndex) % months.length;
    const monthName = months[monthIndex];

    columnsbalanceBudgetReal.push({
      title: monthName,
      width: 120,
      align: "center",
      render: (text, record) => {
        return (
          <p style={{ textAlign: "right" }}>
            {record.monthlyReals[monthIndex].toFixed(0)}
          </p>
        );
      },
    });
  });

  columnsbalanceBudgetReal.push({
    title: <h1 style={{ textAlign: "center", width: 100 }}>Total </h1>,
    width: 120,
    align: "center",

    render: (_, record) => {
      return (
        <p style={{ textAlign: "right" }}>
          {(
            record.monthlyReals[0] +
            record.monthlyReals[1] +
            record.monthlyReals[2] +
            record.monthlyReals[3] +
            record.monthlyReals[4] +
            record.monthlyReals[5] +
            record.monthlyReals[6] +
            record.monthlyReals[7] +
            record.monthlyReals[8] +
            record.monthlyReals[9] +
            record.monthlyReals[10] +
            record.monthlyReals[11]
          ).toFixed(0)}
        </p>
      );
    },
  });

  const columnsbalancePerformance = [
    {
      title: `${t("glnumber")}`,
      dataIndex: "glNumber",
      align: "center",
    },
    {
      title: `${t("Category")}`,
      dataIndex: "category",
      width: 200,
      align: "center",
    },
    {
      title: `${t("Description")}`,
      dataIndex: "description",
      align: "center",
    },
  ];

  months.forEach((month, index) => {
    const monthIndex = (index + startingMonthIndex) % months.length;
    const monthName = months[monthIndex];

    columnsbalancePerformance.push({
      title: monthName,

      children: [
        {
          title: <p style={{ textAlign: "center", width: 100 }}>Budget</p>,

          align: "center",
          render: (text, record) => {
            return record.monthlyBudgets[monthIndex].toFixed(0);
          },
        },
        {
          title: (
            <p style={{ textAlign: "center", color: "blue", width: 100 }}>
              {t("Real")}
            </p>
          ),
          align: "center",

          render: (text, record) => {
            return record.monthlyReals[monthIndex].toFixed(0);
          },
        },

        {
          title: (
            <p style={{ textAlign: "center", width: 100 }}>{t("Difference")}</p>
          ),
          dataIndex: "street",
          align: "center",

          render: (text, record) => {
            let performance =
              record.monthlyReals[monthIndex] -
              record.monthlyBudgets[monthIndex];
            return (
              <>
                {performance < 0 ? (
                  <p style={{ textAlign: "right", color: "red" }}>
                    ( {performance.toFixed(0)} )
                  </p>
                ) : (
                  <p style={{ textAlign: "right", color: "blue" }}>
                    {performance.toFixed(0)}
                  </p>
                )}
              </>
            );
          },
        },
      ],
    });
  });

  columnsbalancePerformance.push({
    title: <h1 style={{ textAlign: "center", width: 100 }}>Total </h1>,
    width: 120,
    render: (_, record) => {
      let per =
        record.monthlyReals[0] -
        record.monthlyBudgets[0] +
        record.monthlyReals[1] -
        record.monthlyBudgets[1] +
        record.monthlyReals[2] -
        record.monthlyBudgets[2] +
        record.monthlyReals[3] -
        record.monthlyBudgets[3] +
        record.monthlyReals[4] -
        record.monthlyBudgets[4] +
        record.monthlyReals[5] -
        record.monthlyBudgets[5] +
        record.monthlyReals[6] -
        record.monthlyBudgets[6] +
        record.monthlyReals[7] -
        record.monthlyBudgets[7] +
        record.monthlyReals[8] -
        record.monthlyBudgets[8] +
        record.monthlyReals[9] -
        record.monthlyBudgets[9] +
        record.monthlyReals[10] -
        record.monthlyBudgets[10] +
        record.monthlyReals[10] -
        record.monthlyBudgets[11];

      return <p style={{ textAlign: "right" }}> {per.toFixed(0)}</p>;
    },
  });

  const items = [
    {
      key: "1",
      label: <span style={{ width: 300, textAlign: "center" }}>Budget</span>,
      children: (
        <div>
          <Table
            columns={columnsbalanceBudget}
            dataSource={Revenue}
            scroll={{
              x: 2000,
            }}
            bordered
            summary={(pageData) => {
              const items = [];
              items.push(
                <Table.Summary.Cell colSpan={0} index={1}>
                  <Text style={{ textAlign: "center" }}></Text>
                </Table.Summary.Cell>
              );
              if (Revenue) {
                let itemsvalue = summary(
                  Company,
                  Revenue,
                  count["budget"],
                  "monthlyBudgets"
                );

                itemsvalue?.map((val) =>
                  items.push(
                    <Table.Summary.Cell index={val.monthIndex}>
                      <Text style={{ textAlign: "center" }}>
                        {val.monthName}
                      </Text>
                    </Table.Summary.Cell>
                  )
                );
              }
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={4} colSpan={3}>
                      <h3 style={{ textAlign: "center" }}>{t("Average")}</h3>
                    </Table.Summary.Cell>

                    {items}
                    <Table.Summary.Cell index={12}>
                      <Text>
                        <p style={{ textAlign: "right", width: 100 }}>
                          {" "}
                          {totalBudget.toFixed(0)}
                        </p>
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <span style={{ width: 300, textAlign: "center" }}>{t("Real")}</span>
      ),
      children: (
        <div>
          <Table
            columns={columnsbalanceBudgetReal}
            dataSource={Revenue}
            scroll={{
              x: 2000,
            }}
            bordered
            summary={(pageData) => {
              const items = [];
              items.push(
                <Table.Summary.Cell colSpan={0} index={1}>
                  <Text style={{ textAlign: "center" }}></Text>
                </Table.Summary.Cell>
              );

              if (Revenue) {
                let itemsvalue = summary(
                  Company,
                  Revenue,
                  count["reals"],
                  "monthlyReals"
                );

                itemsvalue?.map((val) =>
                  items.push(
                    <Table.Summary.Cell index={val.monthIndex}>
                      <Text style={{ textAlign: "center" }}>
                        {val.monthName}
                      </Text>
                    </Table.Summary.Cell>
                  )
                );
              }
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={4} colSpan={3}>
                      <h3 style={{ textAlign: "center" }}>{t("Average")}</h3>
                    </Table.Summary.Cell>

                    {items}
                    <Table.Summary.Cell index={12}>
                      <Text>
                        <p style={{ textAlign: "right", width: 100 }}>
                          {" "}
                          {totalReal.toFixed(0)}
                        </p>
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </div>
      ),
    },

    {
      key: "3",
      label: (
        <span style={{ width: 300, textAlign: "center" }}>Performance</span>
      ),
      children: (
        <div>
          {" "}
          <Table
            columns={columnsbalancePerformance}
            dataSource={Revenue}
            scroll={{
              x: 2000,
            }}
            bordered
            summary={(pageData) => {
              const items = [];
              if (Revenue) {
                let itemsvalueReal = summary(
                  Company,
                  Revenue,
                  count["reals"],
                  "monthlyReals"
                );
                let itemsvalueBudget = summary(
                  Company,
                  Revenue,
                  count["budget"],
                  "monthlyBudgets"
                );
                let itemsvaluePerformance = summary(
                  Company,
                  Revenue,
                  count["performance"],
                  "monthlyPerformances"
                );

                for (let i = 0; i < itemsvalueBudget.length.toFixed(0); i++) {
                  items.push(
                    <Table.Summary.Cell
                      index={itemsvalueBudget[i].monthIndex.toFixed(0)}
                    >
                      <Text style={{ textAlign: "center" }}>
                        {itemsvalueBudget[i].monthName}
                      </Text>
                    </Table.Summary.Cell>
                  );
                  items.push(
                    <Table.Summary.Cell
                      index={itemsvalueReal[i].monthIndex.toFixed(0)}
                    >
                      <Text style={{ textAlign: "center" }}>
                        {itemsvalueReal[i].monthName}
                      </Text>
                    </Table.Summary.Cell>
                  );

                  items.push(
                    <Table.Summary.Cell index={i}>
                      {itemsvaluePerformance[i].monthName < 0 ? (
                        <Text style={{ textAlign: "right", color: "red" }}>
                          ( {itemsvaluePerformance[i].monthName})
                        </Text>
                      ) : (
                        <Text style={{ textAlign: "right", color: "blue" }}>
                          {itemsvaluePerformance[i].monthName}
                        </Text>
                      )}
                    </Table.Summary.Cell>
                  );
                }
              }

              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={1} colSpan={3}>
                      <h3 style={{ textAlign: "center" }}>{t("Average")}</h3>
                    </Table.Summary.Cell>

                    {items}

                    <Table.Summary.Cell index={10}>
                      <Text>
                        <p style={{ textAlign: "right", width: 100 }}>
                          {" "}
                          {total.toFixed(0)}
                        </p>
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <Card
      title={
        <span>
          <CalendarOutlined style={{ marginRight: 8 }} />
          {t("RevenueSummary")}
        </span>
      }
      extra={
        <div style={{ display: "flex", alignItems: "center" }}>
          <Space>
            <DatePicker
              name="year"
              picker="year"
              placeholder={t("Selectyear")}
              style={{ width: 200, height: 35 }}
              onChange={onChangee}
              defaultValue={dayjs(date)}
              suffixIcon={<RightOutlined />}
            />

            <Link
              to={{
                pathname: `/revenue`,
              }}
            >
              <Button
                className="Create-button"
                type="primary"
                danger
                icon={<RollbackOutlined style={{ color: "#fff" }} />}
                style={{
                  marginLeft: "flex",
                  width: 200,
                  height: 35,
                }}
              >
                {t("GoBackToRevenue")}
              </Button>
            </Link>
          </Space>
        </div>
      }
    >
      <Tabs
        style={{ marginBottom: 45, marginTop: 25, color: "#059BFF" }}
        type="card"
        centered
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
      />
    </Card>
  );
}

export default RevenueSummary;

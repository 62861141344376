import React, { useState, useEffect } from "react";
import {
  Collapse,
  Button,
  Input,
  Card,
  Popconfirm,
  Select,
  Tooltip,
} from "antd";
import { JSON_API } from "../../../services/Constants";
import { useTranslation } from "react-i18next";
import {
  EditOutlined,
  DeleteOutlined,
  CaretRightOutlined,
  PlusOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { useSelectedChartAccounts } from "./ChartAccountContext";
import axios from "axios";

const { Panel } = Collapse;

const Section = ({
  initialSection,
  complexity,
  partId,
  deleteSection,
  categoryId,
  editSection,
  enterpriseId,
  isReadOnly,
}) => {
  const [section, setSection] = useState(initialSection);
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState();
  const [editedChartAccountId, setEditedChartAccountId] = useState(0);
  const { selectedChartAccountIds, setSelectedChartAccountIds } =
    useSelectedChartAccounts();
  const [chartAccounts, setChartAccounts] = useState([
    { id: 0, glAccount: { id: 0, glNumber: "NULL", description: "default" } },
  ]);
  let uniqueId = parseInt(
    `${new Date().getTime()}${Math.floor(Math.random() * 1000)}`,
    10
  );
  let { t } = useTranslation();
  
  const RESERVED_NAMES = ["Inflow Total","Outflow Total", "Summary Total","Final Cash","Difference","Intermediate Cash", "Inflow Global Total"]; 
  useEffect(() => {
    axios
      .get(
        `${JSON_API}/ChartAccounts?enterpriseId=${enterpriseId}&year=${2023}`
      )
      .then((response) => {
        setChartAccounts(response.data);
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the chart accounts: ",
          error
        );
      });
  }, [enterpriseId]);
  // ... (All the other methods from the Sections component, slightly modified to work with the single section)

  const handleEditClick = () => {
    setIsEditing(true);
    setEditedName(section.name);
    setEditedChartAccountId(section.chartAccountId);
  };

  const handleInputChange = (e) => {
    const newName = e.target.value;
      setEditedName(newName);
  };

  const handleDeleteClick = (event) => {
    deleteSection(partId, section.id); // directly referencing section prop

    if (section) {
      setSelectedChartAccountIds((prevSelected) => {
        return prevSelected.filter(
          (accountId) => accountId !== section.chartAccountId
        );
      });
    }
    // No need to filter out and set sections as you have only one section
    event.stopPropagation();
  };

  const addSubsectionToSection = () => {
    const newSubsection = {
      id: uniqueId,
      name: "New Subsection",
      chartAccountId: 0,
      subSections: [],
    };

    if (section.subSections) {
      section.subSections.push(newSubsection);
    } else {
      section.subSections = [newSubsection];
    }

    editSection(partId, section, categoryId);
  };

  const editSubsection = (subsectionId, newName, newChartAccountId) => {
    console.log(
      `Edit a subsection: sectionId ${section.id}, subsectionId ${subsectionId}, newName ${newName}, newChartAccountId ${newChartAccountId}`
    );

    const subsectionToEdit = section.subSections.find(
      (ss) => ss.id === subsectionId
    );

    if (subsectionToEdit) {
      subsectionToEdit.name = newName;
      subsectionToEdit.chartAccountId = newChartAccountId;
      console.log("Updated subsection:", JSON.stringify(subsectionToEdit));
      // No need to update the entire sections array anymore,
      // just directly set the modified section
      setSection({ ...section });
    } else {
      console.error(`Subsection with id ${subsectionId} not found.`);
    }
  };

  const deleteSubSection = (subSectionId) => {
    console.log(`delete a subsection id ${subSectionId}`);

    if (!section.subSections) return;

    // Filter out the subsection to be deleted
    const updatedSubSections = section.subSections.filter(
      (s) => s.id !== subSectionId
    );

    // Update the section's subSections
    const updatedSection = {
      ...section,
      subSections: updatedSubSections,
    };

    setSection(updatedSection);
  };
  const handleSelectChange = (value) => {
    if (value === 0) {
      section.name = "New Section";
      setEditedName("custom section");
    } else {
      const selectedAccount = chartAccounts.find(
        (account) => account.id === value
      );
      if (selectedAccount) {
        section.name = `${selectedAccount.glAccount.glNumber}-${selectedAccount.glAccount.description}`;
        section.chartAccountId = selectedAccount.id;
        setEditedName(section.name);
        setEditedChartAccountId(section.chartAccountId);
      }
    }

    updateSelectedChartAccountIds(section.chartAccountId, value);
    section.chartAccountId = value;
    setSection({ ...section });
  };
  const updateSelectedChartAccountIds = (oldId, newId) => {
    setSelectedChartAccountIds((prevSelected) => {
      const updated = prevSelected.filter((id) => id !== oldId);
      updated.push(newId);
      return updated;
    });
  };
  // Save Changes
  const saveChanges = (section) => {
    console.log(
      `part Id ${partId}, sectionId ${section.id}, newName ${editedName}`
    );
    if (!partId || !section.id || !editedName) {
      console.error("Missing variables. Aborting save.");
      return;
    }
    if (section) {
      if (isValidName(editedName)) {
        section.name = editedName;
      } else {
        // Optional: You can notify the user that the entered name is reserved.
        alert("This name is reserved. Please choose a different name.");
      }
      section.chartAccountId = editedChartAccountId;
      // Assuming editSection updates the section in the parent component or elsewhere.
      editSection(partId, section, categoryId);
    } else {
      console.error("Section not found. Aborting save.");
    }

    setIsEditing(false);
  };
  const isValidName = (name) => {
    const lowerCaseName = name.toLowerCase(); // Convert input name to lowercase
    return !RESERVED_NAMES.some(reserved => reserved.toLowerCase() === lowerCaseName);
  };  
  return (
    <div style={{marginLeft: partId==4 && complexity==2?50:0}} >
    <Collapse
      justify="center"
      align="middle"
      accordion
      bordered={false}
      expandIcon={({ isActive }) => ( <CaretRightOutlined rotate={isActive ? 90 : 0} /> )}

    >
      {/* No need to map sections anymore. Just render the single section. */}
      <Panel
        key={section.id}
        style={{
          borderRadius: 4,
          margin: "10px 0",
          overflow: "hidden",
          fontWeight: section.isImmutable ? "bold" : "normal",
          backgroundColor: section.isImmutable ? "" :"#cbf4ff",
          height:50
        }}
        header={
          isEditing ? (
            <>
              <Select
                style={{ width: 150, marginRight: 10 }}
                value={section.chartAccountId}
                defaultValue={0}
                onChange={(value) => handleSelectChange(value)}
                disabled={selectedChartAccountIds.includes(
                  section.chartAccountId
                )}
              >
                {chartAccounts.map((account) => (
                  <Select.Option
                    key={account.id}
                    value={account.id}
                    disabled={selectedChartAccountIds.includes(account.id)}
                  >
                    {account.glAccount.glNumber +
                      "-" +
                      account.glAccount.description}
                  </Select.Option>
                ))}
              </Select>
              <Input
                style={{ width: 150, height: 32 }}
                value={editedName}
                onChange={handleInputChange}
                autoFocus
              />
              <Button
                type="link"
                icon={<CheckOutlined />}
                style={{ marginLeft: 5, fontWeight: "bold", color: "green" }}
                onClick={() => saveChanges(section)}
              >
                Save
              </Button>
            </>
          ) : (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>{section?.name}</span>
              {!section?.isImmutable && (
                <div>
                  <Button
                    disabled={isReadOnly}
                    type="link"
                    icon={<EditOutlined />}
                    style={{ marginRight: 5 }}
                    onClick={() => handleEditClick(section.id)}
                  />
                  <Popconfirm
                    title={t("Areyousuretodeletethissection")}
                    okText="Yes"
                    cancelText="No"
                    onConfirm={(event) => handleDeleteClick(event, section.id)}
                  >
                    <span onClick={(e) => e.stopPropagation()}>
                      <Button
                        disabled={isReadOnly}
                        type="link"
                        icon={
                          <DeleteOutlined
                            style={{ color: "red", marginRight: 20 }}
                          />
                        }
                      />
                    </span>
                  </Popconfirm>
                  {/* Moving arrows removed since it's a single section. */}
                </div>
              )}
            </div>
          )
        }
      >
        {/* <Card bordered={false}>
          <Tooltip title="add sub-section">
            <Button
              onClick={() => addSubsectionToSection(section.id)}
              type="link"
              size="small"
              shape="circle"
              icon={
                <PlusOutlined style={{ color: "white", marginRight: -5 }} />
              }
              style={{
                position: "absolute",
                fontWeight: "bold",
                backgroundColor: "#5D81D5",
                top: 10,
                right: 20,
                zIndex: 1,
              }}
            />
          </Tooltip>
          <br />
        </Card> */}
      </Panel>
    </Collapse>
    </div>
  );
};

export default Section;

import React, { createContext, useContext, useState } from "react";

const ChartAccountContext = createContext();

export const useSelectedChartAccounts = () => {
  const context = useContext(ChartAccountContext);
  if (!context) {
    throw new Error(
      "use Selected Chart Accounts must be used with in a Selected Chart Account Provider"
    );
  }
  return context;
};

export const SelectedChartAccountProvider = ({ children }) => {
  const [selectedChartAccountIds, setSelectedChartAccountIds] = useState([]);
  const value = { selectedChartAccountIds, setSelectedChartAccountIds };

  return (
    <ChartAccountContext.Provider value={value}>
      {children}
    </ChartAccountContext.Provider>
  );
};

import {
  DeleteOutlined,
  InfoCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Tabs,
  Typography,
  message,
} from "antd";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CompanyContext } from "../../contexts/CompanyContext";
import { JSON_API } from "../../services/Constants";
import Budget from "./Tables/Budget";
import HyphothesisOfGl from "./Tables/HyphothesisOfGl";
import Performance from "./Tables/Performance";
import Reals from "./Tables/Reals";
import { useAbility } from "../../contexts/abilityContext";

import { Link, useHistory, useParams } from "react-router-dom";
const { Option } = Select;

const { TextArea } = Input;
const { Text } = Typography;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 12,
  },
};

// ON CHANGE SELECT
const handleChange = (value) => {
  console.log(`selected ${value}`);
};
// For tabs
const onChange = (key) => {
  console.log(key);
};

//for tabs Form
const onChangee = (key) => {
  console.log(key);
};

function LiabilityDetail() {
  const { ability } = useAbility();
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const canUpdate = ability.can("update", "Liabilities");
  const { Company } = useContext(CompanyContext);
  const [messageApi, contextHolder] = message.useMessage();
  const { id } = useParams();
  const [balance, setbalance] = useState("");
  const { TextArea } = Input;
  const [liability, setliability] = useState([]);
  const [Real, setReal] = useState([]);
  const [performance, setperformance] = useState([]);
  const [Description, setDescription] = useState("");
  const [Category, setCategory] = useState();
  const [CategoryName, setCategoryName] = useState();
  const [open, setOpen] = useState(false);
  const [glAccountId, setglAccountId] = useState(null);
  const history = useHistory();
  const [gifi, setGifi] = useState("");
  const [glAccount, setglAccount] = useState(null);
  const [Hypo, setHypo] = useState(null);
  const [statementcategory, setStatementCategory] = useState([{}]);
  const [statementclass, setStatementClass] = useState([{}]);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [Class, setClass] = useState();
  const [glAccountDescription, setGlAccountDescription] = useState("");

  useEffect(() => {
    getLiability();
    console.log(CategoryName);
    getReals();
    getPerformance();
    getStatementClass();
    getHypothesis();
  }, [Company.id]);

  function handleredirection() {
    history.push("/liabilities");
    getLiability(false);
  }

  const getLiability = async () => {
    try {
      const res = await axios.get(
        `${JSON_API}/Liability/LiabilityBudgets/${id}`
      );
      console.log("dataaaaa:", res.data);
      setliability(res.data);

      if (res.data.financialStatementCategory) {
        setCategory(res.data.financialStatementCategory.id);
        setCategoryName(
          currentLang === "fr"
            ? res.data.financialStatementCategory.labelFr
            : res.data.financialStatementCategory.label
        );
      }

      if (res.data.financialStatementClass) {
        setClass(res.data.financialStatementClass.id);
        handleclass(res.data.financialStatementClass.id);
      }

      setGifi(res.data.financialStatementType.gifi);
      setglAccount(res.data.glAccount.glNumber);
      setGlAccountDescription(
        `(${res.data.glAccount.glNumber}) : ${res.data.glAccount.description}`
      );
      setDescription(res.data.glAccount.description);
      getHypothesis(res.data.glAccount.id);
    } catch (err) {
      console.error("Error fetching assets details:", err);
    }
  };

  const getPerformance = async () => {
    await axios
      .get(`${JSON_API}/Liability/LiabilityPerformance/${id}`)
      .then((res) => {
        console.log("test", res.data);
        setperformance(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getReals = async () => {
    await axios
      .get(`${JSON_API}/Liability/LiabilityReal/${id}`)
      .then((res) => {
        console.log("test", res.data);
        setReal(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DeleteFinancialStatement = async () => {
    await axios
      .delete(`${JSON_API}/Liability/${liability.id}`)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const UpdateFinancialStatement = async () => {
    console.log(liability);
    console.log(Description);

    const obj = {
      id: liability.id,
      financialStatementClassId: liability.financialStatementClass.id,
      financialStatementCategoryId: Category,
      description: Description,
      budgets: liability.budgets,
      reals: Real.reals,
    };

    console.log(obj);
    await axios
      .put(`${JSON_API}/Liability/LiabilityBudgetUpdate`, obj)
      .then((res) => {
        getLiability();
        message.success(t("UpdatedSuccessfully"));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const months = [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
  ];

  function handleBudgetChange(budget) {
    setliability((prevState) => {
      return { ...prevState, budgets: budget };
    });

    Real.reals.map((e) => {
      budget.map((Budget) => {
        if (e.year == Budget.year) {
          const confirm = !Budget.confirmed;
          if (confirm) {
            const startingMonthIndex = Company.startPeriod - 1;
            for (let i = 0; i < months.length; i++) {
              const monthIndex = (i + startingMonthIndex) % months.length;
              const monthName = months[monthIndex];
              e.reals[monthIndex] = Budget.budgets[monthIndex];
            }
          }
        }
      });
    });

    console.log(liability.budgets);
  }
  function handleRealChange(real) {
    setReal((prevState) => {
      return { ...prevState, reals: real };
    });
  }

  useEffect(() => {
    getLiability();
    getStatementClass();
    handleclass();
    getHypothesis();
    console.log(liability);
  }, []);

  const getHypothesis = async (e) => {
    await axios
      .get(`${JSON_API}/GLAccount/${e}`)
      .then((res) => {
        console.log("hypothesis: ", res.data);
        setHypo(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleclass = async (ClassId) => {
    await axios
      .get(`${JSON_API}/FinancialStatementCategory/class/${ClassId}`)
      .then((res) => {
        console.log(res);
        setStatementCategory(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStatementClass = async () => {
    await axios
      .get(`${JSON_API}/FinancialStatementClass`)
      .then((res) => {
        console.log(res);
        setStatementClass(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const itemsForm = [
    {
      key: "1",
      align: "center",
      label: (
        <span style={{ width: 300, textAlign: "center" }}>
          {t("Maininformation")}
        </span>
      ),
      children: liability.budgets && (
        <div>
          <Form
            {...layout}
            name="nest-messages"
            style={{
              maxWidth: 800,
              margin: "auto",
            }}
          >
            <Form.Item label={t("Class")}>
              <Input
                value={
                  currentLang === "fr"
                    ? liability.financialStatementClass?.labelFr
                    : liability.financialStatementClass?.label
                }
                disabled
              />
            </Form.Item>

            <Form.Item label={t("Category")}>
              <Select
                disabled={!canUpdate}
                value={Category}
                onChange={(value) => setCategory(value)}
              >
                {statementcategory &&
                  statementcategory.map((e) => (
                    <Option key={e.id} value={e.id}>
                      {currentLang === "fr" ? e.labelFr : e.label}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item label={"Type"}>
              <Input value={gifi} disabled />
            </Form.Item>

            <Form.Item label={t("glnumber")}>
              <Input value={glAccountDescription} disabled={true} />
            </Form.Item>

            <Form.Item label={t("Description")}>
              <Input.TextArea
                disabled={!canUpdate}
                onChange={(e) => setDescription(e.target.value)}
                style={{
                  width: 400,
                }}
                value={Description}
                rows={2}
              />
            </Form.Item>
          </Form>
        </div>
      ),
    },
    {
      key: "2",
      align: "center",
      label: (
        <span style={{ width: 300, textAlign: "center" }}>
          {t("Hyphothesis")}
        </span>
      ),
      children: (
        <div>{Hypo && <HyphothesisOfGl HypothesesDataSource={Hypo} />}</div>
      ),
    },
  ];

  // for table Buget ,Reals and Performance
  const items = [
    {
      key: "1",
      label: <span style={{ width: 200, textAlign: "center" }}>Budget</span>,
      align: "center",
      children: (
        <div>
          {liability.budgets && (
            <Budget
              LiabilityBudgets={
                liability.financialStatementClass && liability.budgets
              }
              onBudgetChange={handleBudgetChange}
            />
          )}
        </div>
      ),
    },

    {
      key: "2",
      label: (
        <span style={{ width: 200, textAlign: "center" }}>{t("Real")}</span>
      ),
      align: "center",
      children: (
        <div>
          {liability.budgets && (
            <Reals
              LiabilityReals={Real.reals}
              LiabilityBudgets={
                liability.financialStatementClass && liability.budgets
              }
              onRealChange={handleRealChange}
            />
          )}
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <span style={{ width: 200, textAlign: "center" }}>Performance</span>
      ),

      align: "center",
      children: (
        <div>
          {liability.budgets && (
            <Performance
              LiabilityReals={Real.reals}
              LiabilityBudgets={
                liability.financialStatementClass && liability.budgets
              }
              LiabilityPerformance={performance.performances}
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <div>
      {contextHolder}
      <Card
        bordered={false}
        className="header-solid mb-24"
        title={
          <h3 className="font-semibold">
            {t("LiabilitiesDetail")} {t("for")} {Description}{" "}
          </h3>
        }
      >
        <Row justify="space-between">
          <ConfigProvider
            theme={{
              token: {
                // colorPrimary: "#00629d",
              },
            }}
          >
            <Space
              style={{
                display: "flex",
                marginLeft: "flex",
              }}
              align="baseline"
            >
              {ability && ability.can("update", "Liabilities") && (
                <Button
                  type="primary"
                  size="medium"
                  textAlign="center"
                  onClick={UpdateFinancialStatement}
                >
                  <SaveOutlined />
                  {t("save")}
                </Button>
              )}
            </Space>
          </ConfigProvider>

          <Space>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#FFA805",
                },
              }}
            >
              <Link to="/liabilities">
                <Button
                  type="primary"
                  style={{
                    textAlign: "right",
                    marginLeft: "flex",
                  }}
                >
                  <InfoCircleOutlined />
                  {t("backtofinancialstatement")}
                </Button>
              </Link>
            </ConfigProvider>

            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#FF0606",
                },
              }}
            >
              {ability && ability.can("delete", "Liabilities") && (
                <Button
                  type="primary"
                  style={{
                    textAlign: "right",
                    marginLeft: "flex",
                  }}
                  onClick={() => setOpen(true)}
                >
                  <DeleteOutlined />
                  {t("deletStatement")}
                </Button>
              )}
              <Modal
                title={t("Deletethestatment")}
                showIcon
                closable
                description={t("Areyousuretodeletethisone")}
                centered
                open={open}
                onOk={() => {
                  setOpen(false);
                  DeleteFinancialStatement(false);
                  handleredirection();
                }}
                okText={t("yes")}
                cancelText={t("Cancel")}
                onCancel={() => setOpen(false)}
                width={400}
              >
                <span>{t("Areyousuretodeletethisone")} </span>
              </Modal>
            </ConfigProvider>
          </Space>
        </Row>
        <br></br>

        {/* <Table columns={columns} dataSource={ChartofAccounts} bordered /> */}
        <Tabs
          style={{ marginBottom: 32, Color: "#059BFF" }}
          type="card"
          centered
          defaultActiveKey="1"
          items={itemsForm}
          onChange={onChangee}
        />
        <div>
          <Tabs
            style={{ marginBottom: 32, Color: "#059BFF" }}
            type="card"
            centered
            defaultActiveKey="1"
            items={items}
            onChange={onChange}
          />
        </div>
      </Card>
    </div>
  );
}

LiabilityDetail.acl = {
  subject: "Liabilities",
  action: "read",
};

export default LiabilityDetail;

import React, { useContext } from "react";
import { useAbility } from "../../../contexts/abilityContext";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Input, Table, Typography, Popconfirm, Tag, Button } from "antd";
import { useState } from "react";
import { CompanyContext } from "../../../contexts/CompanyContext";
import { useTranslation } from "react-i18next";
const { Text } = Typography;
const { TextArea } = Input;

function BudgetRevenue(props) {
  const { ability } = useAbility();
  const readOnly = !ability.can("update", "Revenue");
  let { t } = useTranslation();
  const [AssetBudgets, setAssetBudgets] = useState(props.AssetBudgets);
  const { TextArea } = Input;
  const { Company } = useContext(CompanyContext);
  const [revenuevalue, setrevenuevalue] = useState(0);

  function handleinputchange(ee, i, n) {
    const newBudget = [...AssetBudgets];
    newBudget.map((e) => {
      if (e.id == i) {
        e.budgets[n] = parseFloat(ee.target.value) || 0;
      }
    });
    setAssetBudgets(newBudget);
    props.onBudgetChange(newBudget);
  }

  const cancel = (e) => {
    console.log(e);
  };

  function confirm(i) {
    const newBudget = [...AssetBudgets];
    const updatedBudget = newBudget.map((e) => {
      if (e.id === i) {
        return { ...e, confirmed: true };
      }
      return e;
    });
    setAssetBudgets(updatedBudget);
    props.onBudgetChange(updatedBudget);
  }

  const items = [
    {
      title: `${t("Year")}`,
      width: 120,
      dataIndex: "year",
      align: "center",
      render: (text, record) => {
        return <p style={{ textAlign: "center" }}>{text}</p>;
      },
    },
  ];

  const months = [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
  ];

  const startingMonthIndex = Company.startPeriod - 1;

  for (let i = 0; i < months.length; i++) {
    const monthIndex = (i + startingMonthIndex) % months.length;
    const monthName = months[monthIndex];

    items.push({
      title: monthName,
      width: 120,
      align: "center",
      render: (text, record) => {
        let confirm = false;

        return (
          <>
            {props.Revenue.sellingPrice != 0
              ? (props.AssetUnit &&
                  props.AssetUnit.map((e) => {
                    if (e.year == record.year) {
                      record.budgets[monthIndex] =
                        e.units[monthIndex] * props.Revenue.sellingPrice;
                      setrevenuevalue(
                        e.units[monthIndex] * props.Revenue.sellingPrice
                      );
                    }
                  }),
                (confirm = true))
              : (setrevenuevalue(record.budgets[monthIndex]),
                (confirm = record.confirmed))}
            <Input
              disabled={confirm || readOnly}
              type="number"
              value={record.budgets[monthIndex].toFixed(0)}
              onChange={(e) => handleinputchange(e, record.id, monthIndex)}
              style={{ textAlign: "right", width: 120 }}
            />
          </>
        );
      },
    });
  }

  items.push({
    title: <h1 style={{ textAlign: "center", width: 100 }}>Total </h1>,
    width: 120,
    render: (_, record) => {
      return (
        <p style={{ textAlign: "right" }}>
          {(
            record.budgets[0] +
            record.budgets[1] +
            record.budgets[2] +
            record.budgets[3] +
            record.budgets[4] +
            record.budgets[5] +
            record.budgets[6] +
            record.budgets[7] +
            record.budgets[8] +
            record.budgets[9] +
            record.budgets[10] +
            record.budgets[11]
          ).toFixed(0)}
        </p>
      );
    },
  });
  if (!ability.can("read", "Revenue")) {
    items.push({
      title: "Actions",
      align: "center",
      width: 100,
      render: (_, record) => (
        <>
          {ability.can("update", "Revenue") &&
            (record.confirmed ? (
              <div className="subsections-container">
                <Tag color="green" icon={<CheckCircleOutlined />}>
                  {t("Confirmed")}
                </Tag>
              </div>
            ) : (
              <Popconfirm
                type="link"
                title={t("Areyousuretoconfirm")}
                onConfirm={() => confirm(record.id)}
                onCancel={cancel}
                okText={t("yes")}
                cancelText={t("no")}
              >
                <Button
                  size="medium"
                  type="primary"
                  style={{ borderRadius: "20px", textTransform: "uppercase" }}
                >
                  {t("Confirm")}
                </Button>
              </Popconfirm>
            ))}
        </>
      ),
    });
  }

  if (
    ability.can("update", "Order book") ||
    ability.can("delete", "Order book")
  ) {
    items.push({
      title: "Actions",
      align: "center",
      width: 100,
      render: (_, record) => (
        <>
          {ability.can("update", "Revenue") &&
            (record.confirmed ? (
              <div className="subsections-container">
                <Tag color="green" icon={<CheckCircleOutlined />}>
                  {t("Confirmed")}
                </Tag>
              </div>
            ) : (
              <Popconfirm
                type="link"
                title={t("Areyousuretoconfirm")}
                onConfirm={() => confirm(record.id)}
                onCancel={cancel}
                okText={t("yes")}
                cancelText={t("no")}
              >
                <Button
                  size="medium"
                  type="primary"
                  style={{ borderRadius: "20px", textTransform: "uppercase" }}
                >
                  {t("Confirm")}
                </Button>
              </Popconfirm>
            ))}
        </>
      ),
    });
  }

  return (
    <Table
      columns={items}
      dataSource={props.AssetBudgets}
      scroll={{
        x: 1300,
      }}
      pagination={true}
      bordered
      summary={(pageData) => {
        const items = [];

        const startingMonthIndex = Company.startPeriod - 1;

        let totalmontant = 0;
        let totalbudget = 0;
        let totalfevrierbudget = 0;
        let totalMarsbudget = 0.0;
        let totalavrilbudget = 0;
        let totalmaibudget = 0;
        let totalJuinbudget = 0.0;
        let totaljuilletbudget = 0;
        let totalaoutbudget = 0;
        let totalseptembrebudget = 0;
        let totaloctobrebudget = 0;
        let totalnovemberbudget = 0;
        let totaldecembrebudget = 0;
        let totaltotal = 0;

        let totalbudgetcount = 0;
        let totalfevrierbudgetcount = 0;
        let totalMarsbudgetcount = 0;
        let totalavrilbudgetcount = 0;
        let totalmaibudgetcount = 0;
        let totalJuinbudgetcount = 0;
        let totaljuilletbudgetcount = 0;
        let totalaoutbudgetcount = 0;
        let totalseptembrebudgetcount = 0;
        let totaloctobrebudgetcount = 0;
        let totalnovemberbudgetcount = 0;
        let totaldecembrebudgetcount = 0;

        if (props.Revenue.sellingPrice != 0) {
          {
            props.AssetUnit &&
              props.AssetUnit.map((e) => {
                //console.log(e.budgets[1]),
                totalmontant = e.units[0] * props.Revenue.sellingPrice;

                totalbudget += totalmontant;
                totalmontant = e.units[1] * props.Revenue.sellingPrice;
                totalfevrierbudget += totalmontant;
                totalmontant = e.units[2] * props.Revenue.sellingPrice;
                totalMarsbudget += totalmontant;
                totalmontant = e.units[3] * props.Revenue.sellingPrice;
                totalavrilbudget += totalmontant;
                totalmontant = e.units[4] * props.Revenue.sellingPrice;
                totalmaibudget += totalmontant;
                totalmontant = e.units[5] * props.Revenue.sellingPrice;
                totalJuinbudget += totalmontant;
                totalmontant = e.units[6] * props.Revenue.sellingPrice;
                totaljuilletbudget += totalmontant;
                totalmontant = e.units[7] * props.Revenue.sellingPrice;
                totalaoutbudget += totalmontant;
                totalmontant = e.units[8] * props.Revenue.sellingPrice;
                totalseptembrebudget += totalmontant;
                totalmontant = e.units[9] * props.Revenue.sellingPrice;
                totaloctobrebudget += totalmontant;
                totalmontant = e.units[10] * props.Revenue.sellingPrice;
                totalnovemberbudget += totalmontant;
                totalmontant = e.units[11] * props.Revenue.sellingPrice;
                totaldecembrebudget += totalmontant;
                totalmontant = e.totalBudget;
                totaltotal += totalmontant;
              });
          }
        } else {
          {
            props.AssetBudgets &&
              props.AssetBudgets.map((e) => {
                //console.log(e.budgets[1]),
                totalmontant = e.budgets[0];
                if (totalmontant != 0) {
                  totalbudgetcount += 1;
                }
                totalbudget += totalmontant;
                totalmontant = e.budgets[1];
                if (totalmontant != 0) {
                  totalfevrierbudgetcount += 1;
                }
                totalfevrierbudget += totalmontant;
                totalmontant = e.budgets[2];
                if (totalmontant != 0) {
                  totalMarsbudgetcount += 1;
                }
                totalMarsbudget += totalmontant;
                totalmontant = e.budgets[3];
                if (totalmontant != 0) {
                  totalavrilbudgetcount += 1;
                }
                totalavrilbudget += totalmontant;
                totalmontant = e.budgets[4];
                if (totalmontant != 0) {
                  totalmaibudgetcount += 1;
                }
                totalmaibudget += totalmontant;
                totalmontant = e.budgets[5];
                if (totalmontant != 0) {
                  totalJuinbudgetcount += 1;
                }
                totalJuinbudget += totalmontant;
                totalmontant = e.budgets[6];
                if (totalmontant != 0) {
                  totaljuilletbudgetcount += 1;
                }
                totaljuilletbudget += totalmontant;
                totalmontant = e.budgets[7];
                if (totalmontant != 0) {
                  totalaoutbudgetcount += 1;
                }
                totalaoutbudget += totalmontant;
                totalmontant = e.budgets[8];
                if (totalmontant != 0) {
                  totalseptembrebudgetcount += 1;
                }
                totalseptembrebudget += totalmontant;
                totalmontant = e.budgets[9];
                if (totalmontant != 0) {
                  totaloctobrebudgetcount += 1;
                }
                totaloctobrebudget += totalmontant;
                totalmontant = e.budgets[10];
                if (totalmontant != 0) {
                  totalnovemberbudgetcount += 1;
                }
                totalnovemberbudget += totalmontant;
                totalmontant = e.budgets[11];
                if (totalmontant != 0) {
                  totaldecembrebudgetcount += 1;
                }
                totaldecembrebudget += totalmontant;
                totalmontant = e.totalBudget;
                totaltotal += totalmontant;
              });
          }
        }

        const months = [
          totalbudget,
          totalfevrierbudget,
          totalMarsbudget,
          totalavrilbudget,
          totalmaibudget,
          totalJuinbudget,
          totaljuilletbudget,
          totalaoutbudget,
          totalseptembrebudget,
          totaloctobrebudget,
          totalnovemberbudget,
          totaldecembrebudget,
        ];
        const monthscount = [
          totalbudgetcount,
          totalfevrierbudgetcount,
          totalMarsbudgetcount,
          totalavrilbudgetcount,
          totalmaibudgetcount,
          totalJuinbudgetcount,
          totaljuilletbudgetcount,
          totalaoutbudgetcount,
          totalseptembrebudgetcount,
          totaloctobrebudgetcount,
          totalnovemberbudgetcount,
          totaldecembrebudgetcount,
        ];
        for (let i = 0; i < months.length; i++) {
          const monthIndex = (i + startingMonthIndex) % months.length;
          const monthName = months[monthIndex];
          let monthNamecount = monthscount[monthIndex];
          {
            if (monthNamecount == 0) {
              monthNamecount = 1;
            }
          }

          items.push(
            <Table.Summary.Cell index={monthIndex}>
              <Text style={{ textAlign: "right" }}>
                {(monthName / monthNamecount).toFixed(0)}
              </Text>
            </Table.Summary.Cell>
          );
        }

        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={1} colSpan={1}>
                <h3 style={{ textAlign: "center" }}>{t("Average")}</h3>
              </Table.Summary.Cell>

              {items}

              <Table.Summary.Cell index={10}>
                <Text>
                  {(
                    totalbudget +
                    totalfevrierbudget +
                    totalMarsbudget +
                    totalavrilbudget +
                    totalmaibudget +
                    totalJuinbudget +
                    totaljuilletbudget +
                    totalaoutbudget +
                    totalseptembrebudget +
                    totaloctobrebudget +
                    totalnovemberbudget +
                    totaldecembrebudget
                  ).toFixed(0)}
                </Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        );
      }}
    />
  );
}

BudgetRevenue.acl = {
  subject: "Revenue",
  action: "read",
};

export default BudgetRevenue;

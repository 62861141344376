import {useContext, useEffect, useState} from "react";

import {Button, Card, Col, Drawer, message, Row, Switch, Typography,} from "antd";

import {SettingOutlined} from "@ant-design/icons";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import {useTranslation} from "react-i18next";
import {CompanyContext} from "../contexts/CompanyContext";
import {JSON_API} from "../services/Constants";
import {useAbility} from "../contexts/abilityContext";
import { generateMonthsArray, getFiscalYearForMonth,getRealOrEstimateForMonthHeaderTitle } from "../services/utilities";

function Home() {
    const {Title, Text} = Typography;
    const {ability} = useAbility();
    let {t} = useTranslation();
    const [open, setopen] = useState(false);
    const {Company} = useContext(CompanyContext);
    const [messageApi, contextHolder] = message.useMessage();
    const [Dashboard, setDashboard] = useState({});
    const [YearBudgetDashboard, setYearBudgetDashboard] = useState([]);
    const [BudgetDashboard, setBudgetDashboard] = useState([]);
    const [RealDashboard, setRealDashboard] = useState([]);
    const [RHrealDashboard, setRHrealDashboard] = useState([]);
    const [RHbudgetDashboard, setRHbudgetDashboard] = useState([]);
    const [rhRealDashboard, setrhRealDashboard] = useState([]);
    const [rhbudgetDashboard, setrhbudgetDashboard] = useState([]);
    const [rhrealDashboard, setrhrealDashboard] = useState([]);
    const [rhsalaryDashboard, setrhsalaryDashboard] = useState([]);
    const [arrayrhDashboard, setarrayrhDashboard] = useState([]);
    const [rhDashboard, setrhDashboard] = useState([]);
    const [Dashboardsales, setDashboardsales] = useState({});
    const [YearBudgetDashboardsales, setYearBudgetDashboardsales] = useState([]);
    const [BudgetDashboardsales, setBudgetDashboardsales] = useState([]);
    const [RealDashboardsales, setRealDashboardsales] = useState([]);
    const [LabelDashboard, setLabelDashboard] = useState([]);
    const [ProductDashboard, setProductDashboard] = useState([]);
    const [RevenueRealDashboard, setRevenueRealDashboard] = useState([]);
    const [RevenuebudgetDashboard, setRevenuebudgetDashboard] = useState([]);
    const [RevenuerealDashboard, setRevenuerealDashboard] = useState([]);
    const [RevenueDashboard, setRevenueDashboard] = useState([]);
    const [Dashboardnumbers, setDashboardnumbers] = useState({});
    const [Dashboardsettings, setDashboardsettings] = useState({});
    const [col, setcol] = useState(1);
    const [chart1, setchart1] = useState(1);
    const [chart2, setchart2] = useState(1);
    const [chart3, setchart3] = useState(1);
    const [startPeriod, setstartPeriod] = useState(1);
    const handleDrawer = () => {
        setopen(true);
    };
    const onClose = () => {
        setopen(false);
    };

    useEffect(() => {
        getDashboardsales();
        getDashboard();
        getDashboardsettings();
        getDashboardNumbers();
        getStartPeriode();
    }, [Company.id]);

    const getStartPeriode = async () => {
        await axios
            .get(`${JSON_API}/Enterprises/main-info`)
            .then((res) => {
                const filteredData = res.data.filter((item) => item.id === Company.id);

                if (filteredData.length > 0) {
                    setstartPeriod(filteredData[0].startPeriod);
                } else {
                    // Handle case when no matching item is found
                    setstartPeriod(1); // Or any other default value
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    // Exemple: supposons que la période de début est juillet (7ème mois)

    const months = [
        `${t("January")}`,
        `${t("February")}`,
        `${t("March")}`,
        `${t("April")}`,
        `${t("May")}`,
        `${t("June")}`,
        `${t("July")}`,
        `${t("August")}`,
        `${t("September")}`,
        `${t("October")}`,
        `${t("November")}`,
        `${t("December")}`,
    ];

    // Réorganiser les mois en fonction de la période de début
    const reorderedMonths = [
        ...months.slice(startPeriod - 1), // Commencez par le mois de la période de début
        ...months.slice(0, startPeriod - 1), // Puis ajoutez les mois avant la période de début
    ];

    const getDashboardsales = async () => {
        const array = [];
        const arraybudget = [];
        const arrayreal = [];
        const arrayLabel = [];
        const arrayProduct = [];
        const arrayRevenueReal = [];
        const arrayrevenuebudget = [];
        const arrayrevenuereal = [];
        const arrayrevenue = [];
        await axios
            .get(`${JSON_API}/Revenue/RevenueDashboard/${Company.id}`)
            .then((res) => {
                setDashboardsales(res.data);
                res.data.revenueRealTotalsByProduct.forEach((e) => {
                    arrayLabel.push(e.productLabel);
                    arrayProduct.push(e.realValue);
                });
                res.data.revenueTotalsByYear.forEach((e) => {
                    array.push(e.year);
                    arraybudget.push((e.budget / 1000).toFixed(2));
                    arrayreal.push((e.real / 1000).toFixed(2));
                });
                res.data.currentYearRevenueTotalsPercent.forEach((e) => {
                    arrayRevenueReal.push(e.realTotalBeforeCurrentMonthPercent.toFixed(2));
                    arrayRevenueReal.push(e.estimatedTotalAfterCurrentMonthPercent.toFixed(2));
                    arrayRevenueReal.push(e.achievedBudgetPercent.toFixed(2));
                });
                var currentYear = new Date().getFullYear();
                var currentMonth = new Date().getMonth() + 1;

                const reorderedRevenue = [
                    ...res.data.revenueTotalsByMonth.slice(Company.startPeriod - 1),
                    ...res.data.revenueTotalsByMonth.slice(0, Company.startPeriod - 1),
                ];
                const reorderedMonths = generateMonthsArray(Company.startPeriod);
                reorderedRevenue.forEach((e, index) => {
                    
                    arrayrevenuebudget.push((e.budget / 1000).toFixed(2));
                    let fiscalYearOfMonth = getFiscalYearForMonth(
                        reorderedMonths[index],
                        Company.startPeriod,
                        currentYear
                    );
                    let title = getRealOrEstimateForMonthHeaderTitle(
                        fiscalYearOfMonth,
                        currentYear,
                        reorderedMonths[index],
                        currentMonth
                    );
                    
                    if (title === "Real") {
                        arrayrevenuereal.push((e.real / 1000).toFixed(2));
                        arrayrevenue.push(0);
                    } else {
                        arrayrevenue.push((e.real / 1000).toFixed(2));
                        arrayrevenuereal.push(0);
                    }
                });

                setYearBudgetDashboardsales(array);
                setBudgetDashboardsales(arraybudget);
                setRealDashboardsales(arrayreal);
                setLabelDashboard(arrayLabel);
                setProductDashboard(arrayProduct);
                setRevenueRealDashboard(arrayRevenueReal);
                setRevenuebudgetDashboard(arrayrevenuebudget);
                setRevenuerealDashboard(arrayrevenuereal);
                setRevenueDashboard(arrayrevenue);
                
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getDashboard = async () => {
        const array = [];
        const arraysalarybudget = [];
        const arraysalaryreal = [];
        const arrayrhsalary = [];
        const arraysalary = [];
        const arrayLabel = [];
        const arrayProduct = [];
        const arrayRHReal = [];
        const arrayrhbudget = [];
        const arrayrhreal = [];
        const arrayRhbudget = [];
        const arrayRhreal = [];
        const arrayrh = [];
        await axios
            .get(`${JSON_API}/Employees/RHDashboard/${Company.id}`)
            .then((res) => {
                setDashboard(res.data);
                res.data.salaryTotalsByYear.forEach((e) => {
                    array.push(e.year);
                    arraysalarybudget.push((e.budget / 1000).toFixed(2));
                    arraysalaryreal.push((e.real / 1000).toFixed(2));
                });
                res.data.employeesCountByYear.forEach((e) => {
                    array.push(e.year);
                    arrayRhbudget.push(e.countBudget);
                    arrayRhreal.push(e.countReal);
                });
                res.data.currentYearSalaryTotalsPercent.forEach((e) => {
                    arrayRHReal.push(e.realTotalBeforeCurrentMonthPercent.toFixed(2));
                    arrayRHReal.push(e.estimatedTotalAfterCurrentMonthPercent.toFixed(2));
                    arrayRHReal.push(e.achievedBudgetPercent.toFixed(2));
                });
                res.data.currentSalary.forEach((e) => {
                    arrayrhsalary.push((e.nonContractualTotalBudgetCurrentYear / 1000).toFixed(2));
                    arraysalary.push((e.nonContractualTotalRealCurrentYear / 1000).toFixed(2));
                    arrayrhsalary.push((e.contractualTotalBudgetCurrentYear / 1000).toFixed(2));
                    arraysalary.push((e.contractualTotalRealCurrentYear / 1000).toFixed(2));
                    arrayrhsalary.push(
                        ((e.nonContractualTotalBudgetCurrentYear + e.contractualTotalBudgetCurrentYear) / 1000).toFixed(2)
                    );
                    arraysalary.push(((e.nonContractualTotalRealCurrentYear + e.contractualTotalRealCurrentYear) / 1000).toFixed(2));
                });
                var currenttime = res.data.currentMonth;
                var currentYear = new Date().getFullYear(); // Utiliser l'année actuelle
                var currentMonth = new Date().getMonth() + 1; // Utiliser le mois actuel (0-11, donc +1)

                // Fonction pour obtenir le nom du mois à partir de son index
                function getMonthName(index) {
                    const months = [
                        "January",
                        "February",
                        "March",
                        "April",
                        "May",
                        "June",
                        "July",
                        "August",
                        "September",
                        "October",
                        "November",
                        "December",
                    ];

                    // Vérifier si l'index est valide
                    if (index < 1 || index > 12) {
                        return "Invalid Month";
                    }

                    return months[index - 1];
                }

                const reorderedMonths = generateMonthsArray(Company.startPeriod);
                // Réorganiser les dépenses par mois en fonction de la période de début de l'entreprise
                const reorderhByMonths = [
                    ...res.data.salaryTotalsByMonth.slice(Company.startPeriod - 1), // Commencez par le mois de la période de début
                    ...res.data.salaryTotalsByMonth.slice(0, Company.startPeriod - 1), // Puis ajoutez les mois avant la période de début
                ];
                reorderhByMonths.forEach((e, index) => {

                    arrayrhbudget.push((e.budget / 1000).toFixed(2));

                    let fiscalYearOfMonth = getFiscalYearForMonth(
                        reorderedMonths[index],
                        Company.startPeriod,
                        currentYear
                    );
                    let title = getRealOrEstimateForMonthHeaderTitle(
                        fiscalYearOfMonth,
                        currentYear,
                        reorderedMonths[index],
                        currentMonth
                    );
                    if (title === "Real") {
                        arrayrhreal.push((e.real / 1000).toFixed(2));
                        arrayrh.push(0);
                    } else {
                        arrayrh.push((e.real / 1000).toFixed(2));
                        arrayrhreal.push(0);
                    }
                    
                });
                setYearBudgetDashboard(array);
                setBudgetDashboard(arraysalarybudget);
                setRealDashboard(arraysalaryreal);
                setrhsalaryDashboard(arrayrhsalary);
                setarrayrhDashboard(arraysalary);
                setRHbudgetDashboard(arrayRhbudget);
                setRHrealDashboard(arrayRhreal);
                setrhRealDashboard(arrayRHReal);
                setrhbudgetDashboard(arrayrhbudget);
                setrhrealDashboard(arrayrhreal);
                setrhDashboard(arrayrh);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getDashboardNumbers = async () => {
        await axios
            .get(`${JSON_API}/DashboardSettings/Dashboard/${Company.id}`)
            .then((res) => {
                setDashboardnumbers(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getDashboardsettings = async () => {
        await axios
            .get(`${JSON_API}/DashboardSettings/${Company.id}`)
            .then((res) => {
                setDashboardsettings(res.data);
                setchart1(res.data.chiffreDAffaireParAnnee);
                setchart2(res.data.venteParProduit);
                setchart3(res.data.chiffreDAffaireBudget);
                if (
                    res.data.chiffreDAffaireParAnnee &&
                    res.data.venteParProduit &&
                    res.data.chiffreDAffaireBudget
                ) {
                    setcol(1);
                } else {
                    setcol(0);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const updatesettings = async () => {
        await axios
            .put(`${JSON_API}/DashboardSettings`, Dashboardsettings)
            .then((res) => {
                messageApi.open({
                    type: "success",
                    content: `${t("UpdatedSuccessfully")}`,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const dollor = [
        <svg
            width="22"
            height="22"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            key={0}
        >
            <path
                d="M8.43338 7.41784C8.58818 7.31464 8.77939 7.2224 9 7.15101L9.00001 8.84899C8.77939 8.7776 8.58818 8.68536 8.43338 8.58216C8.06927 8.33942 8 8.1139 8 8C8 7.8861 8.06927 7.66058 8.43338 7.41784Z"
                fill="#fff"
            ></path>
            <path
                d="M11 12.849L11 11.151C11.2206 11.2224 11.4118 11.3146 11.5666 11.4178C11.9308 11.6606 12 11.8861 12 12C12 12.1139 11.9308 12.3394 11.5666 12.5822C11.4118 12.6854 11.2206 12.7776 11 12.849Z"
                fill="#fff"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 5C11 4.44772 10.5523 4 10 4C9.44772 4 9 4.44772 9 5V5.09199C8.3784 5.20873 7.80348 5.43407 7.32398 5.75374C6.6023 6.23485 6 7.00933 6 8C6 8.99067 6.6023 9.76515 7.32398 10.2463C7.80348 10.5659 8.37841 10.7913 9.00001 10.908L9.00002 12.8492C8.60902 12.7223 8.31917 12.5319 8.15667 12.3446C7.79471 11.9275 7.16313 11.8827 6.74599 12.2447C6.32885 12.6067 6.28411 13.2382 6.64607 13.6554C7.20855 14.3036 8.05956 14.7308 9 14.9076L9 15C8.99999 15.5523 9.44769 16 9.99998 16C10.5523 16 11 15.5523 11 15L11 14.908C11.6216 14.7913 12.1965 14.5659 12.676 14.2463C13.3977 13.7651 14 12.9907 14 12C14 11.0093 13.3977 10.2348 12.676 9.75373C12.1965 9.43407 11.6216 9.20873 11 9.09199L11 7.15075C11.391 7.27771 11.6808 7.4681 11.8434 7.65538C12.2053 8.07252 12.8369 8.11726 13.254 7.7553C13.6712 7.39335 13.7159 6.76176 13.354 6.34462C12.7915 5.69637 11.9405 5.26915 11 5.09236V5Z"
                fill="#fff"
            ></path>
        </svg>,
    ];
    const profile = [
        <svg
            width="22"
            height="22"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            key={0}
        >
            <path
                d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z"
                fill="#fff"
            ></path>
            <path
                d="M17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z"
                fill="#fff"
            ></path>
            <path
                d="M12.9291 17C12.9758 16.6734 13 16.3395 13 16C13 14.3648 12.4393 12.8606 11.4998 11.6691C12.2352 11.2435 13.0892 11 14 11C16.7614 11 19 13.2386 19 16V17H12.9291Z"
                fill="#fff"
            ></path>
            <path
                d="M6 11C8.76142 11 11 13.2386 11 16V17H1V16C1 13.2386 3.23858 11 6 11Z"
                fill="#fff"
            ></path>
        </svg>,
    ];
    const heart = [
        <svg
            width="22"
            height="22"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            key={0}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.17157 5.17157C4.73367 3.60948 7.26633 3.60948 8.82843 5.17157L10 6.34315L11.1716 5.17157C12.7337 3.60948 15.2663 3.60948 16.8284 5.17157C18.3905 6.73367 18.3905 9.26633 16.8284 10.8284L10 17.6569L3.17157 10.8284C1.60948 9.26633 1.60948 6.73367 3.17157 5.17157Z"
                fill="#fff"
            ></path>
        </svg>,
    ];
    const cart = [
        <svg
            width="22"
            height="22"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            key={0}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 2C7.79086 2 6 3.79086 6 6V7H5C4.49046 7 4.06239 7.38314 4.00612 7.88957L3.00612 16.8896C2.97471 17.1723 3.06518 17.455 3.25488 17.6669C3.44458 17.8789 3.71556 18 4 18H16C16.2844 18 16.5554 17.8789 16.7451 17.6669C16.9348 17.455 17.0253 17.1723 16.9939 16.8896L15.9939 7.88957C15.9376 7.38314 15.5096 7 15 7H14V6C14 3.79086 12.2091 2 10 2ZM12 7V6C12 4.89543 11.1046 4 10 4C8.89543 4 8 4.89543 8 6V7H12ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10ZM13 9C12.4477 9 12 9.44772 12 10C12 10.5523 12.4477 11 13 11C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9Z"
                fill="#fff"
            ></path>
        </svg>,
    ];

    const count = [
        {
            today: t("TotalDepartement"),
            title: `${Dashboardnumbers.departement}`,
            icon: profile,
            bnb: "bnb2",
        },
        {
            today: t("TotalEmployee"),
            title: `${Dashboardnumbers.employee}`,
            icon: profile,
            bnb: "bnb2",
        },
        {
            today: t("TotalClient"),
            title: `${Dashboardnumbers.client}`,
            icon: heart,
            bnb: "redtext",
        },
        {
            today: t("TotalProduct"),
            title: `${Dashboardnumbers.product}`,
            icon: cart,
            bnb: "bnb2",
        },
    ];

    const Chartsales = {
        series: [
            {
                name: `${t("Budget")}`,
                data: BudgetDashboardsales,
            },
            {
                name: `${t("RealEstimate")}`,
                data: RealDashboardsales,
            },
        ],
        options: {
            chart: {
                type: "bar",
                height: 350,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "55%",
                    endingShape: "rounded",
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
            },
            xaxis: {
                categories: YearBudgetDashboardsales,
            },
            yaxis: {
                title: {
                    text: `${t("thousands")}`,
                },
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return (
                            `${
                                Company.currencies &&
                                Company.currencies.length > 0 &&
                                Company.currencies[0].shortLabel
                                    ? Company.currencies[0].shortLabel
                                    : "$"
                            } ` +
                            val +
                            ` ${t("thousands")}`
                        );
                    },
                },
            },
        },
    };
    const BudgetChartsales = {
        series: [
            {
                name: "values",
                data: RevenueRealDashboard,
            },
        ],
        options: {
            chart: {
                type: "bar",
                height: 350,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "55%",
                    endingShape: "rounded",
                    distributed: true,
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
            },
            xaxis: {
                categories: [
                    `${t("Real")}`,
                    `${t("Estimate")}`,
                    `${t("budgetatteint")}`,
                ],
            },
            yaxis: {
                title: {
                    text: `${t("Pourcentage")}`,
                },
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return "% " + val;
                    },
                },
            },
        },
    };

    const oldBudgetChart = {
        series: [
            {
                name: "Réel",
                data: [450, 50, 550],
                color: "#fff",
            },
        ],

        options: {
            chart: {
                type: "bar",
                width: "100%",
                height: "auto",

                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "55%",
                    borderRadius: 5,
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 1,
                colors: ["transparent"],
            },
            grid: {
                show: true,
                borderColor: "#ccc",
                strokeDashArray: 2,
            },
            xaxis: {
                categories: ["Réel", "Estimé", "%budget atteint"],
                labels: {
                    show: true,
                    align: "right",
                    minWidth: 0,
                    maxWidth: 160,
                    style: {
                        colors: ["#fff", "#fff", "#fff"],
                    },
                },
            },
            yaxis: {
                title: {
                    text: `${t("Pourcentage")}`,
                    style: {color: "white"},
                },
                labels: {
                    show: true,
                    align: "right",
                    minWidth: 0,
                    maxWidth: 160,
                    style: {
                        colors: ["#fff", "#fff", "#fff"],
                    },
                },
            },

            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + "%";
                    },
                },
            },
        },
    };
    const ProductChart = {
        series: ProductDashboard,
        options: {
            chart: {
                width: "100%",
                type: "pie",
            },
            labels: LabelDashboard,
            legend: {
                position: "left",
                itemMargin: {
                    horizontal: 5,
                    vertical: 10,
                },
                formatter: function (val, opts) {
                    const index = opts.seriesIndex;
                    const percentage = (
                        (opts.w.globals.series[index] /
                            opts.w.globals.seriesTotals.reduce((a, b) => a + b, 0)) *
                        100
                    ).toFixed(2);
                    return `${val} (${percentage}%)`;
                },
            },
            dataLabels: {
                enabled: true,
                formatter: function (val, opts) {
                    return val > 5 ? `${val.toFixed(2)}%` : "";
                },
                style: {
                    fontSize: "10px",
                    colors: ["#ffffff"],
                },
            },
            colors: [
                "#FF6633",
                "#FFB399",
                "#FF33FF",
                "#FFFF99",
                "#00B3E6",
                "#E6B333",
                "#3366E6",
                "#999966",
                "#99E6E6",
                "#66664D",
                "#B34D4D",
                "#80B300",
                "#809900",
                "#E6B3B3",
                "#6680B3",
                "#66991A",
                "#FF99E6",
                "#CCFF1A",
                "#FF1A66",
                "#E6331A",
                "#33FFCC",
                "#66994D",
                "#B366CC",
                "#4D8000",
                "#B33300",
                "#CC80CC",
                "#6666FF",
                "#991AFF",
                "#E666FF",
                "#4DB3FF",
                "#1AB399",
                "#E666B3",
                "#33991A",
                "#CC9999",
                "#B3B31A",
                "#00E680",
                "#4D8066",
                "#809980",
                "#E6FF80",
                "#1AFF33",
                "#999933",
                "#FF3380",
                "#CCCC00",
                "#66E64D",
                "#4D80CC",
                "#9900B3",
                "#E64D66",
                "#4DB380",
                "#FF4D4D",
                "#99E6E6",
                "#6666FF",
            ],
            responsive: [
                {
                    breakpoint: 500,
                    options: {
                        chart: {
                            width: 400,
                        },
                    },
                },
            ],
        },
    };
    const RealChartsales = {
        series: [
            {
                name: `${t("Budget")}`,
                data: RevenuebudgetDashboard,
            },
            {
                name: `${t("Real")}`,
                data: RevenuerealDashboard,
            },
            {
                name: `${t("Estimate")}`,
                data: RevenueDashboard,
            },
        ],
        options: {
            chart: {
                type: "bar",
                height: 350,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "55%",
                    endingShape: "rounded",
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
            },
            xaxis: {
                categories: reorderedMonths,
            },
            yaxis: {
                title: {
                    text: `${t("thousands")}`,
                },
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return (
                            `${
                                Company.currencies &&
                                Company.currencies.length > 0 &&
                                Company.currencies[0].shortLabel
                                    ? Company.currencies[0].shortLabel
                                    : "$"
                            } ` +
                            val +
                            ` ${t("thousands")}`
                        );
                    },
                },
            },
        },
    };
    const SalesChart = {
        series: [
            {
                name: `${t("Budget")}`,
                data: [44, 55, 450, 200, 100, 220, 500, 100, 400, 230, 500, 1],
            },
            {
                name: `${t("RealEstimate")}`,
                data: [76, 85, 450, 200, 100, 220, 500, 100, 400, 230, 500, 5],
            },
        ],
        options: {
            chart: {
                type: "bar",
                height: 350,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "55%",
                    endingShape: "rounded",
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
            },
            xaxis: {
                categories: [
                    `${t("January")}`,
                    `${t("February")}`,
                    `${t("March")}`,
                    `${t("April")}`,
                    `${t("May")}`,
                    `${t("June")}`,
                    `${t("July")}`,
                    `${t("August")}`,
                    `${t("September")}`,
                    `${t("October")}`,
                    `${t("November")}`,
                    `${t("December")}`,
                ],
            },
            yaxis: {
                title: {
                    text: `${t("thousands")}`,
                },
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return (
                            `${
                                Company.currencies &&
                                Company.currencies.length > 0 &&
                                Company.currencies[0].shortLabel
                                    ? Company.currencies[0].shortLabel
                                    : "$"
                            } ` +
                            val +
                            `${t("thousands")}`
                        );
                    },
                },
            },
        },
    };
    const Chart = {
        series: [
            {
                name: `${t("Budget")}`,
                data: BudgetDashboard,
            },
            {
                name: `${t("RealEstimate")}`,
                data: RealDashboard,
            },
        ],
        options: {
            chart: {
                type: "bar",
                height: 350,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "55%",
                    endingShape: "rounded",
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
            },
            xaxis: {
                categories: YearBudgetDashboard,
            },
            yaxis: {
                title: {
                    text: `${t("thousands")}`,
                },
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return (
                            `${
                                Company.currencies &&
                                Company.currencies.length > 0 &&
                                Company.currencies[0].shortLabel
                                    ? Company.currencies[0].shortLabel
                                    : "$"
                            } ` +
                            val +
                            ` ${t("thousands")}`
                        );
                    },
                },
            },
        },
    };
    const ChartBudget = {
        series: [
            {
                name: `${t("Budget")}`,
                data: RHbudgetDashboard,
            },
            {
                name: `${t("RealEstimate")}`,
                data: RHrealDashboard,
            },
        ],
        options: {
            chart: {
                type: "bar",
                height: 350,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "55%",
                    endingShape: "rounded",
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
            },
            xaxis: {
                categories: YearBudgetDashboard,
            },
            yaxis: {
                title: {
                    text: `${t("persons")}`,
                },
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val;
                    },
                },
            },
        },
    };
    const ChartReal = {
        series: [
            {
                name: `${t("Budget")}`,
                data: rhsalaryDashboard,
            },
            {
                name: `${t("RealEstimate")}`,
                data: arrayrhDashboard,
            },
        ],
        options: {
            chart: {
                type: "bar",
                height: 350,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "55%",
                    endingShape: "rounded",
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
            },
            xaxis: {
                categories: [
                    `${t("salary")}`,
                    `${t("soustraitant")}`,
                    `${t("totaldepense")}`,
                ],
            },
            yaxis: {
                title: {
                    text: `${t("thousands")}`,
                },
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return (
                            `${
                                Company.currencies &&
                                Company.currencies.length > 0 &&
                                Company.currencies[0].shortLabel
                                    ? Company.currencies[0].shortLabel
                                    : "$"
                            } ` +
                            val +
                            ` ${t("thousands")}`
                        );
                    },
                },
            },
        },
    };
    const BudgetChart = {
        series: [
            {
                name: "values",
                data: rhRealDashboard,
            },
        ],
        options: {
            chart: {
                type: "bar",
                height: 350,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "55%",
                    endingShape: "rounded",
                    distributed: true,
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
            },
            xaxis: {
                categories: [
                    `${t("Real")}`,
                    `${t("Estimate")}`,
                    `${t("budgetatteint")}`,
                ],
            },
            yaxis: {
                title: {
                    text: `${t("Pourcentage")}`,
                },
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return "% " + val;
                    },
                },
            },
        },
    };

    const RealChart = {
        series: [
            {
                name: `${t("Budget")}`,
                data: rhbudgetDashboard,
            },
            {
                name: `${t("Real")}`,
                data: rhrealDashboard,
            },
            {
                name: `${t("Estimate")}`,
                data: rhDashboard,
            },
        ],
        options: {
            chart: {
                type: "bar",
                height: 350,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "55%",
                    endingShape: "rounded",
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
            },
            xaxis: {
                categories: reorderedMonths.slice(0, 12),
            },
            yaxis: {
                title: {
                    text: `${t("thousands")}`,
                    style: {
                        fontSize: "16px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontWeight: "normal",
                        letterSpacing: "2px",
                        color: "#333",
                    },
                },
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return (
                            `${
                                Company.currencies &&
                                Company.currencies.length > 0 &&
                                Company.currencies[0].shortLabel
                                    ? Company.currencies[0].shortLabel
                                    : "$"
                            } ` +
                            val +
                            ` ${t("thousands")}`
                        );
                    },
                },
            },
        },
    };
    return (
        <Card>
            <div className="layout-content">
                {contextHolder}
                <Row gutter={[0, 24]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={24} className="mb-24">
                        <Button onClick={handleDrawer} icon={<SettingOutlined/>}>
                            {t("Dashboardsettings")}
                        </Button>
                    </Col>

                    {ability.can("read", "Revenue") &&
                        Dashboardsettings.chiffreDAffaireParAnnee && (
                            <Col xs={24} sm={24} md={16} lg={16} xl={12} className="mb-24">
                                <Card bordered={false} className="criclebox h-full">
                                    <h3 style={{textAlign: "center"}}>
                                        {t("ChiffreDAffaire")}
                                    </h3>
                                    <ReactApexChart
                                        options={Chartsales.options}
                                        series={Chartsales.series}
                                        type="bar"
                                        height={250}
                                    />
                                </Card>
                            </Col>
                        )}

                    {ability.can("read", "Revenue") &&
                        Dashboardsettings.chiffreDAffaireBudget && (
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
                                <Card bordered={false} className="criclebox h-full">
                                    <h3 style={{textAlign: "center"}}>
                                        {t("ChiffreDAffaireBudget") +
                                            " " +
                                            new Date().getFullYear()}
                                    </h3>
                                    <ReactApexChart
                                        options={BudgetChartsales.options}
                                        series={BudgetChartsales.series}
                                        type="bar"
                                        height={250}
                                    />
                                </Card>
                            </Col>
                        )}

                    {ability.can("read", "Order book") &&
                        Dashboardsettings.venteParProduit && (
                            <Col xs={24} sm={24} md={16} lg={16} xl={12} className="mb-24">
                                <Card bordered={false} className="criclebox h-full">
                                    <h3 style={{textAlign: "center", marginBottom: 59}}>
                                        {t("Vente") + " " + new Date().getFullYear()}
                                    </h3>
                                    <ReactApexChart
                                        options={ProductChart.options}
                                        series={ProductChart.series}
                                        type="pie"
                                    />
                                </Card>
                            </Col>
                        )}
                    {ability.can("read", "HR planning") &&
                        Dashboardsettings.depensesParAnnee && (
                            <Col xs={24} sm={24} md={16} lg={16} xl={12} className="mb-24">
                                <Card bordered={false} className="criclebox h-full">
                                    <h3 style={{textAlign: "center"}}>
                                        {t("depensedessalaires")}
                                    </h3>
                                    <ReactApexChart
                                        options={Chart.options}
                                        series={Chart.series}
                                        type="bar"
                                        height={250}
                                    />
                                </Card>
                            </Col>
                        )}
                    {ability.can("read", "HR planning") &&
                        Dashboardsettings.croissanceParAnnee && (
                            <Col xs={24} sm={24} md={16} lg={16} xl={12} className="mb-24">
                                <Card bordered={false} className="criclebox h-full">
                                    <h3 style={{textAlign: "center"}}>
                                        {t("croissancedeseffectifs")}
                                    </h3>
                                    <ReactApexChart
                                        options={ChartBudget.options}
                                        series={ChartBudget.series}
                                        type="bar"
                                        height={250}
                                    />
                                </Card>
                            </Col>
                        )}

                    {ability.can("read", "HR planning") &&
                        Dashboardsettings.depensesbudget && (
                            <Col xs={24} sm={24} md={16} lg={16} xl={12} className="mb-24">
                                <Card bordered={false} className="criclebox h-full">
                                    <h3 style={{textAlign: "center"}}>
                                        {t("pourcentagedesdepenses") +
                                            " " +
                                            new Date().getFullYear()}
                                    </h3>
                                    <ReactApexChart
                                        options={BudgetChart.options}
                                        series={BudgetChart.series}
                                        type="bar"
                                        height={250}
                                    />
                                </Card>
                            </Col>
                        )}
                    {ability.can("read", "HR planning") &&
                        Dashboardsettings.depenseAnnee && (
                            <Col xs={24} sm={24} md={16} lg={16} xl={12} className="mb-24">
                                <Card bordered={false} className="criclebox h-full">
                                    <h3 style={{textAlign: "center"}}>
                                        {t("depensedessalairesparstatus") +
                                            " " +
                                            new Date().getFullYear()}
                                    </h3>
                                    <ReactApexChart
                                        options={ChartReal.options}
                                        series={ChartReal.series}
                                        type="bar"
                                        height={250}
                                    />
                                </Card>
                            </Col>
                        )}
                </Row>
                {ability.can("read", "Revenue") &&
                    Dashboardsettings.chiffreDAffaireParMois && (
                        <Row gutter={[24, 0]}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={24} className="mb-24">
                                <Card bordered={false} className="criclebox h-full">
                                    <h3 style={{textAlign: "center"}}>
                                        {t("ChiffreDAffaireparmois") +
                                            " " +
                                            new Date().getFullYear()}
                                    </h3>
                                    <ReactApexChart
                                        options={RealChartsales.options}
                                        series={RealChartsales.series}
                                        type="bar"
                                        height={380}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    )}
                {ability.can("read", "HR planning") &&
                    Dashboardsettings.depensesParMois && (
                        <Row gutter={[24, 0]}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={24} className="mb-24">
                                <Card bordered={false} className="criclebox h-full">
                                    <h3 style={{textAlign: "center"}}>
                                        {t("depensedessalairesparmois") +
                                            " " +
                                            new Date().getFullYear()}
                                    </h3>
                                    <ReactApexChart
                                        options={RealChart.options}
                                        series={RealChart.series}
                                        type="bar"
                                        height={380}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    )}
            </div>
            <Drawer
                title={t("Dashboardsettings")}
                width={500}
                onClose={onClose}
                open={open}
            >
                {ability.can("read", "Revenue") && (
                    <h5>
                        {t("ChiffreDAffaire")}{" "}
                        <Switch
                            style={{float: "right"}}
                            defaultChecked={Dashboardsettings.chiffreDAffaireParAnnee}
                            onChange={(e) => {
                                if (chart2 && chart3) {
                                    setcol(e);
                                }
                                setchart1(e);
                                Dashboardsettings.chiffreDAffaireParAnnee = e;
                            }}
                        />
                    </h5>
                )}
                {ability.can("read", "Order book") && (
                    <h5>
                        {t("Vente") + " " + new Date().getFullYear()}{" "}
                        <Switch
                            style={{float: "right"}}
                            defaultChecked={Dashboardsettings.venteParProduit}
                            onChange={(e) => {
                                if (chart1 && chart3) {
                                    setcol(e);
                                }
                                setchart2(e);
                                Dashboardsettings.venteParProduit = e;
                            }}
                        />
                    </h5>
                )}
                {ability.can("read", "Revenue") && (
                    <h5>
                        {t("ChiffreDAffaireBudget") + " " + new Date().getFullYear()}{" "}
                        <Switch
                            style={{float: "right"}}
                            defaultChecked={Dashboardsettings.chiffreDAffaireBudget}
                            onChange={(e) => {
                                if (chart1 && chart2) {
                                    setcol(e);
                                }
                                setchart3(e);
                                Dashboardsettings.chiffreDAffaireBudget = e;
                            }}
                        />
                    </h5>
                )}
                {ability.can("read", "HR planning") && (
                    <h5>
                        {t("depensedessalaires")}{" "}
                        <Switch
                            style={{float: "right"}}
                            defaultChecked={Dashboardsettings.depensesParAnnee}
                            onChange={(e) => {
                                Dashboardsettings.depensesParAnnee = e;
                            }}
                        />
                    </h5>
                )}
                {ability.can("read", "HR planning") && (
                    <h5>
                        {t("croissancedeseffectifs")}{" "}
                        <Switch
                            style={{float: "right"}}
                            defaultChecked={Dashboardsettings.croissanceParAnnee}
                            onChange={(e) => {
                                Dashboardsettings.croissanceParAnnee = e;
                            }}
                        />
                    </h5>
                )}
                {ability.can("read", "HR plannings") && (
                    <h5>
                        {t("pourcentagedesdepenses") + " " + new Date().getFullYear()}{" "}
                        <Switch
                            style={{float: "right"}}
                            defaultChecked={Dashboardsettings.depensesbudget}
                            onChange={(e) => {
                                Dashboardsettings.depensesbudget = e;
                            }}
                        />
                    </h5>
                )}
                {ability.can("read", "HR planning") && (
                    <h5>
                        {t("depensedessalairesparstatus") + " " + new Date().getFullYear()}{" "}
                        <Switch
                            style={{float: "right"}}
                            defaultChecked={Dashboardsettings.depenseAnnee}
                            onChange={(e) => {
                                Dashboardsettings.depenseAnnee = e;
                            }}
                        />
                    </h5>
                )}
                {ability.can("read", "Revenue") && (
                    <h5>
                        {t("ChiffreDAffaireparmois") + " " + new Date().getFullYear()}{" "}
                        <Switch
                            style={{float: "right"}}
                            defaultChecked={Dashboardsettings.chiffreDAffaireParMois}
                            onChange={(e) => {
                                Dashboardsettings.chiffreDAffaireParMois = e;
                            }}
                        />
                    </h5>
                )}
                {ability.can("read", "Revenue") && (
                    <h5>
                        {t("depensedessalairesparmois") + " " + new Date().getFullYear()}{" "}
                        <Switch
                            style={{float: "right"}}
                            defaultChecked={Dashboardsettings.depensesParMois}
                            onChange={(e) => {
                                Dashboardsettings.depensesParMois = e;
                            }}
                        />
                    </h5>
                )}
                <Button onClick={updatesettings} style={{float: "right"}}>
                    {t("save")}
                </Button>
            </Drawer>
        </Card>
    );
}

Home.acl = {
    subject: "Dashboard",
    action: "read",
};
export default Home;
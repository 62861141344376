import { useContext, useEffect, useState } from "react";
import { CompanyContext } from "../../../contexts/CompanyContext";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAbility } from "../../../contexts/abilityContext";
import {
  Button,
  Card,
  Divider,
  Popconfirm,
  Row,
  Space,
  Table,
  message,
  Modal,
} from "antd";
import {
  SnippetsFilled,
  PlusCircleFilled,
  DeleteOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import axios from "axios";
import { JSON_API } from "../../../services/Constants";

const CashFlowInstance = () => {
  const { Company } = useContext(CompanyContext);
  const { ability } = useAbility();
  let { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const [currentPage, setCurrentPage] = useState(() => {
    return Number(localStorage.getItem("currentPageCashFlow")) || 1;
  });
  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    localStorage.setItem("currentPageCashFlow", pagination.current);
  };
  const showNameModal = (name) => {
    setSelectedName(name);
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedName(null);
  };
  const history = useHistory();
  const getData = async () => {
    setLoading(true);
    console.log("api call", JSON_API);
    axios
      .get(`${JSON_API}/aaCashFlows/enterprise/${Company.id}`)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        messageApi.error(t("ErrorFetchingData"));
      });
  };
  const handleCreateNewCashflow = () => {
    axios
      .post(`${JSON_API}/aaCashFlows/${Company.id}`)
      .then((res) => {
        getData();
      })
      .catch((err) => {
        messageApi.error(t("ErrorCreatingCashflow"));
      });
  };
  const handleDuplicateCashflow = () => {
    axios
      .post(`${JSON_API}/aaCashFlows/duplicate/${Company.id}`)
      .then((res) => {
        getData();
      })
      .catch((err) => {
        messageApi.error(t("ErrorCreatingCashflow"));
      });
  };
  const handleDelete = (id) => {
    axios
      .delete(`${JSON_API}/aaCashFlows/Instances/${id}`)
      .then((res) => {
        getData();
      })
      .catch((err) => {
        messageApi.error(t("ErrorDeletingCashflow"));
      });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [Company.id]);

  const handleViewDetails = (id, freq) => {
    // Redirect to view details
    history.push(
      `/cashflowInstanceDetails/${id}?freq=${encodeURIComponent(
        freq + "F$rQ$"
      )}`
    );
  };
  const columns = [
    {
      title: <h1 style={{ textAlign: "center" }}> {t("Name")}</h1>,
      dataIndex: "nom",
      width: "30%",
      render: (_, record) => (
        <div
          style={{
            textAlign: "left",
            maxWidth: "400px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            cursor: "pointer",
          }}
          onClick={() => showNameModal(record.name)}
        >
          {record.name}
        </div>
      ),
    },
    {
      title: <h1 style={{ textAlign: "center" }}>{t("Creationdate")}</h1>,
      dataIndex: "date",
      align: "center",

      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          {dayjs(record.createdDate).format("YYYY / MM / DD")}
        </div>
      ),
    },
    {
      title: <h1 style={{ textAlign: "center" }}>Actions</h1>,
      dataIndex: "operation",
      align: "center",
      render: (_, record) => {
        return (
          <>
            <Button
              type="link"
              icon={<EyeOutlined />}
              onClick={() => handleViewDetails(record.id, record.frequency)}
            >
              {t("Details")}
            </Button>

            {ability && ability.can("delete", "CashFlow") && (
              <Popconfirm
                title={t("Areyousuretodeletethisone")}
                okText={t("yes")}
                cancelText={t("no")}
                onConfirm={() => {
                  handleDelete(record.id);
                }}
              >
                <Button type="link" danger>
                  <DeleteOutlined />
                  {t("Delete")}
                </Button>
              </Popconfirm>
            )}
          </>
        );
      },
    },
  ];
  return (
    <>
      <div>
        {contextHolder}
        <Card
          bordered={false}
          className="header-solid mb-24"
          title={
            <h3 className="font-semibold">
              <Divider> {t("cashFlowInstances")}</Divider>
            </h3>
          }
        >
          <Row justify="end">
            {ability && ability.can("create", "CashFlow") && (
              <Space style={{ marginBottom: 15 }}>
                <Button
                  className="Create-button"
                  type="primary"
                  icon={<SnippetsFilled />}
                  disabled={data.length === 0}
                  onClick={() => {
                    handleDuplicateCashflow();
                  }}
                >
                  {t("DuplicateLatestCashflow")}
                </Button>
                <Button
                  className="Create-button"
                  type="primary"
                  icon={<PlusCircleFilled />}
                  onClick={() => {
                    handleCreateNewCashflow();
                  }}
                >
                  {t("CreateNewCashflow")}
                </Button>
              </Space>
            )}
          </Row>
          <div style={{ overflowX: "auto", backgroundColor: "white" }}>
            <Table
              bordered
              pagination={{ current: currentPage }}
              onChange={handleTableChange}
              columns={columns}
              dataSource={data}
              loading={loading}
            />
          </div>
          <Modal
            title={t("detail")}
            visible={isModalVisible}
            onOk={closeModal}
            onCancel={closeModal}
          >
            <p>{selectedName}</p>
          </Modal>
        </Card>
      </div>
    </>
  );
};

CashFlowInstance.acl = {
  subject: "CashFlow",
  action: "read",
};

export default CashFlowInstance;

import {
  FilePdfOutlined,
  DeleteOutlined,
  FileTwoTone,
  FilePdfFilled,
} from "@ant-design/icons";
import { Card, Collapse, List, Tabs, Button, message } from "antd";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CompanyContext } from "../contexts/CompanyContext";
import { JSON_API } from "../services/Constants";

const Reporting = () => {
  const { Company } = useContext(CompanyContext);
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const { TabPane } = Tabs;
  const { Panel } = Collapse;
  // const standardreports = [];
  const [standardreports, setStandardreports] = useState([]);
  const [customreports, setCustomreports] = useState([]);

  const getStandardreports = async () => {
    await axios
      .get(`${JSON_API}/Reporting/standardReports`)
      .then((response) => {
        setStandardreports(response.data);
        console.log("standardreports:", standardreports);
        console.log("standardreports:", response.data);
      });
  };
  const getCustomreports = async () => {
    await axios
      .get(`${JSON_API}/Reporting/customReports/enterprise/${Company.id}`)
      .then((response) => {
        setCustomreports(response.data);
        console.log("customreports:", customreports);
      });
  };

  const deleteCustumReport = async (reportId) => {
    console.log("Deleting report with id:", reportId);
    try {
      await axios.delete(`${JSON_API}/Reporting/customReports/${reportId}`);
      message.success(t("Reportdeletedsuccessfully"));
      // Update custom reports state
      setCustomreports((prevReports) =>
        prevReports.map((item) => ({
          ...item,
          customReports: item.customReports.filter(
            (report) => report.id !== reportId
          ),
        }))
      );
    } catch (error) {
      message.error(t("Failed to delete report"));
    }
  };

  useEffect(() => {
    getStandardreports();

    getCustomreports();
  }, [t("lang"), Company.id]);

  return (
    <>
      <Card
        bordered={false}
        className="header-solid mb-24"
        title={<h3 className="font-semibold">{t("Reports")}</h3>}
      >
        {standardreports && (
          <Tabs centered type="card" defaultActiveKey="1">
            <TabPane
              style={{
                marginBottom: 35,
                width: "100%",
              }}
              type="card"
              tab={t("Standard")}
              key="1"
            >
              <Collapse>
                {standardreports.map((item) => {
                  return (
                    <Panel
                      header={
                        <span
                          style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            color: "#000000",
                          }}
                        >
                          {currentLang === "en" ? item.labelEn : item.labelFr}
                        </span>
                      }
                      key={item.id}
                    >
                      <List
                        itemLayout="horizontal"
                        dataSource={item.standardReports}
                        renderItem={(report) => (
                          <List.Item>
                            <List.Item.Meta
                              title={
                                <Link
                                  to={{
                                    pathname: `/report_viewer/${report.id}`,
                                    state: { stateParam: report.id },
                                  }}
                                  noStyle
                                >
                                  <FileTwoTone
                                    style={{
                                      marginRight: "10px",
                                    }}
                                  />
                                  {currentLang === "en"
                                    ? report.nameEn
                                    : report.nameFr}
                                </Link>
                              }
                            />
                          </List.Item>
                        )}
                      />
                    </Panel>
                  );
                })}
              </Collapse>
            </TabPane>
            <TabPane
              style={{
                marginBottom: 35,
                width: "100%",
              }}
              tab={t("Personalized")}
              key="2"
            >
              <Collapse>
                {customreports.map((item) => (
                  <Panel
                    forceRender={true}
                    header={
                      <span
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#000000",
                        }}
                      >
                        {currentLang === "en" ? item.labelEn : item.labelFr}
                      </span>
                    }
                    key={item.id}
                  >
                    <List
                      itemLayout="horizontal"
                      dataSource={item.customReports}
                      renderItem={(report) => (
                        <List.Item
                          actions={[
                            <Button
                              type="primary"
                              danger
                              icon={<DeleteOutlined />}
                              onClick={() => deleteCustumReport(report.id)}
                              style={{
                                width: "300%",

                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            />,
                          ]}
                        >
                          <List.Item.Meta
                            title={
                              <Link
                                to={{
                                  pathname: `/reportcustum_viewer/${report.id}`,
                                  state: { stateParam: report.id },
                                }}
                              >
                                <Button
                                  type=""
                                  icon={<FilePdfFilled />}
                                  style={{
                                    width: "5%",

                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                />
                                {" " + report.name}
                              </Link>
                            }
                          />
                        </List.Item>
                      )}
                    />
                  </Panel>
                ))}
              </Collapse>
            </TabPane>
          </Tabs>
        )}
      </Card>
    </>
  );
};

export default Reporting;

import {useContext, useEffect, useState} from "react";

import {Card, Col, Row} from "antd";

import axios from "axios";
import ReactApexChart from "react-apexcharts";
import {useTranslation} from "react-i18next";
import {CompanyContext} from "../contexts/CompanyContext";
import {JSON_API} from "../services/Constants";
import { generateMonthsArray,getFiscalYearForMonth,getRealOrEstimateForMonthHeaderTitle } from "../services/utilities";

function RHDashboard() {
    let {t} = useTranslation();
    const {Companies, setCompanies, Company, Actionstate, setActionstate} =
        useContext(CompanyContext);
    const [Dashboard, setDashboard] = useState({});
    const [YearBudgetDashboard, setYearBudgetDashboard] = useState([]);
    const [BudgetDashboard, setBudgetDashboard] = useState([]);
    const [RealDashboard, setRealDashboard] = useState([]);
    const [RHrealDashboard, setRHrealDashboard] = useState([]);
    const [RHbudgetDashboard, setRHbudgetDashboard] = useState([]);
    const [rhRealDashboard, setrhRealDashboard] = useState([]);
    const [rhbudgetDashboard, setrhbudgetDashboard] = useState([]);
    const [rhrealDashboard, setrhrealDashboard] = useState([]);
    const [rhsalaryDashboard, setrhsalaryDashboard] = useState([]);
    const [arrayrhDashboard, setarrayrhDashboard] = useState([]);
    const [rhDashboard, setrhDashboard] = useState([]);
    const [startPeriod, setstartPeriod] = useState(1);

    useEffect(() => {
        getDashboard();
        getStartPeriode();
    }, [Company.id]);
    const getStartPeriode = async () => {
        await axios
            .get(`${JSON_API}/Enterprises/main-info`)
            .then((res) => {
                const filteredData = res.data.filter((item) => item.id === Company.id);

                if (filteredData.length > 0) {
                    setstartPeriod(filteredData[0].startPeriod);
                } else {
                    // Handle case when no matching item is found
                    setstartPeriod(1); // Or any other default value
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const getDashboard = async () => {
        const array = [];
        const arraysalarybudget = [];
        const arraysalaryreal = [];
        const arrayrhsalary = [];
        const arraysalary = [];
        const arrayLabel = [];
        const arrayProduct = [];
        const arrayRHReal = [];
        const arrayrhbudget = [];
        const arrayrhreal = [];
        const arrayRhbudget = [];
        const arrayRhreal = [];
        const arrayrh = [];
        await axios
            .get(`${JSON_API}/Employees/RHDashboard/${Company.id}`)
            .then((res) => {
                setDashboard(res.data);
                res.data.salaryTotalsByYear.map((e) => {
                    array.push(e.year);
                    arraysalarybudget.push((e.budget / 1000).toFixed(2));
                    arraysalaryreal.push((e.real / 1000).toFixed(2));
                });
                res.data.employeesCountByYear.map((e) => {
                    array.push(e.year);
                    arrayRhbudget.push(e.countBudget);
                    arrayRhreal.push(e.countReal);
                });
                res.data.currentYearSalaryTotalsPercent.map((e) => {
                    arrayRHReal.push(e.realTotalBeforeCurrentMonthPercent.toFixed(2));
                    arrayRHReal.push(e.estimatedTotalAfterCurrentMonthPercent.toFixed(2));
                    arrayRHReal.push(e.achievedBudgetPercent.toFixed(2));
                });
                res.data.currentSalary.map((e) => {
                    arrayrhsalary.push((e.nonContractualTotalBudgetCurrentYear / 1000).toFixed(2));
                    arraysalary.push((e.nonContractualTotalRealCurrentYear / 1000).toFixed(2));
                    arrayrhsalary.push((e.contractualTotalBudgetCurrentYear / 1000).toFixed(2));
                    arraysalary.push((e.contractualTotalRealCurrentYear / 1000).toFixed(2));
                    arrayrhsalary.push(
                        ((e.nonContractualTotalBudgetCurrentYear + e.contractualTotalBudgetCurrentYear) / 1000).toFixed(2)
                    );
                    arraysalary.push(((e.nonContractualTotalRealCurrentYear + e.contractualTotalRealCurrentYear) / 1000).toFixed(2));
                });
                var currentYear = new Date().getFullYear(); // Utiliser l'année actuelle
                var currentMonth = new Date().getMonth() + 1; // Utiliser le mois actuel (0-11, donc +1)

                const reorderedMonths = generateMonthsArray(Company.startPeriod);
                // Réorganiser les dépenses par mois en fonction de la période de début de l'entreprise
                const reorderedExpense = [
                    ...res.data.salaryTotalsByMonth.slice(Company.startPeriod - 1), // Commencez par le mois de la période de début
                    ...res.data.salaryTotalsByMonth.slice(0, Company.startPeriod - 1), // Puis ajoutez les mois avant la période de début
                ];

                reorderedExpense.map((e, index) => {
                    arrayrhbudget.push((e.budget / 1000).toFixed(2));
                    let fiscalYearOfMonth = getFiscalYearForMonth(
                        reorderedMonths[index],
                        Company.startPeriod,
                        currentYear
                    );
                    let title = getRealOrEstimateForMonthHeaderTitle(
                        fiscalYearOfMonth,
                        currentYear,
                        reorderedMonths[index],
                        currentMonth
                    );

                    if (title === "Real") {
                        arrayrhreal.push((e.real / 1000).toFixed(2));
                        arrayrh.push(0);
                    } else {
                        arrayrh.push((e.real / 1000).toFixed(2));
                        arrayrhreal.push(0);
                    }
                });

                setYearBudgetDashboard(array);
                setBudgetDashboard(arraysalarybudget);
                setRealDashboard(arraysalaryreal);
                setrhsalaryDashboard(arrayrhsalary);
                setarrayrhDashboard(arraysalary);
                setRHbudgetDashboard(arrayRhbudget);
                setRHrealDashboard(arrayRhreal);
                setrhRealDashboard(arrayRHReal);
                setrhbudgetDashboard(arrayrhbudget);
                setrhrealDashboard(arrayrhreal);
                setrhDashboard(arrayrh);
                console.log(rhDashboard, rhRealDashboard, rhbudgetDashboard);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const months = [
        `${t("January")}`,
        `${t("February")}`,
        `${t("March")}`,
        `${t("April")}`,
        `${t("May")}`,
        `${t("June")}`,
        `${t("July")}`,
        `${t("August")}`,
        `${t("September")}`,
        `${t("October")}`,
        `${t("November")}`,
        `${t("December")}`,
    ];

    // Réorganiser les mois en fonction de la période de début
    const reorderedMonthsCategories = [
        ...months.slice(startPeriod - 1), // Commencez par le mois de la période de début
        ...months.slice(0, startPeriod - 1), // Puis ajoutez les mois avant la période de début
    ];
    const Chart = {
        series: [
            {
                name: `${t("Budget")}`,
                data: BudgetDashboard,
            },
            {
                name: `${t("RealEstimate")}`,
                data: RealDashboard,
            },
        ],
        options: {
            chart: {
                type: "bar",
                height: 350,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "55%",
                    endingShape: "rounded",
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
            },
            xaxis: {
                categories: YearBudgetDashboard,
            },
            yaxis: {
                title: {
                    text: `${t("thousands")}`,
                    style: {
                        fontSize: "16px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontWeight: "normal",
                        letterSpacing: "2px",
                        color: "#333",
                    },
                },
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return (
                            `${
                                Company.currencies &&
                                Company.currencies.length > 0 &&
                                Company.currencies[0].shortLabel
                                    ? Company.currencies[0].shortLabel
                                    : "$"
                            } ` +
                            val +
                            ` ${t("thousands")}`
                        );
                    },
                },
            },
        },
    };
    const ChartBudget = {
        series: [
            {
                name: `${t("Budget")}`,
                data: RHbudgetDashboard,
            },
            {
                name: `${t("RealEstimate")}`,
                data: RHrealDashboard,
            },
        ],
        options: {
            chart: {
                type: "bar",
                height: 350,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "55%",
                    endingShape: "rounded",
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
            },
            xaxis: {
                categories: YearBudgetDashboard,
            },
            yaxis: {
                title: {
                    text: `${t("persons")}`,
                    style: {
                        fontSize: "16px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontWeight: "normal",
                        letterSpacing: "2px",
                        color: "#333",
                    },
                },
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val;
                    },
                },
            },
        },
    };

    const ChartReal = {
        series: [
            {
                name: `${t("Budget")}`,
                data: rhsalaryDashboard,
            },
            {
                name: `${t("RealEstimate")}`,
                data: arrayrhDashboard,
            },
        ],
        options: {
            chart: {
                type: "bar",
                height: 350,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "55%",
                    endingShape: "rounded",
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
            },
            xaxis: {
                categories: [
                    `${t("salary")}`,
                    `${t("soustraitant")}`,
                    `${t("totaldepense")}`,
                ],
            },
            yaxis: {
                title: {
                    text: `${t("thousands")}`,
                    style: {
                        fontSize: "16px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontWeight: "normal",
                        letterSpacing: "2px",
                        color: "#333",
                    },
                },
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return (
                            `${
                                Company.currencies &&
                                Company.currencies.length > 0 &&
                                Company.currencies[0].shortLabel
                                    ? Company.currencies[0].shortLabel
                                    : "$"
                            } ` +
                            val +
                            ` ${t("thousands")}`
                        );
                    },
                },
            },
        },
    };
    const BudgetChart = {
        series: [
            {
                name: "values",
                data: rhRealDashboard,
            },
        ],
        options: {
            chart: {
                type: "bar",
                height: 350,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "55%",
                    endingShape: "rounded",
                    distributed: true,
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
            },
            xaxis: {
                categories: [
                    `${t("Real")}`,
                    `${t("Estimate")}`,
                    `${t("budgetatteint")}`,
                ],
            },
            yaxis: {
                title: {
                    text: `${t("Pourcentage")}`,
                    style: {
                        fontSize: "16px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontWeight: "normal",
                        letterSpacing: "2px",
                        color: "#333",
                    },
                },
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return "% " + val;
                    },
                },
            },
        },
    };
    
    const RealChart = {
        series: [
            {
                name: `${t("Budget")}`,
                data: rhbudgetDashboard,
            },
            {
                name: `${t("Real")}`,
                data: rhrealDashboard,
            },
            {
                name: `${t("Estimate")}`,
                data: rhDashboard,
            },
        ],
        options: {
            chart: {
                type: "bar",
                height: 350,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "55%",
                    endingShape: "rounded",
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
            },
            xaxis: {
                categories: reorderedMonthsCategories,
            },
            yaxis: {
                title: {
                    text: `${t("thousands")}`,
                    style: {
                        fontSize: "16px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontWeight: "normal",
                        letterSpacing: "2px",
                        color: "#333",
                    },
                },
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return (
                            `${
                                Company.currencies &&
                                Company.currencies.length > 0 &&
                                Company.currencies[0].shortLabel
                                    ? Company.currencies[0].shortLabel
                                    : "$"
                            } ` +
                            val +
                            ` ${t("thousands")}`
                        );
                    },
                },
            },
        },
    };
    return (
        <>
            <div className="layout-content">
                <Row gutter={[8, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} className="mb-24">
                        <Card bordered={false} className="criclebox h-full">
                            <h3 style={{textAlign: "center"}}>{t("depensedessalaires")}</h3>
                            <ReactApexChart
                                options={Chart.options}
                                series={Chart.series}
                                type="bar"
                                height={250}
                            />
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} className="mb-24">
                        <Card bordered={false} className="criclebox h-full">
                            <h3 style={{textAlign: "center"}}>
                                {t("croissancedeseffectifs")}
                            </h3>
                            <ReactApexChart
                                options={ChartBudget.options}
                                series={ChartBudget.series}
                                type="bar"
                                height={250}
                            />
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} className="mb-24">
                        <Card bordered={false} className="criclebox h-full">
                            <h3 style={{textAlign: "center"}}>
                                {t("pourcentagedesdepenses") + " " + new Date().getFullYear()}
                            </h3>
                            <ReactApexChart
                                options={BudgetChart.options}
                                series={BudgetChart.series}
                                type="bar"
                                height={250}
                            />
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} className="mb-24">
                        <Card bordered={false} className="criclebox h-full">
                            <h3 style={{textAlign: "center"}}>
                                {t("depensedessalairesparstatus") +
                                    " " +
                                    new Date().getFullYear()}
                            </h3>
                            <ReactApexChart
                                options={ChartReal.options}
                                series={ChartReal.series}
                                type="bar"
                                height={250}
                            />
                        </Card>
                    </Col>
                </Row>

                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
                        <Card bordered={false} className="criclebox h-full">
                            <h3 style={{textAlign: "center"}}>
                                {t("depensedessalairesparmois") +
                                    " " +
                                    new Date().getFullYear()}
                            </h3>
                            <ReactApexChart
                                options={RealChart.options}
                                series={RealChart.series}
                                type="bar"
                                height={380}
                            />
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default RHDashboard;
import {
  AppstoreOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  PlusSquareOutlined,
  RollbackOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Empty,
  Input,
  Popconfirm,
  Space,
  Table,
  message,
} from "antd";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CompanyContext } from "../contexts/CompanyContext";
import { JSON_API } from "../services/Constants";
import { useAbility } from "../contexts/abilityContext";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Input
          defaultValue={record[dataIndex]}
          onChange={(e) => (record[dataIndex] = e.target.value)}
        />
      ) : (
        children
      )}
    </td>
  );
};

const InvestmentCategory = () => {
  const [categories, setCategories] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [loading, setLoading] = useState(true);
  const { Company } = useContext(CompanyContext);
  const { ability } = useAbility();
  const canDelete = ability.can("delete", "Investments");
  const canUpdate = ability.can("update", "Investments");
  let { t } = useTranslation();

  useEffect(() => {
    getInvestmentCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${JSON_API}/InvestmentCategorys`);
      setCategories(response.data);
      setLoading(true);
    } catch (error) {
      message.error(t("ErrorFetchingCategories"));
      setLoading(false);
    }
  };

  const getInvestmentCategories = async () => {
    try {
      const response = await axios.get(`${JSON_API}/InvestmentCategorys`);
      setCategories(response.data);
    } catch (error) {
      console.log(error);
      message.error(t("ErrorFetchingCategories"));
    }
  };

  const isEditing = (record) => record.id === editingKey;

  const edit = (record) => {
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (record) => {
    try {
      if (record.id === "new") {
        await axios.post(`${JSON_API}/InvestmentCategorys`, {
          label: record.label,
        });
        message.success(t("CategoryAddedSuccessfully"));
      } else {
        await axios.put(`${JSON_API}/InvestmentCategorys/${record.id}`, {
          id: record.id,
          label: record.label,
        });
        message.success(t("CategoryUpdatedSuccessfully"));
      }
      fetchCategories();
      setEditingKey("");
    } catch (error) {
      message.error(t("ErrorSavingCategories"));
    }
  };

  const handleDeleteCategory = async (id) => {
    try {
      await axios.delete(`${JSON_API}/InvestmentCategorys/${id}`);
      message.success(t("categoryDeletedSuccessfully"));
      fetchCategories();
    } catch (error) {
      message.error(t("ErrorDeletingCategory"));
    }
  };

  const addCategory = () => {
    setCategories([{ id: "new", label: "" }, ...categories]);
    setEditingKey("new");
  };

  const columns = [
    {
      title: t("Identifier"),
      dataIndex: "id",
      editable: false,
      align: "center",
    },
    {
      title: t("Label"),
      dataIndex: "label",
      editable: true,
      align: "center",
    },
  ];

  if (canDelete || canUpdate) {
    columns.push({
      title: t("Actions"),
      dataIndex: "actions",
      align: "center",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              type="link"
              icon={<SaveOutlined />}
              onClick={() => save(record)}
            >
              {t("SaveCategory")}
            </Button>
            <Popconfirm
              title="Annuler ?"
              onConfirm={cancel}
              okButtonProps={{
                type: "primary",
                danger: true,
                icon: <CheckOutlined />,
              }}
              cancelButtonProps={{
                icon: <CloseOutlined />,
              }}
            >
              <Button type="link" danger size="medium" icon={<CloseOutlined />}>
                {t("Cancel")}
              </Button>
            </Popconfirm>
          </span>
        ) : (
          <span>
            {ability && ability.can("update", "Investments") && (
              <Button
                type="link"
                icon={<EditOutlined />}
                disabled={editingKey !== ""}
                onClick={() => edit(record)}
              >
                {t("EditCategory")}
              </Button>
            )}
            {ability && ability.can("delete", "Investments") && (
              <Popconfirm
                title="Confirmer la suppression ?"
                onConfirm={() => handleDeleteCategory(record.id)}
                okButtonProps={{
                  type: "primary",
                  danger: true,
                  icon: <CheckOutlined />,
                  style: {
                    backgroundColor: "#f5222d",
                    borderColor: "#f5222d",
                  },
                }}
                cancelButtonProps={{
                  icon: <CloseOutlined />,
                }}
              >
                <Button
                  type="link"
                  danger
                  icon={<CloseOutlined />}
                  disabled={editingKey !== ""}
                >
                  {t("DeleteCategory")}
                </Button>
              </Popconfirm>
            )}
          </span>
        );
      },
    });
  }
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <>
      <Card
        title={
          <span style={{ fontSize: "16px" }}>
            <AppstoreOutlined /> {t("CategoriesOfInvestments")}
          </span>
        }
        extra={
          <Space>
            <Link
              to={{
                pathname: `/Investments`,
              }}
            >
              <Button
                // type="primary"
                size="medium"
                className="uppercase"
                style={{
                  textTransform: "uppercase",
                  fontSize: "10px",
                }}
              >
                <RollbackOutlined /> {t("BackToInvestments")}
              </Button>
            </Link>
            {ability && ability.can("create", "Investments") && (
              <Button
                type="primary"
                size="medium"
                style={{
                  fontSize: "10px",
                  textTransform: "uppercase",
                }}
                icon={<PlusSquareOutlined />}
                onClick={addCategory}
                disabled={editingKey !== ""}
              >
                {t("AddNewCategory")}
              </Button>
            )}
          </Space>
        }
      >
        <div style={{ overflowX: "auto",backgroundColor:'white' }}>
        <Table
          locale={{
            emptyText: <Empty description={t("nodata")} />,
          }}
          dataSource={categories}
          columns={mergedColumns}
          rowClassName="editable-row"
          rowKey="id"
          pagination={{
            pageSize: 10,
          }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
        />
        </div>
      </Card>
    </>
  );
};

InvestmentCategory.acl = {
  subject: "Investments",
  action: "read",
};

export default InvestmentCategory;

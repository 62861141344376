import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form, Input, Button, Select, Card, Row, Col, Space } from "antd";
import axios from "axios";
import { JSON_API } from "../../../services/Constants";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { CompanyContext } from "../../../contexts/CompanyContext";

const { Option } = Select;

const FirstStep = ({ setCurrent, setFormData, formData }) => {
  let { t } = useTranslation();
  const [enterpriseData, setEnterpriseData] = useState([]);

  useEffect(() => {
    axios
      .get(`${JSON_API}/abCashFlowModels/enterprises/filter`)
      .then((response) => {
        console.log("Enterprises ", response.data);
        setEnterpriseData(response.data);
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the chart accounts: ",
          error
        );
      });
  }, []);

  return (
    <Row justify="center">
      <Col xs={24} lg={16}>
        <Card
          title={
            <h1 style={{ textAlign: "center", padding: "5px 0" }}>
              {t("Initialization")} - {t("Step1")}
            </h1>
          }
          style={{
            marginTop: "100px",
            borderRadius: "15px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            padding: "40px",
          }}
        >
          <Form
            initialValues={{ 
              name:"Cash Flow Model",
             complexity:0,
              frequency:2
               }}
            onFinish={(values) => {
              console.log("Form submitted with:", values);
              setFormData({ ...formData, ...values });
              setCurrent((prev) => prev + 1);
            }}
            layout="vertical"
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="enterpriseId"
                  label={t("Enterprise")}
                  rules={[
                    {
                      required: true,
                      message: t("pleaseselectEnterpriseNumber"),
                    },
                  ]}
                >
                  <Select placeholder="Enterprise">
                    {enterpriseData.map((enterprise) => (
                      <Option key={enterprise.id} value={enterprise.id}>
                        {enterprise.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="name"
                  label={t("name")}
                  rules={[{ required: true, message: t("PleaseInputName") }]}>
                  <Input placeholder="Name" style={{height:35}} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
            <Col span={12}>
                <Form.Item
                  name="complexity"
                  label={t("Complexity")}
                  rules={[
                    { required: true, message: "Pleaseselectcomplexity!" },
                  ]}
                >
                  <Select placeholder="Complexity">
                    <Option value={0}>{"Simple"}</Option>
                    <Option value={1}>{t("Intermediate")}</Option>
                    <Option value={2}>{t("Advanced")}</Option>
                  </Select>
                </Form.Item>
           </Col>
              <Col span={12}>
                 <Form.Item
                  name="frequency"
                  label={t("Frequency")}
                  rules={[
                    { required: true, message: "Please select frequency!" },
                  ]} 
                  initialValue={2}
                  >
                    <Select placeholder="Frequency" >
                              <Option value={0}>{t("Week")}</Option>
                              <Option value={1}>{t("TwoWeeks")}</Option>
                             <Option value={2}>{t("Month")}</Option>
                    </Select>
               </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Space
                size="large"
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <Link to="/CashFlowModels">
                  <Button
                    type="primary"
                    danger
                    icon={<ArrowLeftOutlined />}
                    style={{
                      width: "300px",
                      height: "40px",
                      borderRadius: "10px",
                    }}
                  >
                    {t("GoBack")}
                  </Button>
                </Link>

                <Button
                  style={{
                    width: "300px",
                    height: "40px",
                    borderRadius: "10px",
                  }}
                  type="primary"
                  htmlType="submit"
                  icon={<ArrowRightOutlined />}
                >
                  {t("Next")}
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

FirstStep.propTypes = {
  setCurrent: PropTypes.func.isRequired,
  setFormData: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
};

export default FirstStep;

import React, { useContext } from "react";
import { useAbility } from "../../../contexts/abilityContext";
import { Button, Input, Table, Typography } from "antd";
import { useState } from "react";
import { CompanyContext } from "../../../contexts/CompanyContext";
import { useTranslation } from "react-i18next";
const { Text } = Typography;
const { TextArea } = Input;

function RealUnit(props) {
  let { t } = useTranslation();
  const { ability } = useAbility();
  const readOnly = !ability.can("update", "Revenue");
  const { Company } = useContext(CompanyContext);
  const [AssetReals, setAssetReals] = useState(props.AssetReals);
  const { TextArea } = Input;
  console.log(AssetReals);

  function handleinputchange(ee, i, n) {
    console.log(props.AssetReals);
    const newReal = [...props.AssetReals];
    newReal.map((e) => {
      if (e.id == i) {
        e.unitReals[n] = parseFloat(ee.target.value) || 0;
      }
    });
    setAssetReals(newReal);
    props.onRealChange(newReal);
  }

  console.log("ttesstt 1");
  console.log(AssetReals);
  const items = [
    {
      title: `${t("Year")}`,
      width: 120,
      dataIndex: "year",
      align: "center",
      render: (text, record) => {
        return <p style={{ textAlign: "center" }}>{text}</p>;
      },
    },
  ];

  const months = [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
  ];

  const startingMonthIndex = Company.startPeriod - 1;

  for (let i = 0; i < months.length; i++) {
    const monthIndex = (i + startingMonthIndex) % months.length;
    const monthName = months[monthIndex];

    items.push({
      title: monthName,
      width: 120,
      align: "center",
      render: (text, record) => {
        let confirm = false;
        return (
          <>
            {props.AssetUnit.map((e) => {
              if (e.year == record.year) {
                confirm = !e.confirmed;
                if (confirm) {
                  {
                    props.AssetBudgets.map((ee) => {
                      if (ee.year == record.year) {
                        record.unitReals[monthIndex] = ee.units[monthIndex];
                      }
                    });
                  }
                }
              }
            })}
            <Input
              type="number"
              value={record.unitReals[monthIndex].toFixed(0)}
              disabled={confirm || readOnly}
              onChange={(e) => handleinputchange(e, record.id, monthIndex)}
              style={{ textAlign: "right", width: 100 }}
            />
          </>
        );
      },
    });
  }

  items.push({
    title: <h1 style={{ textAlign: "center", width: 100 }}>Total</h1>,
    width: 120,
    render: (_, record) => {
      return (
        <p style={{ textAlign: "right" }}>
          {record.unitReals[0] +
            record.unitReals[1] +
            record.unitReals[2] +
            record.unitReals[3] +
            record.unitReals[4] +
            record.unitReals[5] +
            record.unitReals[6] +
            record.unitReals[7] +
            record.unitReals[8] +
            record.unitReals[9] +
            record.unitReals[10] +
            record.unitReals[11]}
        </p>
      );
    },
  });

  return (
    <Table
      columns={items}
      dataSource={props.AssetReals}
      scroll={{
        x: 1300,
      }}
      pagination={true}
      bordered
      summary={(pageData) => {
        const items = [];

        const startingMonthIndex = Company.startPeriod - 1;

        let totalmontant = 0;
        let totalReal = 0;
        let totalfevrierReal = 0;
        let totalMarsReal = 0.0;
        let totalavrilReal = 0;
        let totalmaiReal = 0;
        let totalJuinReal = 0.0;
        let totaljuilletReal = 0;
        let totalaoutReal = 0;
        let totalseptembreReal = 0;
        let totaloctobreReal = 0;
        let totalnovemberReal = 0;
        let totaldecembreReal = 0;
        let totaltotal = 0;

        props.AssetReals &&
          props.AssetReals.map((e) => {
            //console.log(e.Reals[1]),
            totalmontant = e.unitReals[0];
            totalReal += totalmontant;
            totalmontant = e.unitReals[1];
            totalfevrierReal += totalmontant;
            totalmontant = e.unitReals[2];
            totalMarsReal += totalmontant;
            totalmontant = e.unitReals[3];
            totalavrilReal += totalmontant;
            totalmontant = e.unitReals[4];
            totalmaiReal += totalmontant;
            totalmontant = e.unitReals[5];
            totalJuinReal += totalmontant;
            totalmontant = e.unitReals[6];
            totaljuilletReal += totalmontant;
            totalmontant = e.unitReals[7];
            totalaoutReal += totalmontant;
            totalmontant = e.unitReals[8];
            totalseptembreReal += totalmontant;
            totalmontant = e.unitReals[9];
            totaloctobreReal += totalmontant;
            totalmontant = e.unitReals[10];
            totalnovemberReal += totalmontant;
            totalmontant = e.unitReals[11];
            totaldecembreReal += totalmontant;
            totalmontant = e.totalReal;
            totaltotal += totalmontant;
          });

        const months = [
          totalReal,
          totalfevrierReal,
          totalMarsReal,
          totalavrilReal,
          totalmaiReal,
          totalJuinReal,
          totaljuilletReal,
          totalaoutReal,
          totalseptembreReal,
          totaloctobreReal,
          totalnovemberReal,
          totaldecembreReal,
        ];
        for (let i = 0; i < months.length; i++) {
          const monthIndex = (i + startingMonthIndex) % months.length;
          const monthName = months[monthIndex];

          items.push(
            <Table.Summary.Cell index={monthIndex}>
              <Text style={{ textAlign: "right" }}>{monthName.toFixed(0)}</Text>
            </Table.Summary.Cell>
          );
        }

        return (
          <>
            <Table.Summary.Row>
              {/* <Table.Summary.Cell index={1} colSpan={1}>
                <h3 style={{ textAlign: "center" }}>Total</h3>
              </Table.Summary.Cell> */}

              {/* {items} */}

              {/* <Table.Summary.Cell index={10}>
                <Text>
                  {(
                    totalReal +
                    totalfevrierReal +
                    totalMarsReal +
                    totalavrilReal +
                    totalmaiReal +
                    totalJuinReal +
                    totaljuilletReal +
                    totalaoutReal +
                    totalseptembreReal +
                    totaloctobreReal +
                    totalnovemberReal +
                    totaldecembreReal
                  ).toFixed(0)}
                </Text>
              </Table.Summary.Cell> */}
            </Table.Summary.Row>
          </>
        );
      }}
    />
  );
}

RealUnit.acl = {
  subject: "Revenue",
  action: "read",
};

export default RealUnit;

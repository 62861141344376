import React, { useContext, useEffect, useState } from "react";
import {
  ReconciliationOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  PlusSquareOutlined,
  RollbackOutlined,
  DollarOutlined,
  SaveOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import axios from "axios";
import { Link, useHistory, useParams } from "react-router-dom";
import { CompanyContext } from "../../contexts/CompanyContext";
import { JSON_API } from "../../services/Constants";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  Select,
  Popconfirm,
  Input,
  Form,
  Space,
  Table,
  message,
  Empty,
} from "antd";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Input
          defaultValue={record[dataIndex]}
          onChange={(e) => (record[dataIndex] = e.target.value)}
        />
      ) : (
        children
      )}
    </td>
  );
};

const FinancialstatementType = () => {
  const { Company } = useContext(CompanyContext);
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  useEffect(() => {
    getFinancialCategories();
    getFinancialstatementTypes();
  }, []);

  const getFinancialCategories = async () => {
    try {
      const response = await axios.get(
        `${JSON_API}/FinancialStatementCategory`
      );
      setCategories(response.data);
    } catch (error) {
      console.log(error);
      message.error(t("ErrorFetchingCategories"));
    }
  };
  const getFinancialstatementTypes = async () => {
    try {
      const response = await axios.get(
        `${JSON_API}/FinancialStatementType?enterpriseId=${Company.id}`
      );
      setTypes(response.data);
    } catch (error) {
      console.log(error);
      message.error(t("ErrorFetchingLanguages"));
    }
  };

  const handleCategoryChange = (categoryId) => {
    setSelectedCategoryId(categoryId);
  };

  const filteredTypes = types.filter(
    (type) =>
      selectedCategoryId == null ||
      type.financialStatementCategoryId === selectedCategoryId
  );

  const fetchFinancialstatementTypes = async () => {
    try {
      const response = await axios.get(
        `${JSON_API}/FinancialStatementType?enterpriseId=${Company.id}`
      );
      setTypes(response.data);
      setLoading(true);
    } catch (error) {
      message.error(t("ErrorFetchingLanguages"));
      setLoading(false);
    }
  };

  const isEditing = (record) => record.id === editingKey;

  const edit = (record) => {
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (record) => {
    try {
      if (record.id === "new") {
        await axios.post(`${JSON_API}/Languages`, {
          gifi: record.gifi,
          description: record.description,
          descriptionFr: record.descriptionFr,
        });
        message.success(t("FinancialstatementTypeAddedSuccessfully"));
      } else {
        await axios.put(`${JSON_API}/Languages`, {
          id: record.id,
          gifi: record.gifi,
          description: record.description,
          descriptionFr: record.descriptionFr,
        });
        message.success(t("FinancialstatementTypeUpdatedSuccessfully"));
      }
      fetchFinancialstatementTypes();
      setEditingKey("");
    } catch (error) {
      message.error(t("ErrorSavingFinancialstatementType"));
    }
  };

  const addFinancialstatementType = () => {
    setTypes([{ gifi: "", description: "", descriptionFr: "" }, ...types]);
    setEditingKey("new");
  };

  const columns = [
    {
      title: t("GIFI"),
      dataIndex: "gifi",
      editable: true,
      align: "center",
    },
    {
      title: t("Description"),
      dataIndex: "description",
      editable: true,
      align: "center",
    },
    {
      title: t("DescriptionFr"),
      dataIndex: "descriptionFr",
      editable: true,
      align: "center",
    },
    {
      title: t("Actions"),
      dataIndex: "actions",
      align: "center",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              type="link"
              icon={<SaveOutlined />}
              onClick={() => save(record)}
            >
              {t("Save")}
            </Button>
            <Popconfirm
              title={t("CancelEditing")}
              onConfirm={cancel}
              okButtonProps={{
                type: "primary",
                danger: true,
                icon: <CheckOutlined />,
              }}
              cancelButtonProps={{
                icon: <CloseOutlined />,
              }}
            >
              <Button type="link" danger size="medium" icon={<CloseOutlined />}>
                {t("Cancel")}
              </Button>
            </Popconfirm>
          </span>
        ) : (
          <span>
            <Button
              type="link"
              icon={<EditOutlined />}
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              {t("Edit")}
            </Button>
          </span>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Card
      title={
        <span style={{ fontSize: "16px" }}>
          <ReconciliationOutlined /> {t("FinancialstatementType")}
        </span>
      }
      extra={
        <Space>
          <Button
            type="primary"
            size="medium"
            style={{
              fontSize: "10px",
              textTransform: "uppercase",
            }}
            icon={<PlusCircleFilled />}
            onClick={addFinancialstatementType}
            disabled={editingKey !== ""}
          >
            {t("AddNewFinancialstatementType")}
          </Button>
          <Select
            placeholder={t("SelectCategory")}
            style={{ width: 200 }}
            onChange={handleCategoryChange}
          >
            {categories.map((category) => (
              <Select.Option key={category.id} value={category.id}>
                {currentLang === "fr" ? category.nameFr : category.name}
              </Select.Option>
            ))}
          </Select>
        </Space>
      }
    >
      <Table
        locale={{
          emptyText: <Empty description={t("nodata")} />,
        }}
        dataSource={types}
        columns={mergedColumns}
        rowClassName="editable-row"
        rowKey="id"
        pagination={{
          pageSize: 10,
        }}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        style={{ overflowX: "auto",backgroundColor:'white' }}
      />
    </Card>
  );
};

export default FinancialstatementType;

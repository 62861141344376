// .get(`${JSON_API}/assets/summaries/${Company.id}/${year}`)
import {
  CalendarOutlined,
  RightOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Card, DatePicker, Table, Tabs, Typography, Space, Button } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CompanyContext } from "../../contexts/CompanyContext";
import { JSON_API } from "../../services/Constants";
import { countmonth, summary } from "../Financial Statement/Utils/Function";
const { Text } = Typography;

function AssetSummary(props) {
  let { t } = useTranslation();
  const { Company } = useContext(CompanyContext);
  const [year, setYear] = useState(null);
  const [Asset, setAsset] = useState(null);
  const [count, setcount] = useState(0);

  const onChange = (key) => {
    console.log(key);
  };

  var date;
  useEffect(() => {
    date = new Date().getFullYear();
    setYear(year == null ? date : year);

    console.log("year" + date);
    getAsset();
  }, [Company.id, year]);

  const onChangee = (date, dateString) => {
    console.log(date.$y);
    setYear(date.$y);
    console.log("." + year);
  };

  const getAsset = async () => {
    await axios
      .get(`${JSON_API}/assets/summaries/${Company.id}/${year}`)

      .then((res) => {
        setAsset(res.data);

        console.log("summary assets :", res.data);
        let count = countmonth(res.data);

        setcount(count);
        console.log("hhhhahahah ", count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columnsbalanceBudget = [
    {
      title: `${t("glnumber")}`,
      dataIndex: "glNumber",
      align: "center",
    },
    {
      title: `${t("Category")}`,
      dataIndex: "category",
      align: "center",
    },
    {
      title: `${t("Description")}`,
      dataIndex: "description",
      align: "center",
    },
  ];

  const months = [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
  ];

  const startingMonthIndex = Company.startPeriod - 1;

  months.forEach((month, index) => {
    const monthIndex = (index + startingMonthIndex) % months.length;
    const monthName = months[monthIndex];

    columnsbalanceBudget.push({
      title: monthName,
      align: "center",
      render: (text, record) => {
        return (
          <p style={{ textAlign: "right" }}>
            {record.monthlyBudgets[monthIndex].toFixed(0)}
          </p>
        );
      },
    });
  });

  const columnsbalanceBudgetReal = [
    {
      title: `${t("glnumber")}`,
      dataIndex: "glNumber",
      align: "center",
    },
    {
      title: `${t("Category")}`,
      dataIndex: "category",
      align: "center",
    },
    {
      title: `${t("Description")}`,
      dataIndex: "description",
      align: "center",
    },
  ];

  months.forEach((month, index) => {
    const monthIndex = (index + startingMonthIndex) % months.length;
    const monthName = months[monthIndex];

    columnsbalanceBudgetReal.push({
      title: monthName,
      // width: 120,
      align: "center",
      render: (text, record) => {
        return (
          <p style={{ textAlign: "right" }}>
            {record.monthlyReals[monthIndex].toFixed(0)}
          </p>
        );
      },
    });
  });

  const columnsbalancePerformance = [
    {
      title: `${t("glnumber")}`,
      dataIndex: "glNumber",
      align: "center",
    },
    {
      title: `${t("Category")}`,
      dataIndex: "category",
      align: "center",
    },
    {
      title: `${t("Description")}`,
      dataIndex: "description",
      align: "center",
    },
  ];

  months.forEach((month, index) => {
    const monthIndex = (index + startingMonthIndex) % months.length;
    const monthName = months[monthIndex];

    columnsbalancePerformance.push({
      title: monthName,

      children: [
        {
          title: <p style={{ textAlign: "center", width: 100 }}>Budget</p>,
          align: "right",
          render: (text, record) => {
            return record.monthlyBudgets[monthIndex].toFixed(0);
          },
        },
        {
          title: <p style={{ width: 100, textAlign: "center" }}>{t("Real")}</p>,
          align: "right",
          render: (text, record) => {
            return record.monthlyReals[monthIndex].toFixed(0);
          },
        },

        {
          title: (
            <p style={{ textAlign: "center", width: 100 }}>{t("Difference")}</p>
          ),
          dataIndex: "street",
          align: "right",
          render: (text, record) => {
            let budget = 0;
            let performance =
              record.monthlyReals[monthIndex] -
              record.monthlyBudgets[monthIndex];
            return (
              <>
                {performance < 0 ? (
                  <p
                    style={{
                      textAlign: "right",
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    {performance.toFixed(0)}
                  </p>
                ) : (
                  <p
                    style={{
                      textAlign: "right",
                      color: "blue",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    {performance.toFixed(0)}
                  </p>
                )}
              </>
            );
          },
        },
      ],
    });
  });

  const items = [
    {
      key: "1",
      label: (
        <span style={{ width: 300, textAlign: "center", width: 100 }}>
          Budget
        </span>
      ),
      children: (
        <div>
          <Table
            columns={columnsbalanceBudget}
            dataSource={Asset}
            scroll={{
              x: 2000,
            }}
            bordered
            summary={(pageData) => {
              const items = [];
              items.push(
                <Table.Summary.Cell colSpan={0} index={1}>
                  <Text style={{ textAlign: "right" }}></Text>
                </Table.Summary.Cell>
              );
              if (Asset) {
                let itemsvalue = summary(
                  Company,
                  Asset,
                  count["budget"],
                  "monthlyBudgets"
                );

                itemsvalue?.map((val) =>
                  items.push(
                    <Table.Summary.Cell index={val.monthIndex}>
                      <Text style={{ extAlign: "right" }}>{val.monthName}</Text>
                    </Table.Summary.Cell>
                  )
                );
              }
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={4} colSpan={3}>
                      <h3 style={{ textAlign: "center" }}>{t("Average")}</h3>
                    </Table.Summary.Cell>

                    {items}
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <span style={{ width: 100, textAlign: "center" }}>{t("Real")}</span>
      ),
      children: (
        <div>
          <Table
            columns={columnsbalanceBudgetReal}
            dataSource={Asset}
            scroll={{
              x: 2000,
            }}
            bordered
            summary={(pageData) => {
              const items = [];
              items.push(
                <Table.Summary.Cell colSpan={0} index={1}>
                  <Text style={{ textAlign: "right" }}></Text>
                </Table.Summary.Cell>
              );

              if (Asset) {
                let itemsvalue = summary(
                  Company,
                  Asset,
                  count["reals"],
                  "monthlyReals"
                );

                itemsvalue?.map((val) =>
                  items.push(
                    <Table.Summary.Cell index={val.monthIndex}>
                      <Text style={{ extAlign: "right" }}>{val.monthName}</Text>
                    </Table.Summary.Cell>
                  )
                );
              }
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={4} colSpan={3}>
                      <h3 style={{ textAlign: "center" }}>{t("Average")}</h3>
                    </Table.Summary.Cell>

                    {items}
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </div>
      ),
    },

    {
      key: "3",
      label: (
        <span style={{ width: 100, textAlign: "center" }}>Performance</span>
      ),
      children: (
        <div>
          <Table
            columns={columnsbalancePerformance}
            dataSource={Asset}
            scroll={{
              x: 2000,
            }}
            bordered
            summary={(pageData) => {
              const items = [];
              if (Asset) {
                let itemsvalueReal = summary(
                  Company,
                  Asset,
                  count["reals"],
                  "monthlyReals"
                );
                let itemsvalueBudget = summary(
                  Company,
                  Asset,
                  count["budget"],
                  "monthlyBudgets"
                );
                let itemsvaluePerformance = summary(
                  Company,
                  Asset,
                  count["performance"],
                  "monthlyPerformances"
                );
                console.log("count Budget", count["budget"]);
                console.log("count Reals", count["reals"]);
                console.log("count Perf", count["performance"]);

                for (let i = 0; i < itemsvalueBudget.length.toFixed(0); i++) {
                  items.push(
                    <Table.Summary.Cell
                      index={itemsvalueBudget[i].monthIndex.toFixed(0)}
                    >
                      <Text style={{ textAlign: "right" }}>
                        {itemsvalueBudget[i].monthName}
                      </Text>
                    </Table.Summary.Cell>
                  );

                  items.push(
                    <Table.Summary.Cell
                      index={itemsvalueReal[i].monthIndex.toFixed(0)}
                    >
                      <Text style={{ textAlign: "right" }}>
                        {itemsvalueReal[i].monthName}
                      </Text>
                    </Table.Summary.Cell>
                  );

                  items.push(
                    <Table.Summary.Cell
                      index={itemsvaluePerformance[i].monthIndex.toFixed(0)}
                    >
                      {itemsvaluePerformance[i].monthName < 0 ? (
                        <Text
                          style={{
                            textAlign: "right",
                            color: "red",
                            fontWeight: "bold",
                            fontSize: "16px",
                          }}
                        >
                          {itemsvaluePerformance[i].monthName}
                        </Text>
                      ) : (
                        <Text
                          style={{
                            textAlign: "right",
                            color: "blue",
                            fontWeight: "bold",
                            fontSize: "16px",
                          }}
                        >
                          {itemsvaluePerformance[i].monthName}
                        </Text>
                      )}
                    </Table.Summary.Cell>
                  );
                }
              }

              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={1} colSpan={3}>
                      <h3 style={{ textAlign: "center" }}>{t("Average")}</h3>
                    </Table.Summary.Cell>

                    {items}
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <Card
      title={
        <span>
          <CalendarOutlined style={{ marginRight: 8 }} />
          {t("AssetSummary")}
        </span>
      }
      extra={
        <div style={{ display: "flex", alignItems: "center" }}>
          <Space>
            <DatePicker
              name="year"
              picker="year"
              placeholder={t("Selectyear")}
              style={{ width: 200, height: 35 }}
              onChange={onChangee}
              defaultValue={dayjs(date)}
              suffixIcon={<RightOutlined />}
            />

            <Link
              to={{
                pathname: `/Assets`,
              }}
            >
              <Button
                className="Create-button"
                type="primary"
                danger
                icon={<RollbackOutlined style={{ color: "#fff" }} />}
                style={{
                  marginLeft: "flex",
                  width: 200,
                  height: 35,
                }}
              >
                {t("GoBackToAssets")}
              </Button>
            </Link>
          </Space>
        </div>
      }
    >
      <Tabs
        style={{ marginBottom: 45, marginTop: 25, color: "#059BFF" }}
        type="card"
        centered
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
      />
    </Card>
  );
}
AssetSummary.acl = {
  subject: "Assets",
  action: "read",
};

export default AssetSummary;

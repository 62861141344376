import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import axios from "axios";
import {
  Typography,
  Input,
  Card,
  Table,
  Space,
  Button,
  message,
  Tag,
  Dropdown,
  Menu,
  Modal,
  Form,
  Select,
  List,
} from "antd";
import moment from "moment";
import {
  CheckOutlined,
  LockOutlined,
  UsergroupAddOutlined,
  PlusSquareOutlined,
  EditOutlined,
  EyeOutlined,
  UnlockOutlined,
  IdcardOutlined,
  DownOutlined,
  CloseOutlined,
  MinusOutlined,
  CaretDownOutlined,
  ClockCircleOutlined,
  UserOutlined,
  PlusOutlined,
  PlusCircleFilled,
  DownCircleFilled,
} from "@ant-design/icons";
import { CompanyContext } from "../contexts/CompanyContext";
import { JSON_API } from "../services/Constants";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAbility } from "../contexts/abilityContext";

const { Item } = Form;

const Users = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  let { t } = useTranslation();
  const { Company } = useContext(CompanyContext);
  const [loading, setLoading] = useState(false);
  const [changePasswordModalVisible, setChangePasswordModalVisible] =
    useState(false);
  const [Roles, setRoles] = useState([]);
  const { ability } = useAbility();
  const canUpdate = ability.can("update", "Accounts");
  const [selectedRole, setSelectedRole] = useState(null);
  const [changePasswordLoading, setChangePasswordLoading] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [employeeAccounts, setEmployeeAccounts] = useState([]);
  const [manageRolesModalVisible, setManageRolesModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(() => {
    return Number(localStorage.getItem("currentPageUseraccount")) || 1;
  });
  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    localStorage.setItem("currentPageUseraccount", pagination.current);
  };
  const [accountDetailsModalVisible, setAccountDetailsModalVisible] =
    useState(false);

  const [Employees, setEmployees] = useState([]);

  const handleUpdateAccount = (record) => {
    history.push({
      pathname: "/update-employee-account",
      state: { account: record },
    });
  };

  const handleChangePassword = (record) => {
    setSelectedAccount(record);
    setChangePasswordModalVisible(true);
  };

  const handleManageRoles = (record) => {
    setSelectedAccount(record);
    setManageRolesModalVisible(true);
  };

  const handleAddRole = async () => {
    if (selectedRole && selectedRole.length > 0) {
      const promises = selectedRole.map((roleName) =>
        addRoleToAccount({
          email: selectedAccount.email,
          roleName: roleName,
        })
      );
      const results = await Promise.allSettled(promises);
      const successfulResults = results.filter(
        (result) => result.status === "fulfilled"
      );

      if (successfulResults.length > 0) {
        message.success("SuccessfullyAddedRolesToAccount");
      } else {
        message.error("FailedToAddRolesToAccount");
      }

      getEmployeeAccounts();
    } else {
      message.warning("PleaseSelectAtLeastOneRole");
    }
  };

  const handleRemoveRole = async () => {
    if (selectedRole && selectedRole.length > 0) {
      const promises = selectedRole.map((roleName) =>
        removeRoleFromAccount({
          email: selectedAccount.email,
          roleName: roleName,
        })
      );
      await Promise.all(promises);
      getEmployeeAccounts();
      message.success("SuccessfullyRemovedRolesFromAccount");
    } else {
      message.warning("PleaseSelectAtLeastOneRole");
    }
  };

  const addRoleToAccount = async (accountChangeRole) => {
    try {
      const response = await axios.post(
        `${JSON_API}/EmployeeAccounts/roles`,
        accountChangeRole
      );
      return response.data;
    } catch (error) {
      console.log(error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        message.error(t("FailedToAddRoleToAccount"));
      }
    }
  };

  const removeRoleFromAccount = async (accountChangeRole) => {
    try {
      const response = await axios.delete(
        `${JSON_API}/EmployeeAccounts/roles`,
        {
          data: accountChangeRole,
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) {
        message.error(error.response.data.message);
      } else {
        message.error(t("FailedToRemoveRoleFromAccount"));
      }
    }
  };

  const columns = [
    {
      title: t("Employee"),
      dataIndex: "employeeName",
      align: "center",
    },
    {
      title: t("Title"),
      dataIndex: "title",
      align: "center",
      render: (title) => (
        <Tag
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            borderRadius: "5px",
            padding: "5px 10px",
            textAlign: "center",
            textTransform: "uppercase",
          }}
          color="blue"
        >
          {title.label}
        </Tag>
      ),
    },
    {
      title: t("Email"),
      dataIndex: "email",
      align: "center",
    },
    {
      title: t("PhoneNumber"),
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      align: "center",
    },

    {
      title: t("Roles"),
      dataIndex: "roles",
      align: "center",
      width: 100,
      render: (roles) => (
        <>
          {roles.length > 0 ? (
            roles.map((role) => (
              <Tag
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  textAlign: "center",
                }}
                color="green"
                key={role.name}
              >
                {role.name.toUpperCase()}
              </Tag>
            ))
          ) : (
            <Tag
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                borderRadius: "5px",
                padding: "5px 10px",
                textAlign: "center",
              }}
              color="red"
            >
              {t("NoRoleAssociated")}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: t("LastLogin"),
      dataIndex: "lastLogin",
      key: "lastLogin",
      align: "center",
      render: (lastLogin) =>
        lastLogin ? (
          moment(lastLogin).format("DD/MM/YYYY HH:mm")
        ) : (
          <Tag
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              borderRadius: "5px",
              padding: "5px 10px",
              textAlign: "center",
            }}
            color="red"
          >
            {t("NotYetConnected")}
          </Tag>
        ),
    },
  ];

  if (canUpdate) {
    columns.push({
      title: t("Actions"),
      key: "actions",
      align: "center",
      render: (text, record) => (
        <>
          {canUpdate && (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    key="1"
                    icon={<EditOutlined />}
                    onClick={() => handleUpdateAccount(record)}
                  >
                    {t("Update")}
                  </Menu.Item>
                  <Menu.Item
                    key="3"
                    icon={<IdcardOutlined />}
                    onClick={() => handleManageRoles(record)}
                  >
                    {t("ManageRoles")}
                  </Menu.Item>
                  <Menu.Item
                    key="4"
                    icon={<UnlockOutlined />}
                    onClick={() => handleChangePassword(record)}
                    danger
                  >
                    {t("ChangePassword")}
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <Button
                type="link"
                className="flex gap-2 items-center justify-center"
                onClick={(e) => e.preventDefault()}
              >
                {t("Actions")} <DownCircleFilled />
              </Button>
            </Dropdown>
          )}
        </>
      ),
    });
  }

  const handlePasswordChangeSubmit = async (values) => {
    setChangePasswordLoading(true);
    try {
      const response = await axios.put(
        `${JSON_API}/EmployeeAccounts/password-change`,
        {
          ...values,
          email: selectedAccount.email,
        }
      );
      message.success(t("PasswordChangedSuccessfully"));
      setChangePasswordModalVisible(false);
    } catch (error) {
      console.log(error.response.data);
      message.error(t("FailedToChangePassword"));
    } finally {
      setChangePasswordLoading(false);
    }
  };

  const getEmployeeAccounts = async () => {
    try {
      const response = await axios.get(
        `${JSON_API}/EmployeeAccounts/enterprise/${Company.id}`
      );
      setEmployeeAccounts(response.data);
      setLoading(true);
      const accountsWithRoles = response.data.map((account) => ({
        ...account,
        roles: account.roles.map((role) => role.name).join(", "),
      }));
      console.log("accountsWithRoles ", accountsWithRoles);
    } catch (error) {
      console.log(error);
      message.error(t("ErrorFetchingEmployeeAccounts"));
      setLoading(false);
    }
  };

  useEffect(() => {
    getEmployeeAccounts();
    getRoles();
  }, [Company.id, selectedAccount, form]);

  const getRoles = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${JSON_API}/Roles/enterprise/${Company.id}`
      );
      setRoles(response.data);
      console.log(response.data);
    } catch (error) {
      message.error(t("ErrorFetchingRoles"));
      console.error(error);
    }
    setLoading(false);
  };
  return (
    <Card
      title={
        <span style={{ fontSize: "16px" }}>
          <UsergroupAddOutlined /> {t("Users")}
        </span>
      }
    >
      <div style={{ textAlign: "center", marginBottom: 15 }}>
        <h1
          style={{
            fontSize: "24px",
          }}
        >
          {t("Users")} - {Company.name}
        </h1>
      </div>

      <Space style={{ marginBottom: 20, float: "right" }}>
        {ability && ability.can("create", "Accounts") && (
          <Link to="/createUser">
            <Button className="custom-btn" size="medium" type="primary">
              <PlusCircleFilled /> {t("Create")}
            </Button>
          </Link>
        )}
      </Space>

      <Table
        rowKey={(record) => record.Id}
        dataSource={employeeAccounts}
        columns={columns}
        pagination={{ current: currentPage }}
        onChange={handleTableChange}
        style={{ overflowX: "auto", backgroundColor: "white" }}
      />

      {/* Modal To Change Password */}
      <Modal
        style={{ marginTop: "50px", marginBottom: "50px" }}
        bodyStyle={{ marginTop: "2px", marginBottom: "25px" }}
        title={
          <span style={{ fontSize: "16px" }}>
            <LockOutlined /> {t("ChangePassword")}
          </span>
        }
        visible={changePasswordModalVisible}
        onCancel={() => setChangePasswordModalVisible(false)}
        footer={[
          <Button
            key="back"
            type="primary"
            danger
            onClick={() => setChangePasswordModalVisible(false)}
            icon={<CloseOutlined />}
          >
            {t("Cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={changePasswordLoading}
            onClick={() => form.submit()}
            icon={<CheckOutlined />}
          >
            {t("ChangePassword")}
          </Button>,
        ]}
      >
        <Form
          layout="vertical"
          onFinish={handlePasswordChangeSubmit}
          form={form}
        >
          <Item
            label={t("CurrentPassword")}
            name="currentPassword"
            rules={[{ required: true }]}
          >
            <Input.Password prefix={<LockOutlined />} />
          </Item>
          <Item
            label={t("NewPassword")}
            name="newPassword"
            rules={[{ required: true }]}
          >
            <Input.Password prefix={<LockOutlined />} />
          </Item>
        </Form>
      </Modal>

      {/* Modal To Manage Roles */}
      <Modal
        className="custom-modal"
        title={
          <span style={{ fontSize: "16px" }}>
            <IdcardOutlined /> {t("ManageRoles")}
          </span>
        }
        visible={manageRolesModalVisible}
        onCancel={() => setManageRolesModalVisible(false)}
        footer={[
          <Button
            type="primary"
            key="add"
            icon={<PlusOutlined />}
            onClick={handleAddRole}
          >
            {t("AddRoleToAccount")}
          </Button>,
          <Button
            type="primary"
            danger
            key="remove"
            icon={<MinusOutlined />}
            onClick={handleRemoveRole}
          >
            {t("RemoveRoleFromAccount")}
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label={t("SelectRole")}>
            <Select
              suffixIcon={<CaretDownOutlined />}
              mode="multiple"
              onChange={(value) => setSelectedRole(value)}
              label={t("RoleName")}
            >
              {/* Replace with the actual list of roles */}
              {Roles.map((role) => (
                <Select.Option key={role.id} value={role.name}>
                  {role.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};
Users.acl = {
  subject: "Accounts",
  action: "read",
};

export default Users;

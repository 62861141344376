/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {
  Card,
  Space,
  Button,
  Col,
  Divider,
  Empty,
  Form,
  Input,
  Layout,
  Row,
  Checkbox,
  Typography,
  message,
} from "antd";
import axios from "axios";
import React, { Component, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { JSON_API } from "../services/Constants";

import { UserOutlined, LockOutlined } from "@ant-design/icons";
import logo from "../assets/images/blueskylogo.png";
import "../assets/styles/Login.css";
function onChange(checked) {
  console.log(`switch to ${checked}`);
}
const { Title } = Typography;
const { Content } = Layout;

const SignIn = () => {
  const onFinish = async (values) => {
    LoginIn(values);
  };
  const history = useHistory();
  const LoginIn = async (values) => {
    const hide = message.loading("Login in progress..");

    const userinfo = {
      email: values.username,
      password: values.password,
    };

    await axios
      .post(`${JSON_API}/Authentication/login`, userinfo)
      .then((response) => {
        const token = response.data.hash;

        localStorage.setItem("token", token);

        setTimeout(hide);

        let path = `/`;
        history.push(path);
      })
      .catch(function (error) {
        setTimeout(hide); // Masquer le message de chargement dans tous les cas d'erreur
        if (error.response) {
          if (error.response.status === 400) {
            // Vérifiez le corps de la réponse pour le message d'erreur spécifique
            const errorMessage =
              error.response.data.message ||
              "Your password is incorrect, please try again.";
            message.error(errorMessage);
          } else if (error.response.status === 401) {
            message.error("Your password is incorrect, please try again.");
          } else {
            // Pour les autres erreurs HTTP, affichez simplement le code de statut
            message.error(`Error: ${error.response.status}`);
          }
        } else if (error.request) {
          console.log(error.request);
          message.error(
            "No response was received, could not connect to server."
          );
        } else {
          console.log("Error", error.message);
          message.error("An error occurred while setting up the request.");
        }
        console.log(error.config);
      });
  };

  const [loadings, setLoadings] = useState([]);
  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <Layout className="layout-default layout-signin">
        <Content className="flex w-screen h-screen justify-center items-start">
          <Row justify="center">
            <Col className="login-box bg-white p-4 rounded space-y-12 w-full max-w-xl p-8">
              <div className="flex justify-center">
                <img className="w-auto h-32" src={logo} alt="logo" />
              </div>
              <Divider />
              <div className="px-3 py-3">
                <Title className="font-semibold text-center mb-6" level={4}>
                  Enter your email and password to sign in
                </Title>
                <br />
                <Form
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <Form.Item
                    className="username"
                    label="Username"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username",
                      },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined />}
                      placeholder="Username"
                      size="large"
                    />
                  </Form.Item>

                  <Form.Item
                    className="password"
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password",
                      },
                    ]}
                  >
                    <Input.Password
                      prefix={<LockOutlined />}
                      placeholder="Password"
                      size="large"
                    />
                  </Form.Item>

                  <Form.Item
                    name="remember"
                    className="align-center"
                    valuePropName="checked"
                  >
                    <Checkbox onChange={onChange}>Remember me</Checkbox>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                      size="large"
                      loading={loadings[0]}
                    >
                      SIGN IN
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};
export default SignIn;

import React, { useState, useEffect } from "react";
import { Collapse, Button, Input, Card, Popconfirm, Tooltip } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  CaretRightOutlined,
  PlusOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import SubCategories from "./SubCategories";
import Section from "./Section";
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;

const Category = ({
  category,
  moveElement,
  index,
  partId,
  editCategory,
  deleteCategory,
  addSection,
  deleteSection,
  editSection,
  isReadOnly,
  enterpriseId,
}) => {
  const [currentCategory, setCategory] = useState(category);
  const [sections, setSections] = useState(category.sections || []);
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(currentCategory.name);
  let uniqueId = parseInt(
    `${new Date().getTime()}${Math.floor(Math.random() * 1000)}`,
    10
  );

  let { t } = useTranslation();

  useEffect(() => {
    setCategory(category);
    setSections(category.sections || []);
  }, [category]);

  const handleEditClick = () => {
    setIsEditing(true);
    setEditedName(currentCategory.name);
  };
  const handleInputChange = (e) => {
    setEditedName(e.target.value);
  };
  const handleSave = () => {
    const updatedCategory = { ...currentCategory, name: editedName };
    setCategory(updatedCategory);
    editCategory(partId, updatedCategory.id, editedName);
    setIsEditing(false);
  };
  const handleSection = () => {
    console.log(
      "_ -_ -_ -_ -_ -_ -_ -_ -_ -_ -_ -| ADD |-_ -_ -_ -_ -_ -_ -_ -_ -_ -_ -_ -_ -_ -_-_-_-_-_-_-_-_-_-_"
    );
    const newSection = {
      id: uniqueId++,
      name: "New Section",
      chartAccountId: 0,
      isImmutable: false,
      subSections: [],
      position: 0,
    };
    // Add the new section to the existing sections state
    setSections((prevSections) => [...prevSections, newSection]);
  };

  const handleDeleteSection = (sectionId) => {
    const updatedSections = sections.filter((s) => s.id !== sectionId);
    setSections(updatedSections);
  };

  // ... Rest of your component logic ...
  // add section using category id

  return (
    <Collapse
      accordion
      justify="center"
      align="middle"
      bordered={false}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
    >
      <Panel
        key={currentCategory.id}
        style={{
          background: "#cccc5a",
          borderRadius: 4,
          margin: "10px 0",
          overflow: "hidden",
        }}
        header={
          isEditing ? (
            <>
              <Input
                value={editedName}
                onChange={handleInputChange}
                style={{ width: 300, marginRight: 10 }}
                autoFocus
              />
              <Button
                type="link"
                icon={<CheckOutlined />}
                style={{ marginLeft: 5, fontWeight: "bold", color: "green" }}
                onClick={() => handleSave()}
              >
                {t("Save")}
              </Button>
            </>
          ) : (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>{currentCategory.name}</span>
              <div>
                <Button
                  disabled={isReadOnly}
                  type="link"
                  icon={<EditOutlined />}
                  onClick={handleEditClick}
                />
                <Popconfirm
                  title={t("AreYousSureDeleteThisCategory")}
                  okText={t("yes")}
                  cancelText={t("no")}
                  onConfirm={() => deleteCategory(partId, currentCategory.id)}
                >
                  <span onClick={(e) => e.stopPropagation()}>
                    <Button
                      disabled={isReadOnly}
                      type="link"
                      icon={<DeleteOutlined style={{ color: "red" }} />}
                    />
                  </span>
                </Popconfirm>
              </div>
            </div>
          )
        }
      >
        <Card bordered={false}>
          <Tooltip title={t("AddSection")}>
            <Button
              disabled={isReadOnly}
              onClick={() => addSection(partId, currentCategory.id)}
              type="link"
              size="small"
              shape="circle"
              icon={
                <PlusOutlined style={{ color: "#ffff", marginRight: -5 }} />
              }
              style={{
                position: "absolute",
                top: 20,
                fontWeight: "bold",
                backgroundColor: "#81e9e6",
                top: 10,
                right: 20,
                zIndex: 1,
              }}
            />
          </Tooltip>
          <br />
          {currentCategory.subCategories && (
            <SubCategories
              initialSubCategories={currentCategory.subCategories}
              // ... other required props for SubCategories
            />
          )}

          {currentCategory.sections &&
            currentCategory.sections.map((section, idx) => (
              <Section // Adjusted component name
                key={idx}
                initialSection={section} // Send as single section
                partId={partId}
                // addSection={addSection}
                deleteSection={deleteSection}
                editSection={editSection}
                categoryId={currentCategory.id}
                isReadOnly={isReadOnly}
                enterpriseId={enterpriseId}
              />
            ))}
        </Card>
      </Panel>
    </Collapse>
  );
};

export default Category;

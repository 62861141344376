import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  message,
  notification,
  Row,
  Col,
  Typography,
  Form,
  Card,
  Alert,
  Button,
  Divider,
  Input,
  DatePicker,
  Breadcrumb,
} from "antd";
import {
  UsergroupAddOutlined,
  ArrowLeftOutlined,
  SyncOutlined,
  UserAddOutlined,
  SecurityScanOutlined,
  AppstoreAddOutlined,
} from "@ant-design/icons";
import { CompanyContext } from "../contexts/CompanyContext";
import { JSON_API } from "../services/Constants";
import { useTranslation } from "react-i18next";

const { Text, Title } = Typography;

const AddIntegrator = () => {
  const { Company } = useContext(CompanyContext);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  let { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isCreated, setIsCreated] = useState(false);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const generateRandomPassword = () => {
    let companyName = Company.name;
    companyName = capitalizeFirstLetter(companyName);

    const specialCharacters = "@";
    const randomString = Math.random().toString(36).slice(-8);
    const newPassword = `${companyName}${specialCharacters}${randomString}`;

    setPassword(newPassword);
    setConfirmPassword(newPassword);
    setShowAlert(true);
  };

  const handleSubmit = async (values) => {
    if (password !== confirmPassword) {
      notification.error({
        message: t("FailedtoCreateIntegrator"),
        description: t("Passwordsdonotmatch"),
      });
      return;
    }

    setIsLoading(true);

    try {
      const allIntegratorsResponse = await axios.get(
        `${JSON_API}/IntegratorAccounts`
      );

      const duplicate = allIntegratorsResponse.data.some(
        (integrator) =>
          integrator.firstName === values.firstName &&
          integrator.lastName === values.lastName
      );

      if (duplicate) {
        notification.error({
          message: t("FailedtoCreateIntegrator"),
          description: t("IntegratorAlreadyExists"),
        });
        setIsLoading(false);
        return;
      }

      const response = await axios.post(`${JSON_API}/IntegratorAccounts`, {
        firstName: values.firstName,
        lastName: values.lastName,
        joinDate: values.joinDate,
        phoneNumber: values.phoneNumber,
        email: values.email,
        password: password,
      });

      if (response.status === 200) {
        notification.success({
          message: t("IntegratorCreatedSuccessfully"),
          description: t("IntegratorCreatedSuccessfully"),
        });
        setIsCreated(true);
        history.push("/integrators");
      } else {
        notification.error({
          message: t("FailedtoCreateIntegrator"),
          description: t("AnErrorOccurredWhileCreatingTheIntegratorAccount"),
        });
      }
    } catch (err) {
      notification.error({
        message: t("FailedtoCreateIntegrator"),
        description: t("AnErrorOccurredWhileCreatingTheIntegratorAccount"),
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className="create-integrator-card">
      <Breadcrumb
        style={{
          marginBottom: "1rem",
          fontSize: "1.2rem",
          textShadow: "1px 1px 1px #aaa",
        }}
      >
        <Breadcrumb.Item style={{ hover: "none" }}>
          <Link to="/accounts" style={{ hover: "none" }}>
            <Typography.Text>
              <SecurityScanOutlined /> {t("Security")}
            </Typography.Text>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/integrators" style={{ hover: "none" }}>
            <Typography.Text>
              <AppstoreAddOutlined /> {t("Integrators")}
            </Typography.Text>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Typography.Text>
            <UsergroupAddOutlined /> {t("CreateAnIntegrator")}
          </Typography.Text>
        </Breadcrumb.Item>
      </Breadcrumb>

      <Form
        layout="vertical"
        name="register"
        onFinish={handleSubmit}
        style={{ margin: "0 auto", maxWidth: "1000px" }}
      >
        <Typography.Title
          level={2}
          style={{ textAlign: "center", marginBottom: "2rem" }}
        >
          {t("CreateAnIntegrator")}
        </Typography.Title>

        <Divider orientation="left">{t("IntegratorInfos")}</Divider>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="firstName"
              label={t("FirstName")}
              rules={[
                { required: true, message: t("PleaseInputYourFirstName") },
              ]}
            >
              <Input placeholder={t("EnterFirstName")} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="lastName"
              label={t("LastName")}
              rules={[
                { required: true, message: t("PleaseInputYourLastName") },
              ]}
            >
              <Input placeholder={t("EnterLastName")} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="joinDate"
          label={t("DateJoined")}
          rules={[{ required: true, message: t("PleaseChooseDateJoin") }]}
        >
          <DatePicker style={{ width: "100%" }} placeholder={t("SelectDate")} />
        </Form.Item>

        <Divider orientation="left">{t("AccountInfos")}</Divider>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="phoneNumber"
              label={t("PhoneNumber")}
              rules={[
                { required: true, message: t("PleaseinputthePhoneNumber") },
              ]}
            >
              <Input type="tel" placeholder={t("EnterPhoneNumber")} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="email"
              label={t("Email")}
              rules={[{ required: true, message: t("PleaseinputtheEmail") }]}
            >
              <Input type="email" placeholder={t("EnterEmail")} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              label={t("Password")}
              name="password"
              rules={[
                {
                  required: true,
                  message: t("PleaseEnterPassword"),
                },
                {
                  pattern: /.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?].*/,
                  message: t("PasswordSpecialCharError"),
                },
              ]}
            >
              <Input.Password
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={t("EnterPassword")}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label={t("ConfirmPassword")}
              name="confirmPassword"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t("PleaseConfirmPassword"),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t("PasswordsDoNotMatch")));
                  },
                }),
              ]}
            >
              <Input.Password
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder={t("ConfirmPassword")}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} justify="center">
          <Col xs={24} sm={8}>
            <Form.Item>
              <Link to="/integrators">
                <Button type="primary" danger block>
                  <ArrowLeftOutlined /> {t("GoBack")}
                </Button>
              </Link>
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item>
              <Button
                type="secondary"
                icon={<SyncOutlined />}
                onClick={generateRandomPassword}
                block
              >
                {t("GeneratePassword")}
              </Button>
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                disabled={isLoading || isCreated}
              >
                <UserAddOutlined /> {t("CreateAccount")}
              </Button>
            </Form.Item>
          </Col>
        </Row>

        {showAlert && (
          <Alert
            message={t("YourPasswordHasBeenGenerated")}
            type="warning"
            showIcon
            closable
            afterClose={() => setShowAlert(false)}
          />
        )}
      </Form>
    </Card>
  );
};
AddIntegrator.acl = {
  subject: "Accounts",
  action: "create",
};
export default AddIntegrator;

import {
  EditOutlined,
  InfoCircleOutlined,
  PlusSquareOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Descriptions,
  Divider,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import React, { useContext, useEffect, useMemo, useState } from "react";

import axios from "axios";
import { useTranslation } from "react-i18next";
import { CompanyContext } from "../contexts/CompanyContext";
import { JSON_API } from "../services/Constants";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useAbility } from "../contexts/abilityContext";
dayjs.extend(customParseFormat);

const { Option } = Select;
const { Text, Link, Title } = Typography;
const { TextArea } = Input;

// ON CHANGE SELECT
const handleChange = (value) => {
  console.log(`selected ${value}`);
};
function Devis() {
  let { t } = useTranslation();
  const { Company } = useContext(CompanyContext);

  const [size, setSize] = useState("large");

  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();

  const [statementclass, setStatementClass] = useState([{}]);
  const [statementcategory, setStatementCategory] = useState([{}]);
  const [statementtype, setStatementType] = useState([{}]);
  const [glaccount, setGLaccount] = useState([{}]);
  const [componentDisabled, setComponentDisabled] = useState(true);
  const [editingRowbook, setEditingRowbook] = useState(null);
  const [editingRowbook1, setEditingRowbook1] = useState(null);

  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenupdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Devis, setDevis] = useState([]);
  const [Devis2, setDevis2] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState([]);
  const { ability } = useAbility();

  const [loadings, setLoadings] = useState(false);

  const { Panel } = Collapse;
  const [year, setYear] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const showNameModal = (name) => {
    setSelectedName(name);
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedName(null);
  };

  useEffect(() => {
    handleCostumer();
  }, []);

  const getDevisData = async () => {
    await axios
      .get(`${JSON_API}/Devis/Enterprise/${Company.id}`)
      .then((res) => {
        console.log(res);
        setDevis(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // get devis by noDevis
  const getDevisByNo = (noDevis) => {
    try {
      let data = Devis.find((i) => i.noDevis == noDevis);
      const new_data = {
        ...data,
        variance_estimate_invoice: data.variance_estimate_invoice.toFixed(2),
        variance_estimate_invoice_percent:
          data.variance_estimate_invoice_percent.toFixed(2) + " \t %",
        total_variance: data.total_variance.toFixed(2),
        total_variance_percent:
          data.total_variance_percent.toFixed(2) + " \t %",
      };

      form3.setFieldsValue({
        noDevis: new_data.noDevis,
        nomDevis: new_data.nomDevis,
        enterpriseId: new_data.enterpriseId,
        customerName: new_data.customerName,
        employeeName: new_data.employeeName,
        estimated_hourly_rate: new_data.estimated_hourly_rate,
        estimated_total_hours: new_data.estimated_total_hours,
        material_costs: new_data.material_costs,
        other_costs: new_data.other_costs,
        noInvoice: new_data.noInvoice,
        invoice_hourly_rate: new_data.invoice_hourly_rate,
        invoice_total_hours: new_data.invoice_total_hours,
        material_costs_invoice: new_data.material_costs_invoice,
        other_costs_invoice: new_data.other_costs_invoice,
        actual_hourly_rate: new_data.actual_hourly_rate,
        actual_total_hours: new_data.actual_total_hours,
        material_costs_actual: new_data.material_costs_actual,
        other_costs_actual: new_data.other_costs_actual,
        commission: (new_data.commission / new_data.invoice_total_cost) * 100,
      });
      setDevis2([new_data]);
    } catch (err) {
      console.log(err);
    }
  };

  // i want an onchange event to get the data of the devis by noDevis whene i change the value of the input
  const handleDevisNo = async (e) => {
    const noDevis = e.target.value;
    if (e.target.value === "") {
      await getData();
    } else {
      console.log(noDevis);
      await getDevisByNo(noDevis);
    }
  };

  const getStatementClass = async () => {
    await axios
      .get(`${JSON_API}/FinancialStatementClass`)
      .then((res) => {
        console.log(res);
        setStatementClass(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleclass = async (e) => {
    // console.log(e);
    await axios
      .get(`${JSON_API}/FinancialStatementCategory/class/${e}`)

      .then((res) => {
        console.log(res);
        setStatementCategory(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    await axios
      .get(`${JSON_API}/FinancialStatementType/classe/${e}`)

      .then((res) => {
        console.log(res);
        setStatementType(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlecategory = async (e) => {
    // console.log(e);
    await axios
      .get(`${JSON_API}/FinancialStatementType/category/${e}`)

      .then((res) => {
        console.log(res);
        setStatementType(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCostumer = async () => {
    // console.log(e);
    await axios
      .get(`${JSON_API}/Customers/enterprise/${Company.id}`)
      .then((res) => {
        console.log(res);
        setCustomers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleupdateOk = async (e) => {
    setLoading(true);
    console.log("values are : ", e);
    const obj = {
      id: editingRowbook,
      note: e.note,
      financialStatementCategoryId: e.category,
    };
    await axios
      .put(`${JSON_API}/ChartAccounts`, obj)
      .then((response) => {
        getData();
        setLoading(false);
        setEditingRowbook(null);
        form2.resetFields();
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };
  const onChangee = (date, dateString) => {
    setDevis(null);
    console.log(date, dateString);
    setYear(dateString);
  };
  // Checkbox
  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  var date;
  useEffect(() => {
    console.log("useeffect");
    getDevisData();
  }, []);

  useEffect(() => {
    const getDevisData = async () => {
      await axios
        .get(`${JSON_API}/Devis/Enterprise/${Company.id}`)
        .then((res) => {
          console.log(res);
          setDevis(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getDevisData();
  }, [Company]);

  const getData = async () => {
    await axios
      .get(`${JSON_API}/Devis/Enterprise/${Company.id}`)
      .then((response) => {
        setDevis(response.data);
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        setDevis(null);
      });
  };

  const handleDelete = async (e) => {
    await axios
      .delete(`${JSON_API}/Devis/Delete/${e}`)
      .then((res) => {
        getData();
        console.log(res.data);
        setEditingRowbook(null);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const Submit = async (e) => {
    const formData = {
      enterpriseId: Company.id,
      customerName: e.customerName,
      devisName: e.devisName,
      nomDevis: e.nomDevis,
      employeeName: e.employeeName,
      estimated_hourly_rate: e.estimated_hourly_rate,
      estimated_total_hours: e.estimated_total_hours,
      estimated_total_cost: e.estimated_total_cost,
      material_costs: e.material_costs,
      other_costs: e.other_costs,
      noInvoice: e.noInvoice,
      invoice_hourly_rate: e.invoice_hourly_rate,
      invoice_total_hours: e.invoice_total_hours,
      invoice_total_cost: e.invoice_total_cost,
      material_costs_invoice: e.material_costs_invoice,
      commission: e.commission,
      other_costs_invoice: e.other_costs_invoice,
      actual_hourly_rate: e.actual_hourly_rate,
      actual_total_hours: e.actual_total_hours,
      actual_total_cost: e.actual_total_cost,
      material_costs_actual: e.material_costs_actual,
      other_costs_actual: e.other_costs_actual,
      // year: new Date().getFullYear()+"",
      //   note: e.note,
      //   annualBudget: e.annualBudget,
      //   budgetBreakdown: componentDisabled,
      //   glAccountId: e.glAccountId,
      //   financialStatementTypeGIFI: e.type,
      // financialStatementCategoryId:e.category
    };

    console.log(formData);
    await axios
      .post(`${JSON_API}/Devis`, formData)
      .then((res) => {
        getData();
        console.log(res.data);
        setOpen(!open);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const Submitupdate = async (e) => {
    const formData = {
      noDevis: editingRowbook1,
      enterpriseId: Company.id,
      customerName: e.customerName,
      devisName: e.devisName,
      nomDevis: e.nomDevis,
      employeeName: e.employeeName,
      estimated_hourly_rate: e.estimated_hourly_rate,
      estimated_total_hours: e.estimated_total_hours,
      estimated_total_cost: e.estimated_total_cost,
      material_costs: e.material_costs,
      other_costs: e.other_costs,
      noInvoice: e.noInvoice,
      invoice_hourly_rate: e.invoice_hourly_rate,
      invoice_total_hours: e.invoice_total_hours,
      invoice_total_cost: e.invoice_total_cost,
      material_costs_invoice: e.material_costs_invoice,
      commission: e.commission,
      other_costs_invoice: e.other_costs_invoice,
      actual_hourly_rate: e.actual_hourly_rate,
      actual_total_hours: e.actual_total_hours,
      actual_total_cost: e.actual_total_cost,
      material_costs_actual: e.material_costs_actual,
      other_costs_actual: e.other_costs_actual,
    };

    console.log(formData);
    await axios
      .put(`${JSON_API}/Devis`, formData)
      .then((res) => {
        getData();
        setEditingRowbook1(null);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const StatefulModalContent = (props) => {
    console.log("props test: ", props);
    return (
      <Form
        form={props.form}
        layout="horizontal"
        name="form_in_modal"
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 14,
        }}
      >
        <Divider orientation="left">{t("general")}</Divider>

        <Form.Item
          // value={nom}
          name="employeeName"
          label={t("Orderdetail")}
          rules={[
            {
              required: true,
              message: `${t("Pleaseinputtheorderdetails")}`,
            },
          ]}
        >
          {/* const [statementclass, setStatementClass]=useState([{}]);
                  const [statementcategory, setStatementCategory]=useState([{}]);
                  const [statementtype, setStatementType]=useState([{}]); */}

          <Input
            // onChange={e=>setShareHolderShares(e.target.value)}
            // value={ShareHolderShares}
            placeholder={t("Orderdetail")}
            // onChange={e=>setNom(e.target.value)}
            // value={nom}
            // onChange={e=>setNom(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          name="customerName"
          label={t("CustomerName")}
          rules={[
            {
              required: true,
              message: `${t("Pleaseinputthecustomer")}`,
            },
          ]}
        >
          <Input
            // onChange={e=>setShareHolderShares(e.target.value)}
            // value={ShareHolderShares}
            placeholder={t("CustomerName")}
            // onChange={e=>setNom(e.target.value)}
            // value={nom}
            // onChange={e=>setNom(e.target.value)}
          />
          {/* <div style={{ display: "flex", alignItems: "center" }}> */}
          {/* <Select
            disabled={props.form === form2 ? true : false}
            onChange={(value) => {
              setCustomer(value.name);
              console.log(value.name);
            }}
            value={customer}
            style={{ marginRight: "8px" }}
          >
            {customers.map((e) => e && <Option value={e.name}>{e.name}</Option>)}
          </Select> */}
          {/* <Button type="text" >
            +
          </Button> */}
          {/* </div> */}
        </Form.Item>

        <Form.Item
          // value={nom}
          name="nomDevis"
          label={t("namedevis")}
          rules={[
            {
              required: true,
              message: `${t("PleaseinputtheDevisName")}`,
            },
          ]}
        >
          {/* const [statementclass, setStatementClass]=useState([{}]);
                  const [statementcategory, setStatementCategory]=useState([{}]);
                  const [statementtype, setStatementType]=useState([{}]); */}

          <Input
            // onChange={e=>setShareHolderShares(e.target.value)}
            // value={ShareHolderShares}
            placeholder={t("namedevis")}
            // onChange={e=>setNom(e.target.value)}
            // value={nom}
            // onChange={e=>setNom(e.target.value)}
          />
        </Form.Item>

        <Divider orientation="left">{t("estimated")}</Divider>

        <Form.Item
          // value={nom}
          name="estimated_hourly_rate"
          label={t("HourlyRate")}
          rules={[
            {
              required: true,
              message: `${t("Pleaseinputthehourlyrate")}`,
            },
          ]}
        >
          <InputNumber
            // disabled={SHselected}
            min={0}
            //width={800}
            size={"large"}

            // onChange={e=>setShareHolderShares(e)}
          />
        </Form.Item>

        <Form.Item
          // value={nom}
          name="estimated_total_hours"
          label={t("totalHours")}
          rules={[
            {
              required: true,
              message: `${t("Pleaseinputthetotalhours")}`,
            },
          ]}
        >
          <InputNumber
            // disabled={SHselected}
            min={0}
            size={"large"}

            // onChange={e=>setShareHolderShares(e)}
          />
        </Form.Item>
        <Form.Item
          // value={nom}
          name="material_costs"
          label={t("materialCose")}
          rules={[
            {
              required: true,
              message: `${t("Pleaseinputthematerialcost")}`,
            },
          ]}
        >
          <InputNumber
            // disabled={SHselected}
            min={0}
            size={"large"}

            // onChange={e=>setShareHolderShares(e)}
          />
        </Form.Item>
        <Form.Item
          // value={nom}
          name="other_costs"
          label={t("otherCost")}
          rules={[
            {
              required: true,
              message: `${t("Pleaseinputtheothercosts")}`,
            },
          ]}
        >
          <InputNumber
            // disabled={SHselected}
            min={0}
            size={"large"}

            // onChange={e=>setShareHolderShares(e)}
          />
        </Form.Item>

        <Divider orientation="left">{t("invoiced")}</Divider>

        <Form.Item
          // value={nom}
          name="noInvoice"
          label={t("noInvoive")}
          rules={[
            {
              required: true,
              message: `${t("Pleaseinputtheinvoiceno")}`,
            },
          ]}
        >
          <InputNumber
            // disabled={SHselected}
            min={0}
            size={"large"}

            // onChange={e=>setShareHolderShares(e)}
          />
        </Form.Item>

        <Form.Item
          // value={nom}
          name="invoice_hourly_rate"
          label={t("HourlyRate")}
          rules={[
            {
              required: true,
              message: `${t("Pleaseinputthehourlyrate")}`,
            },
          ]}
        >
          <InputNumber
            // disabled={SHselected}
            min={0}
            size={"large"}

            // onChange={e=>setShareHolderShares(e)}
          />
        </Form.Item>
        <Form.Item
          // value={nom}
          name="invoice_total_hours"
          label={t("totalHours")}
          rules={[
            {
              required: true,
              message: `${t("Pleaseinputthetotalhours")}`,
            },
          ]}
        >
          <InputNumber
            // disabled={SHselected}
            min={0}
            size={"large"}

            // onChange={e=>setShareHolderShares(e)}
          />
        </Form.Item>
        <Form.Item
          // value={nom}
          name="material_costs_invoice"
          label={t("materialCose")}
          rules={[
            {
              required: true,
              message: `${t("Pleaseinputthematerialcost")}`,
            },
          ]}
        >
          <InputNumber
            // disabled={SHselected}
            min={0}
            size={"large"}

            // onChange={e=>setShareHolderShares(e)}
          />
        </Form.Item>
        <Form.Item
          // value={nom}
          name="other_costs_invoice"
          label={t("otherCost")}
          rules={[
            {
              required: true,
              message: `${t("Pleaseinputtheothercosts")}`,
            },
          ]}
        >
          <InputNumber
            // disabled={SHselected}
            min={0}
            size={"large"}

            // onChange={e=>setShareHolderShares(e)}
          />
        </Form.Item>

        <Divider orientation="left">{t("actual")}</Divider>

        <Form.Item
          // value={nom}
          name="actual_hourly_rate"
          label={t("HourlyRate")}
          rules={[
            {
              required: true,
              message: `${t("Pleaseinputthehourlyrate")}`,
            },
          ]}
        >
          <InputNumber
            // disabled={SHselected}
            min={0}
            size={"large"}

            // onChange={e=>setShareHolderShares(e)}
          />
        </Form.Item>
        <Form.Item
          // value={nom}
          name="actual_total_hours"
          label={t("totalHours")}
          rules={[
            {
              required: true,
              message: `${t("Pleaseinputthetotalhours")}`,
            },
          ]}
        >
          <InputNumber
            // disabled={SHselected}
            min={0}
            size={"large"}

            // onChange={e=>setShareHolderShares(e)}
          />
        </Form.Item>
        <Form.Item
          // value={nom}
          name="material_costs_actual"
          label={t("materialCose")}
          rules={[
            {
              required: true,
              message: `${t("Pleaseinputthematerialcost")}`,
            },
          ]}
        >
          <InputNumber
            // disabled={SHselected}
            min={0}
            size={"large"}

            // onChange={e=>setShareHolderShares(e)}
          />
        </Form.Item>
        <Form.Item
          // value={nom}
          name="other_costs_actual"
          label={t("otherCost")}
          rules={[
            {
              required: true,
              message: `${t("Pleaseinputtheothercosts")}`,
            },
          ]}
        >
          <InputNumber
            // disabled={SHselected}
            min={0}
            size={"large"}

            // onChange={e=>setShareHolderShares(e)}
          />
        </Form.Item>
        <Divider />
        <Form.Item
          // value={nom}
          name="commission"
          label={t("Commission")}
        >
          <InputNumber
            defaultValue={0}
            min={0}
            max={100}
            formatter={(value) => `${value}%`}
            parser={(value) => value.replace("%", "")}
            // onChange={onChange}
          />
        </Form.Item>
      </Form>
    );
  };

  const CollectionCreateForm = ({ open, onCreate, onCancel }) => {
    const content = useMemo(
      () => <StatefulModalContent form={form} />,
      [statementcategory, statementtype, glaccount]
    );

    return (
      <Modal
        forceRender
        open={open}
        width={800}
        style={{ textAlign: "center" }}
        title={<h3>{t("createnewmarginforenterprise")}</h3>}
        okText={t("create")}
        cancelText="Annuler"
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onCreate(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
              console.log(customers);
            });
        }}
      >
        {content}
      </Modal>
    );
  };
  const CollectionEditForm = ({ open, onCreate, onCancel }) => {
    return (
      <Modal
        forceRender
        open={open}
        width={800}
        style={{ textAlign: "center" }}
        title={<h3>{t("Editmargin")}</h3>}
        okText={t("save")}
        cancelText={t("Cancel")}
        onCancel={onCancel}
        onOk={() => {
          form3
            .validateFields()
            .then((values) => {
              form3.resetFields();
              onCreate(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <StatefulModalContent values={Devis2} form={form3} />
      </Modal>
    );
  };
  const OnopenUpdate = (values) => {
    console.log("Received values of form: ", values);
    setEditingRowbook(null);
  };
  const handleCancel = () => {
    setEditingRowbook(null);
  };

  const CollectionUpdateForm = ({ open, onCreate, onCancel, ability }) => {
    return (
      <Modal
        open={open}
        title={<h4 style={{ textAlign: "center" }}> {t("detail")} </h4>}
        okText="Update"
        width={1500}
        cancelText="Cancel"
        footer={[
          <Button key="back" cancelText="Cancel" onClick={onCancel}>
            {t("return")}
          </Button>,
          // <Button  type="primary" loading={loading} onClick={() => {
          //   form2
          //     .validateFields()
          //     .then((values) => {
          //       handleupdateOk(values);
          //     })
          //     .catch((info) => {
          //       console.log("Validate Failed:", info);
          //     });
          // }}
          //  okText="Update">
          //   Update
          // </Button>,

          ability && ability.can("delete", "Gross Margins") ? (
            <Button type="primary" danger>
              <Popconfirm
                title={t("deletethetask")}
                description={t("deletethetask")}
                okText={t("yes")}
                cancelText={t("no")}
                onConfirm={() => handleDelete(editingRowbook)}
              >
                {t("Delete")}
              </Popconfirm>
            </Button>
          ) : null,
        ]}
        onCancel={onCancel}
        // onOk={() => {
        //   form2
        //     .validateFields()
        //     .then((values) => {
        //       form2.resetFields();
        //       onCreate(values);
        //     })
        //     .catch((info) => {
        //       console.log("Validate Failed:", info);
        //     });
        // }}
      >
        {/* <StatefulModalContent form={form2}/> */}
        <div>
          <h4 style={{ borderLeft: "4px solid #007bff", paddingLeft: "10px" }}>
            {t("Marges")}
          </h4>
          <Table
            locale={{
              emptyText: <Empty description={t("nodata")} />,
            }}
            columns={columns1}
            dataSource={Devis2}
            bordered
            pagination={false}
            style={{ marginBottom: "20px" }}
            sortDirections={[]}
          />
        </div>
        <div>
          <h4 style={{ borderLeft: "4px solid #007bff", paddingLeft: "10px" }}>
            {t("estimated")}
          </h4>
          <Table
            locale={{
              emptyText: <Empty description={t("nodata")} />,
            }}
            columns={columns2}
            dataSource={Devis2}
            bordered
            pagination={false}
            style={{ marginBottom: "20px" }}
            sortDirections={[]}
          />
        </div>
        <div>
          <h4 style={{ borderLeft: "4px solid #007bff", paddingLeft: "10px" }}>
            {t("invoiced")}
          </h4>
          <Table
            locale={{
              emptyText: <Empty description={t("nodata")} />,
            }}
            columns={columns3}
            dataSource={Devis2}
            bordered
            pagination={false}
            style={{ marginBottom: "20px" }}
            sortDirections={[]}
          />
        </div>
        <div>
          <h4 style={{ borderLeft: "4px solid #007bff", paddingLeft: "10px" }}>
            {t("actual")}
          </h4>
          <Table
            locale={{
              emptyText: <Empty description={t("nodata")} />,
            }}
            columns={columns4}
            dataSource={Devis2}
            bordered
            pagination={false}
            sortDirections={[]}
          />
        </div>
      </Modal>
    );
  };

  const columns = [
    {
      title: `${t("NoDevis")}`,
      // dataIndex: "NoDevis",
      align: "center",
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>{record.noDevis}</div>
      ),
    },
    {
      title: `${t("Orderdetail")}`,
      align: "center",
      sorter: (a, b) => a.employeeName - b.employeeName,
      render: (_, record) => (
        <div style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}onClick={() => showNameModal(record.employeeName)}>{record.employeeName}</div>
      ),
    },
    {
      title: `${t("CustomerName")}`,
      // dataIndex: "category",
      align: "center",
      sorter: (a, b) => a.customerName - b.customerName,
      render: (_, record) => (
        <div style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}onClick={() => showNameModal(record.customerName)}>{record.customerName}</div>
      ),
    },
    {
      title: `${t("TotalEstimated")}`,
      // dataIndex: "glAccountId",
      align: "center",
      sorter: (a, b) => a.total_estimate - b.total_estimate,

      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          {record.total_estimate + " " + `${(Company.currencies && Company.currencies.length > 0 && Company.currencies[0].shortLabel)?Company.currencies[0].shortLabel:"$"}`}
        </div>
      ),
    },
    {
      title: `${t("TotalInvoiced")}`,
      // dataIndex: "note",
      align: "center",
      sorter: (a, b) => a.total_invoice - b.total_invoice,
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>{record.total_invoice + " "+`${(Company.currencies && Company.currencies.length > 0 && Company.currencies[0].shortLabel)?Company.currencies[0].shortLabel:"$"}`}</div>
      ),
    },
    {
      title: `${t("TotalActual")}`,
      // dataIndex: "note",
      align: "center",
      sorter: (a, b) => a.total_actual - b.total_actual,
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>{record.total_actual + " "+`${(Company.currencies && Company.currencies.length > 0 && Company.currencies[0].shortLabel)?Company.currencies[0].shortLabel:"$"}`}</div>
      ),
    },
    {
      title: "Actions",
      align: "center",

      render: (_, record) => {
        return (
          <span>
            <Button
              type="link"
              icon={<InfoCircleOutlined />}
              onClick={() => {
                getDevisByNo(record.noDevis);
                setEditingRowbook(record.noDevis);
              }}
            >
              {t("detail")}
            </Button>
            {ability && ability.can("update", "Gross Margins") && (
              <Button
                type="link"
                danger
                icon={<EditOutlined />}
                onClick={() => {
                  getDevisByNo(record.noDevis);
                  setEditingRowbook1(record.noDevis);
                }}
              >
                {t("Edit")}
              </Button>
            )}
            {/* <Button
              className="Create-button"
              type="link"
              loading={loadings}
              icon={<PrinterOutlined />}
              onClick={() => {
                downloadFile(record.noDevis);
              }}
            >
              {t("Print")}
            </Button> */}
          </span>
        );
      },
    },
  ];
  const columns1 = [
    {
      title: `${t("estimatedInvoice")}`,
      // dataIndex: "NoDevis",
      align: "center",
      sorter: (a, b) =>
        a.variance_estimate_invoice - b.variance_estimate_invoice,
      render: (_, record) => (
        <div style={{ textAlign: "right" }}>
          {record.variance_estimate_invoice}
        </div>
      ),
    },
    {
      title: `${t("estimatedInvoice")}` + "% ",
      // dataIndex: "NoDevis",
      align: "center",
      sorter: (a, b) =>
        a.variance_estimate_invoice_percent -
        b.variance_estimate_invoice_percent,
      render: (_, record) => (
        <div style={{ textAlign: "right" }}>
          {record.variance_estimate_invoice_percent}
        </div>
      ),
    },
    {
      title: `${t("estimatedActual")}`,
      align: "center",
      sorter: (a, b) => a.total_variance - b.total_variance,
      render: (_, record) => (
        <div style={{ textAlign: "right" }}>{record.total_variance}</div>
      ),
    },
    {
      title: `${t("estimatedActual")}` + "% ",
      align: "center",
      sorter: (a, b) => a.total_variance_percent - b.total_variance_percent,
      render: (_, record) => (
        <div style={{ textAlign: "right" }}>
          {record.total_variance_percent}
        </div>
      ),
    },
  ];
  const columns2 = [
    {
      title: `${t("NoDevis")}`,
      // dataIndex: "NoDevis",
      align: "center",
      render: (_, record) => (
        <div style={{ textAlign: "right" }}>{record.noDevis}</div>
      ),
    },
    {
      title: `${t("Orderdetail")}`,
      align: "center",
      sorter: (a, b) => a.employeeName - b.employeeName,
      render: (_, record) => (
        <div style={{ textAlign: "left" }}>{record.employeeName}</div>
      ),
    },
    {
      title: `${t("CustomerName")}`,
      // dataIndex: "category",
      align: "center",
      sorter: (a, b) => a.customerName - b.customerName,
      render: (_, record) => (
        <div style={{ textAlign: "left" }}>{record.customerName}</div>
      ),
    },
    {
      title: `${t("HourlyRate")}`,
      // dataIndex: "glAccountId",
      align: "center",
      sorter: (a, b) => a.estimated_hourly_rate - b.estimated_hourly_rate,
      render: (_, record) => (
        <div style={{ textAlign: "right" }}>{record.estimated_hourly_rate}</div>
      ),
    },
    {
      title: `${t("totalHours")}`,
      // dataIndex: "note",
      align: "center",
      sorter: (a, b) => a.estimated_total_hours - b.estimated_total_hours,
      render: (_, record) => (
        <div style={{ textAlign: "right" }}>{record.estimated_total_hours}</div>
      ),
    },
    {
      title: `${t("totalhourlycost")}`,
      // dataIndex: "note",
      align: "center",
      sorter: (a, b) => a.estimated_total_cost - b.estimated_total_cost,
      render: (_, record) => (
        <div style={{ textAlign: "right" }}>{record.estimated_total_cost}</div>
      ),
    },

    {
      title: `${t("materialCose")}`,
      // dataIndex: "note",
      align: "center",
      sorter: (a, b) => a.material_costs - b.material_costs,
      render: (_, record) => (
        <div style={{ textAlign: "right" }}>{record.material_costs}</div>
      ),
    },
    {
      title: `${t("otherCost")}`,
      // dataIndex: "note",
      align: "center",
      sorter: (a, b) => a.other_costs - b.other_costs,
      render: (_, record) => (
        <div style={{ textAlign: "right" }}>{record.other_costs}</div>
      ),
    },
    {
      title: "Total",
      // dataIndex: "note",
      align: "center",
      sorter: (a, b) => a.total_estimate - b.total_estimate,
      render: (_, record) => (
        <div style={{ textAlign: "right" }}>
          {record.total_estimate + " "+`${(Company.currencies && Company.currencies.length > 0 && Company.currencies[0].shortLabel)?Company.currencies[0].shortLabel:"$"}`}{" "}
        </div>
      ),
    },
  ];
  const columns3 = [
    {
      title: `${t("noInvoive")}`,
      // dataIndex: "glAccountId",
      align: "center",
      sorter: (a, b) => a.noInvoice - b.noInvoice,
      render: (_, record) => (
        <div style={{ textAlign: "right" }}>{record.noInvoice}</div>
      ),
    },

    {
      title: `${t("HourlyRate")}`,
      // dataIndex: "glAccountId",
      align: "center",
      sorter: (a, b) => a.invoice_hourly_rate - b.invoice_hourly_rate,
      render: (_, record) => (
        <div style={{ textAlign: "right" }}>{record.invoice_hourly_rate}</div>
      ),
    },
    {
      title: `${t("totalHours")}`,
      // dataIndex: "note",
      align: "center",
      sorter: (a, b) => a.invoice_total_hours - b.invoice_total_hours,
      render: (_, record) => (
        <div style={{ textAlign: "right" }}>{record.invoice_total_hours}</div>
      ),
    },
    {
      title: `${t("totalhourlycost")}`,
      // dataIndex: "note",
      align: "center",
      sorter: (a, b) => a.invoice_total_cost - b.invoice_total_cost,
      render: (_, record) => (
        <div style={{ textAlign: "right" }}>{record.invoice_total_cost}</div>
      ),
    },
    {
      title: `${t("materialCose")}`,
      // dataIndex: "note",
      align: "center",
      sorter: (a, b) => a.material_costs_invoice - b.material_costs_invoice,
      render: (_, record) => (
        <div style={{ textAlign: "right" }}>
          {record.material_costs_invoice}
        </div>
      ),
    },
    {
      title: `${t("otherCost")}`,
      // dataIndex: "note",
      align: "center",
      sorter: (a, b) => a.other_costs_invoice - b.other_costs_invoice,
      render: (_, record) => (
        <div style={{ textAlign: "right" }}>{record.other_costs_invoice}</div>
      ),
    },
    {
      title: "Total",
      // dataIndex: "note",
      align: "center",
      sorter: (a, b) => a.total_invoice - b.total_invoice,
      render: (_, record) => (
        <div style={{ textAlign: "right" }}>{record.total_invoice + " "+`${(Company.currencies && Company.currencies.length > 0 && Company.currencies[0].shortLabel)?Company.currencies[0].shortLabel:"$"}`}</div>
      ),
    },
  ];

  const columns4 = [
    {
      title: `${t("HourlyRate")}`,
      // dataIndex: "glAccountId",
      align: "center",
      sorter: (a, b) => a.actual_hourly_rate - b.actual_hourly_rate,
      render: (_, record) => (
        <div style={{ textAlign: "right" }}>{record.actual_hourly_rate}</div>
      ),
    },
    {
      title: `${t("totalHours")}`,
      // dataIndex: "note",
      align: "center",
      sorter: (a, b) => a.actual_total_hours - b.actual_total_hours,
      render: (_, record) => (
        <div style={{ textAlign: "right" }}>{record.actual_total_hours}</div>
      ),
    },
    {
      title: `${t("totalhourlycost")}`,
      // dataIndex: "note",
      align: "center",
      sorter: (a, b) => a.actual_total_cost - b.actual_total_cost,
      render: (_, record) => (
        <div style={{ textAlign: "right" }}>{record.actual_total_cost}</div>
      ),
    },
    {
      title: `${t("materialCose")}`,
      // dataIndex: "note",
      align: "center",
      sorter: (a, b) => a.material_costs_actual - b.material_costs_actual,
      render: (_, record) => (
        <div style={{ textAlign: "right" }}>{record.material_costs_actual}</div>
      ),
    },
    {
      title: `${t("otherCost")}`,
      // dataIndex: "note",
      align: "center",
      sorter: (a, b) => a.other_costs_actual - b.other_costs_actual,
      render: (_, record) => (
        <div style={{ textAlign: "right" }}>{record.other_costs_actual}</div>
      ),
    },
    {
      title: "Commission",
      // dataIndex: "note",
      align: "center",
      sorter: (a, b) => a.commission - b.commission,
      render: (_, record) => (
        <div style={{ textAlign: "right" }}>{record.commission}</div>
      ),
    },
    {
      title: "Total",
      // dataIndex: "note",
      align: "center",
      sorter: (a, b) => a.total_actual - b.total_actual,
      render: (_, record) => (
        <div style={{ textAlign: "right" }}>{record.total_actual + " "+`${(Company.currencies && Company.currencies.length > 0 && Company.currencies[0].shortLabel)?Company.currencies[0].shortLabel:"$"}`}</div>
      ),
    },
  ];

  return (
    <Card
      bordered={false}
      className="header-solid mb-24"
      title={<h3 className="font-semibold"> {t("GrossMarginsReport")} </h3>}
    >
      <Row justify="space-between" align="middle" style={{ marginBottom: 5 }}>
        <Col flex="auto">
          <input
            type="text"
            //defaultValue={dayjs(date).format("YYYY")}
            name="NoDevis"
            placeholder={t("selectedNoDevis")}
            style={{
              width: 200,
              height: 35,
              borderRadius: "5px",
              border: "1px solid #d9d9d9",
              padding: "4px 11px",
            }}
            onChange={handleDevisNo}
          />
        </Col>
        <Col>
          <Row gutter={16} align="middle" style={{ marginBottom: 5 }}>
            {ability && ability.can("create", "Gross Margins") && (
              <Col>
                <Button
                  className="Create-button"
                  type="primary"
                  onClick={() => {
                    setOpen(true);
                  }}
                  style={{
                    textAlign: "right",
                  }}
                >
                  <PlusCircleFilled /> {t("Create")}
                </Button>
              </Col>
            )}

            {/* <Col>
              <Button
                className="Create-button"
                type="primary"
                loading={loadings}
                icon={<PrinterOutlined />}
                style={{
                  //  width: 128,
                  marginRight: 0,
                  // height: 35,
                  //marginLeft: "0.00001rem",
                }}
                onClick={() => {
                  downloadGrossMarginFile(Company.id);
                }}
              >
                {t("PrintAll")}
              </Button>
            </Col> */}
          </Row>
        </Col>
      </Row>

      <CollectionCreateForm
        open={open}
        onCreate={Submit}
        onCancel={() => {
          form.resetFields();

          setOpen(false);
          // onAddChamp();
        }}
      />
      <CollectionEditForm
        open={editingRowbook1 && true}
        onCreate={Submitupdate}
        onCancel={() => {
          form3.resetFields();
          setEditingRowbook1(null);
          getDevisData();
          setOpen(false);
          // onAddChamp();
        }}
      />
      <CollectionUpdateForm
        open={editingRowbook && true}
        onCreate={OnopenUpdate}
        onCancel={() => {
          form2.resetFields();
          setEditingRowbook(null);
          getDevisData();
          setOpenupdate(false);
        }}
        ability={ability}
      />
      <div style={{ overflowX: "auto",backgroundColor:'white' }}>
      <Table
        locale={{
          emptyText: <Empty description={t("nodata")} />,
        }}
        columns={columns}
        dataSource={Devis}
        bordered
      />
      </div>
      <Modal
          title={t("detail")}
          visible={isModalVisible}
          onOk={closeModal}
          onCancel={closeModal}
        >
          <p>{selectedName}</p>
        </Modal>
    </Card>
  );
}

Devis.acl = {
  subject: "Gross Margins",
  action: "read",
};
export default Devis;

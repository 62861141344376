import {
  DeleteOutlined,
  CheckCircleOutlined,
  InfoCircleOutlined,
  SaveOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import SalesBudgetHypothesis from "./SalesBudgetHypothesis";
import {
  Button,
  Card,
  Tag,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Table,
  Row,
  Select,
  Space,
  Tabs,
  Typography,
  message,
  Popconfirm,
} from "antd";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { CompanyContext } from "../../contexts/CompanyContext";
import { JSON_API } from "../../services/Constants";
import { useAbility } from "../../contexts/abilityContext";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
const { Option } = Select;

const { TextArea } = Input;
const { TabPane } = Tabs;
const { Text } = Typography;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 12,
  },
};

const SalesBudgetDetails = () => {
  const [activeTabMain, setActiveTabMain] = useState("soldUnitsBudgets");
  const [activeTabSub, setActiveTabSub] = useState("Budget");

  const [error, setError] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const { ability } = useAbility();
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const { Company } = useContext(CompanyContext);
  const canUpdate = ability.can("update", "Sales Budget");
  const { id } = useParams();
  const [Gifi, setGifi] = useState("");
  const [GlAccount, setGlAccount] = useState(null);
  const [Hypo, setHypo] = useState(null);
  const [Statementcategory, setStatementCategory] = useState([{}]);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const [SalesBudget, setSalesBudget] = useState([]);
  const [Category, setCategory] = useState();
  const [CategoryName, setCategoryName] = useState();
  const [commission, setCommission] = useState();
  const [gifiDescription, setGifiDescription] = useState("");
  const [description, setDescription] = useState("");

  const [paymentTermId, setPaymentTermId] = useState("");
  const [productId, setProductId] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [employeeName, setEmployeeName] = useState("");

  const [paymentTerms, setPaymentTerms] = useState([]);
  const [products, setProducts] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [salesBudgetDetailsbudget, setSalesBudgetDetailsbudget] = useState()

  const [salesBudgetDetails, setSalesBudgetDetails] = useState({
    soldUnitsBudgets: Array(12)
      .fill()
      .map(() => ({
        soldUnitsBudgets: Array(12).fill(0),
        updated: true,
      })),
    budgets: [],
    soldUnitsReals: Array(12)
      .fill()
      .map((_, i) => ({
        id: i,
        soldUnitsReals: Array(12).fill(0),
        updated: false,
      })),
    reals: [],
    soldUnitsPerformances: [],
    performances: [],
  });

  const [salesBudgetId, setSalesBudgetId] = useState(null);

  const months = [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
  ];

  const getSalesBudgetDetails = async () => {
    try {
      const response = await axios.get(
        `${JSON_API}/SalesBudgets/details/${id}`
      );
      console.log("Sales Budget Details:", response.data);

      if (response.data && response.data.id) {
        setSalesBudgetId(response.data.id);
        setSalesBudget(response.data);
        console.log("Sales Budget Details:", response.data);
        setSalesBudgetId(response.data.id);

        console.log("salesBudgetId : ", salesBudgetId);

        setSalesBudgetDetails({
          soldUnitsBudgets: response.data.soldUnitsBudgets,
          budgets: response.data.budgets,
          soldUnitsReals: response.data.soldUnitsReals,
          reals: response.data.reals,
          soldUnitsPerformances: response.data.soldUnitsPerformances,
          performances: response.data.performances,
        });
//setSalesBudgetDetailsbudget(response.data.soldUnitsBudget)
        const classId = response.data.financialStatementClass?.id;

        const [
          fetchedPaymentTerms,
          fetchedProducts,
          fetchedEmployees,
          fetchedCategories,
        ] = await Promise.all([
          axios.get(`${JSON_API}/PaymentTerm`),
          axios.get(`${JSON_API}/Products/Enterprise/${Company.id}`),
          axios.get(
            `${JSON_API}/Employees/enterprise/filter?enterpriseId=${Company.id}`
          ),
          axios.get(`${JSON_API}/FinancialStatementCategory/class/${classId}`),
        ]);

        setPaymentTerms(fetchedPaymentTerms.data);
        setProducts(fetchedProducts.data);
        setEmployees(
          Array.isArray(fetchedEmployees.data) ? fetchedEmployees.data : []
        );
        setStatementCategory(fetchedCategories.data);
        setCategory(response.data.financialStatementCategory?.id || "");
        setGifi(response.data.financialStatementType?.gifi || "");
        setGifiDescription(
          `${response.data.financialStatementType?.gifi}: ${response.data.financialStatementType?.description}`
        );
        setGlAccount(response.data.glNumber || "");
        const foundPaymentTerm = fetchedPaymentTerms.data.find(
          (pt) => pt.id === response.data.paymentTerm?.id
        );
        setPaymentTermId(foundPaymentTerm ? foundPaymentTerm.id : "");

        const foundProduct = fetchedProducts.data.find(
          (p) => p.id === response.data.product?.id
        );
        setProductId(foundProduct ? foundProduct.id : "");

        const foundEmployee = fetchedEmployees.data.find(
          (e) => e.id === response.data.employee?.id
        );

        if (foundEmployee) {
          setEmployeeId(foundEmployee.id);
          setEmployeeName(`${foundEmployee.name} ${foundEmployee.firstName}`);
        } else {
          setEmployeeId("");
          setEmployeeName("");
        }
        setSellingPrice(response.data.sellingPrice || 0);
        setCommission(response.data.commission || 0);
        setDescription(response.data.description);
      } else {
        console.error("No valid ID found in response");
      }
    } catch (err) {
      console.error("Error fetching sales budget details:", err);
    }
  };

  useEffect(() => {
    getSalesBudgetDetails();
  }, []);

  useEffect(() => {
    const performances = salesBudgetDetails.budgets.map((budgetItem, index) => {
      const budgetValues = budgetItem.budgets || [];
      const realValues = salesBudgetDetails.reals[index]?.reals || [];
      const differences = budgetValues.map((value, i) => {
        const realValue = realValues[i] || 0;
        return realValue - value;
      });
      const totalDifference = differences.reduce((acc, curr) => acc + curr, 0);

      return {
        year: budgetItem.year,
        budgets: budgetValues,
        reals: realValues,
        differences,
        total: totalDifference,
      };
    });

    const soldUnitsPerformances = salesBudgetDetails.soldUnitsBudgets.map(
      (soldUnitsBudgetItem, index) => {
        const soldUnitsBudgetValues =
          soldUnitsBudgetItem.soldUnitsBudgets || [];
        const soldUnitsRealValues =
          salesBudgetDetails.soldUnitsReals[index]?.soldUnitsReals || [];
        const soldUnitsdifferences = soldUnitsBudgetValues.map((value, i) => {
          const soldUnitsRealValue = soldUnitsRealValues[i] || 0;
          return soldUnitsRealValue - value;
        });
        const soldUntistotalDifference = soldUnitsdifferences.reduce(
          (acc, curr) => acc + curr,
          0
        );

        return {
          year: soldUnitsBudgetItem.year,
          soldUnitsBudgets: soldUnitsBudgetValues,
          soldUnitsReals: soldUnitsRealValues,
          soldUnitsdifferences,
          total: soldUntistotalDifference,
        };
      }
    );

    setSalesBudgetDetails((prevDetails) => ({
      ...prevDetails,
      performances,
      soldUnitsPerformances,
    }));
  }, [
    salesBudgetDetails.budgets,
    salesBudgetDetails.reals,
    salesBudgetDetails.soldUnitsBudgets,
    salesBudgetDetails.soldUnitsReals,
  ]);

  function handleredirection() {
    history.push("/sales-budget");
  }

  const onChangee = (key) => {
    console.log(key);
  };

  // const confirmBudget = async (record) => {
  //   try {
  //     const response = await axios.put(
  //       `${JSON_API}/SalesBudgets/ConfirmYearlyBudget/${record.id}`,
  //       {},
  //       { headers: { "Content-Type": "application/json" } }
  //     );

  //     console.log("Budget confirmed successfully:", response.data);
  //     message.success("Budget confirmed successfully!");

  //     setSalesBudgetDetails((prevDetails) => {
  //       const updatedBudgets = prevDetails.budgets.map((budget) => {
  //         if (budget.id === record.id) {
  //           return { ...budget, budgetConfirmed: true };
  //         }
  //         return budget;
  //       });

  //       const updatedSoldUnitsBudgets = prevDetails.soldUnitsBudgets.map(
  //         (budget) => {
  //           if (budget.id === record.id) {
  //             return { ...budget, updated: false };
  //           }
  //           return budget;
  //         }
  //       );

  //       const updatedSoldUnitsReals = prevDetails.soldUnitsReals.map(
  //         (real, index) => {
  //           if (real.id === record.id) {
  //             return { ...real, updated: true };
  //           }
  //           return real;
  //         }
  //       );

  //       return {
  //         ...prevDetails,
  //         budgets: updatedBudgets,
  //         soldUnitsBudgets: updatedSoldUnitsBudgets,
  //         soldUnitsReals: updatedSoldUnitsReals,
  //       };
  //     });
  //   } catch (error) {
  //     console.error("Error confirming budget:", error.response || error);
  //     message.error(
  //       `Failed to confirm the budget: ${
  //         error.response ? error.response.data?.message : error.message
  //       }`
  //     );
  //   }
  // };

  const confirmBudget = (record) => {
    console.log(record)
    setSalesBudgetDetails((prevDetails) => {
      const updatedBudgets = prevDetails.budgets.map((budget) => {
        if (budget.id === record.id) {
          const reals = Array.isArray(budget.budgets)
            ? [...budget.budgets]
            : [];
          return { ...budget, budgetConfirmed: true, reals };
        }
        return budget;
      });

      const updatedReals =JSON.parse(JSON.stringify(updatedBudgets.map((budget) => {
        return { ...budget, reals: budget.budgets };
      })))

      const updatedSoldUnitsBudgets = prevDetails.soldUnitsBudgets.map(
        (budget) => {
          if (budget.id === record.id) {
            return {
              ...budget,
              updated: false,
            };
          }
          return budget;
        }
      );

      const updatedSoldUnitsReals = JSON.parse(JSON.stringify(prevDetails.soldUnitsReals.map(
        (real, index) => {
          if (real.id === record.id) {
            return {
              ...real,
              soldUnitsReals: updatedSoldUnitsBudgets[index].soldUnitsBudgets,
              updated: true,
            };
          }
          return real;
        }
      )));
      console.log(updatedBudgets,updatedReals,updatedSoldUnitsBudgets,updatedSoldUnitsReals)

      return {
        ...prevDetails,
        budgets: updatedBudgets,
        reals: updatedReals,
        soldUnitsBudgets: updatedSoldUnitsBudgets,
        soldUnitsReals: updatedSoldUnitsReals,
      };

      confirmBudgetOnServer(record.id);
    });
  };

  const handleBudgetChange = (newValue, rowIndex, monthIndex) => {
    if (!salesBudgetDetails.budgets[rowIndex].budgetConfirmed) {
      const updatedBudgets = [...salesBudgetDetails.budgets];
      updatedBudgets[rowIndex].budgets[monthIndex] = newValue;
      const newTotal = Array.isArray(updatedBudgets[rowIndex].budgets)
        ? updatedBudgets[rowIndex].budgets.reduce((acc, curr) => acc + curr, 0)
        : 0;
      updatedBudgets[rowIndex].total = newTotal;

      setSalesBudgetDetails({
        ...salesBudgetDetails,
        budgets: updatedBudgets,
      });
    }
    console.log(salesBudgetDetails)
  };

  const handleRealChange = (newValue, rowIndex, monthIndex) => {
    if (salesBudgetDetails.budgets[rowIndex].budgetConfirmed) {
      const updatedReals = [...salesBudgetDetails.reals];
      updatedReals[rowIndex].reals[monthIndex] = newValue;

      const newTotal = updatedReals[rowIndex].reals.reduce(
        (acc, curr) => acc + curr,
        0
      );
      updatedReals[rowIndex].total = newTotal;

      setSalesBudgetDetails({ ...salesBudgetDetails, reals: updatedReals });
    }
  };

  const handleSoldUnitsBudgetChange = (newValue, rowIndex, index) => {
    setSalesBudgetDetails((prevDetails) => {
      /*console.log(
        `Updating budget at row ${rowIndex}, index ${index} with value ${newValue}`
      );*/
      const newDetails = { ...prevDetails };
      const newBudgets = [...newDetails.soldUnitsBudgets];

      if (!newBudgets[rowIndex]) {
        newBudgets[rowIndex] = {
          soldUnitsBudgets: Array(12).fill(0),
          total: 0,
        };
      }
      newBudgets[rowIndex].soldUnitsBudgets[index] = newValue || 0;
      newBudgets[rowIndex].total = newBudgets[rowIndex].soldUnitsBudgets.reduce(
        (acc, curr) => acc + (curr || 0),
        0
      );

      return { ...newDetails, soldUnitsBudgets: newBudgets };
    });
  };

  const handleSoldUnitsRealChange = (newValue, rowIndex, monthIndex) => {
    //console.log(salesBudgetDetails);
    setSalesBudgetDetails((prevDetails) => {
      const newDetails = salesBudgetDetails;
      const newReals = newDetails.soldUnitsReals || [];

      if (!newReals[rowIndex]) {
        newReals[rowIndex] = { soldUnitsReals: Array(12).fill(0), total: 0 };
      }
//console.log(monthIndex)
      newReals[rowIndex].soldUnitsReals[monthIndex] = newValue || 0;
//console.log(newReals)
      newReals[rowIndex].total = newReals[rowIndex].soldUnitsReals.reduce(
        (acc, curr) => acc + (curr || 0),
        0
      );

      if (
        newDetails.soldUnitsBudgets &&
        newDetails.soldUnitsBudgets[rowIndex]
      ) {
        const budgetTotal = newDetails.soldUnitsBudgets[rowIndex].total || 0;
        newDetails.soldUnitsPerformances[rowIndex].total =
          newReals[rowIndex].total - budgetTotal;
      }

      return { ...newDetails, soldUnitsReals: newReals };
    });
  /* Make a copy of the previous details
  const newDetails = { ...salesBudgetDetails };

  // Ensure newReals is initialized
  const newReals = newDetails.soldUnitsReals || [];

  // Ensure newReals[rowIndex] is initialized
  if (!newReals[rowIndex]) {
    newReals[rowIndex] = { soldUnitsReals: Array(12).fill(0), total: 0 };
  }

  // Update the specific value in soldUnitsReals
  newReals[rowIndex].soldUnitsReals[monthIndex] = newValue || 0;

  // Update soldUnitsReals in newDetails
  newDetails.soldUnitsReals = newReals;

  // Return the updated details
  setSalesBudgetDetails(newDetails);*/
  };

  

const updateBudgetsAndRealsWithSellingPrice = (sellingPriceState) => {
    // Retrieve the data from salesBudgetDetails state
    const data = salesBudgetDetails;
//console.log(data)
    // Check if selling price is not equal to 0
    if (sellingPriceState !== 0) {
      //console.log(sellingPriceState)
        // Iterate through each budget entry
        data.budgets.forEach((budgetItem, index) => {
            // Find the corresponding soldUnitsBudgets entry based on the year
            const correspondingSoldUnitsBudget = data.soldUnitsBudgets.find(
                (soldUnitsBudget) => soldUnitsBudget.year === budgetItem.year
            );
            //console.log(correspondingSoldUnitsBudget)
            // If found, update the budgets array with the multiplied values
            if (correspondingSoldUnitsBudget) {
                data.budgets[index].budgets = budgetItem.budgets.map(
                    (budget, i) =>  correspondingSoldUnitsBudget.soldUnitsBudgets[i] * sellingPriceState
                );
                // Update the total
                data.budgets[index].total = data.budgets[index].budgets.reduce((acc, val) => acc + val, 0);
                //console.log(data.budgets[index])
            }
        });

        // Iterate through each real entry
        data.reals.forEach((realItem, index) => {
            // Find the corresponding soldUnitsReal entry based on the year
            const correspondingSoldUnitsReal = data.soldUnitsReals.find(
                (soldUnitsReal) => soldUnitsReal.year === realItem.year
            );
            // If found, update the reals array with the multiplied values
            if (correspondingSoldUnitsReal) {
                data.reals[index].reals = realItem.reals.map(
                    (real, i) =>  correspondingSoldUnitsReal.soldUnitsReals[i] * sellingPriceState
                );
                // Update the total
                data.reals[index].total = data.reals[index].reals.reduce((acc, val) => acc + val, 0);
            }
        });
    } else {
        // If selling price is 0, update state with original data
        setSalesBudgetDetails(data);
    }
    // Update state with new budget and real data
    //console.log(data)
    setSalesBudgetDetails(data);
    //console.log(data)
};
  const handleSellingPriceChange = (value) => {
    //console.log(value)
        setSellingPrice(value); // Update the local state with the new selling price
        //updateBudgetsAndRealsWithSellingPrice(value); // Call the function to update budgets and reals
    };
  function calculateTotal(items) {
    if (Array.isArray(items) && items.length > 0) {
      return items.reduce((sum, value) => sum + (value || 0), 0);
    }
    return 0;
  }

  function calculateTotalDifference(budgets, reals) {
    if (
      Array.isArray(budgets) &&
      budgets.length > 0 &&
      Array.isArray(reals) &&
      reals.length > 0
    ) {
      return budgets.reduce((total, budget, index) => {
        const real = reals[index] || 0;
        return total + (real - budget);
      }, 0);
    }
    return 0;
  }

  const columnsSoldUnitsBudgets = [
    {
      title: t("Year"),
      dataIndex: "year",
      key: "year",
      align: "center",
    },
    ...months.map((month, index) => ({
      title: month,
      key: month,
      align: "center",
      render: (_, record, rowIndex) => {
        const monthValue = record.soldUnitsBudgets[index] ?? 0;
        let budgetConfirmed = true;
        return (
        <>
            {salesBudgetDetails.budgets &&
                  salesBudgetDetails.budgets.map((e) => {
                    if (e.year == record.year) {
                      budgetConfirmed=e.budgetConfirmed;
                    }
                  })}
          <InputNumber
            value={record.soldUnitsBudgets[index] ?? 0}
            disabled={budgetConfirmed}
            
            onChange={(newValue) =>
              handleSoldUnitsBudgetChange(newValue, rowIndex, index)
            }
          /></>
        );
      },
    })),

    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      align: "center",
      render: (text) => (text ? `${text.toLocaleString()}` : "0"),
    },
  ];
  const columnsSoldUnitsReals = [
    {
      title: t("Year"),
      dataIndex: "year",
      key: "year",
      align: "center",
    },
    ...months.map((month, index) => ({
      title: month,
      key: month,
      align: "center",
      render: (_, record, rowIndex) => {
        //console.log("real",salesBudgetDetails)
        //console.log(record)
       let budgetConfirmed = true;
        return (
        <>
            {salesBudgetDetails.budgets &&
                  salesBudgetDetails.budgets.map((e) => {
                    if (e.year == record.year) {
                      budgetConfirmed=e.budgetConfirmed;
                    }
                  })}
          <InputNumber
            value={record.soldUnitsReals[index] ?? 0}
            disabled={!budgetConfirmed}
            
            onChange={(newValue) =>{//console.log("real",salesBudgetDetails)
              handleSoldUnitsRealChange(newValue, rowIndex, index)}
            }
          />
          </>
        );
      },
    })),

    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      align: "center",
      render: (text) => (text ? `${text.toLocaleString()}` : "0"),
    },
  ];

  const columnsSoldUnitsPerformances = [
    {
      title: t("Year"),
      dataIndex: "year",
      key: "year",
      align: "center",
    },
    ...months.map((month, index) => {
      const monthKey = `month${index + 1}`;
      return {
        title: month,
        children: [
          {
            title: t("Budget"),
            dataIndex: `soldUnitsBudgets[${index}]`,
            key: `${monthKey}-soldUnitsBudget`,
            align: "center",
            render: (value, record) => {
              const budgetValue =
                record.soldUnitsBudgets &&
                record.soldUnitsBudgets.length > index
                  ? record.soldUnitsBudgets[index] || 0
                  : 0;
              return `${budgetValue.toLocaleString()}`;
            },
          },
          {
            title: t("Real"),
            dataIndex: `soldUnitsReals[${index}]`,
            key: `${monthKey}-soldUnitsReal`,
            align: "center",
            render: (value, record) => {
              const realValue =
                record.soldUnitsReals && record.soldUnitsReals.length > index
                  ? record.soldUnitsReals[index] || 0
                  : 0;
              return `${realValue.toLocaleString()}`;
            },
          },
          {
            title: t("Difference"),
            key: `${monthKey}-difference`,
            align: "center",
            render: (_, record) => {
              const budgetValue =
                record.soldUnitsBudgets &&
                record.soldUnitsBudgets.length > index
                  ? record.soldUnitsBudgets[index] || 0
                  : 0;
              const realValue =
                record.soldUnitsReals && record.soldUnitsReals.length > index
                  ? record.soldUnitsReals[index] || 0
                  : 0;
              const difference = realValue - budgetValue;
              const formattedValue = `${difference.toLocaleString()}`;
              return (
                <span
                  style={{
                    color: difference >= 0 ? "blue" : "red",
                    fontWeight: "bold",
                  }}
                >
                  {formattedValue}
                </span>
              );
            },
          },
        ],
      };
    }),
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      align: "center",
      render: (text) => (
        <span style={{ color: text >= 0 ? "blue" : "red", fontWeight: "bold" }}>
          {text.toLocaleString()}
        </span>
      ),
    },
  ];

  const columnsBudget = [
    {
      title: t("Year"),
      dataIndex: "year",
      key: "year",
      align: "center",
    },
    ...months.map((month, index) => ({
      title: month,
      dataIndex: `budgets[${index}]`,
      key: month,
      align: "center",
      render: (value, record, rowIndex) => {
        const monthValue =
          record.budgets && record.budgets.length > index
            ? record.budgets[index]
            : 0;
        let isBudgetConfirmed = record.budgetConfirmed;
        return (
        <>
            {sellingPrice && sellingPrice != 0
              ? (salesBudgetDetails.soldUnitsBudgets &&
                  salesBudgetDetails.soldUnitsBudgets.map((e) => {
                    if (e.year == record.year) {
                      record.budgets[index] =
                        e.soldUnitsBudgets[index] * sellingPrice;
                    }
                  }),
                (isBudgetConfirmed = true))
              : 
                (isBudgetConfirmed = record.budgetConfirmed)}
          <InputNumber
            value={record.budgets[index]}
            disabled={isBudgetConfirmed}
            
            onChange={(newValue) =>
              handleBudgetChange(newValue, rowIndex, index, record.key)
            }
          />
          </>
        );
      },
    })),

    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      align: "center",
      render: (_,record) => `${record.budgets && (
            record.budgets[0] +
            record.budgets[1] +
            record.budgets[2] +
            record.budgets[3] +
            record.budgets[4] +
            record.budgets[5] +
            record.budgets[6] +
            record.budgets[7] +
            record.budgets[8] +
            record.budgets[9] +
            record.budgets[10] +
            record.budgets[11]
          ).toFixed(0)}`,
    },

    {
      title: t("BudgetConfirmed"),
      dataIndex: "budgetConfirmed",
      key: "confirmed",
      align: "center",
      render: (confirmed, record) =>
        confirmed ? (
          <Tag color="green" icon={<CheckCircleOutlined />}>
            {t("Confirmed")}
          </Tag>
        ) : (
          <Popconfirm
            title={t("Areyousuretoconfirm")}
            onConfirm={() => confirmBudget(record)}
            okText={t("yes")}
            cancelText={t("no")}
          >
            <Button
              size="medium"
              type="primary"
              style={{ borderRadius: "20px", textTransform: "uppercase" }}
            >
              {t("Confirm")}
            </Button>
          </Popconfirm>
        ),
    },
  ];

  const columnsReal = [
    {
      title: t("Year"),
      dataIndex: "year",
      key: "year",
      align: "center",
    },

    ...months.map((month, index) => ({
      title: month,
      dataIndex: `reals[${index}]`,
      key: month,
      align: "center",
      render: (value, record, rowIndex) => {
        const monthValue =
          record.reals && record.reals.length > index ? record.reals[index] : 0;
        let budgetConfirmed = false;
        return (
          <>{sellingPrice && sellingPrice != 0
              ? (salesBudgetDetails.soldUnitsReals &&
                  salesBudgetDetails.soldUnitsReals.map((e) => {
                    if (e.year == record.year) {
                      record.reals[index] =
                        e.soldUnitsReals[index] * sellingPrice
                    }
                  }),
                (budgetConfirmed = true))
              : 
                (salesBudgetDetails.budgets &&
                  salesBudgetDetails.budgets.map((e) => {
                    if (e.year == record.year) {
                      budgetConfirmed=!e.budgetConfirmed;
                    }
                  }))}
          <InputNumber
            value={record.reals[index]}
            disabled={budgetConfirmed}
            onChange={(newValue) =>
              handleRealChange(newValue, rowIndex, index, record.key)
            }
          />
          </>
        );
      },
    })),
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      align: "center",
      render: (_,record) => `${record.reals && (
            record.reals[0] +
            record.reals[1] +
            record.reals[2] +
            record.reals[3] +
            record.reals[4] +
            record.reals[5] +
            record.reals[6] +
            record.reals[7] +
            record.reals[8] +
            record.reals[9] +
            record.reals[10] +
            record.reals[11]
          ).toFixed(0)}`,
    },
  ];

  const columnsPerformance = [
    {
      title: t("Year"),
      dataIndex: "year",
      key: "year",
      align: "center",
    },

    ...months.map((month, index) => {
      const monthKey = `month${index + 1}`;
      return {
        title: month,
        children: [
          {
            title: t("Budget"),
            dataIndex: `budgets[${index}]`,
            key: `${monthKey}-budget`,
            align: "center",
            render: (value, record) => {
              const budgetValue =
                record.budgets && record.budgets[index]
                  ? record.budgets[index]
                  : 0;
              return `${budgetValue.toLocaleString()}`;
            },
          },
          {
            title: t("Real"),
            dataIndex: `reals[${index}]`,
            key: `${monthKey}-real`,
            align: "center",
            render: (value, record) => {
              const realValue =
                record.reals && record.reals[index] ? record.reals[index] : 0;
              return `${realValue.toLocaleString()}`;
            },
          },
          {
            title: t("Difference"),
            key: `${monthKey}-difference`,
            align: "center",
            render: (_, record) => {
              const budgetValue =
                record.budgets && record.budgets[index]
                  ? record.budgets[index]
                  : 0;
              const realValue =
                record.reals && record.reals[index] ? record.reals[index] : 0;
              const difference = realValue - budgetValue;
              const formattedValue = `${difference.toLocaleString()}`;
              return (
                <span
                  style={{
                    color: difference >= 0 ? "blue" : "red",
                    fontWeight: "bold",
                  }}
                >
                  {formattedValue}
                </span>
              );
            },
          },
        ],
      };
    }),

    {
      title: "Total",
      key: "total",
      align: "center",
      children: [
        {
          title: t("Budget"),
          dataIndex: "totalBudget",
          key: "totalBudget",
          align: "center",
          render: (_, record) => {
            const totalBudget = calculateTotal(record.budgets);
            return `${totalBudget.toLocaleString()}`;
          },
        },
        {
          title: t("Real"),
          dataIndex: "totalReal",
          key: "totalReal",
          align: "center",
          render: (_, record) => {
            const totalReal = calculateTotal(record.reals);
            return `${totalReal.toLocaleString()}`;
          },
        },
        {
          title: t("Difference"),
          key: "totalDifference",
          align: "center",
          render: (_, record) => {
            const totalDifference = calculateTotalDifference(
              record.budgets,
              record.reals
            );
            return (
              <span
                style={{
                  color: totalDifference >= 0 ? "blue" : "red",
                  fontWeight: "bold",
                }}
              >
                {totalDifference.toLocaleString()}
              </span>
            );
          },
        },
      ],
    },
  ];

  const itemsForm = [
    {
      key: "1",
      label: (
        <span style={{ width: 300, textAlign: "center" }}>
          {t("Maininformation")}
        </span>
      ),
      children: SalesBudget.budgets && (
        <div>
          <Form
            {...layout}
            style={{
              maxWidth: 800,
              margin: "auto",
            }}
          >
            <Form.Item label={t("Class")}>
              <Input
                value={
                  currentLang === "fr"
                    ? SalesBudget.financialStatementClass?.labelFr
                    : SalesBudget.financialStatementClass?.label
                }
                disabled
              />
            </Form.Item>

            <Form.Item label={t("Category")}>
              <Select
                disabled={!canUpdate}
                value={Category}
                onChange={(value) => {
                  const selectedCategory = Statementcategory.find(
                    (c) => c.id === value
                  );
                  setCategory(selectedCategory.id);
                  setCategoryName(
                    currentLang === "fr"
                      ? selectedCategory.labelFr
                      : selectedCategory.label
                  );
                }}
              >
                {Statementcategory &&
                  Statementcategory.map((e) => (
                    <Option key={e.id} value={e.id}>
                      {currentLang === "fr" ? e.labelFr : e.label}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item label={"Type"}>
              <Input value={gifiDescription} disabled />
            </Form.Item>

            <Form.Item label={t("glnumber")}>
              <Input value={GlAccount} disabled={true} />
            </Form.Item>

            <Form.Item label={t("PayementTerme")}>
              <Select
                placeholder={t("PleaseSelectPaymentTerm")}
                disabled={true}
                value={paymentTermId}
                onChange={(value) => setPaymentTermId(value)}
              >
                {Array.isArray(paymentTerms) &&
                  paymentTerms.map((term) => (
                    <Option key={term.id} value={term.id}>
                      {term.label}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item label={t("Product")}>
              <Select
                placeholder={t("PleaseSelectProduct")}
                disabled={!canUpdate}
                value={productId}
                onChange={(value) => setProductId(value)}
              >
                {Array.isArray(products) &&
                  products.map((product) => (
                    <Option key={product.id} value={product.id}>
                      {product.label}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item label={t("sellingPrice")}>
              <InputNumber
                disabled={!canUpdate}
                value={sellingPrice}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={handleSellingPriceChange}
              />
            </Form.Item>

            <Form.Item label={t("Employee")}>
              <Select
                placeholder={t("PleaseSelectEmployee")}
                disabled={!canUpdate}
                value={employeeId}
                onChange={(value) => {
                  const selectedEmployee = employees.find(
                    (e) => e.id === value
                  );
                  setEmployeeId(value);
                  setEmployeeName(
                    selectedEmployee
                      ? `${selectedEmployee.name} ${selectedEmployee.firstName}`
                      : ""
                  );
                }}
              >
                {employees.map((employee) => (
                  <Option key={employee.id} value={employee.id}>
                    {employee.name} {employee.firstName}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Commission">
              <Input
                disabled={!canUpdate}
                value={commission}
                onChange={(e) => setCommission(e.target.value)}
              />
            </Form.Item>

            <Form.Item></Form.Item>
          </Form>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <span style={{ width: 300, textAlign: "center" }}>
          {t("Hyphothesis")}
        </span>
      ),
      children: (
        <div>
          {salesBudgetId && (
            <SalesBudgetHypothesis salesBudgetId={salesBudgetId} />
          )}
        </div>
      ),
    },
  ];

  const prepareBudgetValues = (details, key) => {
    if (!details || !Array.isArray(details)) {
      console.warn(`Expected an array for ${key}, but received:`, details);
      return Array.from({ length: 12 }, () => ({
        id: null,
        [key]: Array(12).fill(0),
      }));
    }

    return details.map((detail) => {
      console.log(detail)
      const monthValues = Array.from({ length: 12 }, (_, index) => {
        const value = detail?.[key]?.[index];
        return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
      });

      return {
        id: detail.id || null,
        [key]: monthValues,
        updated:true,
        budgetConfirmed:detail.budgetConfirmed?detail.budgetConfirmed:false
      };
    });
  };

  const updateSalesBudget = async () => {
    console.log(
      "Preparing to update with the following details:",
      salesBudgetDetails
    );

    let updateObject = {
      id: salesBudgetId,
      productId: productId,
      sellingPrice: sellingPrice,
      commission: commission,
      soldUnitsBudgets: prepareBudgetValues(
        salesBudgetDetails.soldUnitsBudgets,
        "soldUnitsBudgets"
      ),
      budgets: prepareBudgetValues(salesBudgetDetails.budgets, "budgets"),
      soldUnitsReals: prepareBudgetValues(
        salesBudgetDetails.soldUnitsReals,
        "soldUnitsReals"
      ),
      reals: prepareBudgetValues(salesBudgetDetails.reals, "reals"),
    };

    if (employeeId) {
      updateObject.employeeId = parseInt(employeeId, 10);
    }
    else{updateObject.employeeId =null}
    console.log("Final update object:", updateObject);

    if (productId) {
      updateObject.productId = parseInt(productId, 10);
    }
    else{updateObject.productId =null}
    console.log("Final update object:", updateObject);
  
    try {
      const response = await axios.put(
        `${JSON_API}/SalesBudgets`,
        updateObject,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      console.log("Sales budget updated successfully:", response.data);
      message.success(t("SalesBudgetUpdatedSuccessfully"));
      setSalesBudgetDetails(response.data);

      for (const budget of response.data.budgets) {
        if (budget.budgetConfirmed && budget.needsServerConfirmation) {
          await confirmBudgetOnServer(budget.id);
        }
      }
    } catch (error) {
      console.error("Error updating sales budget:", error.response || error);
      message.error(
        t("FailedToUpdateSalesBudget", {
          errorMessage: error.response
            ? error.response.data?.message
            : error.message,
        })
      );
    }
  };

  const confirmBudgetOnServer = async (budgetId) => {
    try {
      const confirmResponse = await axios.put(
        `${JSON_API}/SalesBudgets/ConfirmYearlyBudget/${budgetId}`,
        {},
        { headers: { "Content-Type": "application/json" } }
      );
      console.log(
        "Budget confirmed successfully on server:",
        confirmResponse.data
      );
      message.success(t("BudgetConfirmedSuccessfully"));
    } catch (error) {
      console.error("Error confirming budget on server:", error);
      message.error(t("FailedToConfirmBudgetOnServer"));
    }
  };

  const DeleteSalesBudget = async () => {
    await axios
      .delete(`${JSON_API}/SalesBudgets/${salesBudgetId}`)
      .then((res) => {
        console.log(res.data);
        handleredirection();
        message.success(t("SalesBudgetDeletedSuccessfully"));
      })
      .catch((err) => {
        console.error(err);
        message.error(t("FailedToDeleteSalesBudget"));
      });
  };

  return (
    <div>
      <Card
        bordered={false}
        className="header-solid mb-24"
        title={<h3 className="font-semibold">{t("SalesBudgetDetails")}</h3>}
      >
        <div
          style={{ textAlign: "center", marginBottom: 15, fontSize: "20px" }}
        >
          <h1>{description}</h1>
        </div>

        <Row justify="space-between">
          <Space
            style={{
              display: "flex",
              marginLeft: "flex",
            }}
            align="baseline"
          >
            {ability.can("update", "Sales Budget") && (
              <Button
                onClick={updateSalesBudget}
                type="primary"
                size="medium"
                textAlign="center"
              >
                <SaveOutlined />
                {t("save")}
              </Button>
            )}
          </Space>

          <Space>
            <Link to="/sales-budget">
              {ability.can("read", "Sales Budget") && (
                <Button
                  type="primary"
                  style={{
                    textAlign: "right",
                    marginLeft: "flex",
                  }}
                >
                  <InfoCircleOutlined />
                  {t("BackToSalesBudgets")}
                </Button>
              )}
            </Link>

            {ability.can("delete", "Sales Budget") && (
              <Button
                type="primary"
                danger
                style={{
                  float: "right",
                }}
                icon={<DeleteOutlined />}
                onClick={() => setOpen(true)}
              >
                {t("deletesalesbudget")} : {description}
              </Button>
            )}

            <Modal
              title={
                <span>
                  <ExclamationCircleOutlined /> {t("Delete")} : {description}
                </span>
              }
              visible={open}
              onOk={() => {
                DeleteSalesBudget();
                setOpen(false);
              }}
              onCancel={() => setOpen(false)}
              okText={
                <span>
                  <CheckCircleOutlined /> {t("yes")}
                </span>
              }
              cancelText={
                <span>
                  <CloseCircleOutlined /> {t("Cancel")}
                </span>
              }
              okButtonProps={{ type: "danger" }}
              centered
              width={600}
              destroyOnClose
            >
              <p>{t("Areyousuretodeletethisone")}</p>
            </Modal>
          </Space>
        </Row>
        <br></br>

        <Tabs
          style={{ marginBottom: 32, Color: "#059BFF" }}
          type="card"
          centered
          defaultActiveKey="1"
          items={itemsForm}
          onChange={onChangee}
        />

        <Tabs
          defaultActiveKey="soldUnitsBudgets"
          activeKey={activeTabMain}
          onChange={(key) => setActiveTabMain(key)}
          style={{ marginBottom: 35, width: "100%" }}
          type="card"
          centered
        >
          <TabPane tab={t("ExpectedSoldUnits")} key="soldUnitsBudgets">
            <>{console.log("salesBudgetDetailsbudget",salesBudgetDetailsbudget)}</>
            <Table
              columns={columnsSoldUnitsBudgets}
              dataSource={salesBudgetDetails.soldUnitsBudgets}
              locale={{
                emptyText: <Empty />,
              }}
              scroll={{
                x: 2000,
              }}
              pagination={false}
              bordered
            />
          </TabPane>
          <TabPane tab={t("RealSoldUnit")} key="soldUnitsReals">
            <Table
              columns={columnsSoldUnitsReals}
              dataSource={salesBudgetDetails.soldUnitsReals}
              locale={{
                emptyText: <Empty />,
              }}
              scroll={{
                x: 2000,
              }}
              pagination={false}
              bordered
            />
          </TabPane>
          <TabPane tab={t("Performance")} key="soldUnitsPerformances">
            <Table
              columns={columnsSoldUnitsPerformances}
              dataSource={salesBudgetDetails.soldUnitsPerformances}
              locale={{
                emptyText: <Empty />,
              }}
              scroll={{
                x: 2000,
              }}
              pagination={false}
              bordered
            />
          </TabPane>
        </Tabs>

        <Tabs
          defaultActiveKey="Budget"
          activeKey={activeTabSub}
          onChange={(key) => setActiveTabSub(key)}
          style={{ marginBottom: 35, width: "100%" }}
          type="card"
          centered
        >
          <TabPane tab="Budget" key="Budget">
            <Table
              columns={columnsBudget}
              dataSource={salesBudgetDetails.budgets}
              locale={{
                emptyText: <Empty description="No budget data" />,
              }}
              scroll={{
                x: 2000,
              }}
              pagination={false}
              bordered
            />
          </TabPane>
          <TabPane tab={t("Real")} key="Real">
            <Table
              columns={columnsReal}
              dataSource={salesBudgetDetails.reals}
              locale={{
                emptyText: <Empty description="No real data" />,
              }}
              scroll={{
                x: 2000,
              }}
              pagination={false}
              bordered
            />
          </TabPane>
          <TabPane tab="Performance" key="Performance">
            <Table
              columns={columnsPerformance}
              dataSource={salesBudgetDetails.performances}
              locale={{
                emptyText: <Empty description="No performance data" />,
              }}
              scroll={{
                x: 2000,
              }}
              pagination={false}
              bordered
            />
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

SalesBudgetDetails.acl = {
  subject: "Sales Budget",
  action: "read",
};

export default SalesBudgetDetails;

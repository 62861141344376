import { EditOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useAbility } from "../../contexts/abilityContext";
import axios from "axios";
import { CompanyContext } from "../../contexts/CompanyContext";
import { JSON_API } from "../../services/Constants";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useTranslation } from "react-i18next";
dayjs.extend(customParseFormat);

function EmployeeDetail() {
  const [form] = Form.useForm();
  const { ability } = useAbility();
  const { Company } = useContext(CompanyContext);
  const { employeeId } = useParams();
  const [Employee, setEmployee] = useState(null);
  const [defaultCollapse, setDefaultCollapse] = useState();
  const [formDisabled, setFormDisabled] = useState(true);
  const [enterpriseDepartments, setEnterpriseDepartments] = useState([]);
  const [postClassifications, setPostClassifications] = useState([]);
  const [Employees, setEmployees] = useState(null);
  const [titles, setTitles] = useState([]);
  const { t } = useTranslation();
  const { Panel } = Collapse;
  var currentForm = true;
  const { Option } = Select;

  const getEmployee = async () => {
    await axios
      .get(`${JSON_API}/Employees/${employeeId}`)
      .then((res) => {
        console.log("employees info: ", res.data);
        setEmployee(res.data);
        setDefaultCollapse(Employee.forms.length);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getEnterpriseDepartments = async () => {
    await axios
      .get(`${JSON_API}/EnterpriseDepartments/Filter/${Company.id}`)
      .then((res) => {
        console.log(res);
        setEnterpriseDepartments(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    await axios
      .get(`${JSON_API}/PostClassifications`)
      .then((res) => {
        console.log(res);
        setPostClassifications(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    await axios
      .get(`${JSON_API}/Titles?enterpriseId=${Company.id}`)
      .then((res) => {
        console.log(res);
        setTitles(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    await axios
      .get(`${JSON_API}/Employees/enterprise/${Company.id}/${null}`)
      .then((response) => {
        setEmployees(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const Departments =
    enterpriseDepartments == null
      ? []
      : enterpriseDepartments.map((ed) => ({ value: ed.id, label: ed.label }));
  const Titles =
    titles == null
      ? []
      : titles.map((ed) => ({ value: ed.id, label: ed.label }));
  const PostClassifications =
    postClassifications == null
      ? []
      : postClassifications.map((ed) => ({ value: ed.id, label: ed.label }));

  useEffect(() => {
    getEnterpriseDepartments();
    getEmployee();
  }, [Company, employeeId]);
  const handleCancel = () => {
    form.resetFields();
  };

  const onFinish = async (e) => {
    const employee = {
      id: Employee.id,
      name: e.name,
      firstName: e.firstName,
      hiringDate: e.hiringDate,
      titleId: e.titleId,
      visibleMinority: e.visibilityMinority == 1 ? true : false,
      annualSalary: e.annualSalary,
      socialAdvantagePercent: e.socialAdvantage,
      salaryBreakDown: e.salaryBreakDown == 1 ? true : false,
      departureDate: e.departureDate,
      payPeriod: e.payPeriod,
      yearsOfExperienceTotal: e.yearsOfExperienceTotal,
      recognizedYearsOfExperience: e.recognizedYearsOfExperience,
      vacationWeeks: e.vacationWeeks,
      bonus: e.bonus,
      otherSocialAdvantages: e.otherSocialAdvantages,
      jobPostingStartDate: e.jobPostingStartDate,
      insuranceContribution: e.insuranceContribution == 1 ? true : false,
      enterpriseDepartmentId: e.enterpriseDepartmentId,
      superiorId: e.immediateBoss,
      postClassificationId: e.postClassificationId,
      jobStatus: e.jobStatus,
      employeeStatus: e.employeeStatus,
      postStatus: e.postStatus,
      averageAge: e.averageAge,
      titles: [0],
    };
    console.log(employee);
    await axios
      .put(`${JSON_API}/Employees`, employee)
      .then((res) => {
        console.log(res.data);
        message.success("Employee Updated Succefully !", 3);
      })
      .catch(function (error) {
        console.log(error);
      });
    getEmployee();
    setFormDisabled(true);
  };

  const employeeestatus = (e) => {
    switch (e) {
      case 0:
        return `${t("Active")}`;
      case 1:
        return `${t("UnpaidLeave")}`;
      case 2:
        return `${t("Illness")}`;
      case 3:
        return `${t("InvoluntaryDeparture")}`;
      case 4:
        return `${t("VoluntaruDeparture")}`;
      case 5:
        return `${t("Invalidity")}`;
    }
  };

  const payperiod = (e) => {
    switch (e) {
      case "week":
        return `${t("Weekly")}`;
      case "Weekly":
        return `${t("Weekly")}`;
      case "15 Days":
        return `${t("In15Days")}`;
      case "Monthly":
        return `${t("Monthly")}`;
    }
  };

  const jobstatus = (e) => {
    switch (e) {
      case 0:
        return `${t("FullTime")}`;
      case 1:
        return `${t("PartTime")}`;
      case 2:
        return `${t("Temporary")}`;
    }
  };

  const poststatus = (e) => {
    switch (e) {
      case 0:
        return `${t("Temporary")}`;
      case 1:
        return `${t("Completed")}`;
      case 2:
        return `${t("Eliminated")}`;
    }
  };
  return (
    <Card
      bordered={false}
      className="header-solid mb-24"
      title={
        <div>
          <h3 className="font-semibold text-center">{t("employeedetails")}</h3>
          <h4 className="text-center">
            {Employee && Employee.name + " " + Employee.firstName}
          </h4>
        </div>
      }
    >
      <Collapse accordion defaultActiveKey={["current"]}>
        {Employee &&
          Employee.forms.map((e) =>
            currentForm ? (
              <Panel key="current" header={t("currentform")}>
                {(currentForm = false)}
                <Divider orientation="right">
                  {ability.can("update", "Employees Management") && (
                    <Col>
                      <EditOutlined />
                      <Switch
                        size="small"
                        checked={!formDisabled}
                        onChange={(e) => setFormDisabled(!e)}
                      />
                    </Col>
                  )}
                </Divider>
                <Form
                  form={form}
                  onFinish={onFinish}
                  disabled={formDisabled}
                  name="form_in_modal"
                >
                  <Divider orientation="left">
                    {t("GeneralIdentification")}
                  </Divider>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <Form.Item
                        name="firstName"
                        label={t("EmployeeFirstName")}
                        initialValue={Employee.firstName}
                        rules={[
                          {
                            required: true,
                            message: `${t("EmployeeFirstNameErrorMessage")}`,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <Form.Item
                        initialValue={Employee.name}
                        name="name"
                        label={t("EmployeeLastName")}
                        rules={[
                          {
                            required: true,
                            message: `${t("EmployeeLastNameErrorMessage")}`,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <Form.Item
                        initialValue={Employee.sexe}
                        name="gender"
                        label={t("EmployeeGender")}
                        rules={[
                          {
                            required: true,
                            message: `${t("EmployeeGenderErrorMessage")}`,
                          },
                        ]}
                      >
                        <Select
                          placeholder=""
                          style={{ width: "100%" }}
                          options={[
                            {
                              value: "M",
                              label: `${t("Male")}`,
                            },
                            {
                              value: "F",
                              label: `${t("Female")}`,
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <Form.Item
                        initialValue={e.employeeStatus}
                        name="employeeStatus"
                        label={t("EmployeeStatuss")}
                        rules={[
                          {
                            required: true,
                            message: `${t("EmployeeStatusErrorMessage")}`,
                          },
                        ]}
                      >
                        <Select
                          placeholder=""
                          style={{ width: "100%" }}
                          options={[
                            {
                              value: 0,
                              label: `${t("Active")}`,
                            },
                            {
                              value: 1,
                              label: `${t("UnpaidLeave")}`,
                            },
                            {
                              value: 2,
                              label: `${t("Illness")}`,
                            },
                            {
                              value: 3,
                              label: `${t("InvoluntaryDeparture")}`,
                            },
                            {
                              value: 4,
                              label: `${t("VoluntaruDeparture")}`,
                            },
                            {
                              value: 5,
                              label: `${t("Invalidity")}`,
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <Form.Item
                        initialValue={e.averageAge}
                        name="averageAge"
                        label={t("AverageAge")}
                        rules={[
                          {
                            required: true,
                            message: `${t("AverageAgeErrorMessage")}`,
                          },
                        ]}
                      >
                        <Select
                          placeholder=""
                          style={{ width: "100%" }}
                          options={[
                            {
                              value: 0,
                              label: `${t("Under20")}`,
                            },
                            {
                              value: 1,
                              label: `${t("Between20And25")}`,
                            },
                            {
                              value: 2,
                              label: `${t("Between26And30")}`,
                            },
                            {
                              value: 3,
                              label: `${t("Between31And39")}`,
                            },
                            {
                              value: 4,
                              label: `${t("Between40And50")}`,
                            },
                            {
                              value: 5,
                              label: `${t("Beyound50")}`,
                            },
                            // Other options...
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <Form.Item
                        initialValue={e.visibilityMinority}
                        name="VisibilityMinority"
                        label={t("VisibilityMinority")}
                      >
                        <Checkbox />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <Form.Item
                        initialValue={e.recognizedYearsOfExperience}
                        name="recognizedYearsOfExperience"
                        label={t("RecognizedExeperienceYears")}
                      >
                        <InputNumber style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <Form.Item
                        initialValue={e.yearsOfExperienceTotal}
                        name="yearsOfExperienceTotal"
                        label={t("YearsOfExperienceTotal")}
                      >
                        <InputNumber style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Divider orientation="left">{t("BasicConditions")}</Divider>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <Form.Item
                        initialValue={e.annualSalary}
                        name="annualSalary"
                        label={t("AnnualSalary")}
                        rules={[
                          {
                            required: true,
                            message: `${t("AnnualSalaryErrorMessage")}`,
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="0"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <Form.Item
                        initialValue={e.socialAdvantagePercent}
                        name="socialAdvantage"
                        label={t("SocialAdvantages")}
                      >
                        <InputNumber
                          placeholder="0"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <Form.Item
                        initialValue={e.salaryBreakDown}
                        name="salaryBreakDown"
                        label={t("BreakDown")}
                      >
                        <Checkbox />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <Form.Item
                        initialValue={e.vacationWeeks}
                        name="vacationWeeks"
                        label={t("Vaccation")}
                      >
                        <InputNumber style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <Form.Item
                        initialValue={e.bonus}
                        name="bonus"
                        label={t("Bonus")}
                      >
                        <InputNumber
                          placeholder="000.00"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <Form.Item
                        initialValue={e.otherSocialAdvantages}
                        name="otherSocialAdvantages"
                        label={t("OtherAdvantages")}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        initialValue={dayjs(e.jobPostingStartDate)}
                        name="jobPostingStartDate"
                        label={t("JobPostingStartDate")}
                      >
                        <DatePicker
                          name="year"
                          placeholder={t("selectdate")}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        initialValue={e.insuranceContribution}
                        name="insuranceContribution"
                        label={t("InsuranceContribution")}
                      >
                        <Checkbox />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Divider orientation="left">{t("Job")}</Divider>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <Form.Item
                        initialValue={e.immediateBoss}
                        name="immediateBoss"
                        label={t("ImmediateSuperior")}
                      >
                        <Select placeholder=" " style={{ width: "100%" }}>
                          {Employees &&
                            Employees.map((emp) => (
                              <Option key={emp.id} value={emp.id}>
                                {emp.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <Form.Item
                        initialValue={e.titleId}
                        name="titleId"
                        label={t("Title")}
                        rules={[
                          {
                            required: true,
                            message: `${t("SlectATitle")}`,
                          },
                        ]}
                      >
                        <Select
                          placeholder=""
                          style={{ width: "100%" }}
                          options={Titles}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <Form.Item
                        initialValue={e.postClassificationId}
                        name="postClassificationId"
                        label={t("JobClassification")}
                        rules={[
                          {
                            required: true,
                            message: `${t("SlectAJobClassification")}`,
                          },
                        ]}
                      >
                        <Select
                          style={{ width: "100%" }}
                          options={PostClassifications}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <Form.Item
                        initialValue={dayjs(Employee.hiringDate)}
                        name="hiringDate"
                        label={t("HiringDate")}
                        rules={[
                          {
                            required: true,
                            message: `${t("HiringDateErrorMessage")}`,
                          },
                        ]}
                      >
                        <DatePicker
                          placeholder={t("selectdate")}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <Form.Item
                        initialValue={e.enterpriseDepartmentId}
                        name="enterpriseDepartmentId"
                        label={t("Department")}
                        rules={[
                          {
                            required: true,
                            message: `${t("SelectDepartment")}`,
                          },
                        ]}
                      >
                        <Select
                          placeholder={t("SelectDepartment")}
                          style={{ width: "100%" }}
                          options={Departments}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <Form.Item
                        initialValue={e.payPeriod}
                        name="payPeriod"
                        label={t("PayPeriod")}
                      >
                        <Select
                          style={{ width: "100%" }}
                          options={[
                            {
                              value: "Weekly",
                              label: `${t("Weekly")}`,
                            },
                            {
                              value: "15 Days",
                              label: `${t("In15Days")}`,
                            },
                            {
                              value: "Monthly",
                              label: `${t("Monthly")}`,
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <Form.Item
                        initialValue={e.jobStatus}
                        name="jobStatus"
                        label={t("JobStatus")}
                      >
                        <Select
                          placeholder=""
                          style={{ width: "100%" }}
                          options={[
                            {
                              value: 0,
                              label: `${t("FullTime")}`,
                            },
                            {
                              value: 1,
                              label: `${t("PartTime")}`,
                            },
                            {
                              value: 2,
                              label: `${t("Temporary")}`,
                            },
                            {
                              value: 3,
                              label: `${t("Contractual")}`,
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <Form.Item
                        initialValue={e.postStatus}
                        name="postStatus"
                        label={t("PostStatus")}
                      >
                        <Select
                          placeholder=""
                          style={{ width: "100%" }}
                          options={[
                            {
                              value: 0,
                              label: `${t("Temporary")}`,
                            },
                            {
                              value: 1,
                              label: `${t("Completed")}`,
                            },
                            {
                              value: 2,
                              label: `${t("Eliminated")}`,
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <Form.Item
                        initialValue={dayjs(e.departureDate)}
                        name="departureDate"
                        label={t("DepartureDate")}
                      >
                        <DatePicker
                          placeholder={t("selectdate")}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    {ability.can("update", "Employees Management") && (
                      <Form.Item>
                        <Button
                          className="Create-button"
                          type="primary"
                          style={{ marginTop: 40, marginLeft: 150 }}
                          onClick={handleCancel}
                        >
                          {t("cancel")}
                        </Button>
                        <Button
                          className="Create-button"
                          type="primary"
                          htmlType="submit"
                          style={{ marginTop: 40, marginLeft: 5, width: 100 }}
                        >
                          {t("save")}
                        </Button>
                      </Form.Item>
                    )}
                  </Row>
                </Form>
              </Panel>
            ) : (
              <Panel
                key={e.id}
                header={t("previousupdate") + " (" + e.createdAt + ")"}
              >
                <Descriptions title={t("GeneralIdentification")}>
                  <Descriptions.Item label="#N° ">
                    {Employee.id}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("EmployeeFirstName")}>
                    {Employee.firstName}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("EmployeeLastName")}>
                    {Employee.name}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("EmployeeGender")}>
                    {Employee.sexe}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("EmployeeStatuss")}>
                    {/* <Badge status="processing" text="" /> */}

                    {employeeestatus(e.employeeStatus)}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("VisibilityMinority")}>
                    <Checkbox checked={e.visibilityMinority} disabled />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("AverageAge")}>
                    {e.averageAge}{" "}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("RecognizedExeperienceYears")}>
                    {e.recognizedYearsOfExperience}{" "}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("YearsOfExperienceTotal")}>
                    {e.yearsOfExperienceTotal}{" "}
                  </Descriptions.Item>
                </Descriptions>
                <Divider orientation="left">{t("BasicConditions")}</Divider>
                <Descriptions bordered size="small">
                  <Descriptions.Item label={t("AnnualSalary") + " ($)"}>
                    {e.annualSalary}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("SocialAdvantages")}>
                    {e.socialAdvantagePercent}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("Vaccation")}>
                    {e.vacationWeeks}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("Bonus") + " ($)"}>
                    {e.bonus}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("OtherAdvantages")} span={2}>
                    {e.otherSocialAdvantages}{" "}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("JobPostingStartDate")}>
                    {e.jobPostingStartDate}{" "}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("InsuranceContribution")}>
                    <Checkbox checked={e.insuranceContribution} disabled />
                  </Descriptions.Item>
                </Descriptions>

                <Divider orientation="left">{t("Job")}</Divider>
                <Descriptions bordered size="small">
                  <Descriptions.Item label={t("ImmediateSuperior")}>
                    {e.immediateBoss ? e.immediateBoss : "none"}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("HiringDate")}>
                    {Employee.hiringDate}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("JobClassification")}>
                    {e.postClassificationId}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("DepartureDate")}>
                    {e.departureDate}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("Title")}>
                    {e.titleId}{" "}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("Department")}>
                    {e.enterpriseDepartmentId}{" "}
                  </Descriptions.Item>

                  <Descriptions.Item label={t("PayPeriod")}>
                    {payperiod(e.payPeriod)}
                  </Descriptions.Item>

                  <Descriptions.Item label={t("PostStatus")}>
                    {poststatus(e.postStatus)}
                  </Descriptions.Item>
                </Descriptions>
              </Panel>
            )
          )}
      </Collapse>
    </Card>
  );
}
export default EmployeeDetail;

import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Form, Button, message, Card, Divider, Spin } from "antd";
import axios from "axios";
import { JSON_API } from "../services/Constants";
import { CompanyContext } from "../contexts/CompanyContext";

import { FilePdfOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ReportGenerator = () => {
  let { t } = useTranslation();
  const { Company } = useContext(CompanyContext);
  const location = useLocation();
  const Idd = location.state?.stateParam; // Use optional chaining to avoid errors if state is undefined
  const { report } = location.state || {};
  const [customreports, setCustomreports] = useState([]);
  const [customreportsId, setCustomreportsId] = useState([]);
  const [form] = Form.useForm();
  const [generatedReport, setGeneratedReport] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [reportName, setReportName] = useState("");

  const getCustomreports = async () => {
    try {
      const response = await axios.get(
        `${JSON_API}/Reporting/customReports/enterprise/${Company.id}`
      );
      const reports = response.data;
      console.log("Custom reports:", reports); // Vérifier les données récupérées
      setCustomreports(reports);

      // Réinitialiser customreportsId avant de récupérer de nouveaux rapports
      setCustomreportsId([]);

      // Appeler getCustomreportID une seule fois avec l'Idd fourni
      if (Idd) {
        getCustomreportID(Idd);
      }
    } catch (error) {
      console.error("Failed to fetch custom reports:", error);
    }
  };

  const getCustomreportID = async (Idd) => {
    console.log(Idd);
    try {
      const response = await axios.get(
        `${JSON_API}/Reporting/customReports/${Idd}`
      );
      console.log("Custom report ID:", Idd); // Vérifier l'ID du rapport récupéré
      console.log("Custom report response:", response.data.name); // Vérifier les données du rapport récupéré
      setCustomreportsId((prev) => [...prev, response.data]);
      setReportName(response.data.name);
    } catch (error) {
      console.error("Failed to fetch custom report by ID:", error);
    }
  };

  useEffect(() => {
    if (Company.id) {
      getCustomreports();
    }
  }, [Company.id, Idd]);

  useEffect(() => {
    if (customreportsId.length > 0) {
      onFinish();
    }
  }, [customreportsId]);

  const onFinish = async () => {
    if (customreportsId.length === 0) {
      message.error("No reports available to generate.");
      return;
    }

    const currentReport = customreportsId[0];
    console.log("Current report:", currentReport); // Vérifier le rapport actuel avant la soumission

    setLoading(true);

    try {
      const response = await axios.post(
        `${JSON_API}${currentReport.fixedEndPoint}`,
        currentReport.requestBody,
        { responseType: "arraybuffer" } // Ensure the response is treated as binary data
      );
      console.log("Report generation response:", response.data); // Vérifier la réponse de génération de rapport

      // Create a Blob from the PDF data
      const blob = new Blob([response.data], { type: "application/pdf" });
      // Create a URL for the Blob
      const pdfUrl = URL.createObjectURL(blob);
      setPdfUrl(pdfUrl);

      setGeneratedReport(response.data);
      message.success(t("Reportgeneratedsuccessfully"));
    } catch (error) {
      message.error(t("Failedtogeneratereportbecauseithasnocustomdata"));
      console.error(error);
    } finally {
      setLoading(false);
      setCustomreportsId([]); // Réinitialiser customreportsId après la soumission
    }
  };

  return (
    <>
      <Card
        bordered={false}
        className="header-solid mb-24"
        title={
          <h3 className="font-semibold">
            <Link to="/report_section">
              <Button type="primary" danger>
                {t("returntoreportsection")}
              </Button>
            </Link>
            <Divider style={{ color: "#0000FF" }}>
              <h4>
                <FilePdfOutlined style={{ color: "#0000FF" }} />
                {t("Personalizedrapport")}: {reportName}
              </h4>
            </Divider>
          </h3>
        }
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Spin />
          </div>
        ) : (
          pdfUrl && (
            <iframe
              style={{
                width: "80vw",
                height: "100vh",
                border: "none",
              }}
              src={pdfUrl}
              title="PDF Report"
            ></iframe>
          )
        )}
      </Card>
    </>
  );
};

export default ReportGenerator;

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom"; // Import useHistory
import { ArrowLeftOutlined ,EditOutlined} from "@ant-design/icons";
import { Button, Spin ,message, Modal, Form, Input, Select} from "antd";
import { useParams } from "react-router-dom";
import axios from "axios";
import { JSON_API } from "../../../services/Constants";
import { useTranslation } from "react-i18next";
import DetailPart from "./DetailPart";
const { Option } = Select;

const ModelDetail = () => {
  const [parts, setParts] = useState([]);
  const [model, setModel] = useState();
  let { t } = useTranslation();
  const history = useHistory(); // Initialize useHistory
  const { id } = useParams();
  const [loading, setLoading] = useState(true); // Loading state
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [updatedModel, setUpdatedModel] = useState({})


  const fetchData = async () => {
    try {
      setLoading(true); // Set loading to true before fetching data
      const response = await axios.get(`${JSON_API}/abCashFlowModels/${id}`);
      const fetchedParts = response.data.cashFlowModelParts;
      setParts(fetchedParts);
      setModel(response.data); // If you want to store the entire model data
      setLoading(false); // Set loading to true before fetching data
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
      setLoading(false); // Set loading to true before fetching data
    }
  };
  useEffect(() => {
    fetchData();
  }, [id]);
  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "2rem" }}>
        <Spin tip="Loading..." size="large" />
      </div>
    );
  }
  const addCategory = async (partId) => {
    const newCategory = {
        name: "New Category",
        cashFlowPartId: partId,
        parentCategoryId: 0 
    };

    try {
      //  setLoading(true);
        const response = await axios.post(`${JSON_API}/abCashFlowModels/categories`, newCategory); 
        await fetchData()
    } catch (error) {
        console.error("An error occurred while adding the category:", error);
    } finally {
      //  setLoading(false);
    }
};

const addSection = async (partId) => {
  const newSection = {
      name: "New Section",
      cashFlowPartId: partId,
      parentSectionId: 0,
      categoryId: 0
  };

  try {
    //  setLoading(true);
      const response = await axios.post(`${JSON_API}/abCashFlowModels/sections`, newSection);
      await fetchData()
      // const updatedParts = parts.map(part => {
      //     if (part.id === partId) {
      //         const updatedSections = [...part.sections, response.data];

      //         // Sorting sections based on `isImmutable` property.
      //         // Immutable sections will be moved to the end.
      //         updatedSections.sort((a, b) => {
      //             if (a.isImmutable && !b.isImmutable) return 1;
      //             if (!a.isImmutable && b.isImmutable) return -1;
      //             return 0;
      //         });

      //         return {
      //             ...part,
      //             sections: updatedSections
      //         };
      //     }
      //     return part;
      // });
      // setParts(updatedParts);
      message.success(`Section Was Added Successfully`);
  } catch (error) {
      message.error("An error occurred while adding the section:" + error);
  } finally {
     // setLoading(false);
  }
};
const addSectionToCategory = async (partId,categoryId) => {
  const newSection = {
      name: "New Section",
      cashFlowPartId: partId,
      parentSectionId: 0,
      categoryId: categoryId
  };

  try {
    //  setLoading(true);
      const response = await axios.post(`${JSON_API}/abCashFlowModels/sections`, newSection);
      await fetchData() 
      message.success(`Section Was Added Successfully`);
  } catch (error) {
      message.error("An error occurred while adding the section:" + error);
  } finally {
     // setLoading(false);
  }
};

  const editCategory =async (partId,category) => {
    try {
      //  setLoading(true);
      const updatedCategory={
        id:category.id,
        name:category.name,
        position:category.position,
        sections:[],
        subCategories:[]
      }
        const response = await axios.put(`${JSON_API}/abCashFlowModels/${id}/parts/${partId}/categories`, updatedCategory);
        await fetchData()
        message.success(t("CategoryWasUpdatedSuccessfully"));
    } catch (error) {
        message.error("AnErrorOccurredWhileAddingTheCategory:" + error);
    }
  };
  const editSection =async (partId,section) =>{
    try {
      //  setLoading(true);
      const updatedSection={
        id:section.id,
        name:section.name,
        position:section.position,
        chartAccountId:section.chartAccountId,
        subSections:[],
      }
        const response = await axios.put(`${JSON_API}/abCashFlowModels/${id}/parts/${partId}/sections`, updatedSection);
        await fetchData()
        message.success(t("SectionWasUpdatedSuccessfully"));
    } catch (error) {
        message.error(t("AnErrorOccurredWhileAddingTheSection") + error);
    }
  };
  const deleteCategory = async (categoryId) => {
    try {
        await axios.delete(`${JSON_API}/abCashFlowModels/parts/categories/${categoryId}`);
        await fetchData()      
        message.success(t("CategoryDeletedSuccessfully"));
    } catch (error) {
        message.error("AnErrorOccurredWhileDeletingTheCategory " + error);
    } finally {
        // setLoading(false);
    }
};

const deleteSection = async (sectionId) => {
    try {
        await axios.delete(`${JSON_API}/abCashFlowModels/parts/sections/${sectionId}`);
       await fetchData()
        message.success("Section deleted successfully.");
    } catch (error) {
        message.error("An error occurred while deleting the section: " + error);
    } finally {
        // setLoading(false);
    }
};
const showModal = () => {
  setIsModalVisible(true);
};

const handleCancel = () => {
  setIsModalVisible(false);
};
const handleUpdate = async () => {
  const updated={
    id:model.id,
    name:updatedModel.name,
    // complexity:updatedModel.complexity,
    frequency:updatedModel.frequency
  }
  try {
    await axios.put(`${JSON_API}/abCashFlowModels`, updated);
    setIsModalVisible(false);
    await fetchData(); 
    message.success(t("ModelUpdatedSuccessfully"));
  } catch (error) {
    message.error("An error occurred while updating the model: " + error);
  }
};


  const goBackToCashFlowModels = () => {
    history.goBack();
  };
  return (
    <>
      <Button
        type="link"
        icon={<ArrowLeftOutlined />}
        onClick={goBackToCashFlowModels}
      >
        {t("GoBack")}
      </Button>
      <div style={{ textAlign: "center" }}>
        <h1>{model.name}  <EditOutlined onClick={showModal} style={{ marginLeft: '10px' }} /> </h1>
        <Modal 
  title={<span style={{fontSize: '1.5rem', color: '#333'}}>{t("EditModelInfo")}</span>}
  visible={isModalVisible} 
  onCancel={handleCancel}
  onOk={handleUpdate}
  width={500} // Increased width
  bodyStyle={{padding: '2rem'}} // Padding inside the modal
>
  <Form    
    layout="vertical" // Vertical layout for better visual separation
    initialValues={{ 
        name: model.name,
        // complexity: model.complexity,
        frequency: model.frequency
    }}
    style={{marginTop: '1rem'}} // Spacing from top
  >
    <Form.Item
      name="name"
      label={<span style={{fontWeight: 'bold'}}>{t("name")}</span>}
      rules={[{ required: true, message: t("PleaseInputName") }]}
    >
      <Input placeholder="Name" style={{borderRadius: '5px'}} onChange={(e) => setUpdatedModel({...updatedModel, name: e.target.value})}  />
    </Form.Item>

    {/* <Form.Item
      name="complexity"
      label={<span style={{fontWeight: 'bold'}}>{t("Complexity")}</span>}
      rules={[{ required: true, message: "Please select complexity!" }]}
    >
      <Select style={{borderRadius: '5px', width: '100%'}}onChange={(value) => setUpdatedModel({...updatedModel, complexity: value})}>
        <Option value={0}>{"Simple"}</Option>
        <Option value={1}>{t("Intermediate")}</Option>
        <Option value={2}>{t("Advanced")}</Option>
      </Select>
    </Form.Item> */}

    <Form.Item
      name="frequency"
      label={<span style={{fontWeight: 'bold'}}>{t("Frequency")}</span>}
      rules={[{ required: true, message: "Please select frequency!" }]}>
      <Select style={{borderRadius: '5px', width: '100%'}} onChange={(value) => setUpdatedModel({...updatedModel, frequency: value})}>
        <Option value={0}>{t("Week")}</Option>
        <Option value={1}>{t("TwoWeeks")}</Option>
        <Option value={2}>{t("Month")}</Option>
      </Select>
    </Form.Item>
  </Form>
</Modal>

        {parts.map((part, index) => (
          <DetailPart
            key={index}
            partId={part.id}
            part={part}
            addSection={() => addSection(part.id)}
            addSectionToCategory={addSectionToCategory}
            addCategory={() => addCategory(part.id)}
            editCategory={editCategory}
            deleteCategory={deleteCategory}
            editSection={editSection}
            deleteSection={deleteSection}
            isReadOnly={model.isLinked}
            enterpriseId={model.enterpriseId}
            isDetail={true}
            complexity={model.complexity}
          />
        ))}
      </div>
    </>
  );
};
export default ModelDetail;

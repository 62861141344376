import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Select,
  Space,
  Table,
  message,





  Alert
} from "antd";

import {
  CloseCircleFilled,
  DeleteOutlined,
  DollarOutlined,
  EditOutlined,
  PlusCircleFilled,
  SaveFilled,
  SettingOutlined,
} from "@ant-design/icons";
import { Link, useHistory, useParams } from "react-router-dom";
import { CompanyContext } from "../../contexts/CompanyContext";
import { JSON_API } from "../../services/Constants";

import { useTranslation } from "react-i18next";

import { Card, Col, Row } from "antd";
import axios from "axios";
// import "./Tableau.css";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
const { Option } = Select;

function Monthday() {
  let { t } = useTranslation();
  const { Company } = useContext(CompanyContext);

  const [messageApi, contextHolder] = message.useMessage();
  const { id } = useParams();

  const [MonthDays, setMonthDays] = useState();
  const [months, setMonths] = useState([
        { month: "January", value: 0 },
        { month: "February", value: 0 },
        { month: "March", value: 0 },
        { month: "April", value: 0 },
        { month: "May", value: 0 },
        { month: "June", value: 0 },
        { month: "July", value: 0 },
        { month: "August", value: 0 },
        { month: "September", value: 0 },
        { month: "October", value: 0 },
        { month: "November", value: 0 },
        { month: "December", value: 0 }
    ]);
    let workhours;

  const [data, setData] = useState([{}]);
  const [editingRow, setEditingRow] = useState(null);
  const [currentYear,setcurrentYear] = useState(new Date().getFullYear());
  const [visible, setVisible] = useState(false);

  const handleClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    getMonthdays(currentYear);
  }, [Company.id]);

  const { TextArea } = Input;

  const editworkhours = async () => {
    const updatedState = { ...MonthDays };
    months.forEach(month => {
        updatedState[`${month.month.toLowerCase()}Days`] = month.value;
    });

    // Update the state with the new values
    setMonthDays(updatedState);
    console.log("Markets: ", updatedState);
    const validateData = (data) => {
      console.log(data)
    const requiredFields = ['januaryDays', 'februaryDays', 'marchDays', 'aprilDays', 'mayDays', 'juneDays', 'julyDays', 'augustDays', 'septemberDays', 'octoberDays', 'novemberDays', 'decemberDays'];

    // Check if any required field is null
    for (const field of requiredFields) {
        if (data[field] === null) {
            return `numberofdayrequired`;
        }
    }

    // Check if year is a valid number
    if (data.year===null) {
        return `PleaseSelectYear`;
    }

    // Check if workHours is a valid number
    if (data.workHours===null) {
        return `workhoursrequired`;
    }

    return null; // Data is valid
};
const validationMessage = validateData(updatedState);
console.log(validationMessage)
if (validationMessage) {
    messageApi.open({
        type: "success",
        content: `${t(validationMessage)}`,
      }); // Display corresponding message
} else {
    await axios.put(`${JSON_API}/BusinessDaysConfigs`, updatedState).then((response) => {
      console.log("Markets updated Successfully!");
      getMonthdays(currentYear);
      messageApi.open({
        type: "success",
        content: `${t("assignmentupdate")}`,
      });
    });
  }
  };

  const getMonthdays = async (year) => {
    console.log(currentYear)
    await axios
      .get(`${JSON_API}/BusinessDaysConfigs/enterprise/${Company.id}?year=${year}`)
      .then(async(response) => {
        setMonthDays(response.data);
        function reorderMonths(monthIndex) {
    // Validate the input
    if (typeof monthIndex !== 'number' || monthIndex < 0 || monthIndex >= 12) {
        console.error('Invalid input');
        return [];
    }

    const months = [
        { month: "January", value: response.data.januaryDays },
        { month: "February", value: response.data.februaryDays },
        { month: "March", value: response.data.marchDays },
        { month: "April", value: response.data.aprilDays },
        { month: "May", value: response.data.mayDays },
        { month: "June", value: response.data.juneDays },
        { month: "July", value: response.data.julyDays },
        { month: "August", value: response.data.augustDays },
        { month: "September", value: response.data.septemberDays },
        { month: "October", value: response.data.octoberDays },
        { month: "November", value: response.data.novemberDays },
        { month: "December", value: response.data.decemberDays }
    ];

    // Reorder the months array
    const reorderedMonths = [];

    for (let i = 0; i < 12; i++) {
        const newIndex = (i + monthIndex) % 12;
        reorderedMonths[i] = months[newIndex];
    }

    console.log(reorderedMonths);
    setMonths(reorderedMonths);
}
     await reorderMonths(Company.startPeriod? Company.startPeriod:0);
     setcurrentYear(year)
     if(response.data.id===null){
      setVisible(true)
    }
    else{
      setVisible(false)
    }


    
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [form] = Form.useForm();
  const [form1] = Form.useForm();

  const [open, setOpen] = useState(false);

  const columns = [
    {
      title: <h1 style={{ textAlign: "center" }}>{t("months")}</h1>,
      align: "left",
      render: (_, record) => {
          return <div style={{ textAlign: "left" }}>{t(record.month)}</div>;
      },
    },
    {
      title: <h1 style={{ textAlign: "center" }}>{t("numberofday")}</h1>,
      align: "left",
      render: (_, record) => {
        const monthValue = months.find(month => month.month === record.month)?.value || 0;
        console.log(record,currentYear,_)
          return (
            <Form.Item
              name={`numberofday${record.month}${currentYear}`}
              rules={[
                {
                  required: true,
                  message: `${t("numberofdayrequired")}`,
                },
              ]}
              //initialValue={record.value && record.value}
            >
              <InputNumber
                style={{
                  width: 300,
                }}
                type="number"
                defaultValue={record.value && record.value}
                min={0}
                onChange={(e) => {
                        const newValue = e;
                        setMonths(prevMonths => {
                            const updatedMonths = prevMonths.map(month => {
                                if (month.month === record.month) {
                                    return { ...month, value: newValue };
                                }
                                return month;
                            });
                            return updatedMonths;
                        });
                    }}
              />
            </Form.Item>
          )
      },
    },
  ];

  return (
    <>
      <div>
        {contextHolder}
        {/* <h3>{t("Loansmanagement")}</h3> <br />
      <div> */}
      <Space direction="vertical" style={{ width: '100%' }}>
      {visible && (
        <Alert message={t("yearnotexisting")} type="success" closable onClose={handleClose} />
      )}
    </Space>
        <Card
          bordered={false}
          className="header-solid mb-24"
          title={
            <h3 className="font-semibold">
              <SettingOutlined style={{ color: "#3947C4" }} />{" "}
              {t("monthbusinessdays")}
            </h3>
          }
          extra={
            <Space style={{ marginBottom: 15 }}>
              <Button
                className="Create-button"
                type="primary"
                icon={<PlusCircleFilled />}
                onClick={() => {
                  editworkhours()
                }}
              >
                {t("save")}
              </Button>
            </Space>
          }
        >
          <Row align="middle" gutter={[16, 16]}>
              <Col xs={24} sm={12} md={12}>
                <Form.Item name="year" label={t("Year")}>
                  <DatePicker
        defaultValue={dayjs(new Date())}
        placeholder={t("SelectYearPlaceHolder")}
        style={{ width: "100%" }}
        picker="year"
        onChange={(e) => { if(e && e.year()){getMonthdays(e.year())}else{setMonthDays((prevMonthDays) => ({
          ...prevMonthDays,
          year: null
        }));} }}
    />
                </Form.Item>
              </Col>


              <Col xs={24} sm={12} md={12}>
  {MonthDays && (<Form.Item
    label={t("workhours")}
    name={`workhours${MonthDays.year}`}
    //initialValue={MonthDays && MonthDays.workHours}
    rules={[
      {
        required: true,
        message: `${t("workhoursrequired")}`,
      },
    ]}
  >
    <InputNumber 
      min={0}
      type="number"

      defaultValue={MonthDays.workHours && MonthDays.workHours}
      onChange={(newValue) => {
        console.log(newValue)
        setMonthDays((prevMonthDays) => ({
          ...prevMonthDays,
          workHours: newValue
        }));
      }}
      placeholder={t("enterworkhours")}
    />
  </Form.Item>)}
</Col>


            </Row>
          <Form form={form1}>
            <Table bordered columns={columns} dataSource={months} style={{ overflowX: "auto",backgroundColor:'white' }}/>
          </Form>
        </Card>
      </div>
    </>
  );
}

export default Monthday;


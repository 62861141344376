import React, { useEffect } from "react";
import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PlusSquareOutlined,
  UserOutlined,
  DollarOutlined,
  TagOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CompanyContext } from "../contexts/CompanyContext";
import { JSON_API } from "../services/Constants";
import {
  Modal,
  Collapse,
  Radio,
  Switch,
  Tag,
  Row,
  Col,
  Card,
  Statistic,
  Button,
  List,
  Descriptions,
  Avatar,
  Divider,
  Dropdown,
  Menu,
  Space,
  Tooltip,
  Badge,
  Typography,
} from "antd";
import { useAbility } from "../contexts/abilityContext";

const { Meta } = Card;
const { Panel } = Collapse;
const { Text, Title } = Typography;

const Plans = () => {
  let { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [monthly, setMonthly] = useState(true);
  const [planDetails, setPlanDetails] = useState(null);
  const { ability } = useAbility();

  const getAllPlans = async () => {
    try {
      const response = await axios.get(`${JSON_API}/Plans/monthly/${monthly}`);
      setPlans(response.data);
      setLoading(true);
    } catch (error) {
      console.log(error);
      message.error(t("ErrorFetchingPlans"));
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllPlans();
  }, [monthly]);

  const switchDuration = (value) => {
    setMonthly(value);
  };

  const deletePlan = async (planId) => {
    try {
      const response = await axios.delete(`${JSON_API}/Plans/${planId}`);

      if (response.status === 204) {
        console.log("Plan successfully deleted.");
        message.success(t("PlanSuccessfullyDeleted"));
        setLoading(true);

        getAllPlans();
      } else {
        console.log("Failed to delete plan.");
        message.error(t("FailedToDeletePlan"));
        setLoading(false);
      }
    } catch (error) {
      console.error("An error occurred while deleting the plan:", error);
      message.error(t("ErrorDeletingPlan"));
      setLoading(false);
    }
  };

  const handleUpdatePlan = (planId) => {
    setLoading(true);
    axios
      .get(`${JSON_API}/Plans/${planId}`)
      .then((response) => {
        setPlanDetails(response.data);
        history.push(`/update-plan/${planId}`);
        setLoading(false);

        console.log("Plan details:", response.data);
      })
      .catch((error) => {
        console.error("Error fetching plan details:", error);
        message.error("Erreur lors de la récupération des détails du plan.");
        setLoading(false);
      });
  };

  return (
    <Row gutter={[24, 0]}>
      <Col span={24} md={24} className="mb-24">
        <Card
          className="header-solid h-full"
          bordered={false}
          title={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h6 className="font-semibold m-0">{t("OurPlans")}</h6>
              <Switch
                checked={monthly}
                onChange={switchDuration}
                checkedChildren={t("Monthly")}
                unCheckedChildren={t("Yearly")}
                className="ml-auto mr-auto"
              />
              {ability && ability.can("create", "Plans") && (
                <Link to="/createPlan">
                  <Button className="custom-btn" size="medium" type="primary">
                    <PlusSquareOutlined /> {t("Create")}
                  </Button>
                </Link>
              )}
            </div>
          }
          bodyStyle={{ paddingTop: "0" }}
        >
          <Row gutter={[24, 24]}>
            {plans.map((plan, index) => (
              <Col span={12} key={index}>
                <Card
                  className="plan-card"
                  bordered={false}
                  style={{
                    height: "100%",
                  }}
                >
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "1rem" }}
                  >
                    <Col>
                      <h2 style={{ margin: 0 }}>{plan.label}</h2>
                    </Col>
                    <Col>
                      {plan.includesWhiteLableing && (
                        <Tag
                          style={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            borderRadius: "5px",
                            padding: "5px 10px",
                            textAlign: "center",
                            textTransform: "uppercase",
                          }}
                          color="red"
                        >
                          {t("IncludingWhiteLabeling")}
                        </Tag>
                      )}
                    </Col>
                    <Col>
                      <Tag
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          borderRadius: "5px",
                          padding: "5px 10px",
                          textAlign: "center",
                          textTransform: "uppercase",
                        }}
                        color={monthly ? "green" : "blue"}
                      >
                        {monthly ? "Monthly" : "Yearly"}
                      </Tag>
                    </Col>
                  </Row>

                  <Text type="secondary">{plan.description}</Text>

                  <Row gutter={16} style={{ margin: "1.5rem 0" }}>
                    <Col span={8}>
                      <Statistic
                        title={t("StandardUsers")}
                        value={plan.standardUsersCount}
                        prefix={<UserOutlined />}
                      />
                    </Col>
                    <Col span={8}>
                      <Statistic
                        title={t("CostPerExtraUser")}
                        value={plan.costPerExtraUser}
                        prefix={<DollarOutlined />}
                      />
                    </Col>
                    <Col span={8}>
                      <Statistic
                        title={t("CurrentPrice")}
                        value={plan.currentPrice}
                        prefix={<TagOutlined />}
                      />
                    </Col>
                  </Row>

                  <Divider orientation="left"> {t("IncludedSections")}</Divider>
                  {plan.sections.length > 0 ? (
                    plan.sections.map((section, index) => (
                      <Tag
                        style={{
                          fontSize: "12px",
                          margin: "0.35rem",
                          fontWeight: "bold",
                          borderRadius: "5px",
                          padding: "5px 10px",
                          textAlign: "center",
                          textTransform: "uppercase",
                        }}
                        key={index}
                      >
                        {section.label}
                      </Tag>
                    ))
                  ) : (
                    <Text type="danger">
                      {t("PlanDoesNotIncludeAnySections")}
                    </Text>
                  )}

                  <Divider />

                  <Row justify="end" gutter={16}>
                    {ability && ability.can("update", "Plans") && (
                      <Col>
                        <Button
                          type="link"
                          onClick={() => handleUpdatePlan(plan.id)}
                        >
                          <EditOutlined /> {t("Edit")}
                        </Button>
                      </Col>
                    )}
                    {ability && ability.can("delete", "Plans") && (
                      <Col>
                        <Button
                          type="link"
                          danger
                          onClick={() => deletePlan(plan.id)}
                        >
                          <DeleteOutlined /> {t("Delete")}
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
Plans.acl = {
  subject: "Plans",
  action: "read",
};

export default Plans;

import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Upload } from "antd";
import axios from "axios";
import React from "react";
import { JSON_API } from "../services/Constants";

const Testpage = () => {
  const onFinish = async (values) => {
    console.log("Received values:", values);

    const formData = new FormData();
    formData.append("EnterpriseId", 1);
    formData.append("Logo", values.image[0].originFileObj);

    for (var key of formData.entries()) {
      console.log(key[0] + ", " + key[1]);
    }
    const imageResponse = await axios.post(
      `${JSON_API}/Enterprises/upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  };

  const customRequest = ({ file, onSuccess, onError }) => {
    if (file.type.startsWith("image/")) {
      setTimeout(() => {
        onSuccess(file);
      }, 1000);
    } else {
      onError(new Error("File type not allowed"));
    }
  };

  return (
    <Form
      name="image-upload-form"
      onFinish={onFinish}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 14 }}
    >
      <Form.Item
        name="image"
        label="Image"
        valuePropName="fileList"
        getValueFromEvent={(e) => e.fileList}
        rules={[
          {
            required: true,
            message: "Please upload an image!",
          },
        ]}
      >
        <Upload
          customRequest={customRequest}
          maxCount={1} // Allow only one file to be uploaded
          listType="picture"
        >
          <Button icon={<UploadOutlined />}>Upload Image</Button>
        </Upload>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 6, span: 14 }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Testpage;

import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  Card,
  DatePicker,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { CompanyContext } from "../../../contexts/CompanyContext";
import { JSON_API } from "../../../services/Constants";
import { message } from "antd";

function HyphothesisOfOrderbook(RevenueDetailId) {
  console.log("RevenueDetailId ", RevenueDetailId);
  let { t } = useTranslation();
  const { Company } = useContext(CompanyContext);
  const { Option } = Select;
  const { TextArea } = Input;

  const [form4] = Form.useForm();

  const [editingRow2, setEditingRow2] = useState(null);
  const [Hypotheses, setHypotheses] = useState([]);

  const [open, setOpen] = useState(false);
  const [openHyphothese, setopenHyphothese] = useState(false);

  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const [errorMessage, setErrorMessage] = useState(""); // État pour stocker le message d'erreur

  const [loading, setLoading] = useState(true);
  const showNameModal = (name) => {
    setSelectedName(name);
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedName(null);
  };

  //   useEffect(() => {
  //     getHypotheses();
  //   }, [Company.id]);

  useEffect(() => {
    if (RevenueDetailId) {
      getHypotheses(); // Appeler getHypotheses avec l'ID correct
    }
  }, [Company.id, RevenueDetailId]);

  const getHypotheses = async () => {
    try {
      console.log("Fetching hypotheses...");
      console.log("RevenueDetailId:", RevenueDetailId);

      const response = await axios.get(
        `${JSON_API}/Orders/${RevenueDetailId.RevenueDetailId}/hypotheses`
      );

      console.log("API response:", response);

      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if (!response.data) {
        throw new Error("No data received from the API");
      }

      console.log("Response data:", response.data);

      let hypothesesData;

      if (Array.isArray(response.data)) {
        hypothesesData = response.data;
      } else if (
        response.data.hypotheses &&
        Array.isArray(response.data.hypotheses)
      ) {
        hypothesesData = response.data.hypotheses;
      } else {
        throw new Error(
          "Unexpected data structure: hypotheses array not found"
        );
      }

      if (hypothesesData.length === 0) {
        console.log("No hypotheses data found");
        setHypotheses([]);
        return;
      }

      const sortedHypotheses = hypothesesData.sort((p1, p2) => {
        const year1 = parseInt(p1.beginsOnYear);
        const year2 = parseInt(p2.beginsOnYear);
        return year2 - year1; // Sort in descending order
      });

      console.log("Sorted hypotheses:", sortedHypotheses);

      setHypotheses(sortedHypotheses);
    } catch (err) {
      console.error("Error in getHypotheses:", err);
      setHypotheses([]);
    }
  };

  const handlehypoDelete = async (id) => {
    await axios
      .delete(`${JSON_API}/Orders/hypotheses/${id}`)

      .then(() => {
        getHypotheses();
        // messageApi.open({
        //   type: "success",
        //   content: t("Orderdeletedsuccessfully"),
        // });
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  const OnopenHyphothese = async (values) => {
    console.log("Received values of form: ", RevenueDetailId);

    const obj = {
      commandId: RevenueDetailId.RevenueDetailId,
      annualIncrease: values.annualIncrease,
      beginsOnYear: values.beginsOnYear.$y + "",
      beginsOnMonth: values.beginsOnMonth,
      hypothesis: values.hypothesis,
    };

    console.log("obj:", obj);
    await axios
      .post(`${JSON_API}/Orders/hypotheses`, obj)
      .then((res) => {
        console.log("res", res);
        console.log("objjj:", obj);
        getHypotheses();

        setOpen(!open);
        setErrorMessage(""); // Efface le message d'erreur si la requête est réussie
      })
      .catch((err) => {
        console.log(err);
        // Récupère le message d'erreur et l'affiche à l'utilisateur
        if (err.response && err.response.data && err.response.data.error) {
          // message.error(err.response.data.error);
          message.error(t("errorrr")); // Toast pour l'erreur spécifique
        } else {
          message.error("Une erreur est survenue. Veuillez réessayer."); // Toast générique
        }
      });

    setopenHyphothese(false);
  };

  //   const OnopenHyphothese = async (values) => {
  //     console.log("Received values of form: ", RevenueDetailId);

  //     const obj = {
  //       commandId: RevenueDetailId.RevenueDetailId,
  //       annualIncrease: values.annualIncrease,
  //       beginsOnYear: values.beginsOnYear.$y + "",
  //       beginsOnMonth: values.beginsOnMonth,
  //       hypothesis: values.hypothesis,
  //     };

  //     console.log("obj:", obj);
  //     await axios
  //       .post(`${JSON_API}/Orders/hypotheses`, obj)
  //       .then((res) => {
  //         console.log("res", res);
  //         console.log("objjj:", obj);
  //         getHypotheses();

  //         setOpen(!open);
  //         //  getData();

  //         // setcountry(res.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //     setopenHyphothese(false);
  //   };

  const columnsHyphothese = [
    {
      title: <span style={{ textAlign: "center" }}>{t("Year")}</span>,
      dataIndex: "beginsOnYear",
      align: "center",
      sorter: (a, b) => a.beginsOnYear - b.beginsOnYear,
      render: (text, record) => {
        if (editingRow2 === record.id) {
          return (
            <Form.Item
              name="year"
              rules={[
                {
                  required: true,
                  message: `${t("Pleaseinputtheyear")}`,
                },
              ]}
            >
              <DatePicker disabled picker="year" />
            </Form.Item>
          );
        } else {
          return (
            <div style={{ textAlign: "right" }}>{record.beginsOnYear}</div>
          );
        }
      },
    },
    {
      title: <span style={{ textAlign: "center" }}>{t("AnnualIncrease")}</span>,
      dataIndex: "annualIncrease",
      align: "center",
      render: (text, record) => {
        console.log("record", text);
        if (editingRow2 === record.id) {
          return (
            <Form.Item
              name="annualIncrease"
              rules={[
                {
                  required: true,
                  message: `${t("PleaseinputtheAnnualIncrease")}`,
                },
              ]}
            >
              <InputNumber
                min={0}
                max={100}
                size={"large"}
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace("%", "")}
              />
            </Form.Item>
          );
        } else {
          return (
            <div style={{ textAlign: "right" }}>{record.annualIncrease}%</div>
          );
        }
      },
    },
    {
      title: <span style={{ textAlign: "center" }}>{t("BeginOn")}</span>,
      dataIndex: "beginsOnMonth",
      align: "center",
      render: (text, record) => {
        if (editingRow2 === record.id) {
          return (
            <Form.Item
              name="begin"
              value="beginmonth"
              rules={[
                {
                  required: true,
                  message: `${t("PleaseinputtheBeginMonth")}`,
                },
              ]}
            >
              <Select
                placeholder={t("Selectmonth")}
                size={"large"}
                style={{
                  width: "100%",
                }}
              >
                {[...Array(12)].map((_, i) => (
                  <Option key={i + 1} value={i + 1}>
                    {dayjs().month(i).format("MMMM")}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          );
        } else {
          return `${record.beginsOnMonth}/${record.beginsOnYear}`;
        }
      },
    },
    {
      title: <span style={{ textAlign: "center" }}>{t("Hyphothesis")}</span>,
      dataIndex: "hypothesis",
      align: "center",
      render: (text, record) => {
        if (editingRow2 === record.id) {
          return (
            <Form.Item
              name="HyphotheseofGl"
              rules={[
                {
                  required: true,
                  message: `${t("PleaseinputtheHyphothesis")}`,
                },
              ]}
            >
              <Input label={t("Hyphothesis")} />
            </Form.Item>
          );
        } else {
          return (
            <div
              style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
              onClick={() => showNameModal(text)}
            >
              {text}
            </div>
          );
        }
      },
    },
    {
      title: "Actions",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          {editingRow2 === record.id ? (
            <>
              <Button type="link" onClick={() => setEditingRow2(null)}>
                {t("cancel")}
              </Button>
              <Button type="link" htmlType="submit">
                {t("save")}
              </Button>
            </>
          ) : (
            <>
              <Popconfirm
                type="link"
                onClick={() => {
                  setEditingRow2(record.id);
                  form4.setFieldsValue({
                    year: dayjs(record.beginsOnYear),
                    annualIncrease: record.annualIncrease,
                    begin: record.beginsOnMonth,
                    HyphotheseofGl: record.hypothesis,
                  });
                }}
              >
                <a>{t("Edit")}</a>
              </Popconfirm>
              <Popconfirm
                title={t("deleterowHypo")}
                cancelText={t("no")}
                okText={t("yes")}
                onConfirm={() => handlehypoDelete(record.id)}
              >
                <a style={{ marginLeft: ".5rem" }}>{t("Delete")}</a>
              </Popconfirm>
            </>
          )}
        </Space>
      ),
    },
  ];

  const onFinishEditHypo = async (e) => {
    const obj = {
      id: editingRow2,
      annualIncrease: e.annualIncrease,
      beginsOnYear: e.year.$y + "",
      beginsOnMonth: e.begin,
      hypothesis: e.HyphotheseofGl,
    };
    console.log("updated data:", obj);

    await axios
      .put(`${JSON_API}/Orders/hypotheses`, obj)
      .then((res) => {
        setEditingRow2(null);
        getHypotheses();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const CollectionCreateHyphothesis = ({
    openHyphothese,
    OnopenHyphothese,
    onCancel,
  }) => {
    return (
      <Modal
        forceRender
        open={openHyphothese}
        title={t("CreateHypothesisss")}
        okText={t("save")}
        cancelText={t("cancel")}
        onCancel={onCancel}
        onOk={() => {
          form2
            .validateFields()
            .then((values) => {
              form2.resetFields();
              OnopenHyphothese(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form
          form={form2}
          // layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
        >
          <Form.Item
            // value={nom}
            name="beginsOnYear"
            label={t("Year")}
            rules={[
              {
                required: true,
                message: `${t("Pleaseinputtheyear")}`,
              },
            ]}
          >
            <DatePicker
              placeholder={t("Selectyear")}
              picker="year"
              style={{
                width: "60%",
                textAlign: "center",
              }}
              size={"large"}
            />
          </Form.Item>
          <Form.Item
            // value={nom}
            name="annualIncrease"
            label={t("AnnualIncrease")}
            rules={[
              {
                required: true,
                message: `${t("PleaseinputtheAnnualIncrease")}`,
              },
            ]}
          >
            <InputNumber
              // disabled={SHselected}
              min={0}
              max={100}
              size={"large"}
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace("%", "")}
              // onChange={e=>setShareHolderShares(e)}
            />
          </Form.Item>

          <Form.Item
            // value={nom}
            name="beginsOnMonth"
            value="begin"
            label={t("BeginOnMonth")}
            rules={[
              {
                required: true,
                message: `${t("PleaseinputtheBeginMonth")}`,
              },
            ]}
          >
            <Select
              placeholder={t("Selectmonth")}
              // onChange={handleStartPeriodChange}
              size={"large"}
              // style={{
              //   width: "50%",
              // }}
            >
              <Option value={1}>{t("January")}</Option>
              <Option value={2}>{t("February")}</Option>
              <Option value={3}>{t("March")}</Option>
              <Option value={4}>{t("April")}</Option>
              <Option value={5}>{t("May")}</Option>
              <Option value={6}>{t("June")}</Option>
              <Option value={7}>{t("July")}</Option>
              <Option value={8}>{t("August")}</Option>
              <Option value={9}>{t("September")}</Option>
              <Option value={10}>{t("October")}</Option>
              <Option value={11}>{t("November")}</Option>
              <Option value={12}>{t("December")}</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="hypothesis"
            value="hypo"
            label={t("Hyphothesis")}
            rules={[
              {
                required: true,
                message: `${t("PleaseinputtheHyphothesis")}`,
              },
            ]}
          >
            <TextArea rows={2}></TextArea>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  return (
    <Card bordered={false} className="header-solid mb-24">
      <Row justify="end" gutter={[16, 16]}>
        <Space
          style={{
            display: "flex",
            marginBottom: 8,
          }}
          align="baseline"
        >
          <Button
            className="Create-button"
            // disabled={RowGlaccountID ? false : true}
            type="primary"
            onClick={() => {
              setopenHyphothese(true);
            }}
            style={{
              textAlign: "right",
            }}
          >
            {t("CreateanewHyphothesis")}
          </Button>
        </Space>
      </Row>

      <CollectionCreateHyphothesis
        openHyphothese={openHyphothese}
        OnopenHyphothese={OnopenHyphothese}
        onCancel={() => {
          setopenHyphothese(false);
          form2.resetFields();
          // onAddChamp();
        }}
      />

      <Form form={form4} onFinish={onFinishEditHypo}>
        <div style={{ overflowX: "auto", backgroundColor: "white" }}>
          <Table
            // loading={loading}
            locale={{
              emptyText: <Empty description={t("nodata")} />,
            }}
            bordered
            columns={columnsHyphothese}
            dataSource={Hypotheses}
          />
        </div>
        <Modal
          title={t("detail")}
          visible={isModalVisible}
          onOk={closeModal}
          onCancel={closeModal}
        >
          <p>{selectedName}</p>
        </Modal>
      </Form>
    </Card>
  );
}

HyphothesisOfOrderbook.acl = {
  subject: "Hypothesis",
  action: "read",
};

export default HyphothesisOfOrderbook;

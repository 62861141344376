export const summary = (Company, Liability, count, field) => {
  const items = [];
  if (!count) return;
  const startingMonthIndex = Company.startPeriod - 1;

  let totalmontant = 0;
  let totalbudget = 0;
  let totalfevrierbudget = 0;
  let totalMarsbudget = 0.0;
  let totalavrilbudget = 0;
  let totalmaibudget = 0;
  let totalJuinbudget = 0.0;
  let totaljuilletbudget = 0;
  let totalaoutbudget = 0;
  let totalseptembrebudget = 0;
  let totaloctobrebudget = 0;
  let totalnovemberbudget = 0;
  let totaldecembrebudget = 0;
  let totaltotal = 0;

  Liability?.map((e) => {
    totalmontant = count[0] ? e[field][0] / count[0] : 0;
    totalbudget += totalmontant;

    totalmontant = count[1] ? e[field][1] / count[1] : 0;
    totalfevrierbudget += totalmontant;

    totalmontant = count[2] ? e[field][2] / count[2] : 0;
    totalMarsbudget += totalmontant;
    totalmontant = count[3] ? e[field][3] / count[3] : 0;
    totalavrilbudget += totalmontant;
    totalmontant = count[4] ? e[field][4] / count[4] : 0;
    totalmaibudget += totalmontant;
    totalmontant = count[5] ? e[field][5] / count[5] : 0;
    totalJuinbudget += totalmontant;
    totalmontant = count[6] ? e[field][6] / count[6] : 0;
    totaljuilletbudget += totalmontant;
    totalmontant = count[7] ? e[field][7] / count[7] : 0;
    totalaoutbudget += totalmontant;
    totalmontant = count[8] ? e[field][8] / count[8] : 0;
    totalseptembrebudget += totalmontant;
    totalmontant = count[9] ? e[field][9] / count[9] : 0;
    totaloctobrebudget += totalmontant;
    totalmontant = count[10] ? e[field][10] / count[10] : 0;
    totalnovemberbudget += totalmontant;
    totalmontant = count[11] ? e[field][11] / count[11] : 0;

    totaldecembrebudget += totalmontant;
    totalmontant = e.totalBudget;
    totaltotal += totalmontant;
  });

  const months = [
    totalbudget,
    totalfevrierbudget,
    totalMarsbudget,
    totalavrilbudget,
    totalmaibudget,
    totalJuinbudget,
    totaljuilletbudget,
    totalaoutbudget,
    totalseptembrebudget,
    totaloctobrebudget,
    totalnovemberbudget,
    totaldecembrebudget,
  ];
  for (let i = 0; i < months.length; i++) {
    const monthIndex = (i + startingMonthIndex) % months.length;
    const monthName = months[monthIndex];
    items.push({
      monthIndex,
      monthName: monthName.toFixed(0),
    });

    // const startingMonthIndex = Company.startPeriod - 1;

    // let totalmontant = 0;
    // let totalbudget = 0;
    // let totalfevrierbudget = 0;
    // let totalMarsbudget = 0.0;
    // let totalavrilbudget = 0;
    // let totalmaibudget = 0;
    // let totalJuinbudget = 0.0;
    // let totaljuilletbudget = 0;
    // let totalaoutbudget = 0;
    // let totalseptembrebudget = 0;
    // let totaloctobrebudget = 0;
    // let totalnovemberbudget = 0;
    // let totaldecembrebudget = 0;
    // let totaltotal = 0;

    // let totalbudgetcount = 0;
    // let totalfevrierbudgetcount = 0;
    // let totalMarsbudgetcount = 0;
    // let totalavrilbudgetcount = 0;
    // let totalmaibudgetcount = 0;
    // let totalJuinbudgetcount = 0;
    // let totaljuilletbudgetcount = 0;
    // let totalaoutbudgetcount = 0;
    // let totalseptembrebudgetcount = 0;
    // let totaloctobrebudgetcount = 0;
    // let totalnovemberbudgetcount = 0;
    // let totaldecembrebudgetcount = 0;

    // Liability?.map((e) => {
    //   //console.log(e.budgets[1]),
    //   totalmontant = e[field][0];

    //   if (totalmontant != 0) {
    //     totalbudgetcount += 1;
    //   }
    //   totalbudget += totalmontant;
    //   totalmontant = e[field][1];
    //   if (totalmontant != 0) {
    //     totalfevrierbudgetcount += 1;
    //   }
    //   totalfevrierbudget += totalmontant;
    //   totalmontant = e[field][2];
    //   if (totalmontant != 0) {
    //     totalMarsbudgetcount += 1;
    //   }
    //   totalMarsbudget += totalmontant;
    //   totalmontant = e[field][3];
    //   if (totalmontant != 0) {
    //     totalavrilbudgetcount += 1;
    //   }
    //   totalavrilbudget += totalmontant;
    //   totalmontant = e[field][4];
    //   if (totalmontant != 0) {
    //     totalmaibudgetcount += 1;
    //   }
    //   totalmaibudget += totalmontant;
    //   totalmontant = e[field][5];
    //   if (totalmontant != 0) {
    //     totalJuinbudgetcount += 1;
    //   }
    //   totalJuinbudget += totalmontant;
    //   totalmontant = e[field][6];
    //   if (totalmontant != 0) {
    //     totaljuilletbudgetcount += 1;
    //   }
    //   totaljuilletbudget += totalmontant;
    //   totalmontant = e[field][7];
    //   if (totalmontant != 0) {
    //     totalaoutbudgetcount += 1;
    //   }
    //   totalaoutbudget += totalmontant;
    //   totalmontant = e[field][8];
    //   if (totalmontant != 0) {
    //     totalseptembrebudgetcount += 1;
    //   }
    //   totalseptembrebudget += totalmontant;
    //   totalmontant = e[field][9];
    //   if (totalmontant != 0) {
    //     totaloctobrebudgetcount += 1;
    //   }
    //   totaloctobrebudget += totalmontant;
    //   totalmontant = e[field][10];
    //   if (totalmontant != 0) {
    //     totalnovemberbudgetcount += 1;
    //   }
    //   totalnovemberbudget += totalmontant;
    //   totalmontant = e[field][11];
    //   if (totalmontant != 0) {
    //     totaldecembrebudgetcount += 1;
    //   }
    //   totaldecembrebudget += totalmontant;
    //   totalmontant = e.totalBudget;
    //   totaltotal += totalmontant;
    // });

    // const months = [
    //   totalbudget,
    //   totalfevrierbudget,
    //   totalMarsbudget,
    //   totalavrilbudget,
    //   totalmaibudget,
    //   totalJuinbudget,
    //   totaljuilletbudget,
    //   totalaoutbudget,
    //   totalseptembrebudget,
    //   totaloctobrebudget,
    //   totalnovemberbudget,
    //   totaldecembrebudget,
    // ];
    // const monthscount = [
    //   totalbudgetcount,
    //   totalfevrierbudgetcount,
    //   totalMarsbudgetcount,
    //   totalavrilbudgetcount,
    //   totalmaibudgetcount,
    //   totalJuinbudgetcount,
    //   totaljuilletbudgetcount,
    //   totalaoutbudgetcount,
    //   totalseptembrebudgetcount,
    //   totaloctobrebudgetcount,
    //   totalnovemberbudgetcount,
    //   totaldecembrebudgetcount,
    // ];
    // for (let i = 0; i < months.length; i++) {
    //   const monthIndex = (i + startingMonthIndex) % months.length;
    //   const monthName = months[monthIndex];
    //   const monthNamecount = monthscount[monthIndex];

    //   items.push({
    //     monthIndex,
    //     monthName: (monthName / monthNamecount).toFixed(0),
    //   });
  }

  return items;
};

export const countmonth = (data) => {
  let monthscount = {
    budget: {},
    reals: {},
    performance: {},
  };

  const calculatePerType = (e, type) => {
    console.log("calculate per type", e, type);
    e.map((c, i) => {
      if (Math.abs(c) >= 1) {
        if (monthscount[type][i]) {
          monthscount[type][i] += 1;
        } else {
          monthscount[type][i] = 1;
        }
      }
    });
  };

  data.map((e) => {
    calculatePerType(e.monthlyBudgets, "budget");
    calculatePerType(e.monthlyReals, "reals");
    calculatePerType(e.monthlyPerformances, "performance");
  });
  return monthscount;
};

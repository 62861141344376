import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  Card,
  DatePicker,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { CompanyContext } from "../contexts/CompanyContext";
import { JSON_API } from "../services/Constants";
import { useAbility } from "../contexts/abilityContext";
import { DeleteFilled, EditFilled, PlusCircleFilled } from "@ant-design/icons";

function HyphotheseofGl() {
  let { t } = useTranslation();
  const { ability } = useAbility();
  const canUpdate = ability.can("update", "Hypothesis");
  const canDelete = ability.can("delete", "Hypothesis");
  const { Company } = useContext(CompanyContext);
  const { Option } = Select;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [form4] = Form.useForm();

  const [statementtype, setStatementType] = useState([{}]);
  const [RowGlaccountID, setRowGlaccountId] = useState(null);
  const [HypothesesDataSource, sethypothesesDataSource] = useState(null);

  const [disabledYears, setDisabledYears] = useState([]);

  const [editingRow, setEditingRow] = useState(null);
  const [editingRow2, setEditingRow2] = useState(null);

  const [open, setOpen] = useState(false);
  const [openHyphothese, setopenHyphothese] = useState(false);
  const [DataSource, setDataSource] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const [currentPage, setCurrentPage] = useState(() => {
    return Number(localStorage.getItem("currentPageHyphothesisGL")) || 1;
  });
  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    localStorage.setItem("currentPageHyphothesisGL", pagination.current);
  };
  const showNameModal = (name) => {
    setSelectedName(name);
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedName(null);
  };

  const { TextArea } = Input;

  useEffect(() => {
    getData();
  }, [Company.id]);

  const getGlaccount = async () => {
    await axios
      .get(`${JSON_API}/GLAccount/${RowGlaccountID}`)
      .then((response) => {
        console.log("GLAccount:", response.data);
        const data = response.data;
        setDisabledYears([]);
        data.hypotheses.map((o) => {
          o.key = o.id;
          setDisabledYears((current) => [...current, Number(o.beginsOnYear)]);
        });

        console.log("data:", data);

        // setDataSource(response.data);
        sethypothesesDataSource(data.hypotheses);
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  };

  const getData = async () => {
    await axios
      .get(`${JSON_API}/GLAccount/Enterprise/${Company.id}`)
      .then((response) => {
        const newState = response.data.map((obj) => {
          // 👇️ if id equals 2, update country property

          return { ...obj, key: obj.id };
        });
        setDataSource(newState);
        console.log(newState);
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        setDataSource(null);

        console.log(error.config);
      });

    await axios
      .get(`${JSON_API}/FinancialStatementType`)

      .then((res) => {
        console.log(res);
        setStatementType(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handletype = async (e) => {
    sethypothesesDataSource(null);
    setDataSource(null);
    console.log(e);

    if (e != 0) {
      await axios
        .get(`${JSON_API}/GLAccount/FinancialStatementType/${Company.id}/${e}`)

        .then((res) => {
          console.log(res);
          const newState = res.data.map((obj) => {
            // 👇️ if id equals 2, update country property

            return { ...obj, key: obj.id };
          });
          setDataSource(newState);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      getData();
    }
  };

  const disabledDate = (current) => {
    return disabledYears.some((year) => current.year() === year);
  };

  const handlehypoDelete = async (id) => {
    await axios
      .delete(`${JSON_API}/Hypothesis/${id}`)
      .then(() => {
        getData();
        getGlaccount();
        // messageApi.open({
        //   type: "success",
        //   content: t("Orderdeletedsuccessfully"),
        // });
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };
  const OnopenHyphothese = async (values) => {
    const obj = {
      glAccountId: RowGlaccountID,
      annualIncrease: values.annualIncrease,
      beginsOnYear: values.beginsOnYear.$y + "",
      beginsOnMonth: values.beginsOnMonth,
      hypothesis: values.hypothesis,
    };

    console.log("obj:", obj);
    await axios
      .post(`${JSON_API}/Hypothesis`, obj)
      .then((res) => {
        getData();
        getGlaccount();
        // setcountry(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setopenHyphothese(false);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const array = selectedRows[0].hypotheses;
      setDisabledYears([]);
      array.map((o) => {
        setDisabledYears((current) => [...current, Number(o.beginsOnYear)]);
      });
      setRowGlaccountId(selectedRowKeys[0]);

      if (array.length != 0) {
        array.map((o) => {
          o.key = o.id;
        });
      }

      sethypothesesDataSource(array.length != 0 && array);
    },
  };

  const columnsGLAccount = [
    {
      title: t("GLnumber"),
      dataIndex: "glNumber",
      align: "center",

      render: (text, record) => {
        if (editingRow === record.key) {
          return (
            <Form.Item
              name="GLNumber"
              rules={[
                {
                  required: true,
                  message: "Please enter your ",
                },
              ]}
            >
              <Input defaultValue="GL Number" />
            </Form.Item>
          );
        } else {
          return <div style={{ textAlign: "right" }}>{text}</div>;
        }
      },
    },
    {
      title: t("Relatedgifi"),
      dataIndex: "financialStatementTypeGIFI",
      align: "center",
      render: (text, record) => {
        // if (editingRow === record.key) {
        //   return (
        //     <Form.Item
        //       name="gifi"
        //       rules={[
        //         {
        //           required: true,
        //           message: "Please enter your ",
        //         },
        //       ]}
        //     >
        //      <Select notFoundContent={t("nodata")}
        //       size="large"
        //       showSearch
        //       placeholder="Select a Financial Statement Type"
        //     >
        //       {statementtype.map((o) => {
        //       return <Option value={o.gifi}>{"(GIFI: "+ o.gifi +") "+ o.description}</Option>;
        //     })}
        //     </Select>
        //     </Form.Item>
        //   );
        // } else {
        return <div style={{ textAlign: "right" }}>{text}</div>;
        // }
      },
    },
    {
      title: t("Description"),
      dataIndex: "description",
      align: "center",

      render: (text, record) => {
        if (editingRow === record.key) {
          return (
            <Form.Item
              name="Description"
              rules={[
                {
                  required: true,
                  message: "Please enter your ",
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        } else {
          return (
            <div
              style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
              onClick={() => showNameModal(text)}
            >
              {text}
            </div>
          );
        }
      },
    },
    // {
    //   title:"Actions",
    //   align: "center",

    //   render: (_, record) =>
    //   DataSource.length >= 1 ? (
    //     <Space size="middle">
    //       {editingRow === record.key ? (
    //         <>
    //           <Button type="link" onClick={() => setEditingRow(null)}>
    //             Cancel
    //           </Button>
    //           <Button type="link" htmlType="submit">
    //             Save
    //           </Button>
    //         </>
    //       ) : (
    //         <>
    //           <Popconfirm
    //             type="link"
    //             onClick={() => {
    //               setEditingRow(record.key);
    //               form3.setFieldsValue({
    //                 GLNumber: record.glNumber,
    //                 gifi: record.financialStatementTypeGIFI,
    //                 Description: record.description,
    //               });
    //             }}
    //           >
    //             <a>Edit</a>
    //           </Popconfirm>
    //         </>
    //       )}
    //     </Space>
    //   ) : null,

    // },
  ];
  const columnsHyphothese = [
    {
      title: t("Year"),
      dataIndex: "beginsOnYear",
      align: "center",
      render: (text, record) => {
        if (editingRow2 === record.key) {
          return (
            <Form.Item
              name="year"
              rules={[
                {
                  required: true,
                  message: t("Selectyear"),
                },
              ]}
            >
              <DatePicker disabled picker="year" />
            </Form.Item>
          );
        } else {
          return <div style={{ textAlign: "center" }}>{text}</div>;
        }
      },
    },
    {
      title: t("Annualincrease"),
      dataIndex: "annualIncrease",
      align: "center",

      render: (text, record) => {
        if (editingRow2 === record.key) {
          return (
            <Form.Item
              name="annualIncrease"
              rules={[
                {
                  required: true,
                  message: t("Pleaseinputtheannualincrease"),
                },
              ]}
            >
              <InputNumber
                // disabled={SHselected}
                min={0}
                max={100}
                size={"large"}
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace("%", "")}
                // onChange={e=>setShareHolderShares(e)}
              />
            </Form.Item>
          );
        } else {
          return <div style={{ textAlign: "right" }}>{text}%</div>;
        }
      },
    },
    {
      title: t("Beginon"),
      dataIndex: "beginsOnMonth",
      align: "center",
      render: (text, record) => {
        if (editingRow2 === record.key) {
          return (
            <Form.Item
              name="begin"
              rules={[
                {
                  required: true,
                  message: t("Selectmonth"),
                },
              ]}
            >
              <Select
                notFoundContent={t("nodata")}
                placeholder={t("Selectmonth")}
                // onChange={handleStartPeriodChange}
                size={"large"}
                // style={{
                //   width: "50%",
                // }}
              >
                <Option value={1}>{t("January")}</Option>
                <Option value={2}>{t("February")}</Option>
                <Option value={3}>{t("March")}</Option>
                <Option value={4}>{t("April")}</Option>
                <Option value={5}>{t("May")}</Option>
                <Option value={6}>{t("June")}</Option>
                <Option value={7}>{t("July")}</Option>
                <Option value={8}>{t("August")}</Option>
                <Option value={9}>{t("September")}</Option>
                <Option value={10}>{t("October")}</Option>
                <Option value={11}>{t("November")}</Option>
                <Option value={12}>{t("December")}</Option>
              </Select>
            </Form.Item>
          );
        } else {
          return record.beginsOnMonth + "/" + record.beginsOnYear;
        }
      },
    },
    {
      title: t("Hypotheses"),
      dataIndex: "hypothesis",
      align: "center",
      render: (text, record) => {
        if (editingRow2 === record.key) {
          return (
            <Form.Item
              name="HyphotheseofGl"
              rules={[
                {
                  required: true,
                  message: t("Pleaseinputthehypothesis"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        } else {
          return (
            <div
              style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
              onClick={() => showNameModal(text)}
            >
              {text}
            </div>
          );
        }
      },
    },
  ];

  if (canDelete || canUpdate) {
    columnsHyphothese.push({
      title: "Actions",
      align: "center",
      render: (_, record) => {
        if (HypothesesDataSource.length < 1) {
          return null;
        }

        return (
          <Space size="middle">
            {editingRow2 === record.key ? (
              <>
                <Button type="link" onClick={() => setEditingRow2(null)}>
                  {t("cancel")}
                </Button>
                <Button type="link" htmlType="submit">
                  {t("save")}
                </Button>
              </>
            ) : (
              <>
                {canUpdate && (
                  <Button
                    type="link"
                    icon={<EditFilled />}
                    onClick={() => {
                      setEditingRow2(record.key);
                      form4.setFieldsValue({
                        year: dayjs(record.beginsOnYear),
                        annualIncrease: record.annualIncrease,
                        begin: record.beginsOnMonth,
                        HyphotheseofGl: record.hypothesis,
                      });
                    }}
                  >
                    {t("edit")}
                  </Button>
                )}
                {canDelete && (
                  <Popconfirm
                    title={t("deleterow")}
                    cancelText={t("no")}
                    okText={t("yes")}
                    onConfirm={() => handlehypoDelete(record.id)}
                  >
                    <Button
                      type="link"
                      danger
                      icon={<DeleteFilled />}
                      style={{ marginLeft: ".5rem" }}
                    >
                      {t("Delete")}
                    </Button>
                  </Popconfirm>
                )}
              </>
            )}
          </Space>
        );
      },
    });
  }

  const onFinishEditHypo = async (e) => {
    const obj = {
      id: editingRow2,
      annualIncrease: e.annualIncrease,
      beginsOnYear: e.year.$y + "",
      beginsOnMonth: e.begin,
      hypothesis: e.HyphotheseofGl,
    };
    console.log("updated data:", obj);

    await axios
      .put(`${JSON_API}/Hypothesis`, obj)
      .then((res) => {
        getGlaccount();
        getData();
        setEditingRow2(null);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onFinishEdit = async (e) => {
    const obj = {
      id: editingRow,
      glNumber: e.GLNumber,
      description: e.Description,
      enterpriseId: Company.id,
      // financialStatementTypeGIFI: e.gifi
    };
    console.log("updated data:", obj);

    await axios
      .put(`${JSON_API}/GLAccount/glaccount/UpdateGLAccount`, obj)
      .then((res) => {
        getData();
        setEditingRow(null);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const Submit = async (e) => {
    const formData = {
      glNumber: e.glNumber,
      description: e.description,
      enterpriseId: Company.id,
      financialStatementTypeGIFI: e.financialStatementTypeGIFI,
    };

    console.log(formData);
    await axios
      .post(`${JSON_API}/GLAccount`, formData)
      .then((res) => {
        getData();
        console.log(res.data);
        setOpen(!open);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const CollectionCreateGlAcount = ({ open, onCreate, onCancel }) => {
    return (
      <Modal
        forceRender
        open={open}
        width={650}
        title="Create new GL Account "
        okText="Create"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onCreate(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form form={form} name="form_in_modal">
          <Form.Item
            //  value={nom}
            name="glNumber"
            label="GL Number"
            rules={[
              {
                required: true,
                message: "Please input the title of GL Number",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            // value={nom}
            name="financialStatementTypeGIFI"
            label="Type"
            rules={[
              {
                required: true,
                message: "Please input the title of type!",
              },
            ]}
          >
            <Select
              notFoundContent={t("nodata")}
              size="large"
              placeholder="Select a Financial Statement Type"
              style={{ width: 550 }}
            >
              {statementtype.map((o) => {
                return (
                  <Option value={o.gifi}>
                    {"(GIFI: " + o.gifi + ") " + o.description}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            // value={nom}
            name="description"
            label="Description"
            rules={[
              {
                required: true,
                message: "Please input the title of Description ",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const CollectionCreateHyphothesis = ({
    openHyphothese,
    OnopenHyphothese,
    onCancel,
  }) => {
    return (
      <Modal
        forceRender
        open={openHyphothese}
        title={t("Createanewhypothesis")}
        okText={t("create")}
        cancelText={t("cancel")}
        onCancel={onCancel}
        onOk={() => {
          form2
            .validateFields()
            .then((values) => {
              form2.resetFields();
              OnopenHyphothese(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form
          form={form2}
          // layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
        >
          <Form.Item
            // value={nom}
            name="beginsOnYear"
            label={t("Year")}
            rules={[
              {
                required: true,
                message: t("Pleaseinputtheyear"),
              },
            ]}
          >
            <DatePicker
              placeholder={t("Selectyear")}
              picker="year"
              disabledDate={disabledDate}
              style={{
                width: "50%",
                textAlign: "center",
              }}
              size={"large"}
            />
          </Form.Item>
          <Form.Item
            // value={nom}
            name="annualIncrease"
            label={t("Annualincrease")}
            rules={[
              {
                required: true,
                message: t("Pleaseinputtheannualincrease"),
              },
            ]}
          >
            <InputNumber
              // disabled={SHselected}
              min={0}
              max={100}
              size={"large"}
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace("%", "")}
              // onChange={e=>setShareHolderShares(e)}
            />
          </Form.Item>

          <Form.Item
            // value={nom}
            name="beginsOnMonth"
            label={t("Beginon")}
            rules={[
              {
                required: true,
                message: t("Selectmonth"),
              },
            ]}
          >
            <Select
              notFoundContent={t("nodata")}
              placeholder={t("Selectmonth")}
              // onChange={handleStartPeriodChange}
              size={"large"}
              // style={{
              //   width: "50%",
              // }}
            >
              <Option value={1}>{t("January")}</Option>
              <Option value={2}>{t("February")}</Option>
              <Option value={3}>{t("March")}</Option>
              <Option value={4}>{t("April")}</Option>
              <Option value={5}>{t("May")}</Option>
              <Option value={6}>{t("June")}</Option>
              <Option value={7}>{t("July")}</Option>
              <Option value={8}>{t("August")}</Option>
              <Option value={9}>{t("September")}</Option>
              <Option value={10}>{t("October")}</Option>
              <Option value={11}>{t("November")}</Option>
              <Option value={12}>{t("December")}</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="hypothesis"
            label={t("Hypotheses")}
            rules={[
              {
                required: true,
                message: t("Pleaseinputthehypothesis"),
              },
            ]}
          >
            <TextArea rows={2}></TextArea>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  return (
    <Card
      bordered={false}
      className="header-solid mb-24"
      title={
        <h3 className="font-semibold" textAlign="center">
          {t("GLAccountsTheirHyphothesis")}
        </h3>
      }
    >
      <Row justify="end" gutter={[16, 16]}>
        <Space
          style={
            {
              // display: 'flex',
              // marginBottom: 8,
            }
          }
          align="baseline"
        >
          <CollectionCreateGlAcount
            open={open}
            onCreate={Submit}
            onCancel={() => {
              setOpen(false);
              // onAddChamp();
            }}
          />
          <Form.Item
            style={{ marginBottom: 8 }}
            name="type"
            label={t("Filterbytype")}
          >
            <Select
              notFoundContent={t("nodata")}
              showSearch
              onChange={(e) => handletype(e)}
              style={{ width: 550 }}
              placeholder={t("Selectafinancialstatementtype")}
              size="large"
            >
              <Option value={0}>{t("Alltypes")}</Option>
              {statementtype.map((o) => {
                return (
                  <Option value={o.gifi}>
                    {"(" + t("Gifi") + ": " + +o.gifi + ") " + o.description}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Space>
      </Row>
      <Form form={form3} onFinish={onFinishEdit}>
        <div style={{ overflowX: "auto", backgroundColor: "white" }}>
          <Table
            locale={{
              emptyText: <Empty description={t("nodata")} />,
            }}
            bordered
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
            pagination={{ current: currentPage }}
            onChange={handleTableChange}
            columns={columnsGLAccount}
            dataSource={DataSource}
          />
        </div>
      </Form>

      <h1
        style={{
          width: 80,
          height: 35,
          marginLeft: "2rem",
        }}
      >
        {t("Hypotheses")}
      </h1>
      <Row justify="end" gutter={[16, 16]}>
        <Space
          style={{
            display: "flex",
            marginBottom: 8,
          }}
          align="baseline"
        >
          {ability.can("create", "Hypothesis") && (
            <Button
              className="Create-button"
              disabled={RowGlaccountID ? false : true}
              type="primary"
              icon={<PlusCircleFilled />}
              onClick={() => {
                setopenHyphothese(true);
              }}
              style={{
                textAlign: "right",
              }}
            >
              {t("Createanewhypothesis")}
            </Button>
          )}
        </Space>
      </Row>

      <CollectionCreateHyphothesis
        openHyphothese={openHyphothese}
        OnopenHyphothese={OnopenHyphothese}
        onCancel={() => {
          setopenHyphothese(false);
          form2.resetFields();
          // onAddChamp();
        }}
      />

      <Form form={form4} onFinish={onFinishEditHypo}>
        <div style={{ overflowX: "auto", backgroundColor: "white" }}>
          <Table
            locale={{
              emptyText: <Empty description={t("nodata")} />,
            }}
            bordered
            columns={columnsHyphothese}
            dataSource={HypothesesDataSource}
          />
        </div>
        <Modal
          title={t("detail")}
          visible={isModalVisible}
          onOk={closeModal}
          onCancel={closeModal}
        >
          <p>{selectedName}</p>
        </Modal>
      </Form>
    </Card>
  );
}

HyphotheseofGl.acl = {
  subject: "Hypothesis",
  action: "read",
};

export default HyphotheseofGl;

import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusCircleOutlined,
  PicCenterOutlined,
  CloseCircleFilled,
  SaveFilled,
  CheckCircleFilled,
  DeleteFilled,
  PlusCircleFilled,
} from "@ant-design/icons";
import {
  Modal,
  DatePicker,
  Row,
  Col,
  Button,
  Card,
  Empty,
  Form,
  Input,
  Popconfirm,
  Space,
  Table,
  message,
  Spin,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import axios from "axios";
import { useTranslation } from "react-i18next";
import { CompanyContext } from "../contexts/CompanyContext";
import { JSON_API } from "../services/Constants";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useAbility } from "../contexts/abilityContext";
dayjs.extend(customParseFormat);

const SalesOpportunities = () => {
  const { ability } = useAbility();
  let { t } = useTranslation();
  const { Company } = useContext(CompanyContext);
  const [dataSource, setDataSource] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [editingRow, setEditingRow] = useState(null);
  const [form] = Form.useForm();
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);
  // const [year, setYear] = useState(dayjs().year());
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedName, setSelectedName] = useState(null);

  var date;

  useEffect(() => {
    const date = new Date().getFullYear();

    if (year == null) {
      setYear(date);
    }

    console.log("year" + date);

    getOpportunityBooks();
  }, [Company.id, year]);

  const getOpportunityBooks = async () => {
    setLoading(true);

    // Définir un délai de 2 secondes
    setTimeout(async () => {
      try {
        const response = await axios.get(
          `${JSON_API}/OpportunityBooks/enterprise/${Company.id}?year=${year}`
        );
        setDataSource(response.data);
      } catch (error) {
        setDataSource([]);
        message.error(t("Error while fetching opportunity books"));
      } finally {
        setLoading(false);
      }
    }, 1000); // 2000 ms = 2 secondes
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${JSON_API}/OpportunityBooks/${id}`);
      message.success(t("orderbookdeleted"));
      getOpportunityBooks();
    } catch (error) {
      message.error(t("Error while deleting opportunity book"));
    }
  };

  const showNameModal = (name) => {
    setSelectedName(name);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedName(null);
  };

  const defaultColumns = [
    {
      title: t("Name"),
      dataIndex: "name",
      width: "20%",
      align: "center",
      render: (text, record) => {
        if (editingRow === record.id) {
          return (
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: t("opportunitybooknamerequired"),
                },
              ]}
            >
              <Input style={{ maxWidth: "200px" }} />
            </Form.Item>
          );
        } else {
          return (
            <div
              style={{
                textAlign: "center",
                maxWidth: "200px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
              onClick={() => showNameModal(text)}
            >
              {text}
            </div>
          );
        }
      },
    },
    {
      title: t("creationdate"),
      dataIndex: "createdAt",
      align: "center",
      sorter: (a, b) => dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix(),
      sortDirections: ["descend", "ascend"],
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          {dayjs(record.createdAt).format("DD/MM/YYYY")}
        </div>
      ),
    },
    {
      title: t("Year"),
      dataIndex: "year",
      align: "center",
      sorter: (a, b) => a.year - b.year,
      sortDirections: ["descend", "ascend"],
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>{record.year}</div>
      ),
    },
    {
      title: "Total",
      dataIndex: "total",
      align: "center",
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>{record.total}</div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "operation",
      align: "center",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Space size="middle">
            {editingRow === record.id ? (
              <>
                <Button
                  icon={<CloseCircleFilled />}
                  danger
                  type="link"
                  onClick={() => setEditingRow(null)}
                >
                  {t("cancel")}
                </Button>
                <Button icon={<SaveFilled />} type="link" htmlType="submit">
                  {t("save")}
                </Button>
              </>
            ) : (
              <>
                {ability.can("update", "Opportunity Book") && (
                  <Popconfirm
                    type="link"
                    onClick={() => {
                      setEditingRow(record.id);
                      form.setFieldsValue({
                        name: record.name,
                      });
                    }}
                  >
                    <Button type="link">
                      <EditOutlined /> {t("edit")}
                    </Button>
                  </Popconfirm>
                )}
                {ability.can("delete", "Opportunity Book") && (
                  <Popconfirm
                    title={t("deleterow")}
                    onConfirm={() => handleDelete(record.id)}
                    cancelButtonProps={{
                      danger: true,
                      type: "primary",
                    }}
                    okText={
                      <>
                        <CheckCircleFilled /> {t("Delete")}
                      </>
                    }
                    cancelText={
                      <>
                        <CloseCircleFilled /> {t("Cancel")}
                      </>
                    }
                  >
                    <Button type="link" danger>
                      <DeleteFilled /> {t("Delete")}
                    </Button>
                  </Popconfirm>
                )}

                <Link
                  to={{
                    pathname: `/opportunitybook/${record.id}`,
                    state: { stateParam: record.id },
                  }}
                >
                  <Button type="link" icon={<EyeOutlined />}>
                    {t("details")}
                  </Button>
                </Link>
              </>
            )}
          </Space>
        ) : null,
    },
  ];

  const onFinish = async (values) => {
    try {
      const opportunityBook = {
        name: values.name,
        enterpriseId: Company.id,
        year: values.year.format("YYYY"),
      };

      console.log("opportunity Book to be Created:", opportunityBook);
      await axios.post(`${JSON_API}/OpportunityBooks`, opportunityBook);
      getOpportunityBooks();
      message.success(t("opportunitybookcreation"));
    } catch (error) {
      console.log("opportunity book creation Failed : ", error);
      message.error(t("opportunitybookcreationerror"));
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishEdit = async (values) => {
    const orderbookobj = {
      id: editingRow,
      name: values.name,
    };

    console.log("Opportunity edited: ", orderbookobj);

    try {
      await axios.put(`${JSON_API}/OpportunityBooks`, orderbookobj);
      getOpportunityBooks();
      console.log("Opportunity updated Successfully!");
      message.success(t("opportunitybookupdate"));
    } catch (error) {
      message.error(t("Error while updating opportunity book"));
    } finally {
      setEditingRow(null);
    }
  };

  const Duplicatebook = async () => {
    if (!year) {
      console.error("No year selected");
      message.warning(t("NoSelectedyear"));
      return;
    }
    try {
      await axios.post(
        `${JSON_API}/OpportunityBooks/duplicateLastOpportunityBook/${Company.id}?year=${year}`
      );
      getOpportunityBooks();
      console.log("OpportunityBook added Successfully!");
      message.success(t("opportunitybookcreation"));
    } catch (error) {
      message.error(t("Error while duplicating opportunity book"));
    }
  };
  const onChangee = (date, dateString) => {
    console.log(date, dateString);
    setYear(dateString);
  };

  return (
    <>
      <Card
        bordered={false}
        className="header-solid mb-24 stylish-card"
        title={<h3 className="font-semibold">{t("opportunitybook")}</h3>}
        extra={
          <DatePicker
            defaultValue={dayjs(String(year))}
            name="year"
            picker="year"
            placeholder={t("Selectyear")}
            className="stylish-datepicker"
            onChange={onChangee}
          />
        }
      >
        {ability.can("create", "Opportunity Book") && (
          <Form
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="stylish-form"
          >
            <Row align="middle" gutter={[16, 16]}>
              <Col xs={24} sm={12} md={6}>
                <Form.Item
                  label={t("Name")}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t("opportunitybooknamerequired"),
                    },
                  ]}
                >
                  <Input placeholder={t("EnternameOpportunity")} />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={6}>
                <Form.Item name="year" label={t("Year")}>
                  <DatePicker
                    placeholder={t("Selectyear")}
                    style={{ width: "100%" }}
                    picker="year"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={6}>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    icon={<PlusCircleFilled />}
                  >
                    {t("create")}
                  </Button>
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={6}>
                <Form.Item>
                  <Button
                    block
                    onClick={Duplicatebook}
                    icon={<PicCenterOutlined />}
                  >
                    {t("duplicatelastopportunitybook")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}

        <Form
          form={form}
          onFinish={onFinishEdit}
          className="stylish-table-form"
        >
          <Spin spinning={loading} tip={t("loading")}>
            <Table
              locale={{
                emptyText: <Empty description={t("nodata")} />,
              }}
              bordered
              dataSource={dataSource}
              columns={defaultColumns}
            />
          </Spin>
        </Form>
        <Modal
          title={t("Name")}
          visible={isModalVisible}
          onOk={closeModal}
          onCancel={closeModal}
        >
          <p>{selectedName}</p>
        </Modal>
      </Card>
    </>
  );
};

SalesOpportunities.acl = {
  subject: "Opportunity Book",
  action: "read",
};

export default SalesOpportunities;

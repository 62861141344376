import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import {
  ArrowLeftOutlined,
  DisconnectOutlined,
  LinkOutlined,
  PlusCircleOutlined,
  PlusCircleFilled,
  EditFilled,
  DeleteFilled,
  CloseCircleOutlined,
  SaveFilled,
} from "@ant-design/icons";
import {
  Button,
  Card,
  DatePicker,
  Empty,
  Form,
  Input,
  Modal,
  Popconfirm,
  Progress,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Tag,
  message,
} from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { CompanyContext } from "../contexts/CompanyContext";
import { JSON_API } from "../services/Constants";

import axios from "axios";
// import "./Tableau.css";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useAbility } from "../contexts/abilityContext";

dayjs.extend(customParseFormat);
const { TextArea } = Input;

const SelectBox = React.memo(({ value, options, onChange }) => {
  let { t } = useTranslation();

  return (
    <Select
      notFoundContent={t("nodata")}
      value={value}
      onChange={onChange}
      style={{ width: 470 }}
      options={options}
    />
  );
});

function StrategicPlans() {
  const history = useHistory();
  let { t } = useTranslation();
  const { ability } = useAbility();

  const [openActivity, setOpenActivity] = useState(false);
  const [openEditActivity, setOpenEditActivity] = useState(false);

  const [projectNumber, setProjectNumber] = useState("");
  const [predictedEndDate, setPredictedEndDate] = useState("");
  const [actualEndDate, setActualEndDate] = useState("");
  const [comments, setcomments] = useState("");
  const [priority, setPriority] = useState(null);
  const [activity, setActivity] = useState({});
  const [label, setLabel] = useState();
  const [deptdata, setdeptdata] = useState();
  const [deptid, setdeptid] = useState();

  const [activities, setActivities] = useState([]);
  const [steps, setSteps] = useState([]);
  const [step, setStep] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [disableBtn, setdisableBtn] = useState(true);
  const [plans, setPlans] = useState([]);
  const [activeKey, setActiveKey] = useState(null);
  const [plan, setPlan] = useState([]);
  const [planId, setPlanId] = useState(null);
  const [stepId, setStepId] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [employeeId, setEmployeeId] = useState(null);
  const [checkRow, setCheckRow] = useState(false);
  const year = useLocation().state.year;
  const departmentId = useLocation().state.departmentId;

  const [strategicTargets, setStrategicTargets] = useState([]);
  const [strategicTargetId, setStrategicTargetId] = useState(null);
  const [departmentName, setDepartmentName] = useState(null);

  const [spinner, setSpinner] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  const { Company } = useContext(CompanyContext);
  const { Option } = Select;
  const [form] = Form.useForm();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const showNameModal = (name) => {
    setSelectedName(name);
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedName(null);
  };

  const getDepartmentdata = async (e) => {
    await axios
      .get(`${JSON_API}/Departments/${e}`)
      .then((res) => {
        setDepartmentName(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPlans = async (e) => {
    await axios
      .get(`${JSON_API}/StrategicPlans/${departmentId}/${year}`)
      .then((res) => {
        setPlans(res.data);
        console.log("plans: ", res.data);

        if (e == 0) {
          const data = res.data[0];
          console.log("strategic plans du departement:", data);
          getSteps(data.id);
          setPlanId(data.id);
          setActiveKey(data.id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getdata = async () => {
    await axios
      .get(`${JSON_API}/EnterpriseDepartments/${departmentId}`)
      .then((res) => {
        console.log("departement de lentreprise:", res.data);
        getDepartmentdata(res.data.departmentId);
      })
      .catch((err) => {
        console.log(err);
      });

    await axios
      .get(`${JSON_API}/EnterpriseDepartments/${Company.id}/${year}`)
      .then((res) => {
        const person = res.data.find((p) => p.id === departmentId);
        setdeptdata(person);

        console.log("data dept,", person);
      })
      .catch((err) => {
        console.log(err);
      });

    await axios
      .get(
        `${JSON_API}/Employees/enterprise/filter?enterpriseId=${Company.id}&departmentId=${departmentId}`
      )
      .then((res) => {
        setEmployees(res.data);
        console.log(employees);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTargets = async () => {
    await axios
      .get(`${JSON_API}/StrategicTargets/targets/${departmentId}/${year}`)
      .then((res) => {
        console.log(res);
        setStrategicTargets(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSteps = async (e) => {
    console.log("eee", e);
    await axios
      .get(`${JSON_API}/StrategicPlanSteps/plan/${e}`)
      .then((res) => {
        console.log("steps:", res.data);
        setSteps(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getdata();
    getPlans(0);
    getTargets();
  }, []);
  useEffect(() => {
    return planId == null ? null : getSteps();
  }, [planId]);
  useEffect(() => {
    return stepId == null ? null : getActivities();
  }, [stepId]);

  const selectTargetId = (targetId) => {
    console.log(targetId);
    setStrategicTargetId(targetId);
  };

  const createStrategicPlan = async () => {
    setSpinner(true);
    const obj = {
      strategicTargetId: strategicTargetId,
      enterpriseDepartmentId: departmentId,
    };
    await axios
      .post(`${JSON_API}/StrategicPlans`, obj)
      .then((res) => {
        // setPlanId(res.data.id);
        console.log("OOOOOOOOOOOOOOOOOOO ", res.data);
        console.log("all targets", strategicTargets);
        message.success(t("Targetassociated"));
        setSpinner(false);
        getTargets();
        getPlans(1);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
      });
  };
  const deletStrategicPlan = async (id) => {
    setSpinner(true);

    await axios
      .delete(`${JSON_API}/StrategicPlans/${id}`)
      .then((res) => {
        console.log(res.data);
        getPlans();
        getTargets();
        setSteps([]);
        setActivities([]);
        setSpinner(false);
        message.success(t("Targetdissociated"));
      })
      .catch((err) => {
        if (err.response.status == 400) {
          message.error(
            t("Cannotdissociateatargetifithasactivitiesinitssteps")
          );
        }

        console.log(err.response.status);
      });
  };

  const columnss = [
    {
      title: t("activity"),
      dataIndex: "label",
      align: "left",
      render: (label, record) => {
        if (editingRow === record.id) {
          return (
            <Form.Item
              name="label"
              rules={[
                {
                  required: true,
                  message: t("enteralabl"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        } else {
          return (
            <div
              style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
              onClick={() => showNameModal(label)}
            >
              <b>{label}</b>
            </div>
          );
        }
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
      align: "center",
      render: (status, record) => {
        if (editingRow === record.id) {
          return (
            <Form.Item
              name="status"
              rules={[
                {
                  type: "number",
                  required: true,
                  message: t("selectastatus"),
                },
              ]}
            >
              <Select
                notFoundContent={t("nodata")}
                options={[
                  {
                    value: 0,
                    label: "(0%) " + t("NotStarted"),
                  },
                  {
                    value: 25,
                    label: "(25%) " + t("LowProgress"),
                  },
                  {
                    value: 50,
                    label: "(50%) " + t("MediumProgress"),
                  },
                  {
                    value: 75,
                    label: "(75%) " + t("HighProgress"),
                  },
                  {
                    value: 100,
                    label: "(100%) " + t("Done"),
                  },
                ]}
              />
            </Form.Item>
          );
        } else {
          switch (status) {
            case 0:
              return (
                <Tag
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    borderRadius: "5px",
                    padding: "5px 10px",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                  color={"#9d0208"}
                >
                  {t("NotStarted")}
                </Tag>
              );
            case 25:
              return (
                <Tag
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    borderRadius: "5px",
                    padding: "5px 10px",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                  color={"#ee9b00"}
                >
                  {t("LowProgress")}
                </Tag>
              );
            case 50:
              return (
                <Tag
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    borderRadius: "5px",
                    padding: "5px 10px",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                  color={"#f77f00"}
                >
                  {t("MediumProgress")}
                </Tag>
              );
            case 75:
              return (
                <Tag
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    borderRadius: "5px",
                    padding: "5px 10px",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                  color={"#6096ba"}
                >
                  {t("HighProgress")}
                </Tag>
              );
            case 100:
              return (
                <Tag
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    borderRadius: "5px",
                    padding: "5px 10px",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                  color={"#40916c"}
                >
                  {t("Done")}
                </Tag>
              );
            default:
              return null;
          }
        }
      },
    },
  ];

  if (ability.can("read", "Strategic Planning")) {
    columnss.push({
      title: "Actions",
      key: "operation",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          {editingRow === record.id ? (
            <>
              <Button
                type="link"
                danger
                icon={<CloseCircleOutlined />}
                onClick={() => setEditingRow(null)}
              >
                {t("cancel")}
              </Button>
              <Button type="link" icon={<SaveFilled />} htmlType="submit">
                {t("save")}
              </Button>
            </>
          ) : (
            <>
              {ability.can("update", "Strategic Planning") && (
                <Button
                  type="link"
                  icon={<EditFilled />}
                  onClick={() => {
                    setEditingRow(record.id);
                    form.setFieldsValue({
                      label: record.label,
                      status: record.status,
                    });
                  }}
                >
                  {t("edit")}
                </Button>
              )}
              {ability.can("delete", "Strategic Planning") && (
                <Popconfirm
                  title={t("Suretodelete")}
                  onConfirm={() => handleDeleteActivity(record.id)}
                  okText={t("yes")}
                  cancelText={t("no")}
                >
                  <Button type="link" danger icon={<DeleteFilled />}>
                    {t("Delete")}
                  </Button>
                </Popconfirm>
              )}
            </>
          )}
        </Space>
      ),
    });
  }

  const columns = [
    {
      title: t("priority"),
      dataIndex: "priority",
      align: "center",
      render: (_, { priority }) => {
        switch (priority) {
          case 0:
            return (
              <Tag
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
                color={"geekblue"}
              >
                {t("High")}
              </Tag>
            );
          case 1:
            return (
              <Tag
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
                color={"#fa2"}
              >
                {t("Medium")}
              </Tag>
            );
          case 2:
            return (
              <Tag
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
                color={"green"}
              >
                {t("Low")}
              </Tag>
            );
          default:
            return null;
        }
      },
    },
    {
      title: t("projectnumber"),
      dataIndex: "projectNumber",
      align: "center",
      render: (_, record) => {
        return (
          <div style={{ textAlign: "center" }}>{record.projectNumber}</div>
        );
      },
    },
    {
      title: t("responsible"),
      dataIndex: "responsible",
      align: "center",
      render: (_, record) => {
        return (
          <div
            style={{
              textAlign: "left",
              maxWidth: "400px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor: "pointer",
            }}
            onClick={() =>
              showNameModal(
                `${record.responsible.name} ${record.responsible.firstName}`
              )
            }
          >{`${record.responsible.name} ${record.responsible.firstName}`}</div>
        );
      },
    },
    {
      title: t("predicteddate"),
      dataIndex: "predictedEndDate",
      align: "center",
      render: (_, record) => {
        return (
          <div style={{ textAlign: "center" }}>
            {dayjs(record.predictedEndDate).format("DD/MM/YYYY")}
          </div>
        );
      },
    },
    {
      title: t("enddate"),
      dataIndex: "actualEndDate",
      align: "center",
      render: (_, record) => {
        return (
          <div style={{ textAlign: "center" }}>
            {dayjs(record.actualEndDate).format("DD/MM/YYYY")}
          </div>
        );
      },
    },
    {
      title: t("progression"),
      dataIndex: "progress",
      align: "center",
      render: (_, { progress }) => {
        return (
          <Progress type="circle" percent={Math.round(progress)} width={60} />
        );
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
      align: "center",
      render: (_, { status }) => {
        switch (status) {
          case 0:
            return (
              <Tag
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
                color={"#9d0208"}
              >
                {t("NotStarted")}
              </Tag>
            );
          case 25:
            return (
              <Tag
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
                color={"#ee9b00"}
              >
                {t("LowProgress")}
              </Tag>
            );
          case 50:
            return (
              <Tag
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
                color={"#f77f00"}
              >
                {t("MediumProgress")}
              </Tag>
            );
          case 75:
            return (
              <Tag
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
                color={"#6096ba"}
              >
                {t("HighProgress")}
              </Tag>
            );
          case 100:
            return (
              <Tag
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
                color={"#40916c"}
              >
                {t("Done")}
              </Tag>
            );
          default:
            return (
              <Tag
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
                color={"red"}
              >
                {t("NotStarted")}
              </Tag>
            );
        }
      },
    },
    {
      title: t("comments"),
      dataIndex: "comments",
      align: "center",
      render: (text) => {
        return (
          <div
            style={{
              textAlign: "left",
              maxWidth: "400px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor: "pointer",
            }}
            onClick={() => showNameModal(text)}
          >
            {text}
          </div>
        );
      },
    },
  ];

  if (ability.can("read", "Strategic Planning")) {
    columns.push({
      title: "Actions",
      align: "center",
      fixed: "center",
      render: (_, record) => {
        return (
          <Space size="middle">
            {ability.can("update", "Strategic Planning") && (
              <Button
                type="link"
                icon={<EditFilled />}
                onClick={() => {
                  handleEdit(record.key);
                  form.setFieldsValue({
                    projectNumber: record.projectNumber,
                    employeeId: record.responsible.id,
                    predictedEndDate: dayjs(record.predictedEndDate),
                    actualEndDate: dayjs(record.actualEndDate),
                    priority: record.priority,
                    comments: record.comments,
                  });
                }}
              >
                {t("edit")}
              </Button>
            )}
            {ability.can("delete", "Strategic Planning") && (
              <Popconfirm
                title={t("Suretodelete")}
                onConfirm={() => handleDeleteStep(record.key)}
                okText={t("yes")}
                cancelText={t("no")}
              >
                <Button type="link" danger icon={<DeleteFilled />}>
                  {t("Delete")}
                </Button>
              </Popconfirm>
            )}
          </Space>
        );
      },
    });
  }

  // if (!ability.can("read", "Strategic Planning")) {
  //   columns.push({
  //     title: "Actions",
  //     align: "center",
  //     fixed: "center",
  //     render: (_, record) => {
  //       return (
  //         <Space size="middle">
  //           {ability.can("update", "Strategic Planning") && (
  //             <Popconfirm
  //               open={false}
  //               onClick={() => {
  //                 handleEdit(record.key);
  //                 form.setFieldsValue({
  //                   projectNumber: record.projectNumber,
  //                   employeeId: record.responsible.id,
  //                   predictedEndDate: dayjs(record.predictedEndDate),
  //                   actualEndDate: dayjs(record.actualEndDate),
  //                   priority: record.priority,
  //                   comments: record.comments,
  //                 });
  //               }}
  //             >
  //               <Button type="link" icon={<EditFilled />}>
  //                 {t("edit")}
  //               </Button>
  //             </Popconfirm>
  //           )}
  //           {/* {ability.can("delete", "Strategic Planning") && ( */}
  //           <Popconfirm
  //             okText={t("yes")}
  //             cancelText={t("no")}
  //             title={t("Suretodelete")}
  //             onConfirm={() => handleDeleteStep(record.key)}
  //           >
  //             <Button type="link" danger icon={<DeleteFilled />}>
  //               {t("Delete")}
  //             </Button>
  //           </Popconfirm>
  //           {/* )} */}
  //         </Space>
  //       );
  //     },
  //   });
  // }
  const handleEditActivity = async (values) => {
    const activity = {
      id: editingRow,
      label: values.label,
      status: values.status,
    };
    console.log(values);

    await axios
      .put(`${JSON_API}/StrategicPlanStepActivitys`, activity)
      .then((response) => {
        console.log(response.data);
        getActivities();
        getSteps(planId);
        getdata();
        message.open({
          type: "success",
          content: t("Activityeditedsuccessfully"),
        });
      })
      .catch((err) => {
        console.log(err);
      });
    setEditingRow(null);
  };

  const rowSelection = (key) => {
    setdisableBtn(false);
    setSelectedRowKeys(key);
    console.log(`selectedRowKeys: ${key}`);
    setCheckRow(true);
    setStepId(key[0]);
  };

  const stepsDto =
    steps == null
      ? []
      : steps.map((p) => ({
          key: p.id,
          projectNumber: p.projectNumber,
          responsible: p.responsible,
          responsibleid: p.responsible.id,
          predictedEndDate: p.predictedEndDate,
          actualEndDate: p.actualEndDate,
          comments: p.comments,
          priority: p.priority,
          status: p.status,
          progress: p.progress,
        }));

  const items =
    plans == null
      ? []
      : plans.map((p) => ({
          key: p.id,
          label: p.target,
          children: (
            <>
              <Row
                justify="end"
                gutter={[16, 16]}
                style={{
                  marginBottom: 20,
                  marginTop: 20,
                }}
              >
                {ability.can("create", "Strategic Planning") && (
                  <Space align="center">
                    <Popconfirm
                      okText={t("yes")}
                      cancelText={t("no")}
                      onConfirm={() => deletStrategicPlan(p.id)}
                      type="link"
                      title={
                        departmentName
                          ? t("dissociatemsg") + " " + p.target + " ?"
                          : ""
                      }
                    >
                      <Button icon={<DisconnectOutlined />} danger>
                        <b>{t("dissociate")}</b>
                      </Button>
                    </Popconfirm>

                    <Button
                      icon={<PlusCircleFilled />}
                      type="primary"
                      onClick={() => setOpen(true)}
                    >
                      {t("newstep")}
                    </Button>
                  </Space>
                )}
              </Row>

              <Table
                locale={{
                  emptyText: <Empty description={t("nodata")} />,
                }}
                rowSelection={{
                  type: "radio",
                  selectedRowKeys: selectedRowKeys,
                  onChange: rowSelection,
                }}
                columns={columns}
                dataSource={stepsDto}
                scroll={{ x: 1500, y: 500 }}
              />

              <h3
                style={{ display: "inline-block", width: "10%", height: "3%" }}
              >
                {t("activities")}
              </h3>

              <Row justify="end" gutter={[16, 16]}>
                <Space
                  style={{
                    display: "flex",
                    marginBottom: 8,
                  }}
                  align="center"
                >
                  {ability.can("create", "Strategic Planning") && (
                    <Button
                      icon={<PlusCircleOutlined />}
                      disabled={disableBtn}
                      type="primary"
                      onClick={() => {
                        setOpenActivity(true);
                      }}
                    >
                      {t("newactivity")}
                    </Button>
                  )}
                </Space>
              </Row>

              <Form form={form} onFinish={handleEditActivity}>
                <div style={{ overflowX: "auto" }}>
                  <Table
                    locale={{
                      emptyText: <Empty description={t("nodata")} />,
                    }}
                    columns={columnss}
                    dataSource={activities}
                  />
                </div>
                <Modal
                  title={t("detail")}
                  visible={isModalVisible}
                  onOk={closeModal}
                  onCancel={closeModal}
                >
                  <p>{selectedName}</p>
                </Modal>
              </Form>
            </>
          ),
        }));

  const onChange = (key) => {
    setSelectedRowKeys([]);
    setdisableBtn(true);
    setActiveKey(key);
    console.log("key is:", key);
    getSteps(key);
    setPlanId(key);
    setActivities(null);
  };

  const getActivities = async () => {
    await axios
      .get(`${JSON_API}/StrategicPlanStepActivitys/activities/${stepId}`)
      .then((res) => {
        console.log(res.data);
        setActivities(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteStep = async (id) => {
    console.log(id);
    await axios
      .delete(`${JSON_API}/StrategicPlanSteps/${id}`)
      .then((response) => {
        getSteps(planId);
        setActivities([]);
        setActiveKey();
        message.success(t("Stepdeletedsuccessfully"));
      })
      .catch((err) => {
        if (err.response.status == 400) {
          message.warning(t("CannotdeleteaStepifithasoneormoreactivities"));
        }
        if (err.response.status == 500) {
          getSteps(planId);
          setActiveKey();
          setActivities([]);
        }
        message.error(t("Anerroroccuredpleasetryagain"));
        console.log(err);
      });
  };

  const submite = async (e) => {
    const formData = {
      projectNumber: e.projectNumber,
      predictedEndDate: e.predictedEndDate,
      actualEndDate: e.actualEndDate,
      comments: e.comments,
      priority: e.priority,
      employeeId: employeeId,
      strategicPlanId: planId,
    };
    console.log(formData);
    axios
      .post(`${JSON_API}/StrategicPlanSteps`, formData)
      .then((res) => {
        getSteps(planId);
        setOpen(!open);
        message.success(t("Stepaddedsuccessfully"));
      })
      .catch(function (error) {
        console.log("Erreuuuuuuuuuuuuuur!" + error);
        message.error(t("Anerroroccuredpleasetryagain"));
      });
  };

  const handleEdit = async (id) => {
    setStep(id);
    setOpenn(true);
  };

  const employeesList =
    employees == null
      ? []
      : employees.map((emp) => ({
          value: emp.id,
          label: emp.name,
        }));

  const handleSelectChange = (value) => {
    setEmployeeId(value);
  };

  const StatefulModalContent = (props) => {
    return (
      <Form
        form={props.form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: "public",
        }}
      >
        <Form.Item
          value={employeeId}
          name="employeeId"
          label={t("responsable")}
          rules={[
            {
              required: true,
              message: t("Selectaresponsibleplease"),
            },
          ]}
        >
          <Select
            notFoundContent={t("nodata")}
            Box
            value={employeeId}
            options={employeesList}
            onChange={handleSelectChange}
          />
        </Form.Item>
        <Form.Item
          name="projectNumber"
          label={t("projectnumber")}
          rules={[
            {
              required: true,
              message: t("Enterprojectnumberplease"),
            },
          ]}
        >
          <Input
            style={{
              width: 470,
            }}
          />
        </Form.Item>

        <Form.Item
          name="predictedEndDate"
          label={t("predicteddate")}
          rules={[
            {
              required: true,
              message: t("Selectanestimatedfinishdate"),
            },
          ]}
        >
          <DatePicker
            placeholder={t("Selectadate")}
            style={{
              width: 470,
            }}
          />
        </Form.Item>

        <Form.Item
          name="actualEndDate"
          label={t("enddate")}
          rules={[
            {
              required: true,
              message: t("Selectadate"),
            },
          ]}
        >
          <DatePicker
            placeholder={t("Selectadate")}
            style={{
              width: 470,
            }}
          />
        </Form.Item>
        <Form.Item
          name="priority"
          label={t("priority")}
          rules={[
            {
              type: "number",
              required: true,
              message: t("Selectapriority"),
            },
          ]}
        >
          <Select
            notFoundContent={t("nodata")}
            style={{
              width: 470,
            }}
            options={[
              {
                value: 0,
                label: t("High"),
              },
              {
                value: 1,
                label: t("Medium"),
              },
              {
                value: 2,
                label: t("Low"),
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          name="comments"
          label={t("comments")}
          rules={[
            {
              required: true,
              message: t("Enteracommentplease"),
            },
          ]}
        >
          <TextArea style={{ width: 470 }} rows={4} />
        </Form.Item>
      </Form>
    );
  };

  const CollectionAddActivity = ({ openActivity, onCreate, onCancel }) => {
    const [form] = Form.useForm();
    return (
      <Modal
        open={openActivity}
        title={t("createanewactivity")}
        okText={t("create")}
        width={800}
        cancelText={t("cancel")}
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onCreate(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form
          form={form}
          name="form_in_modal"
          initialValues={{ modifier: "public" }}
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Form.Item
            value={label}
            rules={[
              {
                required: true,
                message: t("enteralabl"),
              },
            ]}
            name="label"
            label={t("Label")}
            style={{ flex: "0 1 calc(50% - 8px)" }}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="status"
            label={t("status")}
            rules={[
              {
                type: "number",
                required: true,
                message: t("selectastatus"),
              },
            ]}
            style={{ flex: "0 1 calc(50% - 8px)" }}
          >
            <Select
              notFoundContent={t("nodata")}
              options={[
                { value: 0, label: "(0%) " + t("NotStarted") },
                { value: 25, label: "(25%) " + t("LowProgress") },
                { value: 50, label: "(50%) " + t("MediumProgress") },
                { value: 75, label: "(75%) " + t("HighProgress") },
                { value: 100, label: "(100%) " + t("Done") },
              ]}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const CollectionUpdateActivity = ({
    openEditActivity,
    onUpdateActivity,
    onCancel,
  }) => {
    return (
      <Modal
        openEditActivity={openEditActivity}
        title={t("Updateactivity")}
        okText={t("Update")}
        cancelText={t("cancel")}
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onUpdateActivity(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form form={form} layout="vertical" name="form_in_modal">
          <Form.Item label={t("Label")}>
            <Input
              defaultValue={activity.label}
              style={{ width: "95%", height: "31%" }}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const handleDeleteActivity = async (id) => {
    console.log("id", id);
    await axios
      .delete(`${JSON_API}/StrategicPlanStepActivitys/${id}`)
      .then((response) => {
        console.log("response", response);
        message.success(t("Activitdeletedsuccessfully"));
        getActivities();
        getSteps(planId);
      })
      .catch((response) => {
        getActivities();
        getSteps(planId);
      });
  };

  const [open, setOpen] = useState(false);
  const [openn, setOpenn] = useState(false);

  const onUpdateTarget = async (values) => {
    console.log("Received values of form: ", values);

    const formData = {
      id: step,
      projectNumber: values.projectNumber,
      predictedEndDate: values.predictedEndDate,
      actualEndDate: values.actualEndDate,
      comments: values.comments,
      priority: values.priority,
      employeeId: values.employeeId,
      // strategicPlanId: planId,
    };
    console.log("Received values of form: ", formData);
    await axios
      .put(`${JSON_API}/StrategicPlanSteps`, formData)
      .then((res) => {
        getSteps(planId);
        setOpenn(false);
        message.success(t("Stepupdatedsuccessfully"));
      })
      .catch(function (error) {
        console.log("Erreuuuuuuuuuuuuuur!" + error);
        setOpenn(true);
        message.error(t("ErrorWhileUpdatingStep"));
      });
  };

  const addActivity = async (e) => {
    const formData = {
      label: e.label,
      status: e.status,
      strategicPlanStepId: stepId,
    };
    console.log(formData);
    await axios
      .post(`${JSON_API}/StrategicPlanStepActivitys`, formData)
      .then((res) => {
        getdata();
        getSteps(planId);
        getActivities();
        setOpenActivity(!openActivity);
        message.success(t("Activityaddedsuccessfully"));
      })
      .catch(function (error) {
        console.log("Erreuuuuuuuuuuuuuur!" + error);
        message.error(t("ErrorWhileAddingActivity"));
      });
  };
  const onUpdateActivity = async (values) => {
    console.log("Received values of form: ", values);
    await axios
      .put(`${JSON_API}/StrategicPlanStepActivitys`, values)
      .then((res) => {
        setActivity(res.data);
        setOpenEditActivity(false);
        getdata();
        message.success(t("Activityupdatedsuccessfully"));
      })
      .catch(function (error) {
        console.log("Erreuuuuuuuuuuuuuur!" + error);
        message.error(t("ErrorWhileUpdatingActivity"));
      });
  };
  function handleGoBack() {
    history.goBack();
  }

  return (
    <>
      <Card
        bordered={false}
        className="header-solid mb-24"
        title={
          departmentName && (
            <h2 className="font-semibold">
              {t("strategicplanning")} & {departmentName.label} ({year}){" "}
            </h2>
          )
        }
        extra={
          <Button
            type="primary"
            danger
            icon={<ArrowLeftOutlined />}
            onClick={handleGoBack}
          >
            {t("Goback")}
          </Button>
        }
      >
        <Progress
          percent={deptdata && Math.round(deptdata.strategicProgress)}
          strokeColor={{ "0%": "#108ee9", "100%": "#FFDE00" }}
        />

        <Form name="basic">
          <Space
            style={{
              display: "flex",
              marginBottom: 16,
              marginTop: 16,
            }}
            align="baseline"
          >
            <Form.Item
              label={t("associateastrategictarget")}
              name="target"
              rules={[
                {
                  required: true,
                  message: t("selectatarget"),
                },
              ]}
            >
              <Select
                notFoundContent={t("nodata")}
                onChange={selectTargetId}
                size="large"
                style={{ width: 300 }}
                placeholder={t("selectatarget")}
              >
                {strategicTargets.map((st) => (
                  <Option key={st.id} value={st.id}>
                    {st.type}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item>
              {ability.can("update", "Strategic Planning") && (
                <Button
                  onClick={() => createStrategicPlan()}
                  loading={spinner}
                  icon={<LinkOutlined />}
                  type="primary"
                >
                  {t("associate")}
                </Button>
              )}
            </Form.Item>
          </Space>
        </Form>

        <Modal
          open={openn}
          title={t("updatestep")}
          okText={t("save")}
          cancelText={t("cancel")}
          onCancel={() => {
            setOpenn(false);
            form.resetFields();
          }}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                onUpdateTarget(values);
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          <StatefulModalContent form={form} />
        </Modal>

        <Tabs
          style={{
            marginBottom: 35,
            width: "100%",
          }}
          type="card"
          items={items}
          onChange={onChange}
          activeKey={activeKey}
        />

        <Modal
          open={open}
          title={t("createanewstep")}
          okText={t("create")}
          cancelText={t("cancel")}
          onCancel={() => {
            setOpen(false);
            form.resetFields();
          }}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                submite(values);
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          <StatefulModalContent form={form} />
        </Modal>

        <CollectionUpdateActivity
          openEditActivity={openEditActivity}
          onUpdateActivity={onUpdateActivity}
          onCancel={() => {
            setOpenEditActivity(false);
          }}
        />

        <CollectionAddActivity
          openActivity={openActivity}
          onCreate={addActivity}
          onCancel={() => {
            setOpenActivity(false);
          }}
        />
      </Card>
    </>
  );
}
StrategicPlans.acl = {
  subject: "Strategic Planning",
  action: "read",
};
export default StrategicPlans;

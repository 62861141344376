import {
  DeleteOutlined,
  PicCenterOutlined,
  PlusCircleOutlined,
  CheckCircleFilled,
  EyeOutlined,
  CloseCircleFilled,
  SaveFilled,
  EditFilled,
  EyeFilled,
  DeleteFilled,
  PlayCircleFilled,
  PlusCircleFilled,
} from "@ant-design/icons";
import {
  Modal,
  Row,
  Col,
  DatePicker,
  Button,
  Card,
  Empty,
  Form,
  Input,
  Popconfirm,
  Space,
  Table,
  message,
  Spin,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import axios from "axios";
import { useTranslation } from "react-i18next";
import { CompanyContext } from "../contexts/CompanyContext";
import { JSON_API } from "../services/Constants";
import { useAbility } from "../contexts/abilityContext";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const SalesOrderBook = () => {
  const { ability } = useAbility();
  let { t } = useTranslation();
  const { Company } = useContext(CompanyContext);
  const current = new Date();
  const [year, setYear] = useState(null);
  const [dataSource, setDataSource] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  const [editingRow, setEditingRow] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  var date;
  // useEffect(() => {
  //   date = new Date().getFullYear();
  //   if (year == null) {
  //     setYear(date);
  //   }
  //   console.log("year" + date);
  // }, [Company.id]);

  // useEffect(() => {
  //   getorderbooks();
  // }, [year]);

  useEffect(() => {
    const date = new Date().getFullYear();

    if (year == null) {
      setYear(date);
    }

    console.log("year" + date);

    getorderbooks();
  }, [Company.id, year]);

  // const getorderbooks = async () => {
  //     setLoading(true);
  //   try {
  //     const response = await axios.get(
  //       `${JSON_API}/OrderBooks/enterprise/${Company.id}?year=${year}`
  //     );
  //     setDataSource(response.data);
  //   } catch (error) {
  //     setDataSource(null);
  //   }
  // };

  const getorderbooks = async () => {
    setLoading(true);

    // Définir un délai de 2 secondes
    setTimeout(async () => {
      try {
        const response = await axios.get(
          `${JSON_API}/OrderBooks/enterprise/${Company.id}?year=${year}`
        );
        setDataSource(response.data);
      } catch (error) {
        setDataSource([]);
      } finally {
        setLoading(false);
      }
    }, 2000); // 2000 ms = 2 secondes
  };

  const showNameModal = (name) => {
    setSelectedName(name);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedName(null);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${JSON_API}/OrderBooks/${id}`);
      message.success(t("orderbookdeleted"));
      getorderbooks();
    } catch (error) {
      message.error(t("Error While Deleting Order Book"));
    }
  };

  const defaultColumns = [
    {
      title: `${t("Name")}`,
      dataIndex: "name",
      width: "20%",
      align: "center",
      render: (text, record) => {
        if (editingRow === record.id) {
          return (
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: `${t("orderbooknamerequired")}`,
                },
              ]}
            >
              <Input style={{ maxWidth: "200px" }} />
            </Form.Item>
          );
        } else {
          return (
            <div
              style={{
                textAlign: "center",
                maxWidth: "200px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
              onClick={() => showNameModal(text)}
            >
              {text}
            </div>
          );
        }
      },
    },
    {
      title: `${t("creationdate")}`,
      dataIndex: "createdAt",
      align: "center",
      sorter: (a, b) => dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix(),
      sortDirections: ["descend", "ascend"],
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          {dayjs(record.createdAt).format("DD/MM/YYYY")}
        </div>
      ),
    },
    {
      title: `${t("Year")}`,
      dataIndex: "year",
      align: "center",
      sorter: (a, b) => a.year - b.year,
      sortDirections: ["descend", "ascend"],
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>{record.year}</div>
      ),
    },
    {
      title: "Total",
      dataIndex: "total",
      align: "center",

      render: (_, record) => (
        <div style={{ textAlign: "center" }}>{record.total}</div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "operation",
      align: "center",

      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Space size="middle">
            {editingRow === record.id ? (
              <>
                <Button type="link" danger onClick={() => setEditingRow(null)}>
                  <CloseCircleFilled /> {t("cancel")}
                </Button>
                <Button type="link" htmlType="submit">
                  <SaveFilled /> {t("save")}
                </Button>
              </>
            ) : (
              <>
                {ability.can("update", "Order book") && (
                  <Popconfirm
                    type="link"
                    onClick={() => {
                      setEditingRow(record.id);
                      form.setFieldsValue({
                        name: record.name,
                      });
                    }}
                  >
                    <Button type="link" icon={<EditFilled />}>
                      {t("edit")}
                    </Button>
                  </Popconfirm>
                )}
                {ability.can("delete", "Order book") && (
                  <Popconfirm
                    title={t("deleterow")}
                    onConfirm={() => handleDelete(record.id)}
                    cancelButtonProps={{
                      danger: true,
                      type: "primary",
                    }}
                    okText={
                      <>
                        <CheckCircleFilled /> {t("Delete")}
                      </>
                    }
                    cancelText={
                      <>
                        <CloseCircleFilled /> {t("Cancel")}
                      </>
                    }
                  >
                    <Button type="link" danger icon={<DeleteFilled />}>
                      {t("delete")}
                    </Button>
                  </Popconfirm>
                )}

                <Link
                  to={{
                    pathname: `/orderbook/${record.id}`,
                    state: { stateParam: record.id },
                  }}
                >
                  <Button type="link" icon={<EyeFilled />}>
                    {t("details")}
                  </Button>
                </Link>
              </>
            )}
          </Space>
        ) : null,
    },
  ];

  const onFinish = async (values) => {
    try {
      const orderbook = {
        name: values.name,
        enterpriseId: Company.id,
        year: values.year.format("YYYY"),
      };

      console.log("Orderbook to be Created:", orderbook);
      await axios.post(`${JSON_API}/OrderBooks`, orderbook);
      getorderbooks();
      console.log("Orderbook added Successfully!");
      message.success(t("orderbookcreation"));
    } catch (error) {
      console.log("Orderbook creation Failed:", error);
      message.error(t("orderbookcreationerror"));
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishEdit = async (values) => {
    const orderbookobj = {
      id: editingRow,
      name: values.name,
    };
    console.log("orderbookedited: ", orderbookobj);
    await axios.put(`${JSON_API}/OrderBooks`, orderbookobj).then((response) => {
      getorderbooks();
      message.success(t("orderbookupdate"));
    });
    setEditingRow(null);
  };

  const Duplicatebook = async () => {
    if (!year) {
      console.error("No year selected");
      message.warning(t("NoSelectedyear"));
      return;
    }
    await axios
      .post(
        `${JSON_API}/OrderBooks/duplicateLastOrderBook/${Company.id}?year=${year}`
      )
      .then((response) => {
        getorderbooks();
        console.log("Orderbook added Successfully!");
        message.success(t("orderbookcreation"));
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }

        console.log(error.config);
      });
  };

  const onChangee = (date, dateString) => {
    console.log(date, dateString);
    setYear(dateString);
  };

  return (
    <>
      <Card
        bordered={false}
        className="header-solid mb-24 stylish-card"
        title={<h3 className="font-semibold">{t("orderbook")}</h3>}
        extra={
          <DatePicker
            defaultValue={dayjs(date)}
            name="year"
            picker="year"
            placeholder={t("Selectyear")}
            className="stylish-datepicker"
            onChange={onChangee}
          />
        }
      >
        {ability.can("create", "Order book") && (
          <Form
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="stylish-form"
          >
            <Row align="middle" gutter={[16, 16]}>
              <Col xs={24} sm={12} md={6}>
                <Form.Item
                  label={t("Name")}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: `${t("orderbooknamerequired")}`,
                    },
                  ]}
                >
                  <Input placeholder={t("Entername")} />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={6}>
                <Form.Item name="year" label={t("Year")}>
                  <DatePicker
                    placeholder={t("Selectyear")}
                    style={{ width: "100%" }}
                    picker="year"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={6}>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    icon={<PlusCircleFilled />}
                  >
                    {t("create")}
                  </Button>
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={6}>
                <Form.Item>
                  <Button
                    block
                    onClick={Duplicatebook}
                    icon={<PicCenterOutlined />}
                  >
                    {t("duplicatelastorderbook")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}

        <Form
          form={form}
          onFinish={onFinishEdit}
          className="stylish-table-form"
        >
          <Spin spinning={loading} tip={t("loading")}>
            <Table
              locale={{ emptyText: <Empty description={t("nodata")} /> }}
              bordered
              dataSource={dataSource}
              columns={defaultColumns}
              className="stylish-table"
            />
          </Spin>
        </Form>
        <Modal
          title={t("NameofTheOrderBook")}
          visible={isModalVisible}
          onOk={closeModal}
          onCancel={closeModal}
        >
          <p>{selectedName}</p>
        </Modal>
      </Card>
    </>
  );
};
SalesOrderBook.acl = {
  subject: "Order book",
  action: "read",
};
export default SalesOrderBook;

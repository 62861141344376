import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Select,
  Space,
  Table,
  message,
} from "antd";

import {
  DeleteOutlined,
  DollarOutlined,
  EditOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Link, useHistory, useParams } from "react-router-dom";
import { CompanyContext } from "../../contexts/CompanyContext";
import { JSON_API } from "../../services/Constants";

import { useTranslation } from "react-i18next";

import { Card, Col, Row } from "antd";
import axios from "axios";
// import "./Tableau.css";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
const { Option } = Select;

function Customer() {
  let { t } = useTranslation();
  const { Company } = useContext(CompanyContext);

  const [messageApi, contextHolder] = message.useMessage();
  const { id } = useParams();

  const [TypeIndustries, setTypeIndustries] = useState([{}]);

  const [data, setData] = useState([{}]);
  const [editingRow, setEditingRow] = useState(null);

  useEffect(() => {
    getCustomers();
  }, []);

  const handleDelete = async (e) => {
    console.log(e);
    await axios.delete(`${JSON_API}/Customers/${e}`);
    message.success(t("Customerdeletedsuccessfully"));
    getCustomers();
  };

  const { TextArea } = Input;

  const submite = async (e) => {
    const formData = {
      name: e.name,
      enterpriseId: Company.id,
    };

    console.log("form", formData);
    axios
      .post(`${JSON_API}/Customers`, formData)
      .then((res) => {
        console.log("Customers", res);
        getCustomers();

        console.log(res.data);
        setOpen(!open);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onFinishEdit = async (e) => {
    const type = {
      id: editingRow,
      name: e.name,
    };

    console.log("customer: ", type);

    await axios.put(`${JSON_API}/Customers`, type).then((response) => {
      console.log("CustomersupdatedSuccessfully!");
      getCustomers();
      messageApi.open({
        type: "success",
        content: `${t("CustomersupdatedSuccessfully")}`,
      });
    });
    setEditingRow(null);
  };

  const getCustomers = async () => {
    await axios
      .get(`${JSON_API}/Customers/enterprise/${Company.id}`)
      .then((response) => {
        setTypeIndustries(response.data);
        console.log(TypeIndustries, "Customers");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [form] = Form.useForm();
  const [form1] = Form.useForm();

  const [open, setOpen] = useState(false);

  const CollectionCreateForm = ({ open, onCreate, onCancel }) => {
    return (
      <Modal
        forceRender
        open={open}
        title={t("Add Customers")}
        okText={t("Create")}
        cancelText={t("Cancel")}
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onCreate(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
        >
          <Form.Item
            name="name"
            label={t("name")}
            rules={[
              {
                required: true,
                message: `${t("Pleaseenterlabel")}`,
              },
            ]}
          >
            <Input
              style={{
                width: 470,
              }}
            ></Input>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const columns = [
    {
      title: <h1 style={{ textAlign: "center" }}>{t("name")}</h1>,
      align: "left",
      render: (_, record) => {
        if (editingRow === record.id) {
          return (
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: `${t("Veuillersaisirna")}`,
                },
              ]}
            >
              <Input
                style={{
                  width: 300,
                }}
              />
            </Form.Item>
          );
        } else {
          return <div style={{ textAlign: "left" }}>{record.name}</div>;
        }
      },
    },

    {
      title: <h3>Actions</h3>,
      key: "operation",
      align: "center",
      //   width: 100,

      render: (_, record) => (
        <Space size="middle">
          {editingRow === record.id ? (
            <>
              <Button type="primary" htmlType="submit">
                {t("save")}
              </Button>
              <Button type="link" onClick={() => setEditingRow(null)}>
                {t("cancel")}
              </Button>
            </>
          ) : (
            <>
              <Button
                type="link"
                onClick={() => {
                  setEditingRow(record.id);
                  form1.setFieldsValue({
                    name: record.name,
                  });
                }}
              >
                <EditOutlined />
                {t("edit")}{" "}
              </Button>
              <Popconfirm
                title={t("deleterow")}
                onConfirm={() => handleDelete(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link " danger>
                  <DeleteOutlined />
                  {t("Delete")}
                </Button>
              </Popconfirm>
            </>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <div>
        {contextHolder}
        {/* <h3>{t("Loansmanagement")}</h3> <br />
      <div> */}
        <Card bordered={false} className="header-solid mb-24">
          <h3 className="font-semibold">
            <Divider>
              {" "}
              <SettingOutlined style={{ color: "#3947C4" }} />
              {t("ParametersforCustomer")}
            </Divider>
          </h3>
          <Row justify="space-between">
            <Space style={{ marginBottom: 15 }}>
              <Button
                className="Create-button"
                type="primary"
                onClick={() => {
                  setOpen(true);
                }}
              >
                {t("Create")}
              </Button>
              <CollectionCreateForm
                open={open}
                onCreate={submite}
                onCancel={() => {
                  setOpen(false);
                  // onAddChamp();
                }}
              />
            </Space>
          </Row>
          <Form form={form1} onFinish={onFinishEdit}>
            <Table bordered columns={columns} dataSource={TypeIndustries} style={{ overflowX: "auto",backgroundColor:'white' }} />
          </Form>
        </Card>
      </div>
    </>
  );
}

export default Customer;

import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Select,
  Space,
  Table,
  message,
} from "antd";

import {
  DeleteOutlined,
  DollarOutlined,
  EditOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Link, useHistory, useParams } from "react-router-dom";
import { CompanyContext } from "../contexts/CompanyContext";
import { JSON_API } from "../services/Constants";

import { useTranslation } from "react-i18next";

import { Card, Col, Row } from "antd";
import axios from "axios";
// import "./Tableau.css";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
const { Option } = Select;

function InvestismentCategory() {
  let { t } = useTranslation();

  const { Company } = useContext(CompanyContext);

  const [messageApi, contextHolder] = message.useMessage();
  const { id } = useParams();

  const [Projectbenefit, setProjectbenefit] = useState([{}]);

  const [data, setData] = useState([{}]);
  const [editingRow, setEditingRow] = useState(null);

  useEffect(() => {
    getProjectGlobalScales();
  }, []);

  const handleDelete = async (e) => {
    console.log(e);
    try {
      await axios.delete(`${JSON_API}/ProjectBenefits/${e}`);
      message.success(t("Projectbenefitwassuccessfullydeleted"));
      getProjectGlobalScales();
    } catch (error) {
      if (error.response && error.response.status === 500) {
        message.error(t("Youcannotdeletebecauseitisassociated"));
      } else {
        message.error(t("Anerroroccurred"));
      }
    }
  };

  const { TextArea } = Input;

  const submite = async (e) => {
    const formData = {
      enterpriseId: Company.id,
      labelEn: e.labelen,
      labelFr: e.labelfr,
    };

    console.log("form", formData);
    if (!formData.labelEn && !formData.labelFr) {
      message.error(t("PleasefillinatleastonelabelFrenchorEnglish"));
      return;
    }
    axios
      .post(`${JSON_API}/ProjectBenefits`, formData)
      .then((res) => {
        getProjectGlobalScales();

        setOpen(!open);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onFinishEdit = async (e) => {
    const type = {
      id: editingRow,
      labelEn: e.labelen,
      labelFr: e.labelfr,
      enterpriseId: Company.id,
    };

    console.log("Project: ", type);

    await axios
      .put(`${JSON_API}/ProjectBenefits/${editingRow}`, type)
      .then((response) => {
        getProjectGlobalScales();
        messageApi.open({
          type: "success",
          content: `${t("Projectbenefitshasbeensuccessfullyupdatedd")}`,
        });
      });
    setEditingRow(null);
  };

  const getProjectGlobalScales = async () => {
    try {
      const response = await axios.get(
        `${JSON_API}/ProjectBenefits?enterpriseId=${Company.id}`
      );
      setProjectbenefit(response.data);
      console.log(response.data, "prpus");
    } catch (err) {
      console.log(err);
    }
  };

  const [form] = Form.useForm();
  const [form1] = Form.useForm();

  const [open, setOpen] = useState(false);

  const CollectionCreateForm = ({ open, onCreate, onCancel }) => {
    return (
      <Modal
        forceRender
        open={open}
        title={t("Projectbenefits")}
        okText={t("Create")}
        cancelText={t("Cancel")}
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onCreate(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
        >
          <Form.Item name="labelen" label={t("labelEn")}>
            <Input
              style={{
                width: 470,
              }}
            ></Input>
          </Form.Item>
        </Form>
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
        >
          <Form.Item name="labelfr" label={t("labelFr")}>
            <Input
              style={{
                width: 470,
              }}
            ></Input>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const columns = [
    {
      title: <h1 style={{ textAlign: "center" }}>{t("labelFr")}</h1>,
      align: "left",
      render: (_, record) => {
        if (editingRow === record.id) {
          return (
            <Form.Item
              name="labelfr"
              // initialValue={record.labelFr}
            >
              <Input
                style={{
                  width: 300,
                }}
              />
            </Form.Item>
          );
        } else {
          return <div style={{ textAlign: "left" }}>{record.labelFr}</div>;
        }
      },
    },

    {
      title: <h1 style={{ textAlign: "center" }}>{t("labelEn")}</h1>,
      align: "left",
      render: (_, record) => {
        if (editingRow === record.id) {
          return (
            <Form.Item name="labelen">
              <Input
                style={{
                  width: 300,
                }}
              />
            </Form.Item>
          );
        } else {
          return <div style={{ textAlign: "left" }}>{record.labelEn}</div>;
        }
      },
    },

    {
      title: <h3>Actions</h3>,
      key: "operation",
      align: "center",
      width: 300,

      render: (_, record) => (
        <Space size="middle">
          {editingRow === record.id ? (
            <>
              <Button type="primary" htmlType="submit">
                {t("save")}
              </Button>
              <Button type="link" onClick={() => setEditingRow(null)}>
                {t("cancel")}
              </Button>
            </>
          ) : (
            <>
              <Button
                type="link"
                onClick={() => {
                  setEditingRow(record.id);
                  form1.setFieldsValue({
                    labelen: record.labelEn,
                    labelfr: record.labelFr,
                  });
                }}
              >
                <EditOutlined />
                {t("edit")}{" "}
              </Button>
              <Popconfirm
                title={t("deleterow")}
                onConfirm={() => handleDelete(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link " danger>
                  <DeleteOutlined />
                  {t("Delete")}
                </Button>
              </Popconfirm>
            </>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <div>
        {contextHolder}
        {/* <h3>{t("Loansmanagement")}</h3> <br />
      <div> */}
        <Card bordered={false} className="header-solid mb-24">
          <h3 className="font-semibold">
            <Divider>
              <SettingOutlined style={{ color: "#3947C4" }} />
              {t("Projectbenefits")}
            </Divider>
          </h3>
          <Row justify="space-between">
            <Space style={{ marginBottom: 15 }}>
              <Button
                className="Create-button"
                type="primary"
                onClick={() => {
                  setOpen(true);
                }}
              >
                {t("Create")}
              </Button>
              <CollectionCreateForm
                open={open}
                onCreate={submite}
                onCancel={() => {
                  setOpen(false);
                  // onAddChamp();
                }}
              />
            </Space>
          </Row>
          <Form form={form1} onFinish={onFinishEdit}>
            <Table
              bordered
              columns={columns}
              dataSource={Projectbenefit}
              style={{ overflowX: "auto", backgroundColor: "white" }}
            />
          </Form>
        </Card>
      </div>
    </>
  );
}

export default InvestismentCategory;

import {
  Tag,
  Button,
  Card,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import { useEffect, useState, useContext } from "react";
import { CompanyContext } from "../../../contexts/CompanyContext";
import { useHistory } from "react-router-dom"; // Import useHistory
import SharedSpinner from "../../../assets/js/SharedSpinner";
import {
  CheckCircleOutlined,
  CheckSquareTwoTone,
  CloseOutlined,
  CloseSquareTwoTone,
  DeleteTwoTone,
  ArrowLeftOutlined,
  EditTwoTone,
  FileAddOutlined,
  PlusSquareTwoTone,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import axios from "axios";
import { JSON_API } from "../../../services/Constants";
import { useLocation } from "react-router-dom";
import { useAbility } from "../../../contexts/abilityContext";

const getUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const transform = (parts) => {
  const data = [];
  const transformedParts = [];
  parts.forEach((part) => {
    const [transformedData, transformedCategories, transformedRows] =
      transformCategories(part.categories, part.rows);
    const key = getUUID();
    data.push({
      key: key,
      id: part.id,
      name: part.name,
      children: transformedData,
    });
    transformedParts.push({
      ...part,
      key: key,
      categories: transformedCategories,
      rows: transformedRows,
    });
  });
  return [data, transformedParts];
};

const transformCategories = (categories, rows, type) => {
  const data = [];
  const transformedCategories = [];
  const transformedRows = [];
  if (categories)
    categories.forEach((category) => {
      if (category) {
        const key = getUUID();
        const [transformedData, transformedCategories1, transformedRows1] =
          transformCategories(category.children, category.rows);
        data.push({
          key: key,
          id: category.id,
          name: category.name,
          glNumber: category.glNumber,
          periods: category.periods,
          budgetTotal: category.budgetTotal,
          realTotal: category.realTotal,
          performanceTotal: category.performanceTotal,
          confirmed: category.confirmed,
          hypothesis: category.hypothesis,
          isCustom: !!category.isCustom,
          addRow: category.confirmed === undefined ? true : false,

          children: transformedData,
        });
        transformedCategories.push({
          ...category,
          key: key,
          rows: transformedRows1,
          children: transformedCategories1,
        });
      }
    });
  if (rows)
    rows.forEach((row) => {
      if (row) {
        const key = getUUID();
        const [transformedData, transformedCategories2, transformedRows2] =
          transformCategories(row.children);
        data.push({
          key: key,
          id: row.id,
          name: row.name,
          glNumber: row.glNumber,
          periods: row.periods,
          budgetTotal: row.budgetTotal,
          realTotal: row.realTotal,
          performanceTotal: row.performanceTotal,
          confirmed: row.confirmed,
          hypothesis: row.hypothesis,
          editable: true,
          isCustom: !!row.isCustom,
          isSpecial: !!row.isSpecial,
          children: transformedData,
        });
        transformedRows.push({
          ...row,
          key: key,
          children: transformedCategories2,
        });
      }
    });
  return [data, transformedCategories, transformedRows];
};

const EditButton = ({ onClick, disabled }) => {
  const { t } = useTranslation();
  const { ability } = useAbility();
  return (
    <>
      {ability && ability.can("update", "CashFlow") && (
        <Button
          size="small"
          type="link"
          onClick={onClick}
          disabled={disabled}
          icon={<EditTwoTone twoToneColor={"#ff9e01"} />}
        >
          <Typography.Text
            style={{
              marginLeft: 0,
            }}
          >
            {t("Edit")}
          </Typography.Text>
        </Button>
      )}
    </>
  );
};
const SaveButton = ({ onClick }) => {
  const { t } = useTranslation();
  const { ability } = useAbility();
  return (
    <>
      {ability && ability.can("update", "CashFlow") && (
        <Button
          size="small"
          type="link"
          onClick={onClick}
          icon={<CheckSquareTwoTone twoToneColor={"#4bc444"} />}
        >
          <Typography.Text
            style={{
              marginLeft: 0,
            }}
          >
            {t("SaveValues")}
          </Typography.Text>
        </Button>
      )}
    </>
  );
};
const CancelButton = ({ onClick }) => {
  const { t } = useTranslation();
  const { ability } = useAbility();
  return (
    <>
      {ability && ability.can("update", "CashFlow") && (
        <Button
          size="small"
          type="link"
          onClick={onClick}
          icon={<CloseSquareTwoTone twoToneColor={"#f44336"} />}
        >
          <Typography.Text
            style={{
              marginLeft: 0,
            }}
          >
            {t("Cancel")}
          </Typography.Text>
        </Button>
      )}
    </>
  );
};
const AddButton = ({ onClick, text }) => {
  const { ability } = useAbility();
  return (
    <>
      {ability && ability.can("create", "CashFlow") && (
        <Button
          size="small"
          type="link"
          onClick={onClick}
          icon={<PlusSquareTwoTone twoToneColor={"#127cd1"} />}
        >
          <Typography.Text
            style={{
              marginLeft: 0,
            }}
          >
            {text}
          </Typography.Text>
        </Button>
      )}
    </>
  );
};
const DeleteButton = ({ onClick }) => {
  const { ability } = useAbility();
  return (
    <>
      {ability && ability.can("delete", "CashFlow") && (
        <Button
          size="small"
          type="link"
          onClick={onClick}
          icon={<DeleteTwoTone twoToneColor={"#f44336"} />}
        >
          <Typography.Text
            style={{
              marginLeft: 0,
            }}
          >
            Row
          </Typography.Text>
        </Button>
      )}
    </>
  );
};
const EditableCell = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  ...restProps
}) => {
  const { t } = useTranslation();

  const isPeriod = dataIndex && dataIndex.includes("periods");
  const inputNode = isPeriod ? (
    <InputNumber size="small" />
  ) : (
    <Input size="small" />
  );

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
function findObjectByKey(data, key) {
  if (!data) {
    return null;
  }
  for (let i = 0; i < data.length; i++) {
    if (data[i].key && data[i].key === key) {
      return data[i];
    } else if (
      Array.isArray(data[i].rows) ||
      Array.isArray(data[i].categories) ||
      Array.isArray(data[i].children)
    ) {
      let resultrows = findObjectByKey(data[i].rows, key);
      let resultcategories = findObjectByKey(data[i].categories, key);
      let resultchildren = findObjectByKey(data[i].children, key);

      if (resultrows) {
        return resultrows;
      }
      if (resultcategories) {
        return resultcategories;
      }
      if (resultchildren) {
        return resultchildren;
      }
    }
  }
  return null;
}
function findObjectByKeyAndReplace(data, key, newObject) {
  if (!data) {
    return null;
  }
  for (let i = 0; i < data.length; i++) {
    if (data[i].key && data[i].key === key) {
      data[i] = newObject;
      return data;
    } else if (
      Array.isArray(data[i].children) ||
      Array.isArray(data[i].rows) ||
      Array.isArray(data[i].categories)
    ) {
      let resultchildren = findObjectByKeyAndReplace(
        data[i].children,
        key,
        newObject
      );
      let resultrows = findObjectByKeyAndReplace(data[i].rows, key, newObject);
      let resultcategories = findObjectByKeyAndReplace(
        data[i].categories,
        key,
        newObject
      );

      if (resultchildren) {
        data[i].children = resultchildren;
        return data;
      }
      if (resultrows) {
        data[i].rows = resultrows;
        return data;
      }
      if (resultcategories) {
        data[i].categories = resultcategories;
        return data;
      }
    }
  }
  return null;
}
const AddRowModal = ({
  open,
  periodNumber,
  addRow,
  onCancel,
  loading,
  chartAccounts,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      title={t("addRow")}
      okText={t("Add")}
      cancelText={t("Cancel")}
      onCancel={onCancel}
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            const periods = [];
            for (let i = 1; i <= periodNumber; i++) {
              periods.push({
                key: getUUID(),
                value: values[`period-${i}`],
              });
            }
            values.periods = periods;
            addRow(values);
          })
          .catch((info) => {});
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: "public",
        }}
      >
        <Form.Item
          name="name"
          label={t("Name")}
          rules={[
            {
              required: true,
              message: t("pleaseinputname"),
            },
          ]}
        >
          <Input></Input>
        </Form.Item>
        <Form.Item name="chartaccount" label={t("Chartofaccount")}>
          <Select defaultValue={-1}>
            <Select.Option value={-1}>{t("None")}</Select.Option>
            {chartAccounts.map((chartAccount, i) => (
              <Select.Option key={chartAccount.id} value={i}>
                {chartAccount.glAccount.glNumber}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="assumption" label={t("Hypotheses")}>
          <Input></Input>
        </Form.Item>
      </Form>
    </Modal>
  );
};
const types = {
  budget: "budget",
  real: "real",
  performance: "performance",
};
const CashFlowInstanceDetails = () => {
  const { ability } = useAbility();
  const [numOfPeriods, setNumOfPeriods] = useState(0);
  const { Company } = useContext(CompanyContext);
  const { id } = useParams();
  const { freq } = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [editingKey, setEditingKey] = useState("");
  const [addRowKey, setAddRowKey] = useState("");
  const [form] = Form.useForm();
  const [tableForm] = Form.useForm();
  const [instance, setInstance] = useState(null);
  const [data, setData] = useState(null);
  const [addRowModalOpen, setAddRowModalOpen] = useState(false);
  const [type, setType] = useState(types.budget);
  const [mergedColumns, setMergedColumns] = useState([]);
  let { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const isEditing = (record) => record.key === editingKey;
  const [nameEditModalVisible, setNameEditModalVisible] = useState(false);
  const [editNameLoading, setEditNameLoading] = useState(false);
  const [addRowLoading, setAddRowLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chartAccounts, setChartAccounts] = useState([]);
  const history = useHistory(); // Initialize useHistory
  const [frequency, setFrequency] = useState(params.get("freq"));
  const getInstance = async () => {
    await axios
      .get(`${JSON_API}/aaCashFlows/${id}`)
      .then((res) => {
        const [transformedData, transformedParts] = transform(res.data.parts);
        setData(transformedData);
        setNumOfPeriods(getPeriodsLength(transformedData[0]));
        setInstance({
          ...res.data,
          parts: transformedParts,
        });
        setFrequency(res.data.frequency);
      })
      .catch((err) => {});
  };
  const handleDelete = async (id) => {
    await axios
      .delete(`${JSON_API}/aaCashFlows/Instance/Row/${id}`)
      .then((res) => {
        getInstance();
      })
      .catch((err) => {
        messageApi.error(t("deleteRowError"));
      });
  };
  useEffect(() => {
    let isMounted = true;

    const getInstance1 = async () => {
      try {
        const res = await axios.get(`${JSON_API}/aaCashFlows/${id}`);
        const [transformedData, transformedParts] = transform(res.data.parts);
        if (isMounted) {
          setData(transformedData);
          setNumOfPeriods(res.data.numOfPeriods);
          setInstance({
            ...res.data,
            parts: transformedParts,
          });
          setFrequency(instance.frequency);
        }
      } catch (err) {}
    };

    const getChartAccounts1 = async () => {
      try {
        const res = await axios.get(
          `${JSON_API}/ChartAccounts?enterpriseId=${Company.id}`
        );
        if (isMounted) {
          console.log("getChartAccounts1 -> res.data", res.data);
          setChartAccounts(res.data);
        }
      } catch (err) {}
    };

    // Call the functions
    getInstance1();
    getChartAccounts1();

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [id]);

  const getStartPeriods = (data) => {
    if (!data) return;
    if (data.periods) {
      const endPeriods = [];
      data.periods.forEach((period) => {
        endPeriods.push(`${period.startPeriod}`);
      });
      return endPeriods;
    } else {
      return getStartPeriods(data.children[0]);
    }
  };
  const getEndPeriods = (data) => {
    if (!data) return;
    if (data.periods) {
      const endPeriods = [];
      data.periods.forEach((period) => {
        endPeriods.push(`${period.endPeriod}`);
      });
      return endPeriods;
    } else {
      return getEndPeriods(data.children[0]);
    }
  };
  const getPeriodsLength = (data) => {
    if (!data) return;
    if (data.periods) {
      return data.periods.length;
    } else {
      return getPeriodsLength(data.children[0]);
    }
  };
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const generatePeriodColumns = async (numOfPeriods, key) => {
    const periodColumns = [];
    const endPeriods = data && data.length !== 0 ? getEndPeriods(data[0]) : [];
    const startPeriods =
      data && data.length !== 0 ? getStartPeriods(data[0]) : [];
    const date = startPeriods[0] ? new Date(startPeriods[0]) : null;
    const startIndex = date?.getMonth();
    const reorderedMonthNames = monthNames
      .slice(startIndex)
      .concat(monthNames.slice(0, startIndex));
    // const parsedEndDate = end ? new Date(end).toLocaleDateString() : "";
    for (let i = 1; i <= numOfPeriods; i++) {
      const parsedEndDate = endPeriods[i - 1]
        ? new Date(endPeriods[i - 1]).toLocaleDateString()
        : "";
      const parsedStartDate = startPeriods[i - 1]
        ? new Date(startPeriods[i - 1]).toLocaleDateString()
        : "";
      // const monthName = date.toLocaleString("en-US", { month: "long" });
      if (key === types.performance) {
        periodColumns.push({
          title:
            frequency == decodeURIComponent("F$rQ$")
              ? t(reorderedMonthNames[i - 1]?.toString())
              : `${parsedStartDate + " " + t("to") + " " + parsedEndDate}`,
          align: "center",
          editable: true,
          children: [
            {
              title: t("Budget"),
              dataIndex: ["periods", i - 1, types.budget],
              key: `period${i}`,
              align: "center",
              editable: true,
              width: 130,
              render: (text, record) => {
                if (text !== 0 && text !== 0 && (!text || text === "")) {
                  return null;
                }
                return text < 0 ? (
                  <div
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      textAlign: "right",
                    }}
                  >
                    ${text}
                  </div>
                ) : (
                  <div style={{ fontWeight: "bold", textAlign: "right" }}>
                    ${text}
                  </div>
                );
              },
            },

            {
              title: t("Real"),
              dataIndex: ["periods", i - 1, types.real],
              key: `period${i}`,
              width: 130,
              editable: true,
              align: "center",
              render: (text, record) => {
                if (text !== 0 && text !== 0 && (!text || text === "")) {
                  return null;
                }
                return text < 0 ? (
                  <div
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      textAlign: "right",
                    }}
                  >
                    ${text}
                  </div>
                ) : (
                  <div style={{ fontWeight: "bold", textAlign: "right" }}>
                    ${text}
                  </div>
                );
              },
            },
            {
              title: t("Difference"),
              dataIndex: ["periods", i - 1, types.performance],
              align: "center",
              width: 130,
              key: `period${i}`,
              render: (text, record) => {
                if (text !== 0 && text !== 0 && (!text || text === "")) {
                  return null;
                }

                return text < 0 ? (
                  <div
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      textAlign: "right",
                    }}
                  >
                    ${text}
                  </div>
                ) : (
                  <div style={{ fontWeight: "bold", textAlign: "right" }}>
                    ${text}
                  </div>
                );
              },
            },
          ],
        });
      } else {
        periodColumns.push({
          title: decodeURIComponent("F$rQ$")
            ? t(reorderedMonthNames[i - 1]?.toString())
            : `${parsedStartDate + " " + t("to") + " " + parsedEndDate}`,
          dataIndex: ["periods", i - 1, key],
          align: "center",
          width: 130,
          key: `period${i}`,
          editable: true,
          render: (text, record) => {
            if (text !== 0 && (!text || text === "")) {
              return null;
            }
            return text < 0 ? (
              <div
                style={{ color: "red", fontWeight: "bold", textAlign: "right" }}
              >
                ${text}
              </div>
            ) : (
              <div style={{ fontWeight: "bold", textAlign: "right" }}>
                ${text}
              </div>
            );
          },
        });
      }
    }
    return periodColumns;
  };
  const edit = (record) => {
    tableForm.setFieldsValue({
      hypothesis: "",
      ...record,
    });

    setEditingKey(record.key);
  };
  const save = async (key) => {
    console.log("save -> key", key);
    try {
      const { hypothesis, periods } = await tableForm.validateFields();
      console.log("save -> hypothesis", hypothesis);
      console.log("save -> periods", periods);

      const row = findObjectByKey(instance.parts, key);

      console.log("save -> row", row);

      const newPeriods = row.periods.map((period, i) => {
        console.log("save -> period", period);
        return {
          ...period,
          budget: periods[i].budget ?? period.budget,
          real: periods[i].real ?? period.real,
          performance: periods[i].performance ?? period.performance,
        };
      });
      console.log("save -> newPeriods", newPeriods);
      const newRow = {
        ...row,
        hypothesis: hypothesis,
        periods: newPeriods,
      };
      console.log("save -> newRow", newRow);
      const newData = findObjectByKeyAndReplace(instance.parts, key, newRow);

      console.log("save -> newData", newData);
      const newInstance = {
        ...instance,
        parts: newData,
      };
      console.log("save -> newInstance", newInstance);
      setLoading(true);
      await axios
        .put(`${JSON_API}/aaCashFlows`, newInstance)
        .then((res) => {
          setEditingKey("");
          const [transformedData, transformedParts] = transform(res.data.parts);
          setData(transformedData);
          setInstance({
            ...res.data,
            parts: transformedParts,
          });
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          messageApi.error("Error while saving row");
        });
    } catch (errInfo) {}
  };
  const handleOpenAddRowModal = (key) => {
    setAddRowKey(key);
    setAddRowModalOpen(true);
  };
  const handleClosedAddRowModal = () => {
    setAddRowModalOpen(false);
    setAddRowKey("");
  };

  const cancel = () => {
    setEditingKey("");
  };
  const addRow = async (parentKey, newRecord) => {
    setAddRowLoading(true);
    const parent = findObjectByKey(instance.parts, parentKey);
    console.log("parent", parent);

    const chartAccountIndex = newRecord.chartaccount;
    const chartAccount =
      chartAccountIndex === -1 ? null : chartAccounts[chartAccountIndex];
    const startPeriods = getStartPeriods(data[0]);
    const endPeriods = getEndPeriods(data[0]);
    const formatDateString = (dateString) => {
      const date = new Date(dateString);
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
  };
    const newRow = {
      isCustom: true,
      id: 0,
      name: newRecord.name,
      chartAccountId: chartAccount ? chartAccount.id : 0,
      hypothesis: newRecord.assumption,
      periods: startPeriods.map((startPeriod, index) => ({
        key: getUUID(),
        id: 0,
        budget: 0,
        real: 0,
        performance: 0,
        startPeriod: formatDateString(startPeriod),
        endPeriod: formatDateString(endPeriods[index])
    })),
      glNumber: chartAccount ? chartAccount.glAccount.glNumber : "",
      budgetTotal: 0,
      realTotal: 0,
      performanceTotal: 0,
      confirmed: false,
    };

    if (!parent.rows) {
      parent.rows = [];
    }
    parent.rows = [...parent.rows, newRow];
    const newData = findObjectByKeyAndReplace(
      instance.parts,
      parentKey,
      parent
    );

    const newInstance = {
      ...instance,
      parts: newData,
    };
    await axios
      .put(`${JSON_API}/aaCashFlows`, newInstance)
      .then((res) => {
        const [transformedData, transformedParts] = transform(res.data.parts);
        setData(transformedData);
        setInstance({
          ...res.data,
          parts: transformedParts,
        });
        setAddRowLoading(false);
        setAddRowModalOpen(false);
        //remove chart account from chart accounts
        if (chartAccountIndex !== -1) {
          chartAccounts.splice(chartAccountIndex, 1);
        }
      })
      .catch((err) => {
        messageApi.error("Error while adding row");
      });
  };
  const thePeriodColumns = [generatePeriodColumns(numOfPeriods, type)];

  const columns = [
    {
      title: t("Name"),
      dataIndex: "name",
      key: "name",
      align: "center",
      width: 350,
      render: (text, record) => {
        const isSpecial = record.isSpecial;
        if (isSpecial) {
          return (
            <div style={{ fontWeight: "bold", textAlign: "left" }}>{text}</div>
          );
        }
        return <div style={{ textAlign: "left" }}>{text}</div>;
      },
    },

    { title: t("GLnumber"), dataIndex: "glNumber", key: "glNumber" },
    {
      title: t("Hypotheses"),
      dataIndex: "hypothesis",
      key: "hypothesis",
      align: "center",
      editable: true,
      render: (text, record) => {
        return <div style={{ textAlign: "left" }}>{text}</div>;
      },
    },
    ...thePeriodColumns,
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      align: "center",
      render: (text, record) => {
        if (text !== 0 && text !== 0 && (!text || text === "")) {
          return null;
        }
        return (
          <span style={{ fontWeight: "bold", textAlign: "center" }}>
            {text}$
          </span>
        );
      },
    },
    {
      align: "left",
      width: 0,
      fixed: "right",
      render: () => {
        return <span style={{ display: "none" }}></span>;
      },
    },
    {
      width: 0,
      className: "hidden-column",
      render: () => {
        return <span style={{ display: "none" }}></span>;
      },
    },
  ];
  if (type == types.budget) {
    columns.push({
      title: t("Confirmed"),
      dataIndex: "confirmed",
      key: "confirmed",
      align: "center",
      width: 130,
      render: (value, record) => {
        if (record.confirmed === undefined || record.isSpecial) {
          return null;
        }
        if (value) {
          return (
            <Tag
              color="green"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                borderRadius: "5px",
                padding: "5px 10px",
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              {t("Confirmed")}
            </Tag>
          );
        } else {
          return (
            <>
              {ability.can("update", "CashFlow") && (
                <Button
                  type="primary"
                  size="small"
                  disabled={loading || editingKey !== ""}
                  onClick={() => {
                    handleConfirm(record.key);
                  }}
                >
                  {t("Confirm")}
                </Button>
              )}
            </>
          );
        }
      },
    });
  }
  if (type !== types.performance) {
    columns.push({
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      render: (_, record) => {
        const editable = isEditing(record);
        if (editable) {
          return (
            <span>
              <SaveButton onClick={() => save(record.key)} />
              <Popconfirm title={t("Suretocancel")} onConfirm={cancel}>
                <CancelButton />
              </Popconfirm>
            </span>
          );
        } else {
          const EditingCondition =
            record.isSpecial ||
            record.confirmed === undefined ||
            (type === types.budget
              ? record.confirmed
              : !record.confirmed) ? null : (
              <EditButton
                disabled={editingKey !== "" || loading}
                onClick={() => {
                  edit(record);
                }}
              />
            );
          const Deletable = record.isCustom ? (
            <Popconfirm
              title={t("Suretodelete")}
              onConfirm={() => {
                handleDelete(record.id);
              }}
            >
              <DeleteButton />
            </Popconfirm>
          ) : null;
          return (
            <Space>
              {record.addRow && type == types.budget ? (
                <AddButton
                  onClick={() => handleOpenAddRowModal(record.key)}
                  text={t("addRow")}
                />
              ) : null}
              {EditingCondition}
              {Deletable}
            </Space>
          );
        }
      },
    });
  }

  useEffect(() => {
    handleTypeChange();

    return () => {};
  }, [type, data, instance, editingKey, loading, numOfPeriods]);
  const handleTypeChange = async () => {
    if (type === types.budget) {
      const tempColumns = [...columns];
      //get 3 first and last columns
      const firstColumns = tempColumns.slice(0, 3);
      const lastColumns = tempColumns.slice(tempColumns.length - 2);
      //get period columns
      const periodColumns = await generatePeriodColumns(numOfPeriods, type);
      const total = {
        title: "Total",
        dataIndex: "budgetTotal",
        key: "total",
        align: "center",
        width: 130,
        render: (text, record) => {
          if (text !== 0 && (!text || text === "")) {
            return null;
          }
          return (
            <div style={{ fontWeight: "bold", textAlign: "right" }}>
              {text}$
            </div>
          );
        },
      };
      setMergedColumns(
        [...firstColumns, ...periodColumns, total, ...lastColumns].map(
          (col) => {
            if (!col.editable) {
              return col;
            }
            return {
              ...col,
              onCell: (record) => ({
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
              }),
            };
          }
        )
      );
    } else if (type === types.real) {
      const tempColumns = [...columns];
      //get 3 first and last columns
      const firstColumns = tempColumns.slice(0, 3);
      const lastColumns = tempColumns.slice(tempColumns.length - 2);
      //get period columns
      const periodColumns = await generatePeriodColumns(numOfPeriods, type);
      const total = {
        title: "Total",
        dataIndex: "realTotal",
        key: "total",
        align: "center",
        width: 130,
        render: (text, record) => {
          if (text !== 0 && (!text || text === "")) {
            return null;
          }
          return (
            <div style={{ fontWeight: "bold", textAlign: "right" }}>
              {text}$
            </div>
          );
        },
      };
      setMergedColumns(
        [...firstColumns, ...periodColumns, total, ...lastColumns].map(
          (col) => {
            if (!col.editable) {
              return col;
            }
            return {
              ...col,
              onCell: (record) => ({
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
              }),
            };
          }
        )
      );
    } else if (type === types.performance) {
      const tempColumns = [...columns];
      //get 3 first and last columns
      const firstColumns = tempColumns.slice(0, 3);
      const lastColumns = tempColumns.slice(tempColumns.length - 2);
      //get period columns
      const periodColumns = await generatePeriodColumns(numOfPeriods, type);
      const total = {
        title: "Total",
        dataIndex: "performanceTotal",
        key: "total",
        align: "center",
        width: 130,
        render: (text, record) => {
          if (text !== 0 && (!text || text === "")) {
            return null;
          }
          return (
            <div style={{ fontWeight: "bold", textAlign: "right" }}>
              {text}$
            </div>
          );
        },
      };
      setMergedColumns(
        [...firstColumns, ...periodColumns, total, ...lastColumns].map(
          (col) => {
            if (!col.editable) {
              return col;
            }
            if (col.children) {
              return {
                ...col,
                children: col.children.map((child) => {
                  if (!child.editable) {
                    return child;
                  }
                  return {
                    ...child,
                    onCell: (record) => ({
                      record,
                      dataIndex: child.dataIndex,
                      title: child.title,
                      editing: isEditing(record),
                    }),
                  };
                }),
              };
            }

            return {
              ...col,
              onCell: (record) => ({
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
              }),
            };
          }
        )
      );
    }
  };
  const handleUpdateName = async (values) => {
    const { label } = values;
    setEditNameLoading(true);
    const instanceDeepCopy = JSON.parse(JSON.stringify(instance));
    instanceDeepCopy.name = label;

    await axios
      .put(`${JSON_API}/aaCashFlows`, instanceDeepCopy)
      .then(async (res) => {
        setEditNameLoading(false);
        setNameEditModalVisible(false);
        await getInstance();
        messageApi.success(t("NameUpdatedSuccessfully"));
      })
      .catch((err) => {
        setEditNameLoading(false);
        messageApi.error(t("FailedToUpdateName"));
      });
  };
  const handleConfirm = async (key) => {
    const row = findObjectByKey(instance.parts, key);
    const newRow = {
      ...row,
      confirmed: true,
    };

    const newData = findObjectByKeyAndReplace(instance.parts, key, newRow);
    const newInstance = {
      ...instance,
      parts: newData,
    };
    setLoading(true);
    await axios
      .put(`${JSON_API}/aaCashFlows`, newInstance)
      .then((res) => {
        const [transformedData, transformedParts] = transform(res.data.parts);
        setData(transformedData);
        setInstance({
          ...res.data,
          parts: transformedParts,
        });
        setLoading(false);
      })
      .catch((err) => {
        messageApi.error(t("ErrorWhileConfirming"));
        setLoading(false);
      });
  };
  const handleReturn = () => {
    history.goBack();
  };
  return (
    <div>
      {contextHolder}
      <Modal
        open={nameEditModalVisible}
        title={
          <>
            <FileAddOutlined />
            <span style={{ marginLeft: "8px", marginBottom: "25px" }}>
              {t("modifyInstanceName")}
            </span>
          </>
        }
        onCancel={() => setNameEditModalVisible(false)}
        confirmLoading={editNameLoading}
        footer={[
          <Button
            type="primary"
            danger
            key="cancel"
            onClick={() => setNameEditModalVisible(false)}
            icon={<CloseOutlined />}
          >
            {t("Cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            icon={<CheckCircleOutlined />}
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  // handleSubmit(values);
                  handleUpdateName(values);
                })
                .catch((info) => {});
            }}
          >
            {t("Edit")}
          </Button>,
        ]}
        style={{ marginTop: "50px", marginBottom: "50px" }}
        bodyStyle={{ marginTop: "2px", marginBottom: "25px" }}
      >
        <Form form={form}>
          <Form.Item
            label={t("Name")}
            name="label"
            rules={[
              {
                required: true,
                message: t("PleaseInputName"),
              },
            ]}
            initialValue={instance ? instance.name : ""}
          >
            <Input placeholder={t("SectionName")} />
          </Form.Item>
        </Form>
      </Modal>
      <Card>
        <Button type="link" icon={<ArrowLeftOutlined />} onClick={handleReturn}>
          {t("GoBack")}
        </Button>
        <Row justify="center">
          <Space>
            <span style={{ fontSize: "2rem" }}>
              {instance ? instance.name : ""}
            </span>
            <EditButton
              onClick={() => {
                setNameEditModalVisible(true);
              }}
            />
          </Space>
        </Row>
        <Row
          justify="center"
          style={{
            marginBottom: 32,
          }}
        >
          <Space>{loading && <SharedSpinner />}</Space>
        </Row>
        <Row
          justify="space-between"
          style={{
            marginBottom: 16,
          }}
        >
          <Space>
            <Typography style={{ fontWeight: "bold" }}>
              {" "}
              {t("show")} :{" "}
            </Typography>
            <Select
              onChange={(value) => {
                setType(value);
              }}
              value={type}
              style={{ width: 150 }}
            >
              <Select.Option value={types.budget}>{t("Budget")}</Select.Option>
              <Select.Option value={types.real}>{t("Real")}</Select.Option>
              <Select.Option value={types.performance}>
                {t("Performance")}
              </Select.Option>
            </Select>
          </Space>
        </Row>
        <AddRowModal
          open={addRowModalOpen}
          onCancel={handleClosedAddRowModal}
          periodNumber={numOfPeriods}
          addRow={(values) => addRow(addRowKey, values)}
          loading={addRowLoading}
          chartAccounts={chartAccounts}
        />
        <Form form={tableForm} component={false}>
          <Table
            key={Date.now()}
            bordered
            columns={mergedColumns}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            scroll={{ x: type === "performance" ? 5000 : 2700 }}
            dataSource={data}
            expandable={{
              defaultExpandAllRows: true,
              rowExpandable: (record) => {
                if (record.children && record.children.length > 0) {
                  return true;
                }
                return false;
              },
            }}
            loading={loading}
          />
        </Form>
      </Card>
    </div>
  );
};
CashFlowInstanceDetails.acl = {
  subject: "CashFlow",
  action: "read",
};

export default CashFlowInstanceDetails;

export function generateMonthsArray(startPeriod) {
    // Validate the startPeriod input
    if (startPeriod < 1 || startPeriod > 12) {
        throw new Error("startPeriod must be between 1 and 12");
    }

    // Create an array representing the months of the year
    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    // Split the array into two parts and concatenate them
    const firstPart = months.slice(startPeriod - 1);
    const secondPart = months.slice(0, startPeriod - 1);

    return firstPart.concat(secondPart);
}
export function getRealOrEstimateForMonthHeaderTitle(
    fiscalYearOfMonth,
    currentYear,
    month,
    currentMonth
) {
    if (fiscalYearOfMonth < currentYear) {
        return "Real";
    } else if (fiscalYearOfMonth === currentYear) {
        return month < currentMonth ? "Real" : "Estimated";
    } else {
        return "Estimated";
    }
}

export function getFiscalYearForMonth(month, startPeriod, year) {
    if (startPeriod === 1) return year;
    if (month >= startPeriod) {
        return year - 1;
    }
    return year;
}
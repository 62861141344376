import { Menu, Button, Layout, Tooltip } from "antd";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/blueskylogo.png";
import { CompanyContext } from "../../contexts/CompanyContext";

import {
  AimOutlined,
  AppstoreAddOutlined,
  BlockOutlined,
  BookFilled,
  BookOutlined,
  DollarCircleFilled,
  HomeFilled,
  NotificationOutlined,
  ProfileOutlined,
  ReconciliationOutlined,
  SettingOutlined,
  UnlockOutlined,
  UserOutlined,
  UserSwitchOutlined,
  UsergroupAddOutlined,
  FileSearchOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  FullscreenExitOutlined,
} from "@ant-design/icons";
import SubMenu from "antd/es/menu/SubMenu";
import { useAbility } from "../../contexts/abilityContext";
import { JSON_API_logo } from "../../services/Constants";

function Sidenav({ color, onCollapse }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  let { t } = useTranslation();
  const { ability } = useAbility();
  const { Company } = useContext(CompanyContext);
  const [collapsed, setCollapsed] = useState(false);
  const { Sider } = Layout;

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    onCollapse(!collapsed); // Notify the parent of the collapse state change
  };

  const menuStructure = [
    {
      title: t("general"),
      items: [
        {
          title: t("dashboard"),
          icon: <HomeFilled />,
          link: "/dashboard",
          subject: "Dashboard",
          action: "read",
        },
        {
          title: t("generalinf"),
          icon: <FullscreenExitOutlined />,
          link: "/generalinformations",
          subject: "General Informations",
          action: "read",
        },
      ],
    },
    {
      title: t("strategicplanning"),
      items: [
        {
          title: t("strategicplanning"),
          icon: <AimOutlined />,
          link: "/strategic_planning",
          subject: "Strategic Planning",
          action: "read",
        },
      ],
    },
    {
      title: t("sales"),
      items: [
        {
          title: t("Dashboard"),
          icon: <BookFilled />,
          link: "/SalesDashboard",
          subject: "Order book",
          action: "read",
        },
        {
          title: t("SalesBudgetDetails"),
          icon: <FileSearchOutlined />,
          link: "/sales-budget",
          subject: "Sales Budget",
          action: "read",
        },
        {
          title: t("orderbook"),
          icon: <BookFilled />,
          link: "/orderbooks",
          subject: "Order book",
          action: "read",
        },
        {
          title: t("opportunitybook"),
          icon: <BookOutlined />,
          link: "/opportunities",
          subject: "Opportunity Book",
          action: "read",
        },

        {
          title: t("PerformanceMonitoring"),
          icon: <BookOutlined />,
          link: "/performance-monitoring",
          subject: "Sales Budget",
          action: "read",
        },
      ],
    },
    {
      title: t("Revenues"),
      items: [
        {
          title: t("Dashboard"),
          icon: <ReconciliationOutlined />,
          link: "/RevenueDashboard",
          subject: "Revenue",
          action: "read",
        },
        {
          title: t("RevenueDetail"),
          icon: <ReconciliationOutlined />,
          link: "/revenue",
          subject: "Revenue",
          action: "read",
        },
      ],
    },
    // {
    //   title: t("SalesBudget"),
    //   items: [
    //     {
    //       title: t("SalesBudgetDetails"),
    //       icon: <FileSearchOutlined />,
    //       link: "/sales-budget",
    //       subject: "Sales Budget",
    //       action: "read",
    //     },
    //   ],
    // },
    {
      title: t("Expenses"),
      items: [
        {
          title: t("Dashboard"),
          icon: <ReconciliationOutlined />,
          link: "/ExpenseDashboard",
          subject: "Expense",
          action: "read",
        },
        {
          title: t("ExpenseDetails"),
          icon: <ReconciliationOutlined />,
          link: "/expense",
          subject: "Expense",
          action: "read",
        },
      ],
    },
    {
      title: t("HumanResources"),
      items: [
        {
          title: t("Dashboard"),
          icon: <ReconciliationOutlined />,
          link: "/RHDashboard",
          subject: "HR planning",
          action: "read",
        },
        {
          title: t("HumanResource"),
          icon: <UserOutlined />,
          link: "/human_resources",
          subject: "Employees Management",
          action: "read",
        },
        {
          title: t("HumanResourcesPlanning"),
          icon: <UserOutlined />,
          link: "/human_resources_planning",
          subject: "HR planning",
          action: "read",
        },
      ],
    },
    {
      title: t("Marketing"),
      items: [
        {
          title: t("Dashboard"),
          icon: <NotificationOutlined />,
          link: "/MarketingDashboard",
          subject: "Marketing Expenses",
          action: "read",
        },
        {
          title: t("Marketingexpenses"),
          icon: <NotificationOutlined />,
          link: "/marketing",
          subject: "Marketing Expenses",
          action: "read",
        },
      ],
    },
    {
      title: t("Production"),
      items: [
        {
          title: t("grossMargins"),
          icon: <ReconciliationOutlined />,
          link: "/devis",
          subject: "Gross Margins",
          action: "read",
        },
        {
          title: t("AssignmentTracking"),
          icon: <BookFilled />,
          link: "/AssignmentTracking",
          subject: "Assignment Tracking",
          action: "read",
        },
      ],
    },
    {
      title: t("Financialstatements"),
      items: [
        {
          title: t("Asset"),
          icon: <ReconciliationOutlined />,
          link: "/Assets",
          subject: "Assets",
          action: "read",
        },
        {
          title: t("liabilities"),
          icon: <ReconciliationOutlined />,
          link: "/liabilities",
          subject: "Liabilities",
          action: "read",
        },
        {
          title: t("equity"),
          icon: <ReconciliationOutlined />,
          link: "/equity",
          subject: "Retained Earnings",
          action: "read",
        },
      ],
    },
    {
      title: t("Finance"),
      items: [
        {
          title: t("Loan"),
          icon: <DollarCircleFilled />,
          link: "/loan",
          subject: "Loans",
          action: "read",
        },
        {
          title: t("Margin"),
          icon: <DollarCircleFilled />,
          link: "/margin",
          subject: "Margins",
          action: "read",
        },
      ],
    },
    {
      title: t("CashFlow"),
      items: [
        {
          title: t("CashFlowInstances"),
          icon: <SettingOutlined />,
          link: "/CashFlowInstances",
          subject: "CashFlow",
          action: "read",
        },
      ],
    },
    {
      title: t("Investments"),
      items: [
        {
          title: t("Dashboard"),
          icon: <DollarCircleFilled />,
          link: "/InvestmentDashboard",
          subject: "Investments",
          action: "read",
        },
        {
          title: t("ProjectFile"),
          icon: <DollarCircleFilled />,
          link: "/projectfile",
          subject: "Investments",
          action: "read",
        },
        {
          title: t("Investments"),
          icon: <DollarCircleFilled />,
          link: "/Investments",
          subject: "Investments",
          action: "read",
        },
      ],
    },
    {
      title: t("Reports"),
      items: [
        {
          title: t("Reportingsection"),
          icon: <DollarCircleFilled />,
          link: "/report_section",
          subject: "Reporting Section",
          action: "read",
        },
      ],
    },
    {
      title: t("Chartaccounts"),
      items: [
        {
          title: t("Chartaccounts"),
          icon: <ReconciliationOutlined />,
          link: "/chartofaccounts",
          subject: "Chart of Accounts",
          action: "read",
        },
        {
          title: t("Hypotheses"),
          icon: <BlockOutlined />,
          link: "/glaccountsandhyphotheses",
          subject: "Hypothesis",
          action: "read",
        },
      ],
    },
    {
      title: t("Security"),
      items: [
        {
          title: t("ManageSections"),
          icon: <ProfileOutlined />,
          link: "/manageSections",
          subject: "Manage Sections",
          action: "read",
        },
        {
          title: t("Roles"),
          icon: <UserSwitchOutlined />,
          link: "/Roles",
          subject: "Roles",
          action: "read",
        },
        {
          title: t("Accounts"),
          icon: <UsergroupAddOutlined />,
          link: "/accounts",
          subject: "Accounts",
          action: "read",
        },
        {
          title: t("Plans"),
          icon: <AppstoreAddOutlined />,
          link: "/plans",
          subject: "Plans",
          action: "read",
        },
        {
          title: t("ManageAccess"),
          icon: <UnlockOutlined />,
          link: "/manageAccess",
          subject: "Manage Access",
          action: "read",
        },
      ],
    },
    {
      title: t("Parameters"),
      items: [
        {
          title: t("Configurations"),
          icon: <SettingOutlined />,
          link: "/SettingsMenu",
          subject: "Configurations",
          action: "read",
        },
      ],
    },
  ];

  function filterItemsByAbility(ability, items) {
    return items.filter((item) =>
      item.subject && item.action
        ? ability && ability.can(item.action, item.subject)
        : true
    );
  }
  function filterMenuByAbility(ability, menu) {
    return menu
      .map((section) => ({
        ...section,
        items: filterItemsByAbility(ability, section.items),
      }))
      .filter((section) => section.items.length > 0);
  }

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={toggleCollapsed}
      style={{
        backgroundColor: "white",
        minWidth: collapsed ? "80px" : "250px",
      }}
      width={250}
    >
      <div
        className="brand"
        style={{ display: collapsed ? "none" : "block"}}
      >
        <NavLink to="/" style={{overflow: "hidden"}}>
          <img
            src={
              Company
                ? Company.logoPath
                  ? JSON_API_logo + Company.logoPath
                  : logo
                : logo
            }
            alt="bleusky logo"
            className="brand"
            style={{
              width: "100%",
              height: "100%",
              padding:"10px",
              objectFit: "fit"
            }}
          />
        </NavLink>
      </div>
      {ability && (
        <Menu theme="light" mode="inline" collapsed={collapsed} >
          {filterMenuByAbility(ability, menuStructure).map((menu, i) => (
            <SubMenu
              key={i}
              title={!collapsed ? menu.title : ""}
              icon={<span className="label">{menu.items[0].icon}</span>} // Optional icon display
            >
              {menu.items.map((item, index) => (
               
                <Menu.Item key={i + "-" + index}>
                  <Tooltip title={collapsed ? item.title : ""}>
                    <NavLink to={item.link} style={{marginLeft:"20px"}}>
                      <span className="icon">
                        {
                          <span className="label" style={{ color: "#5677fb" }}>
                            {item.icon}
                          </span>
                        }
                      </span>
                      <span className="label" style={{ textAlign: "center" }}>
                        {item.title}
                      </span>

                      {/* {!collapsed && (
                        <>
                          <span className="label">{item.title}</span>

                        
                        </>
                      )} */}
                    </NavLink>
                  </Tooltip>
                </Menu.Item>
              ))}
            </SubMenu>
          ))}
        </Menu>
      )}
    </Sider>
  );
}

export default Sidenav;

import React, { useContext, useEffect, useState } from "react";
import {
  Badge,
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Progress,
  Radio,
  Select,
  Space,
  Table,
  Checkbox,
  Tag,
  Typography,
  icon,
  message,
} from "antd";
import {
  CloseCircleFilled,
  DeleteOutlined,
  DollarOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  NotificationOutlined,
  PlusCircleFilled,
  EyeFilled,
} from "@ant-design/icons";
import { Link, useHistory, useParams } from "react-router-dom";
import { CompanyContext } from "../contexts/CompanyContext";
import { JSON_API } from "../services/Constants";
import { Card, Col, Row } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useAbility } from "../contexts/abilityContext";
import { useTranslation } from "react-i18next";
dayjs.extend(customParseFormat);
const { Option } = Select;

function ProjectFile() {
  let { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const { Company } = useContext(CompanyContext);
  const [messageApi, contextHolder] = message.useMessage();
  const { id } = useParams();
  const [Object, setObject] = useState([{}]);
  const { ability } = useAbility();
  const canUpdate = ability.can("update", "Loans");
  const canDelete = ability.can("delete", "Loans");
  const [data, setData] = useState([{}]);
  const [editingRow, setEditingRow] = useState(null);
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(() => {
    return Number(localStorage.getItem("currentPagePret")) || 1;
  });
  const [Projectoperationalpriorities, setProjectoperationalpriorities] =
    useState([{}]);
  const [projectstaus, setprojectstaus] = useState([{}]);
  const [InvestmentProjectFiles, setInvestmentProjectFiles] = useState([{}]);
  const [formYear, setFormYear] = useState("");

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    localStorage.setItem("currentPagePret", pagination.current);
  };
  const showNameModal = (name) => {
    setSelectedName(name);
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedName(null);
  };
  const onChangeyear = (date, dateString) => {
    console.log(date, dateString);
    setYear(dateString || null);
  };
  useEffect(() => {
    const date = new Date().getFullYear();

    if (year == null) {
      setYear(date.toString());
    }

    console.log("year" + year);

    getAllprojectfile();
    getProjectOperationalPriorities();
    getprojectstatus();
  }, [Company.id, year]);

  const getAllprojectfile = async () => {
    if (!year) {
      setInvestmentProjectFiles([]);
      setLoading(false);
      return;
    }

    setLoading(true);

    setTimeout(async () => {
      try {
        const response = await axios.get(
          `${JSON_API}/InvestmentProjectFiles?year=${year}&enterpriseId=${Company.id}`
        );
        setInvestmentProjectFiles(response.data);
        console.log("reeeeeeee", response.data);
      } catch (error) {
        setInvestmentProjectFiles([]);
        message.error(t("ErrorFetchingProjectFiles"));
      } finally {
        setLoading(false);
      }
    }, 2000);
  };
  const getProjectOperationalPriorities = async () => {
    await axios
      .get(`${JSON_API}/ProjectOperationalPriorities`)
      .then((response) => {
        setProjectoperationalpriorities(response.data);
        console.log(Projectoperationalpriorities, "projectstaus");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getprojectstatus = async () => {
    await axios
      .get(`${JSON_API}/ProjectStatuses`)
      .then((response) => {
        setprojectstaus(response.data);
        console.log(projectstaus, "projectstaus");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${JSON_API}/InvestmentProjectFiles/${id}`);
      message.success(t("InvestmentProjectFilesDeletedSuccessfully"));
      getAllprojectfile();
    } catch (error) {
      message.error(t("ErrorDeletingLoan"));
    }
  };

  const onChange = (value) => {
    console.log("changed", value);
  };

  const { TextArea } = Input;

  const submite = async (e) => {
    const formData = {
      enterpriseId: Company.id,
      projectName: e.projectName,
      projectNumber: e.projectNumber,
      projectOperationalPriorityId: e.Projectoperationalpriorities,
      projectStatusId: +e.projectStatus,
      year: e.year,
    };

    console.log("form", formData);
    axios
      .post(`${JSON_API}/InvestmentProjectFiles`, formData)
      .then((res) => {
        message.success(t("InvestmentProjectFilesAddedSuccessfully"));
        getAllprojectfile();
        console.log(res.data);
        setOpen(!open);
      })
      .catch(function (error) {
        console.log(error);
        message.error(t("ErrorAddingLoan"));
      });
  };

  const CollectionCreateForm = ({ open, onCreate, onCancel }) => {
    const [formYear, setFormYear] = useState(year);

    const onFormYearChange = (date, dateString) => {
      setFormYear(dateString);
    };

    return (
      <Modal
        forceRender
        open={open}
        title={t("AddfileProject")}
        okText={
          <>
            <PlusCircleFilled /> {t("Create")}
          </>
        }
        cancelText={
          <>
            <CloseCircleFilled /> {t("Cancel")}
          </>
        }
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              values.year = formYear;
              form.resetFields();
              onCreate(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        {/* Form content remains the same */}
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
        >
          <Form.Item
            name="projectNumber"
            label={
              <span style={{ fontWeight: "bold" }}> {t("ProjectNumber")}</span>
            }
            rules={[
              {
                required: true,
                message: `${t("ProjectNumber")}`,
              },
            ]}
          >
            <Input style={{ width: 470 }}></Input>
          </Form.Item>
          <Form.Item
            name="projectName"
            label={
              <span style={{ fontWeight: "bold" }}> {t("ProjectName")}</span>
            }
            rules={[
              {
                required: true,
                message: `${t("ProjectName")}`,
              },
            ]}
          >
            <Input style={{ width: 470 }}></Input>
          </Form.Item>
          <Form.Item
            name="Projectoperationalpriorities"
            label={
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {t("Projectoperationalpriorities")}
              </span>
            }
            rules={[
              {
                required: true,
                message: `${t("selectapriority")}`,
              },
            ]}
          >
            <Select style={{ width: "100%" }}>
              {Projectoperationalpriorities &&
                Projectoperationalpriorities.map((option) => (
                  <Option key={option.id} value={option.id}>
                    {currentLang === "en" ? option.labelEn : option.labelFr}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="year"
            label={<span style={{ fontWeight: "bold" }}>{t("Year")}</span>}
            rules={[
              {
                required: true,
                message: t("pleaseinputtheyear"),
              },
            ]}
          >
            <DatePicker
              value={formYear ? dayjs(formYear) : null}
              picker="year"
              placeholder={t("Selectyear")}
              onChange={(date, dateString) => setFormYear(dateString)}
              style={{ width: 200, height: 35, marginLeft: 20 }}
            />
          </Form.Item>
          <Form.Item
            label={<span style={{ fontWeight: "bold" }}>{t("Status")}</span>}
            name="projectStatus"
          >
            <Radio.Group>
              <Space direction="horizontal" wrap>
                {projectstaus &&
                  projectstaus.map((status) => (
                    <Radio value={status.id} key={status.id}>
                      {currentLang === "en" ? status.labelEn : status.labelFr}
                    </Radio>
                  ))}
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="modifier"
            className="collection-create-form_last-form-item"
          ></Form.Item>
        </Form>
      </Modal>
    );
  };

  const columns = [
    {
      title: <h1 style={{ textAlign: "center" }}>{t("ProjectNumber")}</h1>,
      dataIndex: "projectNumber",
      width: "30%",
      align: "center",
      render: (_, record) => (
        <div
          style={{
            textAlign: "left",
            maxWidth: "400px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            cursor: "pointer",
          }}
          onClick={() => showNameModal(record.projectNumber)}
        >
          {record.projectNumber}
        </div>
      ),
    },
    {
      title: <h1 style={{ textAlign: "center" }}>{t("ProjectName")}</h1>,
      dataIndex: "projectName",
      width: "30%",
      align: "center",
      render: (_, record) => (
        <div
          style={{
            textAlign: "left",
            maxWidth: "400px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            cursor: "pointer",
          }}
          onClick={() => showNameModal(record.projectName)}
        >
          {record.projectName}
        </div>
      ),
    },
    {
      title: <h1 style={{ textAlign: "center" }}>{t("Total")} </h1>,
      dataIndex: "expectedTotal",
      align: "center",
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>{record.expectedTotal} { Company.currencies[0].shortLabel}</div>
      ),
    },
    {
      title: <h1 style={{ textAlign: "center" }}>{t("priority")}</h1>,
      dataIndex: "projectOperationalPriority",
      width: "30%",
      align: "center",
      render: (_, record) => (
        <div
          style={{
            textAlign: "left",
            maxWidth: "400px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            cursor: "pointer",
          }}
        >
          {currentLang === "en" ? record.priorityEn : record.priorityFr}
        </div>
      ),
    },
    {
      title: <h1 style={{ textAlign: "center" }}>{t("Status")}</h1>,
      dataIndex: "projectStatus",
      width: "30%",
      align: "center",
      render: (_, record) => (
        <div
          style={{
            textAlign: "left",
            maxWidth: "400px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            cursor: "pointer",
          }}
        >
          {currentLang === "en" ? record.statusEn : record.statusFr}
        </div>
      ),
    },
    {
      title: <h1 style={{ textAlign: "center" }}>{t("Year")}</h1>,
      dataIndex: "year",
      align: "center",
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>{record.year}</div>
      ),
    },
  ];

  if (canDelete || canUpdate) {
    columns.push({
      title: <h1 style={{ textAlign: "center" }}>Actions</h1>,
      align: "center",
      render: (_, record) => (
        <>
          {canDelete && (
            <Popconfirm
              title={t("Areyousuretodeletethisone")}
              okText={t("yes")}
              cancelText={t("no")}
              onConfirm={() => handleDelete(record.id)}
            >
              <Button type="link" danger>
                <DeleteOutlined />
                {t("Delete")}
              </Button>
            </Popconfirm>
          )}
          {canUpdate && (
            <Link
              to={{
                pathname: `/projectFile/${record.id}`,
                state: { stateParam: record.id },
              }}
            >
              <Button type="link" icon={<EyeFilled />}>
                {t("details")}
              </Button>
            </Link>
          )}
        </>
      ),
    });
  }

  return (
    <>
      <div>
        {contextHolder}
        <Card
          bordered={false}
          className="header-solid mb-24"
          title={
            <h3 className="font-semibold">
              <Divider>
                <NotificationOutlined style={{ color: "#3947C4" }} />{" "}
                <DollarOutlined style={{ color: "#3947C4" }} />{" "}
                {t("ProjectFile")}{" "}
              </Divider>
            </h3>
          }
        >
          <Row justify="space-between">
            <Space style={{ marginBottom: 15 }}>
              {ability && ability.can("create", "Investments") && (
                <Button
                  className="Create-button"
                  type="primary"
                  icon={<PlusCircleFilled />}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  {t("Create")}
                </Button>
              )}
              <DatePicker
                value={year ? dayjs(year) : null}
                picker="year"
                placeholder={t("Selectyear")}
                onChange={onChangeyear}
                style={{ width: 200, height: 35, marginLeft: 20 }}
                allowClear
              />
              <CollectionCreateForm
                open={open}
                onCreate={submite}
                onCancel={() => {
                  setOpen(false);
                }}
              />
            </Space>
          </Row>
          <div style={{ overflowX: "auto", backgroundColor: "white" }}>
            <Table
              loading={loading}
              bordered
              pagination={{ current: currentPage }}
              onChange={handleTableChange}
              columns={columns}
              dataSource={InvestmentProjectFiles}
            />
          </div>
          <Modal
            title={t("detail")}
            visible={isModalVisible}
            onOk={closeModal}
            onCancel={closeModal}
          >
            <p>{selectedName}</p>
          </Modal>
        </Card>
      </div>
    </>
  );
}

ProjectFile.acl = {
  subject: "Investments",
  action: "read",
};

export default ProjectFile;

import React, { useContext } from "react";
import { useAbility } from "../../../contexts/abilityContext";
import { Button, Input, Table, Typography, Popconfirm } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CompanyContext } from "../../../contexts/CompanyContext";
const { Text } = Typography;
const { TextArea } = Input;

function SoldeBExpected(props) {
  let { t } = useTranslation();
  const { ability } = useAbility();
  const readOnly = !ability.can("update", "Revenue");
  const [AssetBudgets, setAssetBudgets] = useState(props.AssetBudgets);
  const { TextArea } = Input;

  function handleinputchange(ee, i, n) {
    const newBudget = [...AssetBudgets];
    newBudget.map((e) => {
      if (e.id == i) {
        e.units[n] = parseFloat(ee.target.value) || 0;
      }
    });
    setAssetBudgets(newBudget);
    props.onBudgetChange(newBudget);
  }
  const cancel = (e) => {
    console.log(e);
  };

  function confirm(i) {
    const newBudget = [...AssetBudgets];
    newBudget.map((e) => {
      if (e.id == i) {
        e.confirmed = true;
      }
    });
    setAssetBudgets(newBudget);
    props.onBudgetChange(newBudget);
  }

  const { Company } = useContext(CompanyContext);

  console.log("ttesstt 1");
  const items = [
    {
      title: `${t("Year")}`,
      width: 120,
      dataIndex: "year",
      align: "center",
      //   key: "year",
      render: (text, record) => {
        return <p style={{ textAlign: "center" }}>{text}</p>;
      },
    },
  ];

  const months = [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
  ];

  const startingMonthIndex = Company.startPeriod - 1;

  for (let i = 0; i < months.length; i++) {
    const monthIndex = (i + startingMonthIndex) % months.length;
    const monthName = months[monthIndex];

    items.push({
      title: monthName,
      width: 120,
      align: "center",
      render: (text, record) => {
        let confirm = false;
        return (
          <>
            {props.AssetUnit.map((e) => {
              if (e.year == record.year) {
                confirm = e.confirmed;
              }
            })}
            <Input
              type="number"
              value={record.units[monthIndex].toFixed(0)}
              disabled={confirm || readOnly}
              onChange={(e) => handleinputchange(e, record.id, monthIndex)}
              style={{ textAlign: "right", width: 120 }}
            />
          </>
        );
      },
    });
  }

  items.push({
    title: <h1 style={{ textAlign: "center", width: 100 }}>Total </h1>,
    width: 120,
    align: "center",
    render: (_, record) => {
      return (
        <p style={{ textAlign: "right" }}>
          {" "}
          {(
            record.units[0] +
            record.units[1] +
            record.units[2] +
            record.units[3] +
            record.units[4] +
            record.units[5] +
            record.units[6] +
            record.units[7] +
            record.units[8] +
            record.units[9] +
            record.units[10] +
            record.units[11]
          ).toFixed(0)}
        </p>
      );
    },
  });

  return (
    <Table
      columns={items}
      dataSource={props.AssetBudgets}
      scroll={{
        x: 1300,
      }}
      pagination={true}
      bordered
      summary={(pageData) => {
        const items = [];

        const startingMonthIndex = Company.startPeriod - 1;

        let totalmontant = 0;
        let totalbudget = 0;
        let totalfevrierbudget = 0;
        let totalMarsbudget = 0.0;
        let totalavrilbudget = 0;
        let totalmaibudget = 0;
        let totalJuinbudget = 0.0;
        let totaljuilletbudget = 0;
        let totalaoutbudget = 0;
        let totalseptembrebudget = 0;
        let totaloctobrebudget = 0;
        let totalnovemberbudget = 0;
        let totaldecembrebudget = 0;
        let totaltotal = 0;

        props.AssetBudgets &&
          props.AssetBudgets.map((e) => {
            //console.log(e.budgets[1]),
            totalmontant = e.units[0];
            totalbudget += totalmontant;
            totalmontant = e.units[1];
            totalfevrierbudget += totalmontant;
            totalmontant = e.units[2];
            totalMarsbudget += totalmontant;
            totalmontant = e.units[3];
            totalavrilbudget += totalmontant;
            totalmontant = e.units[4];
            totalmaibudget += totalmontant;
            totalmontant = e.units[5];
            totalJuinbudget += totalmontant;
            totalmontant = e.units[6];
            totaljuilletbudget += totalmontant;
            totalmontant = e.units[7];
            totalaoutbudget += totalmontant;
            totalmontant = e.units[8];
            totalseptembrebudget += totalmontant;
            totalmontant = e.units[9];
            totaloctobrebudget += totalmontant;
            totalmontant = e.units[10];
            totalnovemberbudget += totalmontant;
            totalmontant = e.units[11];
            totaldecembrebudget += totalmontant;
            totalmontant = e.totalBudget;
            totaltotal += totalmontant;
          });

        const months = [
          totalbudget,
          totalfevrierbudget,
          totalMarsbudget,
          totalavrilbudget,
          totalmaibudget,
          totalJuinbudget,
          totaljuilletbudget,
          totalaoutbudget,
          totalseptembrebudget,
          totaloctobrebudget,
          totalnovemberbudget,
          totaldecembrebudget,
        ];
        for (let i = 0; i < months.length; i++) {
          const monthIndex = (i + startingMonthIndex) % months.length;
          const monthName = months[monthIndex];

          items.push(
            <Table.Summary.Cell index={monthIndex}>
              <Text style={{ textAlign: "right" }}>{monthName.toFixed(0)}</Text>
            </Table.Summary.Cell>
          );
        }

        return (
          <>
            <Table.Summary.Row>
              {/* <Table.Summary.Cell index={1} colSpan={1}>
                <h3 style={{ textAlign: "center" }}>Total</h3>
              </Table.Summary.Cell>

              {items}

              <Table.Summary.Cell index={10}>
                <Text>
                  {(
                    totalbudget +
                    totalfevrierbudget +
                    totalMarsbudget +
                    totalavrilbudget +
                    totalmaibudget +
                    totalJuinbudget +
                    totaljuilletbudget +
                    totalaoutbudget +
                    totalseptembrebudget +
                    totaloctobrebudget +
                    totalnovemberbudget +
                    totaldecembrebudget
                  ).toFixed(0)}
                </Text>
              </Table.Summary.Cell> */}
            </Table.Summary.Row>
          </>
        );
      }}
    />
  );
}

SoldeBExpected.acl = {
  subject: "Revenue",
  action: "read",
};

export default SoldeBExpected;

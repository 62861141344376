import { AppstoreOutlined } from "@ant-design/icons";
import {
  Button,
  Empty,
  Input,
  Popconfirm,
  Select,
  Table,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAbility } from "../../../contexts/abilityContext";
import { CompanyContext } from "../../../contexts/CompanyContext";
import { message } from "antd";
import axios from "axios";
import { JSON_API } from "../../../services/Constants";

const { Text } = Typography;
const { TextArea } = Input;

function Real(props) {
  const { Option } = Select;
  const { Company } = useContext(CompanyContext);
  const { ability } = useAbility();
  const readOnly = !ability.can("update", "Marketing Expenses");
  let { t } = useTranslation();
  const [AssetReals, setAssetReals] = useState(props.AssetReals);
  const [marketingtype, setmarketingtype] = useState([]);
  const [Descreption, setDescreption] = useState("");
  const [Annual, setAnnual] = useState("");

  const [currentPage, setCurrentPage] = useState(() => {
    return Number(localStorage.getItem("currentPageMarketingreals")) || 1;
  });
  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    localStorage.setItem("currentPageMarketingreals", pagination.current);
  };

  const { TextArea } = Input;
  console.log(AssetReals);

  function handleinputchange(ee, i, n) {
    console.log(props.AssetReals);
    const newReal = [...props.AssetReals];
    newReal.map((e) => {
      if (e.id == i) {
        e.reals[n] = parseFloat(ee.target.value) || 0;
      }
    });
    setAssetReals(newReal);
    props.onRealChange(newReal);
  }

  useEffect(() => {
    getMarketingType();
  }, []);

  const getMarketingType = async () => {
    await axios
      .get(`${JSON_API}/Marketing/MarketingType`)
      .then((res) => {
        console.log(res);
        setmarketingtype(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function handledescreptionchange(ee, i) {
    const newBudget = [...AssetReals];
    newBudget.map((e) => {
      if (e.id == i) {
        e.description = ee.target.value || "";
      }
    });
    setAssetReals(newBudget);
    props.onBudgetChange(newBudget);
  }
  function handletypechange(ee, i) {
    const newBudget = [...AssetReals];
    newBudget.map((e) => {
      if (e.id == i) {
        e.typeId = ee;
      }
      console.log("target", ee);
    });
    setAssetReals(newBudget);
    props.onBudgetChange(newBudget);
  }

  function handleAnnualbudgetchange(ee, i) {
    const newBudget = [...AssetReals];
    newBudget.map((e) => {
      if (e.id == i) {
        e.annualBudget = ee.target.value || "";
      }
    });
    setAssetReals(newBudget);
    props.onBudgetChange(newBudget);
  }

  console.log("ttesstt 1");

  const items = [
    {
      title: "Description",
      width: 200,
      dataIndex: "description",
      align: "center",
      render: (text, record) => {
        return (
          <Input
            disabled={readOnly}
            defaultValue={text}
            onChange={(e) => handledescreptionchange(e, record.id)}
            style={{
              width: 200,
            }}
          ></Input>
        );
      },
    },
    {
      title: "Type",
      width: 120,
      dataIndex: "typeId",
      align: "center",
      //   key: "year",
      render: (text, record) => {
        return (
          <Select
            disabled={readOnly}
            onChange={(e) => handletypechange(e, record.id)}
            defaultValue={text}
            style={{
              width: 200,
              textAlign: "center",
            }}
          >
            {marketingtype &&
              marketingtype.map((option) => (
                <Option key={option.id} value={option.id}>
                  {option.label}
                </Option>
              ))}
          </Select>
        );
      },
    },

    {
      title: t("Annualbudget"),
      width: 120,
      dataIndex: "annualBudget",
      align: "center",

      render: (text, record) => {
        return (
          <Input
            disabled={readOnly}
            defaultValue={text}
            onChange={(e) => handleAnnualbudgetchange(e, record.id)}
            style={{
              width: 200,
              textAlign: "center",
            }}
          ></Input>
        );
      },
    },
  ];

  const months = [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
  ];

  const startingMonthIndex = Company.startPeriod - 1;

  months.forEach((month, index) => {
    const monthIndex = (index + startingMonthIndex) % months.length;
    const monthName = months[monthIndex];

    items.push({
      title: monthName,
      width: 120,
      align: "center",
      render: (text, record) => {
        return (
          <>
            <Input
              type="number"
              value={
                record.confirmed
                  ? record.reals[monthIndex].toFixed(0)
                  : record.budgets[monthIndex].toFixed(0)
              }
              disabled={!record.confirmed || readOnly}
              onChange={(e) => handleinputchange(e, record.id, monthIndex)}
              style={{ textAlign: "center", width: 100 }}
            />
          </>
        );
      },
    });
  });

  items.push({
    title: "Total",
    align: "center",
    width: 170,
    render: (_, record) => {
      return (
        <div style={{ fontWeight: "bold", textAlign: "center", width: 120 }}>
          {" "}
          {(
            record.reals[0] +
            record.reals[1] +
            record.reals[2] +
            record.reals[3] +
            record.reals[4] +
            record.reals[5] +
            record.reals[6] +
            record.reals[7] +
            record.reals[8] +
            record.reals[9] +
            record.reals[10] +
            record.reals[11]
          ).toFixed(0)}
        </div>
      );
    },
  });

  return (
    <Table
      locale={{
        emptyText: <Empty description={t("nodata")} />,
      }}
      pagination={{ current: currentPage }}
      onChange={handleTableChange}
      columns={items}
      dataSource={props.AssetReals}
      scroll={{
        x: 1300,
      }}
      bordered
      summary={(pageData) => {
        const items = [];

        const startingMonthIndex = Company.startPeriod - 1;

        let totalmontant = 0;
        let totalReal = 0;
        let totalfevrierReal = 0;
        let totalMarsReal = 0.0;
        let totalavrilReal = 0;
        let totalmaiReal = 0;
        let totalJuinReal = 0.0;
        let totaljuilletReal = 0;
        let totalaoutReal = 0;
        let totalseptembreReal = 0;
        let totaloctobreReal = 0;
        let totalnovemberReal = 0;
        let totaldecembreReal = 0;
        let totaltotal = 0;

        let totalbudgetcount = 0;
        let totalfevrierbudgetcount = 0;
        let totalMarsbudgetcount = 0;
        let totalavrilbudgetcount = 0;
        let totalmaibudgetcount = 0;
        let totalJuinbudgetcount = 0;
        let totaljuilletbudgetcount = 0;
        let totalaoutbudgetcount = 0;
        let totalseptembrebudgetcount = 0;
        let totaloctobrebudgetcount = 0;
        let totalnovemberbudgetcount = 0;
        let totaldecembrebudgetcount = 0;

        props.AssetReals &&
          props.AssetReals.map((e) => {
            //console.log(e.budgets[1]),
            totalmontant = e.reals[0];
            totalReal += totalmontant;
            totalmontant = e.reals[1];
            totalfevrierReal += totalmontant;
            totalmontant = e.reals[2];
            totalMarsReal += totalmontant;
            totalmontant = e.reals[3];
            totalavrilReal += totalmontant;
            totalmontant = e.reals[4];
            totalmaiReal += totalmontant;
            totalmontant = e.reals[5];
            totalJuinReal += totalmontant;
            totalmontant = e.reals[6];
            totaljuilletReal += totalmontant;
            totalmontant = e.reals[7];
            totalaoutReal += totalmontant;
            totalmontant = e.reals[8];
            totalseptembreReal += totalmontant;
            totalmontant = e.reals[9];
            totaloctobreReal += totalmontant;
            totalmontant = e.reals[10];
            totalnovemberReal += totalmontant;
            totalmontant = e.reals[11];
            totaldecembreReal += totalmontant;
            totalmontant = e.totalBudget;
            totaltotal += totalmontant;
          });

        const months = [
          totalReal,
          totalfevrierReal,
          totalMarsReal,
          totalavrilReal,
          totalmaiReal,
          totalJuinReal,
          totaljuilletReal,
          totalaoutReal,
          totalseptembreReal,
          totaloctobreReal,
          totalnovemberReal,
          totaldecembreReal,
        ];

        const monthscount = [
          totalbudgetcount,
          totalfevrierbudgetcount,
          totalMarsbudgetcount,
          totalavrilbudgetcount,
          totalmaibudgetcount,
          totalJuinbudgetcount,
          totaljuilletbudgetcount,
          totalaoutbudgetcount,
          totalseptembrebudgetcount,
          totaloctobrebudgetcount,
          totalnovemberbudgetcount,
          totaldecembrebudgetcount,
        ];

        months.forEach((month, index) => {
          const monthIndex = (index + startingMonthIndex) % months.length;
          const monthName = months[monthIndex];
          let monthNamecount = monthscount[monthIndex];
          {
            if (monthNamecount == 0) {
              monthNamecount = 1;
            }
          }

          items.push(
            <Table.Summary.Cell index={monthIndex}>
              <Text style={{ fontWeight: "bold", textAlign: "center" }}>
                {(monthName / monthNamecount).toFixed(0)}
              </Text>
            </Table.Summary.Cell>
          );
        });

        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell
                index={1}
                colSpan={3}
                className="centered-content"
              >
                <h3 style={{ fontWeight: "bold" }}>Total</h3>
              </Table.Summary.Cell>
              {items}
            </Table.Summary.Row>
          </>
        );
      }}
    />
  );
}

Real.acl = {
  subject: "Marketing Expenses",
  action: "read",
};

export default Real;

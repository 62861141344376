import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Collapse,
  Typography,
  Form,
  Input,
  Button,
  Select,
  Radio,
  Space,
  Row,
  Divider,
  Modal,
  Table,
  InputNumber,
  message,
  Checkbox,
  DatePicker,
} from "antd";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { JSON_API } from "../services/Constants";
import { useLocation, useParams } from "react-router-dom";
import { CompanyContext } from "../contexts/CompanyContext";
//import dayjs from "dayjs";
import dayjs, { Dayjs } from "dayjs";
//import customParseFormat from "dayjs/plugin/customParseFormat";
//dayjs.extend(customParseFormat);

const { Panel } = Collapse;
const { Title } = Typography;
const { Option } = Select;

function ProjectFileDetails() {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const currentLang = i18n.language;
  const { id } = useParams();
  const { Company } = useContext(CompanyContext);
  const [formActivity] = Form.useForm();

  const [InvestmentProjectFilesDetails, setInvestmentProjectFilesDetails] =
    useState({});
  const [enterpriseDepartments, setEnterpriseDepartments] = useState([]);
  const [projectstaus, setprojectstaus] = useState([]);
  const [Projectoperationalpriorities, setProjectoperationalpriorities] =
    useState([]);
  const [Projectobjectif, setProjectobjectif] = useState([]);
  const [ProjectImpact, setProjectImpact] = useState([{}]);
  const [Projectbenefit, setProjectbenefit] = useState([{}]);
  const [Projecteffortgolbal, setProjecteffortgolbal] = useState([{}]);
  const [Projectacquisitions, setProjectacquisitions] = useState([{}]);
  const [ProjectEffortScales, setProjectEffortScales] = useState([{}]);
  const [ProjectComplexityTypes, setProjectComplexityTypes] = useState([{}]);
  const [Projectaquisitiontype, setProjectaquisitiontype] = useState([{}]);
  const [Projectcategorization, setProjectcategorization] = useState([{}]);
  const [InvestismentCategory, setInvestismentCategory] = useState([{}]);
  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingKey, setEditingKey] = useState(null);
  const [selectedComplexity, setSelectedComplexity] = useState({});
  const [year, setYear] = useState("");
  const onChangeyear = (date, dateString) => {
    // Update the state with only the year part
    setYear(date ? date.format("YYYY") : "");
  };

  // Deuxiem table
  const [data2, setData2] = useState([]);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [form2] = Form.useForm();
  const [editingKey2, setEditingKey2] = useState(null);
  const calculateTotal = (dataActivity) => {
    return dataActivity.reduce(
      (acc, current) => acc + (current.estimate || 0),
      0
    );
  };
  const columns2 = [
    {
      title: t("PlannedInvestment"),
      dataIndex: "description",
      key: "description",
      align: "center",
    },

    {
      title: t("InvestmentCategories"),
      dataIndex: "investmentCategoryId", // Change this to match your data structure
      key: "investmentCategoryId",
      align: "center",
      render: (text) => {
        const category = InvestismentCategory.find((cat) => cat.id === text);
        return category ? category.label : "Inconnu";
      },
    },

    {
      title: t("estimated"),
      dataIndex: "estimate",
      key: "estimate",
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={() => handleEdit2(record)} type="primary">
            {t("edit")}
          </Button>
          <Button
            onClick={() => handleDelete2(record.key)}
            type="primary"
            danger
          >
            {t("Delete")}
          </Button>
        </Space>
      ),
    },
  ];

  const handleDelete2 = (key) => {
    if (key === undefined) {
      console.error("Attempt to delete item with undefined key");
      return;
    }
    console.log("Deleting item with key:", key);
    setData2((prevData) => {
      const newData = prevData.filter((item) => item.key !== key);
      console.log("New data after deletion:", newData);
      return newData;
    });
  };

  const handleOk2 = () => {
    form2
      .validateFields()
      .then((values) => {
        if (editingKey2 !== null) {
          setData2((prevData) =>
            prevData.map((item) =>
              item.key === editingKey2
                ? { ...item, ...values, key: editingKey2 }
                : item
            )
          );
          setEditingKey2(null);
        } else {
          const newKey = Date.now().toString();
          setData2([...data2, { ...values, key: newKey }]);
        }
        form2.resetFields();
        setIsModalVisible2(false);
      })
      .catch((errorInfo) => {
        console.log("Validation Failed:", errorInfo);
      });
  };

  const handleCancel2 = () => {
    setIsModalVisible2(false);
  };
  const handleEdit2 = (record) => {
    setEditingKey2(record.key);
    form2.setFieldsValue(record);
    setIsModalVisible2(true);
  };

  const validateWordCount500words = (rule, value) => {
    if (!value) {
      return Promise.resolve();
    }
    const wordCount = value.split(/\s+/).length;
    if (wordCount > 500) {
      return Promise.reject(new Error("Description cannot exceed 500 words"));
    }
    return Promise.resolve();
  };
  const validateWordCountfor250words = (rule, value) => {
    if (!value) {
      return Promise.resolve();
    }
    const wordCount = value.split(/\s+/).filter(Boolean).length;
    if (wordCount > 250) {
      return Promise.reject(new Error("Description cannot exceed 250 words"));
    }
    return Promise.resolve();
  };
  // Troisieme table

  const [data3, setData3] = useState([]);
  const [isModalVisible3, setIsModalVisible3] = useState(false);
  const [form3] = Form.useForm();
  const [editingKey3, setEditingKey3] = useState(null);

  const calculateTotals = (data) => {
    return data.reduce(
      (acc, current) => {
        return {
          firstYear:
            acc.firstYear + (parseFloat(current.firstYearExpense) || 0),
          secondYear:
            acc.secondYear + (parseFloat(current.secondYearExpense) || 0),
          thirdYear:
            acc.thirdYear + (parseFloat(current.thirdYearExpense) || 0),
        };
      },
      { firstYear: 0, secondYear: 0, thirdYear: 0 }
    );
  };
  const columns3 = [
    {
      title: t("Expensetype"),
      dataIndex: "expenseType",
      key: "expenseType",
      align: "center",
    },
    {
      title: t("Year1"),
      dataIndex: "firstYearExpense",
      key: "firstYear",
      align: "center",
    },
    {
      title: t("Year2"),
      dataIndex: "secondYearExpense",
      key: "secondYear",
      align: "center",
    },
    {
      title: t("Year3"),
      dataIndex: "thirdYearExpense",
      key: "thirdYear",
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={() => handleEdit3(record)} type="primary">
            {t("edit")}
          </Button>
          <Button
            onClick={() => handleDelete3(record.key)}
            type="primary"
            danger
          >
            {t("Delete")}
          </Button>
        </Space>
      ),
    },
  ];

  const handleDelete3 = (key) => {
    console.log("Deleting item with key:", key);
    setData3((prevData) => {
      const newData = prevData.filter((item) => item.key !== key);
      console.log("New data after deletion:", newData);
      return newData;
    });
  };

  const handleOk3 = () => {
    form3
      .validateFields()
      .then((values) => {
        if (editingKey3 !== null) {
          // Modifier l'enregistrement existant
          setData3((prevData) =>
            prevData.map((item) =>
              item.key === editingKey3 ? { ...item, ...values } : item
            )
          );
          setEditingKey3(null);
        } else {
          // Ajouter un nouvel enregistrement
          setData3([...data3, { ...values, key: Date.now().toString() }]);
        }
        form3.resetFields();
        setIsModalVisible3(false);
      })
      .catch((errorInfo) => {
        console.log("Validation Failed:", errorInfo);
      });
  };

  const handleCancel3 = () => {
    setIsModalVisible3(false);
  };

  const handleEdit3 = (record) => {
    setEditingKey3(record.key);
    form3.setFieldsValue(record);
    setIsModalVisible3(true);
  };
  // Premiere table

  const handleOk = () => {
    formActivity
      .validateFields()
      .then((values) => {
        if (editingKey !== null) {
          // Modifier l'enregistrement existant
          setData((prevData) =>
            prevData.map((item) =>
              item.key === editingKey ? { ...item, ...values } : item
            )
          );
          setEditingKey(null);
        } else {
          // Ajouter un nouvel enregistrement
          setData([...data, { ...values, key: Date.now().toString() }]);
        }
        formActivity.resetFields();
        setIsModalVisible(false);
      })
      .catch((errorInfo) => {
        console.log("Validation Failed:", errorInfo);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    formActivity.resetFields();
    setEditingKey(null);
  };

  const handleEdit = (record) => {
    setEditingKey(record.key);
    formActivity.setFieldsValue(record);
    setIsModalVisible(true);
  };

  const handleDelete = (key) => {
    setData(data.filter((item) => item.key !== key));
  };

  const columns = [
    {
      title: t("name"),
      dataIndex: "responsible",
      key: "responsible",
      align: "center",
    },
    {
      title: t("activityname"),
      dataIndex: "activityName",
      key: "activityName",
      align: "center",
    },
    {
      title: "Effort(j.p.)",
      dataIndex: "effort",
      key: "effort",
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={() => handleEdit(record)} type="primary">
            {t("edit")}
          </Button>
          <Button
            onClick={() => handleDelete(record.key)}
            type="primary"
            danger
          >
            {t("Delete")}
          </Button>
        </Space>
      ),
    },
  ];

  const getAllprojectfileDetail = async () => {
    try {
      const response = await axios.get(
        `${JSON_API}/InvestmentProjectFiles/${id}`
      );
      setInvestmentProjectFilesDetails(response.data);
      console.log("res", response.data);

      // Initialiser selectedComplexity avec les données de l'API
      const initialComplexity = {};
      response.data.investmentProjectComplexities.forEach((item) => {
        initialComplexity[item.projectComplexityTypeId] = Number(
          item.projectComplexity
        );
      });
      setSelectedComplexity(initialComplexity);

      console.log(response.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const getEnterpriseDepartments = async () => {
    try {
      const res = await axios.get(
        `${JSON_API}/EnterpriseDepartments/Filter/${Company.id}`
      );
      setEnterpriseDepartments(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getprojectstatus = async () => {
    try {
      const response = await axios.get(`${JSON_API}/ProjectStatuses`);
      setprojectstaus(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getProjectOperationalPriorities = async () => {
    try {
      const response = await axios.get(
        `${JSON_API}/ProjectOperationalPriorities`
      );
      setProjectoperationalpriorities(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getProjectObjectives = async () => {
    try {
      const response = await axios.get(
        `${JSON_API}/ProjectObjectives?enterpriseId=${Company.id}`
      );
      setProjectobjectif(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  const getProjectImpact = async () => {
    try {
      const response = await axios.get(
        `${JSON_API}/ProjectImpacts?enterpriseId=${Company.id}`
      );
      setProjectImpact(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  const getProjectBenefits = async () => {
    try {
      const response = await axios.get(
        `${JSON_API}/ProjectBenefits?enterpriseId=${Company.id}`
      );
      setProjectbenefit(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  const getProjectGlobalScales = async () => {
    try {
      const response = await axios.get(
        `${JSON_API}/ProjectGlobalScales?enterpriseId=${Company.id}`
      );
      setProjecteffortgolbal(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  const getProjectAcquisitions = async () => {
    try {
      const response = await axios.get(
        `${JSON_API}/ProjectAcquisitions?enterpriseId=${Company.id}`
      );
      setProjectacquisitions(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getProjectEffortScales = async () => {
    try {
      const response = await axios.get(
        `${JSON_API}/ProjectEffortScales?enterpriseId=${Company.id}`
      );
      setProjectEffortScales(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getProjectComplexityType = async () => {
    try {
      const response = await axios.get(
        `${JSON_API}/ProjectComplexityTypes?enterpriseId=${Company.id}`
      );
      setProjectComplexityTypes(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  const getProjectAcquisitionsTypes = async () => {
    await axios
      .get(`${JSON_API}/ProjectAcquisitionTypes`)
      .then((response) => {
        setProjectaquisitiontype(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getprojectCategorizations = async () => {
    await axios
      .get(`${JSON_API}/ProjectCategorizations`)
      .then((response) => {
        setProjectcategorization(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getInvestimentCategory = async () => {
    await axios
      .get(`${JSON_API}/InvestmentCategorys`)
      .then((response) => {
        setInvestismentCategory(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllprojectfileDetail();
    getEnterpriseDepartments();
    getprojectstatus();
    getProjectOperationalPriorities();
    getProjectObjectives();
    getProjectImpact();
    getProjectBenefits();
    getProjectGlobalScales();
    getProjectAcquisitions();
    getProjectEffortScales();
    getProjectComplexityType();
    getProjectAcquisitionsTypes();
    getprojectCategorizations();
    getInvestimentCategory();
  }, [Company.id]);
  const formattedActivities =
    InvestmentProjectFilesDetails?.projectActivities?.map((item) => ({
      id: item.id,
      key: item.id.toString(),

      responsible: item.responsible,
      activityName: item.activityName,
      effort: item.effort,
    })) || [];
  const formattedRecurrentExpenses =
    InvestmentProjectFilesDetails?.projectRecurrentExpenses?.map((item) => ({
      id: item.id,
      key: item.id.toString(),
      expenseType: item.expenseType,
      firstYearExpense: item.firstYearExpense,
      secondYearExpense: item.secondYearExpense,
      thirdYearExpense: item.thirdYearExpense,
    })) || [];
  const formattedInvestments =
    InvestmentProjectFilesDetails?.projectPlanItems?.map((item) => ({
      id: item.id,
      key: item.id.toString(),
      estimate: item.estimate,
      investmentCategoryId: item.investmentCategoryId,
      description: item.description,
    })) || [];
  console.log(formattedInvestments);
  useEffect(() => {
    setData(formattedActivities);
    setData2(formattedInvestments);
    setData3(formattedRecurrentExpenses);
    form.setFieldsValue({
      year: dayjs(InvestmentProjectFilesDetails?.year),
      projectActivities: formattedActivities,
      projectRecurrentExpenses: formattedRecurrentExpenses,
      projectPlanItems: formattedInvestments,
      projectName: InvestmentProjectFilesDetails.projectName,
      projectNumber: InvestmentProjectFilesDetails.projectNumber,
      applicantName: InvestmentProjectFilesDetails.applicantName,
      enterpriseDepartmentId:
        InvestmentProjectFilesDetails.enterpriseDepartmentId || undefined,
      projectStatusId: InvestmentProjectFilesDetails.projectStatusId,
      projectOperationalPriorityId:
        InvestmentProjectFilesDetails.projectOperationalPriorityId,
      projectObjectiveDescription:
        InvestmentProjectFilesDetails.projectObjectiveDescription,
      // projectObjectiveIds: InvestmentProjectFilesDetails.projectObjectiveIds,
      projectDescription: InvestmentProjectFilesDetails.projectDescription,
      context: InvestmentProjectFilesDetails.context,

      impactsDescription: InvestmentProjectFilesDetails.impactsDescription,
      // projectBenefitIds: InvestmentProjectFilesDetails.projectBenefitIds,

      benefitsDescription: InvestmentProjectFilesDetails.benefitsDescription,
      projectGlobalScaleId:
        InvestmentProjectFilesDetails.projectGlobalScaleId || undefined,
      projectAcquisitionId:
        InvestmentProjectFilesDetails.projectAcquisitionId || undefined,
      projectEffortScaleId:
        InvestmentProjectFilesDetails.projectEffortScaleId || undefined,
      projectaquisitiontypes:
        InvestmentProjectFilesDetails.projectAcquisitionTypeIds || undefined,
      projectcategorizations:
        InvestmentProjectFilesDetails.projectCategorizationIds || undefined,
      projectObjectiveIds:
        InvestmentProjectFilesDetails.projectObjectiveIds || undefined,
      projectBenefitIds:
        InvestmentProjectFilesDetails.projectBenefitIds || undefined,
      projectImpactIds:
        InvestmentProjectFilesDetails.projectImpactIds || undefined,
    });
  }, [InvestmentProjectFilesDetails, form]);

  const handleComplexityChange = (statusId, value) => {
    setSelectedComplexity((prev) => ({
      ...prev,
      [statusId]: Number(value),
    }));
  };
  const onSubmit = () => {
    form.validateFields().then((values) => {
      const formData = {
        ...InvestmentProjectFilesDetails,
        ...values,
        year: values.year ? values.year.format("YYYY") : null,
        projectComplexityDtos: Object.keys(selectedComplexity).map((key) => ({
          projectComplexityTypeId: parseInt(key, 10),
          projectComplexityEnum: selectedComplexity[key],
        })),

        projectObjectiveIds: Array.isArray(values.projectObjectiveIds)
          ? values.projectObjectiveIds
          : values.projectObjectiveIds
          ? [values.projectObjectiveIds]
          : [],
        projectBenefitIds: Array.isArray(values.projectBenefitIds)
          ? values.projectBenefitIds
          : values.projectBenefitIds
          ? [values.projectBenefitIds]
          : [],
        projectCategorizationIds: Array.isArray(values.projectcategorizations)
          ? values.projectcategorizations
          : values.projectcategorizations
          ? [values.projectcategorizations]
          : [],
        projectAcquisitionTypeIds: Array.isArray(values.projectaquisitiontypes)
          ? values.projectaquisitiontypes
          : values.projectaquisitiontypes
          ? [values.projectaquisitiontypes]
          : [],

        ProjectImpactIds: Array.isArray(values.projectImpactIds)
          ? values.projectImpactIds
          : values.projectImpactIds
          ? [values.projectImpactIds]
          : [],

        projectActivityDtos: data.map((item) => ({
          id: item.id, // Vous pouvez gérer les ids si besoin, sinon laisser 0
          responsible: item.responsible,
          activityName: item.activityName,
          effort: item.effort,
        })),
        projectPlanItemDtos: data2.map((item) => ({
          id: item.id, // vous pouvez mettre à jour ceci si vous avez un id unique
          description: item.description,
          estimate: item.estimate,
          investmentCategoryId: item.investmentCategoryId, // ou une autre propriété
        })),
        projectRecurrentExpenseDtos: data3.map((item) => ({
          id: item.id,
          expenseType: item.expenseType,
          firstYearExpense: item.firstYearExpense,
          secondYearExpense: item.secondYearExpense,
          thirdYearExpense: item.thirdYearExpense,
        })),
      };
      console.log("Form data:", formData);

      axios
        .put(`${JSON_API}/InvestmentProjectFiles/${id}`, formData)
        .then((res) => {
          message.success(t("InvestmentProjectFilesAddedSuccessfully"));
          getAllprojectfileDetail();
          console.log(res.data);
        })
        .catch(function (error) {
          console.log(error);
          message.error(t("Verrifier si tous les champ sont remplis"));
        });
    });
  };

  const complexityOptions = [
    { value: 0, labelEn: "Simple", labelFr: "Simple" },
    { value: 1, labelEn: "Medium ", labelFr: "Moyen" },
    { value: 2, labelEn: "Complex", labelFr: "Complexe" },
  ];

  return (
    <Card
      title={t("ProjectFiledetail")}
      style={{
        maxWidth: 1500,
        margin: "20px auto",
        backgroundColor: "#f0f8ff",
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        style={{ marginTop: "20px" }}
      >
        <Collapse accordion defaultActiveKey={["1"]}>
          <Panel
            header={
              <Title level={4} style={{ fontSize: "16px" }}>
                Identification
              </Title>
            }
            key="1"
            style={{ fontSize: "10px" }}
          >
            {/* Champs de la première section */}
            <Form.Item
              name="year"
              label={<span style={{ fontWeight: "bold" }}>{t("Year")}</span>}
              // rules={[{ required: true, message: t("pleaseinputtheyear") }]}
            >
              <DatePicker
                picker="year"
                format="YYYY"
                placeholder={t("Selectyear")}
                onChange={onChangeyear}
                value={form.getFieldValue("year")}
                style={{ width: 1000 }}
              />
            </Form.Item>
            <Form.Item
              label={
                <span style={{ fontWeight: "bold" }}>{t("ProjectName")}</span>
              }
              name="projectName"
              rules={
                [
                  // { required: true, message: t("Pleaseinputtheprojectname") },
                ]
              }
            >
              <Input placeholder="Enter project name" />
            </Form.Item>

            <Form.Item
              label={
                <span style={{ fontWeight: "bold" }}>{t("ProjectNumber")}</span>
              }
              name="projectNumber"
              // rules={[
              //   {
              //     required: true,
              //     message: t("Pleaseinputtheprojectnumber"),
              //   },
              // ]}
            >
              <Input placeholder={t("Pleaseinputtheprojectnumber")} />
            </Form.Item>

            <Form.Item
              label={
                <span style={{ fontWeight: "bold" }}>
                  {t("Nameofrequester")}
                </span>
              }
              name="applicantName"
              // rules={[
              //   {
              //     required: true,
              //     message: t("Pleaseinputthenameofrequester"),
              //   },
              // ]}
            >
              <Input placeholder={t("Pleaseinputthenameofrequester")} />
            </Form.Item>

            <Form.Item
              label={
                <span style={{ fontWeight: "bold" }}>{t("Departement")}</span>
              }
              name="enterpriseDepartmentId"
              // rules={[
              //   {
              //     required: true,
              //     message: t("PleaseSelectDepartment"),
              //   },
              // ]}
            >
              <Select>
                {enterpriseDepartments.map((department) => (
                  <Option key={department.id} value={department.id}>
                    {department.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label={
                <span style={{ fontWeight: "bold" }}>{t("ProjectStatus")}</span>
              }
              name="projectStatusId"
              // rules={[
              //   {
              //     required: true,
              //     message: t("PleaseSelectProjectStatus"),
              //   },
              // ]}
            >
              <Radio.Group>
                <Space direction="horizontal" wrap>
                  {projectstaus.map((status) => (
                    <Radio value={status.id} key={status.id}>
                      {currentLang === "en" ? status.labelEn : status.labelFr}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label={
                <span style={{ fontWeight: "bold" }}>
                  {t("Projectoperationalpriorities")}
                </span>
              }
              name="projectOperationalPriorityId"
              // rules={[
              //   {
              //     required: true,
              //     message: t("PleaseSelectProjectoperationalpriorities"),
              //   },
              // ]}
            >
              <Radio.Group>
                <Space direction="horizontal" wrap>
                  {Projectoperationalpriorities.map((priority) => (
                    <Radio value={priority.id} key={priority.id}>
                      {currentLang === "en"
                        ? priority.labelEn
                        : priority.labelFr}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
          </Panel>
        </Collapse>

        {/* Deuxième Collapse pour les objectifs du projet */}

        <Collapse
          defaultActiveKey={["2"]}
          accordion
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <Panel
            header={
              <Title level={4} style={{ fontSize: "16px" }}>
                {t("Projectobjectives")}
              </Title>
            }
            key="2"
            style={{ fontSize: "10px" }}
          >
            <Form.Item
              name="projectObjectiveIds"
              // rules={[
              //   {
              //     required: true,
              //     message: t("PleaseSelectProjectobjectivess"),
              //   },
              // ]}
            >
              <Checkbox.Group
                style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
              >
                {Projectobjectif &&
                  Projectobjectif.map((sta) => (
                    <div
                      key={sta.id}
                      style={{
                        width: "23.33%",
                        marginBottom: "20px",
                        display: "flex",
                        marginLeft: "14px",
                      }}
                    >
                      <Checkbox
                        key={sta.id}
                        value={sta.id}
                        style={{
                          marginRight: "8px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          {currentLang === "en" ? sta.labelEn : sta.labelFr}
                        </span>
                      </Checkbox>
                    </div>
                  ))}
              </Checkbox.Group>
            </Form.Item>

            <Form.Item
              label={
                <span style={{ fontWeight: "bold" }}>
                  {t("PrincipaleProjectobjectivess")}
                </span>
              }
              name="projectObjectiveDescription"
              // rules={[
              //   {
              //     required: true,
              //     message: t("PleaseinputprojectObjectiveDescription"),
              //   },
              // ]}
            >
              <Input.TextArea
                placeholder="Enter objectif principal du projet"
                rows={4}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Panel>
        </Collapse>

        {/* Troisiemme collapse */}
        <Collapse
          defaultActiveKey={["3"]}
          accordion
          style={{ marginBottom: "20px" }}
        >
          <Panel
            header={
              <Title level={4} style={{ fontSize: "16px" }}>
                {t("ProjectdescriptionandContext")}
              </Title>
            }
            key="3"
            style={{ fontSize: "10px" }}
          >
            <Form.Item
              label={
                <span style={{ fontWeight: "bold" }}>
                  {t("projectdescription")}
                </span>
              }
              name="projectDescription"
              // rules={[
              //   {
              //     required: true,
              //     message: t("EnterDescription"),
              //   },
              //   {
              //     validator: validateWordCount500words,
              //   },
              // ]}
            >
              <Input.TextArea placeholder={t("EnterDescription")} rows={4} />
            </Form.Item>

            <Form.Item
              label={
                <span style={{ fontWeight: "bold" }}>{t("Contexte")}</span>
              }
              name="context"
              // rules={[
              //   {
              //     required: true,
              //     message: t("Entercontext"),
              //   },
              //   {
              //     validator: validateWordCountfor250words,
              //   },
              // ]}
            >
              <Input.TextArea placeholder={t("Entercontext")} rows={4} />
            </Form.Item>
          </Panel>
        </Collapse>
        {/* Quatriemme collapse */}

        <Collapse
          defaultActiveKey={["4"]}
          accordion
          style={{ marginBottom: "20px" }}
        >
          <Panel
            header={
              <Title level={4} style={{ fontSize: "16px" }}>
                {t("Projectimpacts")}
              </Title>
            }
            key="4"
            style={{ fontSize: "10px" }}
          >
            {/* <Form.Item
              name="ProjectImpactIds"
              rules={[
                {
                  required: true,
                  message: t("PleaseselectProjectimpacts"),
                },
              ]}
            >
              <Checkbox.Group
                style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
              >
                {ProjectImpact &&
                  ProjectImpact.map((sta, index) => (
                    <Form.Item
                      key={sta.id}
                      style={{
                        marginRight: "20px",
                        flexBasis: "calc(33.33% - 20px)",
                        marginBottom: "20px",
                      }}
                    >
                      <Checkbox
                        value={sta.id}
                        style={{ display: "block", marginBottom: "10px" }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          {currentLang === "en" ? sta.labelEn : sta.labelFr}
                        </span>
                      </Checkbox>
                    </Form.Item>
                  ))}
              </Checkbox.Group>
            </Form.Item> */}
            <Form.Item
              name="projectImpactIds"
              // rules={[
              //   {
              //     required: true,
              //     message: t("PleaseselectProjectimpacts"),
              //   },
              // ]}
            >
              <Checkbox.Group
                style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
              >
                {ProjectImpact &&
                  ProjectImpact.map((sta, index) => (
                    <Checkbox
                      key={sta.id}
                      value={sta.id}
                      style={{
                        width: "23.33%",
                        marginBottom: "20px",
                        display: "flex",
                        marginLeft: "14px",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        {currentLang === "en" ? sta.labelEn : sta.labelFr}
                      </span>
                    </Checkbox>
                  ))}
              </Checkbox.Group>
            </Form.Item>

            <Form.Item
              label={
                <span style={{ fontWeight: "bold" }}>{t("Description")}</span>
              }
              name="impactsDescription"
              // rules={[
              //   {
              //     required: true,
              //     message: t("EnterDescription"),
              //   },
              //   {
              //     validator: validateWordCountfor250words,
              //   },
              // ]}
            >
              <Input.TextArea placeholder={t("EnterDescription")} rows={4} />
            </Form.Item>
          </Panel>
        </Collapse>

        {/* cinquiémme collapse */}
        <Collapse
          defaultActiveKey={["5"]}
          accordion
          style={{ marginBottom: "20px" }}
        >
          <Panel
            header={
              <Title level={4} style={{ fontSize: "16px" }}>
                {t("Projectbenefits")}
              </Title>
            }
            key="5"
            style={{ fontSize: "10px" }}
          >
            <Form.Item
              name="projectBenefitIds"
              // rules={[
              //   {
              //     required: true,
              //     message: t("PleaseselectProjectbenefits"),
              //   },
              // ]}
            >
              <Checkbox.Group
                style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
              >
                {Projectbenefit &&
                  Projectbenefit.map((sta, index) => (
                    <Checkbox
                      value={sta.id}
                      style={{
                        width: "23.33%",
                        marginBottom: "20px",
                        display: "flex",
                        marginLeft: "14px",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        {currentLang === "en" ? sta.labelEn : sta.labelFr}
                      </span>
                    </Checkbox>
                  ))}
              </Checkbox.Group>
            </Form.Item>

            <Form.Item
              label={
                <span style={{ fontWeight: "bold" }}>
                  {t("benefitsDescription")}
                </span>
              }
              name="benefitsDescription"
              // rules={[
              //   {
              //     required: true,
              //     message: t("EnterDescription"),
              //   },
              //   {
              //     validator: validateWordCountfor250words,
              //   },
              // ]}
            >
              <Input.TextArea placeholder={t("EnterDescription")} rows={4} />
            </Form.Item>
          </Panel>
        </Collapse>

        {/* sisiéme collapse */}

        <Collapse
          defaultActiveKey={["6"]}
          accordion
          style={{ marginBottom: "20px" }}
        >
          <Panel
            header={
              <Title level={4} style={{ fontSize: "16px" }}>
                {t("Scope")}
              </Title>
            }
            key="6"
            style={{ fontSize: "10px" }}
          >
            <Form.Item
              label={
                <span style={{ fontWeight: "bold" }}>
                  {t("Projectglobalscales")}
                </span>
              }
              name="projectGlobalScaleId"
              // rules={[
              //   {
              //     required: true,
              //     message: t("PleaseProjectglobalscales"),
              //   },
              // ]}
            >
              <Radio.Group
                style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
              >
                {Projecteffortgolbal &&
                  Projecteffortgolbal.map((status, index) => (
                    <Radio
                      value={status.id}
                      style={{
                        width: "23.33%",
                        marginBottom: "20px",
                        display: "flex",
                        marginLeft: "14px",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        {currentLang === "en" ? status.labelEn : status.labelFr}
                      </span>
                    </Radio>
                  ))}
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label={
                <span style={{ fontWeight: "bold" }}>
                  {t("Projectacquisitions")}
                </span>
              }
              name="projectAcquisitionId"
              // rules={[
              //   {
              //     required: true,
              //     message: t("PleaseselectProjectacquisitions"),
              //   },
              // ]}
            >
              <Radio.Group
                style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
              >
                {Projectacquisitions &&
                  Projectacquisitions.map((status, index) => (
                    <Radio
                      value={status.id}
                      style={{
                        width: "23.33%",
                        marginBottom: "20px",
                        display: "flex",
                        marginLeft: "14px",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        {currentLang === "en" ? status.labelEn : status.labelFr}
                      </span>
                    </Radio>
                  ))}
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label={
                <span style={{ fontWeight: "bold" }}>
                  {t("Projecteffortscales")}
                </span>
              }
              name="projectEffortScaleId"
              // rules={[
              //   {
              //     required: true,
              //     message: t("PleaseselectProjecteffortscales"),
              //   },
              // ]}
            >
              <Radio.Group
                style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
              >
                {ProjectEffortScales &&
                  ProjectEffortScales.map((status, index) => (
                    <Radio
                      value={status.id}
                      style={{
                        width: "23.33%",
                        marginBottom: "20px",
                        display: "flex",
                        marginLeft: "14px",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        {currentLang === "en" ? status.labelEn : status.labelFr}
                      </span>
                    </Radio>
                  ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={
                <span style={{ fontWeight: "bold" }}>
                  {t("projectComplexity")}
                </span>
              }
              name="projectComplexityDtos"
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {ProjectComplexityTypes &&
                  ProjectComplexityTypes.map((status) => (
                    <div
                      key={status.id}
                      style={{
                        display: "flex",
                        marginBottom: "10px",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "bold",
                          width: "200px",
                          marginRight: "20px",
                          textAlign: "right",
                        }}
                      >
                        {currentLang === "en" ? status.labelEn : status.labelFr}
                      </span>

                      <Radio.Group
                        value={selectedComplexity[status.id]}
                        onChange={(e) =>
                          handleComplexityChange(status.id, e.target.value)
                        }
                        style={{
                          display: "flex",
                        }}
                      >
                        {complexityOptions.map((option) => (
                          <Radio
                            key={option.value}
                            value={option.value}
                            style={{
                              marginRight: "80px",
                            }}
                          >
                            {currentLang === "en"
                              ? option.labelEn
                              : option.labelFr}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </div>
                  ))}
              </div>
            </Form.Item>

            <Form.Item
              label={
                <span style={{ fontWeight: "bold" }}>
                  {t("Projectacquisitionstype")}
                </span>
              }
              name="projectaquisitiontypes"
            >
              <Checkbox.Group
                style={{
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {Projectaquisitiontype &&
                  Projectaquisitiontype.map((status, index) => (
                    <div
                      key={status.id}
                      style={{
                        width: "23.33%",
                        marginBottom: "20px",
                        display: "flex",
                        marginLeft: "14px",
                      }}
                    >
                      <Checkbox
                        value={status.id}
                        style={{
                          marginRight: "8px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          {currentLang === "en"
                            ? status.labelEn
                            : status.labelFr}
                        </span>
                      </Checkbox>
                    </div>
                  ))}
              </Checkbox.Group>
            </Form.Item>

            <Form.Item
              label={
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {t("Projectcategorizations")}
                </span>
              }
              name="projectcategorizations"
              // rules={[
              //   {
              //     required: true,
              //     message: t("PleaseselectProjectcategorizations"),
              //   },
              // ]}
            >
              <Checkbox.Group
                style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
              >
                {Projectcategorization &&
                  Projectcategorization.map((status, index) => (
                    <div
                      key={status.id}
                      style={{
                        width: "23.33%",
                        marginBottom: "20px",
                        display: "flex",
                        marginLeft: "14px",
                      }}
                    >
                      <Checkbox
                        value={status.id}
                        style={{
                          marginRight: "8px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          {currentLang === "en"
                            ? status.labelEn
                            : status.labelFr}
                        </span>
                      </Checkbox>
                    </div>
                  ))}
              </Checkbox.Group>
            </Form.Item>
          </Panel>
        </Collapse>

        {/* septiémme collapse */}

        <Collapse
          defaultActiveKey={["7"]}
          accordion
          style={{ marginBottom: "20px" }}
        >
          <Panel
            header={
              <Title level={4} style={{ fontSize: "16px" }}>
                {t("Tableofactivities")}
              </Title>
            }
            key="7"
            style={{ fontSize: "10px" }}
          >
            <Space
              style={{
                marginBottom: 15,
                width: "100%",
                justifyContent: "flex-end",
                display: "flex",
              }}
            >
              <Button
                type="primary"
                ghost
                onClick={() => setIsModalVisible(true)}
                style={{
                  minWidth: "150px",
                  maxWidth: "100%",
                }}
              >
                {t("Addactivity")}
              </Button>
            </Space>
            <Table
              columns={columns}
              //dataSource={form.getFieldValue("projectActivities")}
              dataSource={data}
              rowKey={(record) => record.id}
            />

            <Modal
              title={editingKey !== null ? t("Editactivity") : t("Addactivity")}
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              okText={editingKey !== null ? t["Savehanges"] : t("Addactivity")}
              cancelText={t("cancel")}
            >
              <Form form={formActivity} layout="vertical" name="form_in_modal">
                <Form.Item
                  name="responsible"
                  label={t("name")}
                  rules={[{ required: true, message: t("entername") }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="activityName"
                  label={t("activityname")}
                  rules={[
                    {
                      required: true,
                      message: t("Enteractivity"),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item name="effort" label="Effort(p-d)">
                  <InputNumber />
                </Form.Item>
              </Form>
            </Modal>
          </Panel>
        </Collapse>

        {/* Huitiémme collapse */}

        <Collapse
          defaultActiveKey={["8"]}
          accordion
          style={{ marginBottom: "20px" }}
        >
          <Panel
            header={
              <Title level={4} style={{ fontSize: "16px" }}>
                {t("tableofInvestment")}
              </Title>
            }
            key="8"
            style={{ fontSize: "10px" }}
          >
            <Space
              style={{
                marginBottom: 15,
                width: "100%",
                justifyContent: "flex-end",
                display: "flex",
              }}
            >
              <Button
                type="primary"
                ghost
                onClick={() => setIsModalVisible2(true)}
                style={{
                  minWidth: "150px",
                  maxWidth: "100%",
                }}
              >
                {t("CreateInvestment")}
              </Button>
            </Space>
            <Table
              columns={columns2}
              dataSource={data2}
              rowKey={(record) => record.key}
              summary={(pageData) => {
                const total = calculateTotal(pageData);
                return (
                  <Table.Summary>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={2}>
                        <h5 style={{ textAlign: "center", fontWeight: "bold" }}>
                          Total {Company.currencies[0].shortLabel}
                        </h5>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        <h5 style={{ textAlign: "center", fontWeight: "bold" }}>
                          {total}
                        </h5>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3} />
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />

            <Modal
              title={
                editingKey2 !== null
                  ? t("EditeInvestment")
                  : t("CreateInvestment")
              }
              visible={isModalVisible2}
              onOk={handleOk2}
              onCancel={handleCancel2}
              okText={
                editingKey2 !== null ? t["Savehanges"] : t("CreateInvestment")
              }
              cancelText={t("cancel")}
            >
              <Form form={form2} layout="vertical" name="form_in_modal2">
                <Form.Item
                  name="description"
                  label={t("PlannedInvestment")}
                  rules={[
                    {
                      required: true,
                      message: t("EnterPlannedInvestment"),
                    },
                  ]}
                >
                  <Input maxLength={100} />
                </Form.Item>

                <Form.Item
                  name="investmentCategoryId"
                  label={t("InvestmentCategories")}
                  rules={[
                    {
                      required: true,
                      message: t("EnterInvestmentCategories"),
                    },
                  ]}
                >
                  <Select>
                    {InvestismentCategory.map((inv) => (
                      <Option key={inv.id} value={inv.id}>
                        {inv.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="estimate"
                  label="Estimation"
                  rules={[
                    {
                      type: "number",
                      required: true,
                      message: "Enterestimation",
                    },
                  ]}
                >
                  <InputNumber min={0} />
                </Form.Item>
              </Form>
            </Modal>
          </Panel>
        </Collapse>

        <Collapse
          defaultActiveKey={["9"]}
          accordion
          style={{ marginBottom: "20px" }}
        >
          <Panel
            header={
              <Title level={4} style={{ fontSize: "16px" }}>
                {t("NewRecurrentExpenseEntry")}
              </Title>
            }
            key="9"
            style={{ fontSize: "10px" }}
          >
            <Space
              style={{
                marginBottom: 15,
                width: "100%",
                justifyContent: "flex-end",
                display: "flex",
              }}
            >
              <Button
                type="primary"
                ghost
                onClick={() => setIsModalVisible3(true)}
                style={{
                  minWidth: "150px",
                  maxWidth: "100%",
                }}
              >
                {t("Addexpense")}
              </Button>
            </Space>
            <Table
              columns={columns3}
              dataSource={data3}
              rowKey={(record) => record.id}
              summary={(pageData) => {
                const totals = calculateTotals(pageData);
                return (
                  <Table.Summary>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}>
                        <h5 style={{ textAlign: "center", fontWeight: "bold" }}>
                          Total {Company.currencies[0].shortLabel}
                        </h5>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1}>
                        <h5 style={{ textAlign: "center", fontWeight: "bold" }}>
                          {totals.firstYear}
                        </h5>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        <h5 style={{ textAlign: "center", fontWeight: "bold" }}>
                          {totals.secondYear}
                        </h5>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3}>
                        <h5 style={{ textAlign: "center", fontWeight: "bold" }}>
                          {totals.thirdYear}
                        </h5>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4} />
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />

            {/* <Modal
              title={t("Addexpense")}
              visible={isModalVisible3}
              onOk={handleOk3}
              onCancel={handleCancel3}
              okText={editingKey3 !== null ? t["Savehanges"] : t("Addexpense")}
              cancelText={t("cancel")}
            > */}
            <Modal
              title={editingKey3 !== null ? t("Editexpense") : t("Addexpense")}
              visible={isModalVisible3}
              onOk={handleOk3}
              onCancel={handleCancel3}
              okText={editingKey3 !== null ? t["Savehanges"] : t("Addexpense")}
              cancelText={t("cancel")}
            >
              <Form form={form3} layout="vertical" name="form_in_modal3">
                <Form.Item
                  name="expenseType"
                  label={t("Expensetype")}
                  rules={[
                    {
                      required: true,
                      message: t("Entertype"),
                    },
                  ]}
                >
                  <Input maxLength={100} />
                </Form.Item>

                <Form.Item
                  name="firstYearExpense"
                  label={t("year1")}
                  rules={[
                    {
                      type: "number",
                      required: true,
                      message: t("Enteryear"),
                    },
                  ]}
                >
                  <InputNumber min={0} />
                </Form.Item>
                <Form.Item
                  name="secondYearExpense"
                  label={t("year2")}
                  rules={[
                    {
                      type: "number",
                      required: true,
                      message: t("Enteryear"),
                    },
                  ]}
                >
                  <InputNumber min={0} />
                </Form.Item>
                <Form.Item
                  name="thirdYearExpense"
                  label={t("year3")}
                  rules={[
                    {
                      type: "number",
                      required: true,
                      message: t("Enteryear"),
                    },
                  ]}
                >
                  <InputNumber min={0} />
                </Form.Item>
              </Form>
            </Modal>
          </Panel>
        </Collapse>

        {/* Bouton de soumission */}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("Savehanges")}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default ProjectFileDetails;
